import axios from "axios";
import {Menu} from "../Menu/menu";
import {DashboardLayoutComponent} from "@syncfusion/ej2-react-layouts";
import ModalPopup from "../Modal/modal";
import {CreateChartView} from "../Modal/createChart";
import {CreateNewModal} from "../Modal/createNewDashboard";
import {RenderViewCustomChart} from "../charts/CustomChartWindow/renderViewCustomChart";
import {PropagateDashboardView} from "../Modal/propagateModale";
import {ConfirmModalView} from "../Modal/confirmModal";
import {convertJsonChart, convertJsonPage} from "../charts/CustomChartWindow/utilities";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {LoadingPage} from "../Common/loadingPage";
import {InputRenaming} from "./inputRenaming";
import {BoardListView} from "./boardListView";
import {ChartBlock} from "./chartBlock";

function RenderDashBoardView() {
    const dashboard = useRef()
    const [data, setData] = useState([])
    const [modalAddChartCustom, setmodalAddChartCustom] = useState(false)
    const [modalAddDashboard, setModalAddDashboard] = useState(false)
    const [modalCustomChart, setModalCustomChart] = useState(false)
    const [dashboardsValue, setDashboards] = useState([])
    const [dataSourceDropdown, setdataSourceDropdown] = useState([])
    const selectedChart = useRef(null)
    const [selectedDashBoard, setSelectedDashBoard] = useState("")
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModalDashboard, setConfirmModalDashboard] = useState(false)
    const [propagateModal, setPropagateModal] = useState(false)
    const {t, i18n} = useTranslation()
    const [defaultGroup, setDefaultGroup] = useState(null)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [initBlocks, setInitBlocks] = useState(true)
    const [lockScreen, setLockSreen] = useState('no_tablet')
    const resizing = useRef(false) //setResizing] = useState(false)
    const dragging = useRef(false) //setDragging] = useState(false)


    const dashboards = useRef()

    useEffect(async () => {
        if (window.localStorage.getItem('token') == "null") {
            navigate("/login")
        }
        if (defaultGroup === null)
            return
        let data = null
        await axios.get(`/eData/dashboard/get/dashboards/false`).then(res => data = res.data)
        data.forEach(d => {
            d.isActive = false
            d.block = []
        })

        if (data.length > 0) {
            data[0].isActive = true
        }
        if (!initBlocks)
            await addChart(data, dashboard, openModalConfirmDelete, editablePanel, true, setIsLoading, cancelEdit)

        dashboards.current = data
        setDashboards([...dashboards.current])
        handleDropdownDashBoard(null, data[0]?.id)
    }, [defaultGroup])

    let cancelEdit = async (jsonChart) => {
        let board = dashboards.current.find(b => b.isActive)
        let blockExistIndex = board.block.findIndex(b => b.jsonChart.id == jsonChart.id)
        let block = await getBlock(board.block[blockExistIndex].id)
        dashboard.current.removePanel(board.block[blockExistIndex].id.toString())
        board.block[blockExistIndex] = block
        addBlock(block, dashboard, openModalConfirmDelete, editablePanel, board.isOwner, cancelEdit)
        setDashboards([...dashboards.current])
    }

    let deletePanel = async (id) => {
        let board = dashboards.current.find(b => b.isActive)
        let err = false
        await axios.delete(`/eData/dashboard/delete/block/${id}`, {
            doNotCatch: true
        }).catch(error => {
            if (error.response.status == 403)
                alert(t("dashboard:error", {context: "not_owner"}))
            err = true
        })
        if (err)
            return
        board.block.splice(board.block.findIndex(b => b.id == id), 1)
        dashboard.current.removePanel(id.toString())
    }
    let editablePanel = (e) => {
        let board = dashboards.current.find(b => b.isActive)
        let block = board.block.find(b => b.id == e.target.id)
        selectedChart.current = {...block}
        setModalCustomChart(true)
    }


    let addDashBoard = async (name) => {
        let findDashBoard = dashboards.current.find(d => d.name == name)
        if (findDashBoard != undefined)
            return alert("Erreur : ce tableau de bord existe déjà")
        let nameUser = ""
        await axios.get("/helpers/operators/NameOfCurrent/Full").then(res => nameUser = res.data)
        let err = false
        let dashboardActif = dashboards.current.find(d => d.isActive)
        if(dashboardActif != undefined)
            dashboardActif = false
        let newDashboard = {
            id: 0,
            name: name,
            block: [],
            isActive: true,
            owner: nameUser,
            isOwner: true
        }
        if (dashboards.current.length == 0)
            newDashboard.isActive = true
        dashboards.current.push(newDashboard)
        let body = {
            name: name
        }
        await axios.post(`/eData/dashboard/new/dashboard`, body).then(res => newDashboard.id = res.data).catch(error => {
            err = true
        })
        if (err)
            return
        setDashboards([...dashboards.current])
        handleDropdownDashBoard(null, newDashboard.id)
    }

    let deleteDashboard = async () => {
        let indexDb = dashboards.current.findIndex(b => b.isActive)
        if (indexDb == 0) {
            if (dashboards.current.length != 1) {
                dashboards.current[1].isActive = true
            }
        } else
            dashboards.current[indexDb - 1].isActive = true
        await axios.delete(`/eData/dashboard/delete/dashboard/${dashboards.current[indexDb].id}`)
        dashboards.current.splice(indexDb, 1)
        if(dashboards.current.length > 0) {
            handleDropdownDashBoard(null, dashboards.current.find(b => b.isActive).id);
        }
        setDashboards([...dashboards.current])
    }

    let add = async (jsonChart, dataQuery, pages, pos) => {
        let board = dashboards.current.find(b => b.isActive)
        let newBlock = {
            id: jsonChart.id,
            jsonChart: jsonChart,
            pages: pages,
            dataQuery: dataQuery,
            panel: {sizeX: pos.sizeX, sizeY: pos.sizeY, row: pos.row, col: pos.col}
        }
        let blockExistIndex = board.block.findIndex(b => b.jsonChart?.id == jsonChart.id)
        if (blockExistIndex == -1) {
            board.block.push(newBlock)
            let query = {
                dashboard: board.id,
                chart: jsonChart.id,
                position: {
                    x: pos.col,
                    y: pos.row
                },
                size: {
                    x: pos.sizeX,
                    y: pos.sizeY
                }
            }
            await axios.post(`/eData/dashboard/new/block`, query).then(res => newBlock.id = res.data)
            await addChart(dashboards.current, dashboard, openModalConfirmDelete, editablePanel, false, setIsLoading, cancelEdit)
        } else {
            dashboard.current.removePanel(board.block[blockExistIndex].id.toString())
            board.block[blockExistIndex].jsonChart = newBlock.jsonChart
            board.block[blockExistIndex].pages = newBlock.pages
            board.block[blockExistIndex].dataQuery = newBlock.dataQuery
            addBlock(board.block[blockExistIndex], dashboard, openModalConfirmDelete, editablePanel, board.isOwner, cancelEdit)
        }
        setDashboards([...dashboards.current])

    }
    let resizeContent = async (e) => {
        let activeBoard = dashboards.current.find(d => d.isActive)
        let block = activeBoard.block.find(b => b.id == e.element.id)
        let panel = setPanelPropperties(block.id, dashboard)

        block.panel = resizeNumberBlock(block, panel)

        dashboard.current.removePanel(block.id.toString())
        addBlock(block, dashboard, openModalConfirmDelete, editablePanel, activeBoard.isOwner, cancelEdit)
        setDashboards([...dashboards.current])

        saveBlocksPosition(activeBoard, dashboard)
    }

    let openPropagateModal = () => {
        setPropagateModal(true)
    }

    let openQueryModal = () => {
        setmodalAddChartCustom(true)
    }
    let openDashboardModal = () => {
        setModalAddDashboard(true)
    }

    let openModalConfirmDelete = (id) => {
        setConfirmModal({show: true, id: id})
    }

    let openModalConfirmDeleteDashboard = (e) => {
        setConfirmModalDashboard({show: true, id: e.target.id})
    }

    let handleDropdownDashBoard = async (e, id) => {
        let idDashBoard = id != undefined ? id : e.value[0]
        if (idDashBoard == null)
            return
        let board = dashboards.current.find(b => b.id == idDashBoard)
        dashboards.current.forEach(b => b.isActive = false)
        board.isActive = true
        dashboard.current.removeAll()
        await addChart(dashboards.current, dashboard, openModalConfirmDelete, editablePanel, true, setIsLoading, cancelEdit)
        setSelectedDashBoard(board.id)
        setInitBlocks(false)
        setDashboards([...dashboards.current])
    }

    useEffect(() => {
        let dataDp = []
        dashboardsValue.forEach(d => {
            let txt = `${d.name} [${d.owner}]`
            dataDp.push({id: d.id, name: txt})
        })
        setdataSourceDropdown(dataDp)
        if (dashboardsValue.length == 0)
            return
        setSelectedDashBoard(dashboards.current.find(d => d.isActive)?.name)
        document.title = t("default:dashboard") + " — " + dashboardsValue.find(b => b.isActive).name
        let button = document.getElementById("lockButton")
        if (button === null) {
            setLockSreen('no_tablet')
            return
        }
        if (getComputedStyle(button).display == "flex" && lockScreen === 'no_tablet') {
            setLockSreen('tablet_false')
        }
        if (getComputedStyle(button).display == "none") {
            setLockSreen('no_tablet')
        }
    }, [dashboardsValue])


    let handletestdrag = async (e) => {
        let activeBoard = dashboards.current.find(d => d.isActive)
        saveBlocksPosition(activeBoard, dashboard)
        setDashboards([...dashboards.current])
    }

    let handleLockScreen = (e) => {
        let result = e.target.checked ? "tablet_true" : "tablet_false"
        setLockSreen(result)
    }

    useEffect(() => {
        if (lockScreen === 'no_tablet') {
            document.body.classList.remove("overflow-hidden")
            dashboard.current.allowResizing = resizing.current = dashboardsValue.find(b => b.isActive) == undefined ? false : dashboardsValue.find(b => b.isActive).isOwner
            dashboard.current.allowDragging = dashboardsValue.find(b => b.isActive) == undefined ? false : dashboardsValue.find(b => b.isActive).isOwner
        }
        if (lockScreen === "tablet_true") {
            document.body.classList.add("overflow-hidden")
            dashboard.current.allowResizing = true
            dashboard.current.allowDragging = true
        }
        if (lockScreen === "tablet_false") {
            document.body.classList.remove("overflow-hidden")
            dashboard.current.allowResizing = false
            dashboard.current.allowDragging = false
        }
    }, [lockScreen, dashboardsValue])


    let validateName = (e) => {
        let dashboardActive = dashboards.current.find(d => d.isActive)
        dashboardActive.name = e.target.value
        let txt = {
            name: e.target.value
        }
        axios.put("eData/Dashboard/rename/Dashboard/" + dashboardActive.id, txt)
        setDashboards([...dashboards.current])
    }

    return (
        <>
            <div id="ListBox-Container"></div>
            <Menu group={defaultGroup} setGroup={setDefaultGroup} dashboard={true} general={true} caract={true} controlCard={true}
                  controleCard={true} lifesheet={true} tableau={true}/>
            <div className="dashboard-banner padding-constraint d-flex align-items-center">
                <InputRenaming
                    isOwner={dashboardsValue.find(b => b.isActive)?.isOwner}
                    name={dashboardsValue.find(b => b.isActive)?.name}
                    functionValidate={validateName}/>
                <div className="dashboard-banner-mobile">
                <button onClick={openModalConfirmDeleteDashboard}
                        className={dashboardsValue.find(b => b.isActive) == undefined ? "d-flex align-items-center button-delete-dashboard disabled" : dashboardsValue.find(b => b.isActive).isOwner ? "d-flex align-items-center button-delete-dashboard" : "d-flex align-items-center button-delete-dashboard disabled"}
                        title={t("dashboard:delete")}>
                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                        <path
                            d="m22.3 9.5h-14.6c-1 0-1.9 0.8-1.9 1.9v16.6c0 1 0.8 1.9 1.9 1.9h14.6c1 0 1.9-0.8 1.9-1.9v-16.7c0-1-0.8-1.8-1.9-1.8zm-9.5 17c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8zm7.2 0c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8z"/>
                        <path className="trash-animation"
                              d="m27.3 4.8h-4.9v-3.3c0-0.7-0.6-1.2-1.2-1.2h-12.3c-0.7 0-1.2 0.6-1.2 1.2v3.4h-5c-0.4 0-0.7 0.3-0.7 0.7v1.4c0 0.4 0.3 0.7 0.7 0.7h24.5c0.4 0 0.7-0.3 0.7-0.7v-1.5c0-0.4-0.3-0.7-0.6-0.7zm-16.9 0c-0.4 0-0.6-0.3-0.6-0.6v-1.2c0-0.4 0.3-0.6 0.6-0.6h9.1c0.4 0 0.6 0.3 0.6 0.6v1.2c0 0.4-0.3 0.6-0.6 0.6h-9.1z"/>
                    </svg>
                </button>
                <button
                    className={dashboardsValue.find(b => b.isActive) == undefined ? "d-flex align-items-center button-delete-dashboard disabled" : dashboardsValue.find(b => b.isActive).isOwner ? "d-flex align-items-center button-delete-dashboard" : "d-flex align-items-center button-delete-dashboard disabled"}
                    onClick={openPropagateModal}
                    title={t("dashboard:propagate")}>
                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                        <path
                            d="m1.3 13.4h8c0.7 0 1.4-0.6 1.4-1.4v-10.6c0-0.7-0.6-1.4-1.4-1.4h-8c-0.8 0-1.4 0.6-1.4 1.4v10.6c0 0.7 0.6 1.4 1.4 1.4zm-1.4 9.2c0 0.8 0.6 1.4 1.4 1.4h8c0.7 0 1.4-0.6 1.4-1.4v-5.4c0-0.7-0.6-1.4-1.4-1.4h-8c-0.8 0.1-1.4 0.6-1.4 1.4v5.4zm13.4 0c0 0.7 0.6 1.4 1.4 1.4h8c0.7 0 1.4-0.6 1.4-1.4v-9.3c0-0.7-0.6-1.4-1.4-1.4h-8c-0.7 0-1.4 0.6-1.4 1.4v9.3zm1.3-13.2h8c0.7 0 1.4-0.6 1.4-1.4v-6.6c0-0.8-0.6-1.4-1.4-1.4h-8c-0.7 0-1.4 0.6-1.4 1.4v6.6c0.1 0.7 0.7 1.4 1.4 1.4z"/>
                        <path className="propager-animation"
                              d="m21.4 31.2c-0.9 0-1.7-0.4-2.3-1-0.5-0.5-0.8-1.3-0.8-2.2 0-0.8 0.4-1.6 1-2.2l1.1-1h-12.4c-1.7 0-3-1.3-3-3s1.3-3 3-3h12.5l-1.1-1c-0.6-0.5-1-1.3-1-2.1s0.3-1.6 0.8-2.2c0.6-0.7 1.4-1 2.2-1s1.5 0.3 2.1 0.8l6.8 6.4c0.7 0.7 1 1.5 1 2.4 0 0.8-0.4 1.6-1 2.1l-6.7 6.3c-0.6 0.4-1.4 0.7-2.2 0.7z"/>
                        <path className="default-white propager-animation"
                              d="m21.4 13.6c0.5 0 0.9 0.2 1.3 0.5l6.8 6.3c0.8 0.8 0.8 2.1 0 2.8l-6.8 6.3c-0.3 0.3-0.8 0.5-1.3 0.5s-1-0.2-1.4-0.6c-0.7-0.7-0.7-2 0.1-2.6l3.3-3.1h-15.4c-1 0-1.8-0.8-1.8-1.8s0.8-1.8 1.8-1.8h15.5l-3.3-3.1c-0.8-0.7-0.8-1.8-0.1-2.6 0.3-0.6 0.8-0.8 1.3-0.8m0-2.4c-1.2 0-2.4 0.5-3.2 1.5-0.7 0.8-1.1 1.9-1.1 3 0 0.7 0.2 1.3 0.5 1.9h-9.6c-2.4 0-4.2 1.9-4.2 4.2s1.8 4.2 4.2 4.2h9.7c-0.3 0.6-0.5 1.2-0.5 1.9 0 1.1 0.4 2.3 1.1 3.1 0.8 0.9 1.9 1.4 3.1 1.4 1.1 0 2.2-0.4 2.9-1.2l6.7-6.3c0.8-0.7 1.3-1.8 1.4-3 0-1.2-0.4-2.4-1.3-3.3l-6.8-6.3c-0.9-0.7-1.9-1.1-2.9-1.1z"/>
                    </svg>
                </button>
                </div>
                <BoardListView handleDropdownDashBoard={handleDropdownDashBoard}
                               dataSource={dataSourceDropdown}
                               dashboards={dashboardsValue}
                               value={selectedDashBoard}
                               setDashboards={setDashboards}

                />

                <div className="dashboard-banner-mobile">
                <button onClick={openDashboardModal} className="d-flex align-items-center button-delete-dashboard"
                        title={t("dashboard:add")}>
                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                        <path
                            d="m1.3 13.4h8c0.7 0 1.4-0.6 1.4-1.4v-10.6c0-0.7-0.6-1.4-1.4-1.4h-8c-0.8 0-1.4 0.6-1.4 1.4v10.6c0 0.7 0.6 1.4 1.4 1.4zm-1.4 9.2c0 0.8 0.6 1.4 1.4 1.4h8c0.7 0 1.4-0.6 1.4-1.4v-5.4c0-0.7-0.6-1.4-1.4-1.4h-8c-0.8 0.1-1.4 0.6-1.4 1.4v5.4zm13.4 0c0 0.7 0.6 1.4 1.4 1.4h8c0.7 0 1.4-0.6 1.4-1.4v-9.3c0-0.7-0.6-1.4-1.4-1.4h-8c-0.7 0-1.4 0.6-1.4 1.4v9.3zm1.3-13.2h8c0.7 0 1.4-0.6 1.4-1.4v-6.6c0-0.8-0.6-1.4-1.4-1.4h-8c-0.7 0-1.4 0.6-1.4 1.4v6.6c0.1 0.7 0.7 1.4 1.4 1.4z"/>
                        <path className="ajouter-animation"
                              d="m26 16.2h-3.8v-3.8c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v3.8h-3.8c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4h3.8v3.8c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4v-3.8h3.8c1.3 0 2.4-1.1 2.4-2.4s-1-2.4-2.4-2.4z"/>
                        <path className="default-white ajouter-animation"
                              d="m19.8 10c1.3 0 2.4 1.1 2.4 2.4v3.8h3.8c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4h-3.8v3.8c0 1.3-1.1 2.4-2.4 2.4s-2.4-1.1-2.4-2.4v-3.8h-3.8c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4h3.8v-3.8c0-1.3 1.1-2.4 2.4-2.4m0-4c-3.5 0-6.3 2.8-6.4 6.2-3.5 0.1-6.2 2.9-6.2 6.4s2.8 6.3 6.2 6.4c0.1 3.5 2.9 6.2 6.4 6.2s6.3-2.8 6.4-6.2c3.5-0.1 6.2-2.9 6.2-6.4s-2.8-6.3-6.2-6.4c-0.1-3.4-2.9-6.2-6.4-6.2z"/>
                    </svg>
                </button>
                <button onClick={openQueryModal}
                        className={dashboardsValue.find(b => b.isActive) == undefined ? "d-flex align-items-center button-delete-dashboard disabled" : dashboardsValue.find(b => b.isActive).isOwner ? "d-flex align-items-center button-delete-dashboard" : "d-flex align-items-center button-delete-dashboard disabled"}
                        title={t("dashboard:block", {context: "add"})}>
                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                        <path
                            d="M20.9,24H3.1C1.4,24,0,22.6,0,20.9V3.1C0,1.4,1.4,0,3.1,0h17.8C22.6,0,24,1.4,24,3.1v17.8  C24,22.6,22.6,24,20.9,24z"/>
                        <path className="ajouter-animation"
                              d="m26 16.2h-3.8v-3.8c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v3.8h-3.8c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4h3.8v3.8c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4v-3.8h3.8c1.3 0 2.4-1.1 2.4-2.4s-1-2.4-2.4-2.4z"/>
                        <path className="default-white ajouter-animation"
                              d="m19.8 10c1.3 0 2.4 1.1 2.4 2.4v3.8h3.8c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4h-3.8v3.8c0 1.3-1.1 2.4-2.4 2.4s-2.4-1.1-2.4-2.4v-3.8h-3.8c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4h3.8v-3.8c0-1.3 1.1-2.4 2.4-2.4m0-4c-3.5 0-6.3 2.8-6.4 6.2-3.5 0.1-6.2 2.9-6.2 6.4s2.8 6.3 6.2 6.4c0.1 3.5 2.9 6.2 6.4 6.2s6.3-2.8 6.4-6.2c3.5-0.1 6.2-2.9 6.2-6.4s-2.8-6.3-6.2-6.4c-0.1-3.4-2.9-6.2-6.4-6.2z"/>
                    </svg>
                </button>
                </div>
                {dashboardsValue.find(b => b.isActive) == undefined ? false : dashboardsValue.find(b => b.isActive).isOwner ?
                    <div id={"lockButton"} className="d-flex align-items-center button-lock"
                         title="Verrouiller ou deverrouiller le tableau de bord">
                        <input onChange={handleLockScreen} id="button-lock" type="checkbox"
                               className="button-lock-input"/>
                        <label htmlFor="button-lock">
                            <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <path
                                    d="m24 29.8h-18c-1.4 0-2.5-1.1-2.5-2.5v-12.9c0-1.4 1.1-2.5 2.5-2.5h18c1.4 0 2.5 1.1 2.5 2.5v12.9c0 1.3-1.1 2.5-2.5 2.5z"/>
                                <path className="lock-animation"
                                      d="M22.3,12h-3.3V7.6c0-2.2-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1v4.3H7.7V7.6c0-4,3.3-7.3,7.3-7.3s7.3,3.3,7.3,7.3V12z"/>
                            </svg>
                        </label>
                    </div> : null}
            </div>
            {isLoading ? <LoadingPage/> : null}
            <div className="margin-constraint dashboard-container">
                <div className='control-section'>
                    <DashboardLayoutComponent
                        allowResizing={resizing.current}
                        allowDragging={dragging.current}
                        dragStop={handletestdrag} resizeStop={(e) => resizeContent(e)}
                        ref={dashboard}
                        id="default_dashboard"
                        columns={10} rows={10} cellSpacing={[20, 20]}/>
                    {modalAddChartCustom ?
                        <ModalPopup id={"addBlock"}
                                    propsAdd={{
                                        addChart: add,
                                        cancelEdit: cancelEdit,
                                        listBlocks: dashboards.current.find(b => b.isActive).block
                                    }}
                                    closeCLicked={false}
                                    content={CreateChartView}
                                    setShowModal={setmodalAddChartCustom}/> : null}
                    {modalAddDashboard ?
                        <ModalPopup propsAdd={{add: addDashBoard, txt: t("dashboard:name")}} closeCLicked={false}
                                    content={CreateNewModal}
                                    setShowModal={setModalAddDashboard}/> : null}
                    {modalCustomChart ?
                        <ModalPopup
                            propsAdd={{chart: selectedChart.current, addChart: add, cancelEdit: cancelEdit}}
                            closeCLicked={false}
                            content={RenderViewCustomChart}
                            setShowModal={setModalCustomChart}/> : null}
                    {propagateModal ?
                        <ModalPopup propsAdd={{idDashboard: dashboards.current.find(d => d.isActive).id}}
                                    closeCLicked={false}
                                    content={PropagateDashboardView}
                                    setShowModal={setPropagateModal}/> : null}
                    {confirmModal.show ?
                        <ModalPopup propsAdd={{
                            function: deletePanel,
                            id: confirmModal.id,
                            txt: t("dashboard:block", {context: "delete_confirmation"}),
                            title: t("default:deletion")
                        }} closeCLicked={true}
                                    content={ConfirmModalView}
                                    setShowModal={setConfirmModal}/> : null}
                    {confirmModalDashboard.show ?
                        <ModalPopup propsAdd={{
                            function: deleteDashboard,
                            id: confirmModal.id,
                            txt: t("dashboard:delete", {context: "confirmation"}),
                            title: t("default:deletion")
                        }} closeCLicked={true}
                                    content={ConfirmModalView}
                                    setShowModal={setConfirmModalDashboard}/> : null}
                </div>
            </div>
        </>
    )
}

async function addChart(dashboards, dashboard, deletePanel, editablePanel, edit, setLoading, cancelEdit) {
    setLoading(true)
    let dashboardActive = dashboards.find(board => board.isActive)
    if (dashboardActive == undefined) {
        dashboard.current.removeAll()
        return setLoading(false)
    }

    let data = null
    await axios.get(`/eData/dashboard/get/blocks/${dashboardActive.id}`).then(res => data = res.data)
    let blocksTemp = []
    data.forEach(block => {
        blocksTemp.push(reformateBlock(block))
    })
    dashboardActive.block = blocksTemp
    dashboard.current.removeAll()
    dashboardActive.block.forEach(b => {
        addBlock(b, dashboard, deletePanel, editablePanel, dashboardActive.isOwner, cancelEdit)
    })
    setLoading(false)
    if (!edit)
        saveBlocksPosition(dashboardActive, dashboard)
}

function addBlock(b, dashboard, deletePanel, editablePanel, editable, cancelEdit) {
    let Chart = () => {
        return (
            <ChartBlock deletePanel={deletePanel}
                        editablePanel={editablePanel}
                        editable={editable}
                        cancelEdit={cancelEdit}
                        block={b}
            />
        )
    }
    let panel = {
        'id': b.id.toString(),
        'sizeX': b.panel.sizeX,
        'sizeY': b.panel.sizeY,
        'row': b.panel.row,
        'col': b.panel.col,
        'header': "",
        content: Chart
    }
    dashboard.current.addPanel(panel)
}

async function getBlock(id) {
    let data = null
    await axios.get(`/eData/dashboard/get/block/${id}`).then(res => data = res.data)
    return reformateBlock(data)
}

function reformateBlock(data) {
    let newBlock = {
        id: data.id,
        jsonChart: convertJsonChart(data.chart),
        pages: convertJsonPage(data.chart),
        //dataQuery: data.chart.filter,
        snapshots: data.snapshots,
        panel: {sizeX: data.size.x, sizeY: data.size.y, row: data.position.y, col: data.position.x}
    }
    return newBlock
}

function setPanelPropperties(id, dashboard) {
    let panel = dashboard.current.panels.find(panel => panel.id == id)
    return panel.properties
}

async function saveBlocksPosition(activeBoard, dashboard) {
    activeBoard.block.forEach(async b => {
        b.panel = setPanelPropperties(b.id, dashboard)
        let query = {
            position: {
                x: b.panel.col,
                y: b.panel.row
            },
            size: {
                x: b.panel.sizeX,
                y: b.panel.sizeY
            }
        }
        await axios.put(`/eData/dashboard/edit/block/${b.id}`, query)
    })
}

function resizeNumberBlock(block, panel) {
    if (block.jsonChart.type == "Number") {
        if (window.screen.width < 800) {
            if (panel.sizeX < 3)
                panel.sizeX = 3
            if (panel.sizeY < 3)
                panel.sizeY = 3
        }
        if (panel.sizeX == 1)
            panel.sizeX = 2
        if (panel.sizeY == 1)
            panel.sizeY = 2
    }
    return panel
}

export {RenderDashBoardView, getBlock}
const summarySVG = <svg enableBackground="new 0 0 45.8 36" version="1.1" viewBox="0 0 45.8 36">
        <path d="m29.5 8.2h8.2c0.9 0 1.6 0.7 1.6 1.6s-0.7 1.6-1.6 1.6h-8.2c-0.9 0-1.6-0.7-1.6-1.6-0.1-0.9 0.7-1.6 1.6-1.6z"/>
        <path d="m29.5 16.4h8.2c0.9 0 1.6 0.7 1.6 1.6s-0.7 1.6-1.6 1.6h-8.2c-0.9 0-1.6-0.7-1.6-1.6-0.1-0.9 0.7-1.6 1.6-1.6z"/>
        <path d="m29.5 24.5h8.2c0.9 0 1.6 0.7 1.6 1.6s-0.7 1.6-1.6 1.6h-8.2c-0.9 0-1.6-0.7-1.6-1.6-0.1-0.8 0.7-1.6 1.6-1.6z"/>
        <path d="M42.5,0H3.3C1.5,0,0,1.5,0,3.3v29.5C0,34.5,1.5,36,3.3,36h39.3c1.8,0,3.3-1.5,3.3-3.3V3.3   C45.8,1.5,44.4,0,42.5,0z M5,3.3h14.6c0.9,0,1.7,0.8,1.7,1.7v26c0,0.9-0.8,1.7-1.7,1.7H5C4,32.7,3.3,32,3.3,31V5C3.3,4,4,3.3,5,3.3   z M24.5,31V5c0-0.9,0.8-1.7,1.7-1.7h14.6c0.9,0,1.7,0.8,1.7,1.7v26c0,0.9-0.8,1.7-1.7,1.7H26.3C25.3,32.7,24.5,32,24.5,31z"/>
    </svg>

const gestionSVG = <svg enableBackground="new 0 0 38.4 36" version="1.1" viewBox="0 0 38.4 36">  
        <path className="st0" d="m0 3.6c0-2 1.6-3.6 3.6-3.6h26.4c2 0 3.6 1.6 3.6 3.6v28.8c0 2-1.6 3.6-3.6 3.6h-26.4c-2 0-3.6-1.6-3.6-3.6v-28.8zm3.6-1.2c-0.7 0-1.2 0.5-1.2 1.2v28.8c0 0.7 0.5 1.2 1.2 1.2h26.4c0.7 0 1.2-0.5 1.2-1.2v-28.8c0-0.7-0.5-1.2-1.2-1.2h-26.4z"/>
        <path className="st0" d="m37.2 6c0.7 0 1.2 0.5 1.2 1.2v25.2c0 2-1.6 3.6-3.6 3.6h-7.2v-2.4h7.2c0.7 0 1.2-0.5 1.2-1.2v-25.2c0-0.7 0.5-1.2 1.2-1.2z"/>
        <path d="m4.8 6h24v4.8h-24v-4.8zm0 7.2h9.6v7.2h-9.6v-7.2zm0 9.6h9.6v2.4h-9.6v-2.4zm0 4.8h9.6v2.4h-9.6v-2.4zm12-14.4h4.8v2.4h-4.8v-2.4zm7.2 0h4.8v2.4h-4.8v-2.4zm-7.2 4.8h4.8v2.4h-4.8v-2.4zm7.2 0h4.8v2.4h-4.8v-2.4zm-7.2 4.8h4.8v2.4h-4.8v-2.4zm7.2 0h4.8v2.4h-4.8v-2.4zm-7.2 4.8h4.8v2.4h-4.8v-2.4zm7.2 0h4.8v2.4h-4.8v-2.4z"/>
    
    </svg>

const etalonSVG = <svg enableBackground="new 0 0 33.4 36" version="1.1" viewBox="0 0 33.4 36">
        <path d="m2.6 33.4h5.8v-5.8h-5.8v5.8zm7 0h6.4v-5.8h-6.4v5.8zm-7-7h5.8v-6.4h-5.8v6.4zm7 0h6.4v-6.4h-6.4v6.4zm-7-7.8h5.8v-5.8h-5.8v5.8zm14.8 14.8h6.4v-5.8h-6.4v5.8zm-7.8-14.8h6.4v-5.8h-6.4v5.8zm15.5 14.8h5.8v-5.8h-5.8v5.8zm-7.7-7h6.4v-6.4h-6.4v6.4zm-7.1-17.4v-5.8c0-0.4-0.3-0.6-0.6-0.6h-1.3c-0.4 0-0.7 0.3-0.7 0.6v5.8c0 0.4 0.3 0.6 0.6 0.6h1.3c0.4 0 0.7-0.2 0.7-0.6zm14.8 17.4h5.8v-6.4h-5.8v6.4zm-7.7-7.8h6.4v-5.8h-6.4v5.8zm7.7 0h5.8v-5.8h-5.8v5.8zm0.6-9.6v-5.8c0-0.4-0.3-0.6-0.6-0.6h-1.3c-0.4 0-0.6 0.3-0.6 0.6v5.8c0 0.4 0.3 0.6 0.6 0.6h1.3c0.3 0 0.6-0.2 0.6-0.6zm7.7-1.3v25.7c0 1.4-1.2 2.6-2.6 2.6h-28.2c-0.7 0-1.3-0.3-1.8-0.8-0.5-0.4-0.8-1.1-0.8-1.8v-25.7c0-0.7 0.3-1.3 0.8-1.8s1.1-0.8 1.8-0.8h2.6v-1.9c0-0.9 0.3-1.7 0.9-2.3s1.4-0.9 2.3-0.9h1.3c0.9 0 1.7 0.3 2.3 0.9s1 1.4 0.9 2.3v1.9h7.7v-1.9c0-0.9 0.3-1.7 0.9-2.3s1.4-1 2.3-0.9h1.3c0.9 0 1.7 0.3 2.3 0.9s1 1.4 0.9 2.3v1.9h2.6c0.7 0 1.3 0.3 1.8 0.8s0.7 1.1 0.7 1.8z"/>
    </svg>

const anomalySVG = <svg enableBackground="new 0 0 38.8 36" version="1.1" viewBox="0 0 38.8 36">
        <path d="m22.2 29.7v-4.1c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2h-4.2c-0.2 0-0.4 0.1-0.5 0.2s-0.2 0.3-0.2 0.5v4.1c0 0.2 0.1 0.4 0.2 0.5s0.3 0.2 0.5 0.2h4.2c0.2 0 0.4-0.1 0.5-0.2s0.2-0.3 0.2-0.5zm-0.1-8 0.4-9.9c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.5-0.2h-4.8c-0.2 0-0.4 0.1-0.5 0.2s-0.2 0.3-0.2 0.5l0.4 9.9c0 0.1 0.1 0.3 0.2 0.4 0.2 0.1 0.3 0.1 0.5 0.1h4c0.2 0 0.4 0 0.5-0.1 0.1-0.3 0.2-0.4 0.2-0.5zm-0.3-20.3 16.6 30.5c0.5 0.8 0.5 1.9 0 2.7-0.5 0.9-1.4 1.4-2.4 1.4h-33.2c-1 0-1.9-0.5-2.4-1.4-0.5-0.8-0.5-1.9 0-2.7l16.6-30.5c0.2-0.4 0.6-0.8 1-1.1 0.9-0.5 1.9-0.5 2.8 0 0.4 0.3 0.8 0.7 1 1.1z"/>
    </svg>

const sortascSVG = <svg enableBackground="new 0 0 30 26" version="1.1" viewBox="0 0 30 26" className="sort-svg">
        <path className="st0" d="m15 25.4h-11.7c-1.8 0-2.9-1.9-2-3.5l5.8-10.1 5.9-10.1c0.9-1.5 3.1-1.5 4 0l11.6 20.2c0.9 1.5-0.2 3.5-2 3.5h-11.6z"/>
    </svg>

const sortdescSVG = <svg enableBackground="new 0 0 30 26" version="1.1" viewBox="0 0 30 26" className="sort-svg">
        <path className="st0" d="M15,0.6h11.7c1.8,0,2.9,1.9,2,3.5l-5.8,10.1L17,24.3c-0.9,1.5-3.1,1.5-4,0L7.2,14.2L1.3,4.1  c-0.9-1.5,0.2-3.5,2-3.5H15z"/>
    </svg>

const caracSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <path d="M28.3,3.5H1.7C0.8,3.5,0,2.7,0,1.7v0C0,0.8,0.8,0,1.7,0h26.5c1,0,1.7,0.8,1.7,1.7v0C30,2.7,29.2,3.5,28.3,3.5z"/>
        <path d="m28.3 12.3h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
        <path d="m28.3 21.2h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
        <path d="m28.3 30h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
    </svg>

const controlcardSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <path d="m28.35 30h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
        <path d="m3.4 1.65v26.6c0 1-0.8 1.7-1.7 1.7-1 0-1.7-0.8-1.7-1.7v-26.5c0-1 0.8-1.7 1.7-1.7 0.9-0.1 1.7 0.7 1.7 1.6z"/>
        <path d="m5.39 23.28c-0.25 0-0.5-0.06-0.73-0.19-0.72-0.4-0.98-1.32-0.58-2.04l8.84-15.88c0.25-0.46 0.73-0.75 1.25-0.77 0.53-0.01 1.02 0.23 1.31 0.67l5.8 8.71 4.9-7.26c0.46-0.69 1.39-0.87 2.08-0.4 0.69 0.46 0.87 1.4 0.4 2.08l-6.15 9.12c-0.28 0.41-0.76 0.69-1.25 0.66-0.5 0-0.97-0.25-1.24-0.67l-5.68-8.52-7.64 13.72c-0.27 0.49-0.78 0.77-1.31 0.77z"/>
    </svg>

export {summarySVG,gestionSVG,etalonSVG,anomalySVG,sortascSVG,sortdescSVG,caracSVG,controlcardSVG}
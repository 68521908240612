import React from "react";

function BullContainer(props) {
    return (
        <div className={props.css == undefined ? "box mb-60" : props.css}>
            <div className="box-title pl-3">
                {props.img}
                <h3 className="m-0">{props.title}</h3>
            </div>
            {props.content}
        </div>
    )
}

export {BullContainer}
import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const apiKey = "jk2pJUM3f3vUEpn7tzvHJQ";
const loadPath = (process.env.REACT_APP_API_URL || "https://localhost:7078/api/") + "localization/localize/edata/{{lng}}/{{ns}}";

i18next
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",

        ns: ["default", "login", "dashboard", "createBlock", "chart", "query", "search", "element", "views", "intervention", "anomaly"],
        defaultNS: "default",

        supportedLngs: ["fr", "en", "pl"],

        backend: {
            loadPath: loadPath
        },

        react: {
            wait: true,
            useSuspense: true //   <---- this will do the magic
        }
    })
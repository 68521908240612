import '../../style/confirmModal.css';
import {useTranslation} from "react-i18next";

function ConfirmModalView(props) {
    const {t, i18n} = useTranslation()
    let closeModale = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    let handleConfirm = () => {
        props.propsAdd.function(props.propsAdd.id)
        closeModale()
    }

    return (
        <div className="modal-box-confirm">
            <div className="box-title d-flex align-items-center justify-content-between">
                <p className="m-0 fw-bold">{props.propsAdd.title}</p>
                <button onClick={closeModale}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path
                            d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"></path>
                    </svg>
                </button>
            </div>
            <div className="wide-container">
                <div className="confirm-container">
                    <p>{props.propsAdd.txt}</p>
                </div>
                <div className="confirm-button">
                    <button onClick={handleConfirm}>{t("default:yes")}</button>
                    <button onClick={closeModale}>{t("default:no")}</button>
                </div>
            </div>
        </div>
    )
}

export {ConfirmModalView}
import {ColorPickerComponent} from "@syncfusion/ej2-react-inputs";
import {useEffect, useRef, useState} from "react";

function ColorPickerRecompose(props) {
    const picker = useRef()
    const [openToogle, setOpenToggle] = useState(false)
    const [closeToogle, setCloseToogle] = useState(false)

    let openPicker = (e) => {
        if (!openToogle)
            setOpenToggle(true)
        else
            setOpenToggle(false)
    }

    let beforeClose = (e) => {
        if (e.event.target.className != "e-btn-icon e-icons e-caret")
            setOpenToggle(false)
    }

    useEffect(() => {
        if (openToogle) {
            picker.current.toggle()
            props.setPicker(props.id)
        }


    }, [openToogle])

    return (
        <div className="d-flex align-items-center color-picker">
            <div className="d-flex align-items-center">
                <ColorPickerComponent beforeClose={beforeClose} ref={picker} value={props.value} enableOpacity={false}
                                      showButtons={true}
                                      id={"color-" + props.id} change={props.handleColorPicker} mode="Palette"/>
                <button onClick={openPicker} id={props.id} className="e-icon-btn e-control e-dropdown-btn e-lib e-btn">
                    <span className="e-btn-icon e-icons e-caret"></span>
                </button>
            </div>
            {props.showName ? <p>{props.name}</p> : null}
        </div>
    )
}

export {ColorPickerRecompose}
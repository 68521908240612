import React, {useEffect, useRef, useState} from "react";
import '../../style/categoryChartMenu.css';
import {SVGMenu} from '../../style/categoryChartMenuSVG'
import axios from "axios";
import {ChartsMenuView} from "./chartsMenu";
import ModalPopup from "./modal";
import {CreateNewModal} from "./createNewDashboard";
import {useTranslation} from "react-i18next";

function CategoryChartMenu(props) {
    const listCharts = useRef()
    const [categories, setCategories] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const {t, i18n} = useTranslation()
    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    let addCategory = async (name) => {
        let catId = 0
        let body = {
            name: name
        }
        await axios.post("/eData/dashboard/new/category", body).then(res => {
            catId = res.data
        })
        categories.splice(categories.length - 1, 0, <ButtonCategory listBlocks={props.listBlocks}
                                                                    setShowModal={props.setShowModal}
                                                                    addChart={props.addChart}
                                                                    icon={SVGMenu[5]}
                                                                    setContent={props.setContent} id={catId} key={catId}
                                                                    title={name}/>)
        setCategories([...categories])
    }

    let openAddModal = () => {
        setModalAdd(true)
    }

    useEffect(async () => {
        let dataBDD = null
        await axios.get("/eData/dashboard/get/categories").then(res => {
            dataBDD = res.data
        })

        dataBDD.forEach(d => {
            categories.push(<ButtonCategory isDefault={d.isDefault}
                                            listBlocks={props.listBlocks}
                                            setShowModal={props.setShowModal}
                                            addChart={props.addChart}
                                            setContent={props.setContent} id={d.id} key={d.id}
                                            icon={SVGMenu[d.displayId - 1]}
                                            refreshBlock={props.refreshBlock}
                                            title={d.name}/>)
        })
        categories.push(<ButtonAdd key={"add"} open={openAddModal} icon={SVGMenu[4]} title={"add"}/>)
        setCategories([...categories])
    }, [])

    return (
        <div className="modal-box-category">
            <div className="modal-box-category-container">
                <button onClick={closeButton} className="close">
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
                <h1 className="m-0">{t("createBlock:header")}</h1>
                <div className="container-category">
                    {categories}
                </div>
            </div>
            {modalAdd ?
                <ModalPopup
                    propsAdd={{innerModal: true, add: addCategory, txt: t("createBlock:category", {context: "name"})}}
                    closeCLicked={false}
                    content={CreateNewModal}
                    setShowModal={setModalAdd}/> : null}
        </div>
    )
}

function ButtonCategory(props) {
    let Icon = props.icon

    let handleClickCat = async (e) => {
        props.setContent(
            <ChartsMenuView isDefault={props.isDefault} listBlocks={props.listBlocks} addChart={props.addChart}
                            title={props.title}
                            setShowModal={props.setShowModal}
                            setContent={props.setContent}
                            catId={e.target.id}
                            refreshBlock={props.refreshBlock}
            />
        )
    }

    return (
        <div id={props.id} onClick={handleClickCat} className="category-box">
            {<Icon id={props.id}/>}
        <p id={props.id} className="m-0">{props.title}</p>
        </div>
    )
}

function ButtonAdd(props) {
    const {t, i18n} = useTranslation()
    let Icon = props.icon
    let handleClick = async (e) => {
        props.open()
    }
    return (
        <div id={props.id} onClick={handleClick} className="category-box" title={t("createBlock:category", {context:"add"})}>
            {<Icon/>}
            <p id={props.id} className="m-0">{props.title}</p>
        </div>
    )
}

export {CategoryChartMenu}
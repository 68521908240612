import {useEffect, useState} from "react"
import {CategoryChartMenu} from "./categoryChartMenu";

function CreateChartView(props){
    const [content, setContent] = useState(<></>)
    const [dataSourceQuery, setDataSourceQuery] = useState()
    const [jsonChart, setJsonChart] = useState()

    useEffect(() => {
        setContent(
            <CategoryChartMenu setShowModal={props.setShowModal} listBlocks={props.propsAdd.listBlocks}
                               addChart={props.propsAdd.addChart}
                               setContent={setContent}
                               refreshBlock={props.propsAdd.cancelEdit}
            />
        )
    }, [])
     
    return(
        <>
        {content}
        </>
    )
}

export {CreateChartView}
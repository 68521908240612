import {Outlet, Route, Routes} from "react-router-dom"
import '../style/common.css'
import '../style/menu.css'
import '../style/connexion.css'
import '../style/recherche.css'
import '../style/dashboard.css'
import '../style/queryBuilder.css'
import {RenderDashBoardView} from "./Dashboard/dashboardRenderView";
import RenderConnexionView from "./Connexion/connexion";
import RenderIntervView from "./Intervention/renderInterventionView";
import RenderElementView from "./Element/renderElementView";
import RenderAnomalieView from "./Anomaly/renderAnomalyView";
import RenderSearchView from "./Search/recherche";
import {Render404View} from "./Common/Render404View";
import {useTranslation} from "react-i18next";

function App() {
    const {t, i18n} = useTranslation()
    return (
        <>
            <Routes>
                <Route path="*" element={<Render404View path={"/dashboard"} number={"404"}
                                                        text={t("default:error_404", {context: "not_found"})}/>}></Route>
                <Route path="/" element={<RenderConnexionView/>}></Route>
                <Route path="/dashboard" element={<RenderDashBoardView/>}></Route>
                <Route path="/login" element={<RenderConnexionView/>}></Route>
                <Route path="intervention" element={<RenderIntervView/>}></Route>
                <Route path="element" element={<RenderElementView/>}></Route>
                <Route path="anomaly" element={<RenderAnomalieView/>}></Route>
                <Route path="search" element={<RenderSearchView/>}></Route>
                <Route path="forbidden" element={<Render404View path={"/login"} number={"403"}
                                                                text={t("default:error_403")}/>}></Route>
            </Routes>
            <Outlet/>
        </>
    )
}
export default App
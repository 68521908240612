import {ListViewComponent} from "@syncfusion/ej2-react-lists";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";

function ModalListConnexion(props) {
    const listView = useRef()
    const [connectionList, setConnectionList] = useState([])
    const {t, i18n} = useTranslation()
    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }
    useEffect(async () => {
        let list = null
        await axios.get('/authentication/ActiveConnections').then(res => list = res.data)

        list.forEach(d => {
            let json = {
                id: d.id,
                text: d.name,
            }
            connectionList.push(json)
        })
        setConnectionList([...connectionList])
    }, [])

    let handleValidate = () => {
        let selectedItems = []
        listView.current.getSelectedItems().data.forEach(i => {
            selectedItems.push(i.id)
        })
        axios.post('/authentication/killConnections', selectedItems)
        closeButton()
    }

    return (
        <div className="modal-box-confirm">
            <div className="box-title d-flex align-items-center justify-content-between">
                <p className="m-0 fw-bold">{t("connected_operators")}</p>
                <button onClick={closeButton}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path
                            d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"></path>
                    </svg>
                </button>
            </div>
            <div className="wide-container">
                <div className="confirm-container">
                    <p>{t("disconnect_operators")}</p>
                    <ListViewComponent ref={listView} key={connectionList} showCheckBox={true}
                                       dataSource={connectionList} id={"list"}/>
                </div>
                <div>
                    <button onClick={handleValidate} className="bouton">{t("default:proceed")}</button>
                </div>
            </div>
        </div>

    )
}

export {ModalListConnexion}
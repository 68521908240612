import {Operator} from "./operator";
import {Cause} from "./cause";
import {CharacteristicsTable} from "./characteristicsTable";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {sortascSVG, sortdescSVG} from "../../../style/logoSVG";
import {sort_by} from "../../../Utilities/SortManager";
import {getValueJsonChild} from "../../../Utilities/FieldManager";
import {consumablesSVG, generatorSVG, normalSVG, standartSVG} from "../../../style/optionsLifeSheetSvg";
import {useTranslation} from "react-i18next";

function TableTest(props) {
    const {t, i18n} = useTranslation()
    const [dataManager, setDataManager] = useState([])
    const [rows, setRows] = useState([])
    const [thHeaders, setThHeaders] = useState([])
    const [sortBy, setSortBy] = useState(null)
    const [headerData, setHeader] = useState([])
    let navigate = useNavigate()

    const [dataDefault, setDataDefault] = useState([])

    useEffect(() => {
        setDataDefault(createTable(props.data, props.col, props.clickableColumn).rows)
        setDataManager(createTable(props.data, props.col, props.clickableColumn).rows)
        setHeader(props.col)
    }, [props.data])

    useEffect(() => {
        if (sortBy == null)
            return
        let dataTemp = dataManager
        headerData.forEach(column => {
            if (column.accessor != sortBy.id) {
                column.isSorted = false
                column.isSortedDesc = false
            }
        })
        let headerSelected = headerData.find(col => col.accessor == sortBy.id)
        let sortFunction = sortBy.sortType ? sortBy.sortType(sortBy.id, sortBy.desc) : sort_by(sortBy.id, sortBy.desc, (a) => a.toUpperCase())
        if (!sortBy.desc) {
            if (headerSelected.isSorted) {
                dataTemp = dataDefault
                headerSelected.isSorted = false
                headerSelected.isSortedDesc = false
                sortBy.desc = false
            } else {
                dataTemp.sort(sortFunction)
                headerSelected.isSorted = true
                headerSelected.isSortedDesc = false
            }
        } else {
            if (headerSelected.isSortedDesc) {
                dataTemp = dataDefault
                headerSelected.isSortedDesc = false
                headerSelected.isSorted = false
            } else {
                dataTemp.sort(sortFunction)
                headerSelected.isSortedDesc = true
                headerSelected.isSorted = false
                sortBy.desc = true
            }
        }

        setHeader([...headerData])
        setDataManager([...dataTemp])
    }, [sortBy])

    useEffect(() => {
        setRows(getTr(dataManager, props.ope, props.cause, props.isCaract, props.setSearchParams, props.clickableColumn, props.path, props.classtd, navigate))
    }, [dataManager])

    useEffect(() => {
        let thHeaderTemp = []
        headerData.forEach(column => {
            thHeaderTemp.push(<th key={column.accessor}>
                                <span className="d-flex align-items-center">
                                    {column.Header}
                                    {props.data.length > 0 ? <div className="d-inline-block ml-10">
                                        <button onClick={() => {
                                            setSortBy({
                                                id: column.accessor,
                                                desc: true,
                                                sortType: column.sortType
                                            })
                                        }}
                                                className={column.isSortedDesc ? "sort-color-active" : "sort-color" + " button-sort"}>{sortascSVG}</button>
                                        <button onClick={() => {
                                            setSortBy({
                                                id: column.accessor,
                                                desc: false,
                                                sortType: column.sortType
                                            })
                                        }}
                                                className={column.isSorted ? "sort-color-active" : "sort-color" + " button-sort"}>{sortdescSVG}</button>
                                    </div> : null}
                                    </span>
            </th>)

        })
        setThHeaders([...thHeaderTemp])
    }, [headerData])


    return (
        <div className='pt-0 p-1 overflow-auto'>
            {props.lifeSheetOptions ? <>
                <div className="button-lifesheet-container">
                    <button onClick={() => {
                        props.setOption("normal")
                    }} title={t("views:lifesheet", {context: "normal"})}
                            className={props.option == "normal" ? "button-lifesheet button-lifesheet-active" : "button-lifesheet"}>
                        {normalSVG}
                    </button>
                    <button onClick={() => props.setOption("standard")}
                            className={props.option == "standard" ? "button-lifesheet button-lifesheet-active" : "button-lifesheet"}
                            title={t("views:lifesheet", {context: "standard"})}>
                        {standartSVG}
                    </button>
                    <button onClick={() => props.setOption("generator")}
                            className={props.option == "generator" ? "button-lifesheet button-lifesheet-active" : "button-lifesheet"}
                            title={t("views:lifesheet", {context: "generator"})}>
                        {generatorSVG}
                    </button>
                    <button onClick={() => props.setOption("consumables")}
                            className={props.option == "consumables" ? "button-lifesheet button-lifesheet-active" : "button-lifesheet"}
                            title={t("views:lifesheet", {context: "consumables"})}>
                        {consumablesSVG}
                    </button>
                </div>
            </> : null}
            {props.actionOperators ? <input onChange={props.handleChangeCheckBox} type="checkbox"/> : null}
            <table>
                <thead>
                <tr>
                    {thHeaders}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    )

}

function createTable(data, columns, ids) {
    let rows = []
    for (let i = 0; i < data.length; i++) {
        let row = {}
        if (ids != undefined) {
            for (var name in ids.positions[i]) {
                row[ids.positions[i][name][1]] = ids.positions[i][name][0]
            }
        }
        if (data[i].itemType)
            row.itemType = data[i].itemType
        if (data[i].isOpened)
            row.isOpened = data[i].isOpened
        if (data[i].cssState)
            row.cssState = data[i].cssState
        for (let j = 0; j < columns.length; j++) {
            row[columns[j].accessor] = data[i][columns[j].accessor]
            if (columns[j].accessor.includes('.')) {
                row[columns[j].accessor] = getValueJsonChild(data[i], columns[j].accessor)
            }
        }
        if (data[i].cause) {
            if (data[i].cause.intervention != null) {
                data[i].cause.designation = data[i].cause.intervention.designation
            }
        }
        rows.push(row)
    }
    return {
        rows: rows
    }
}

function getTr(row, ope, cause, isCaract, setSearchParams, clickableColumn, pathList, classtd, navigate,) {
    let handleCLick = (path, id) => {
        if (!window.location.href.includes(path)) {
            navigate(path + '/?id=' + id);
        }
        else {
            setSearchParams({id: id});
        }
    }
    let tr = []
    for (let i = 0; i < row.length; i++) {
        let rowGene = []
        let colIndex = 0
        for (var name in row[i]) {
            let checkId = false
            if (clickableColumn != undefined) {
                for (let j = 0; j < clickableColumn.namesIds.length; j++) {
                    if (name == clickableColumn.namesIds[j])
                        checkId = true
                }
            }
            if (checkId)
                continue
            if (name == "itemType")
                continue
            if (name == "isOpened")
                continue
            if (name == "cssState")
                continue
            let id = undefined
            let path = ""
            if (clickableColumn != undefined) {
                if (clickableColumn.positions.length != 0) {
                    if (clickableColumn.positions[i][colIndex] != undefined) {
                        id = row[i][clickableColumn.positions[i][colIndex][1]]
                        path = pathList[colIndex]
                    }
                }
            }
            if (id != undefined && id != -1) {
                let css = classtd[colIndex]
                if (row[i].itemType != undefined) {
                    css = row[i].itemType == "Anomaly" ? (row[i].isOpened ? "anomalie-color" : "anomalieClosed-color") : "intervention-color"
                    path = row[i].itemType == "Anomaly" ? "/anomaly" : "/intervention"
                }
                rowGene.push(<td key={i + "-" + colIndex}>
                    <button className={css} onClick={() => {
                        handleCLick(path, id)
                    }}>{row[i][name]}</button>
                </td>)
            } else if (ope.includes(colIndex)) {
                rowGene.push(<td key={i + "-" + colIndex}><Operator
                    name={row[i][name] == null || row[i][name] == "" ? "" : row[i][name].name}
                    mail={row[i][name] == null || row[i][name] == "" ? "" : row[i][name].mail}/>
                </td>)
            } else if (cause.includes(colIndex)) {
                if (row[i][name].intervention == null) {
                    rowGene.push(<td key={i + "-" + colIndex}><Cause name={row[i][name].designation} css={""}
                                                                     path={""} id={-1}/></td>)
                } else {
                    rowGene.push(<td key={i + "-" + colIndex}><Cause
                        name={row[i][name].intervention.designation} css={"intervention-color"}
                        path={"/intervention"} id={row[i][name].intervention.id}/></td>)
                }
            } else if (isCaract) {
                rowGene.push(<td key={i + "-" + colIndex} className={classtd[colIndex]}><CharacteristicsTable
                    css={colIndex == 1 ? "fw-regular" : "tbody-valeur-sous-designation"} data={row[i][name]}
                    format={row[i].format} column={colIndex}/></td>)
            } else {
                rowGene.push(<td key={i + "-" + colIndex} className={classtd[colIndex]}>{row[i][name]}</td>)
            }
            colIndex++
        }
        tr.push(<tr className={row[i].cssState} key={"tr" + i}>{rowGene}</tr>)
    }
    return tr
}

export {createTable, TableTest}
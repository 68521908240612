import {useState} from "react";
import '../../style/createNewDashboard.css'
import {useTranslation} from "react-i18next";

function CreateNewModal(props) {
    const [value, setValue] = useState()
    const {t, i18n} = useTranslation()
    let handleValide = () => {
        props.propsAdd.add(value)
        if (!props.propsAdd.innerModal)
            document.body.classList.remove("overflow-hidden")
        props.setShowModal(false)
    }

    let handleChangeInput = (e) => {
        if (e.target.value.length > 40)
            return
        setValue(e.target.value)
    }

    let handleClose = () => {
        if (!props.propsAdd.innerModal)
            document.body.classList.remove("overflow-hidden")
        props.setShowModal(false)
    }

    return (
        <>
            <div className="modal-box-new-dashboard position-relative">
                <button onClick={handleClose} className="close">
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
                <h2>{props.propsAdd.txt}</h2>
                <input value={value} onChange={handleChangeInput} type="text"/>
                <button onClick={handleValide} className="bouton">{t("default:proceed")}</button>
            </div>
        </>
    )
}

export {CreateNewModal}
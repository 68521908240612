import {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";

function Information(props) {
    const [information, setInformation] = useState(null)
    const {t, i18n} = useTranslation()
    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    let print = () => {
        var divContents = document.getElementById("content").innerHTML
        var a = window.open('', '', 'height=500, width=500')
        a.document.write('<html>')
        a.document.write('<body>')
        a.document.write(divContents)
        a.document.write('</body></html>')
        a.document.close()
        a.print()
    }

    useEffect(async () => {
        await axios.get(`/helpers/documents/annotation/${props.propsAdd.name}/${props.propsAdd.id}`, { doNotCatch: true }).then(res => setInformation(res.data))
    }, [])

    return (
        <>
            <div className="modal-box">
                <div className="box-title d-flex align-items-center">
                    <img src={require('../../img/information.png')}/>
                    <h3 className="information-color pl-2">{t("views:informations")}</h3>
                    <button onClick={closeButton}>
                        <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                            <path className="st0"
                                  d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                        </svg>
                    </button>
                </div>
                <div className="modal-contain">
                    <button onClick={print}>
                        <svg enableBackground="new 0 0 30.6 30.6" version="1.1" viewBox="0 0 30.6 30.6">
                            <g transform="translate(-3.375 -3.375)">
                                <path className="st0"
                                      d="m29.3 8.5h-21.2c-2.2 0-4.1 1.9-4.1 4.1v11.1c0 2.2 1.8 3.9 3.9 3.9h0.6v2.8c0 1.6 1.3 2.8 2.8 2.8h14.7c1.6 0 2.8-1.3 2.8-2.8v-2.8h0.6c2.2 0 3.9-1.8 3.9-3.9v-11.2c0-2.2-1.8-4-4-4zm-2.8 22c0 0.3-0.3 0.6-0.6 0.6h-14.5c-0.3 0-0.6-0.3-0.6-0.6v-11.3c0-0.3 0.3-0.6 0.6-0.6h14.6c0.3 0 0.6 0.3 0.6 0.6v11.3zm1.9-15.2c-0.9 0.1-1.7-0.6-1.8-1.5s0.6-1.7 1.5-1.8 1.7 0.6 1.8 1.5v0.3c-0.1 0.8-0.7 1.4-1.5 1.5z"/>
                                <path className="st0"
                                      d="m24.8 4h-12.3c-2 0-3.6 1.4-3.9 3.4h20.2c-0.3-1.9-2-3.4-4-3.4z"/>
                            </g>
                        </svg>
                    </button>
                    <p id={"content"}>{information}</p>
                </div>
            </div>
        </>
    )
}
export {Information}
import React from "react";
import {BullGestionContent} from "./bullGestionContent";
import {useTranslation} from "react-i18next";

function ContentGestion(props) {
    const {t, i18n} = useTranslation()
    let interv = props.interv
    return (
        <div className="p-2 wrapper d-flex flex-wrap-query gestion-flex2">
            <BullGestionContent
                title={t("views:periodicity")}
                data={[interv.periodicity.periodicity,
                    interv.periodicity.isChronogical ? interv.periodicity.isSliding : null,
                    interv.periodicity.isChronogical || interv.periodicity.isFixed ? interv.periodicity.nextDate : null,
                    interv.periodicity.isChronogical || interv.periodicity.isFixed ? interv.periodicity.range.startDate : null,
                    interv.periodicity.isChronogical || interv.periodicity.isFixed ? interv.periodicity.range.endDate : null,
                    interv.periodicity.originalDate
                ]}
                names={[t("views:periodicity"),
                    t("default:type"),
                    t("views:periodicity", {context: "next_date"}),
                    t("views:periodicity", {context: "range_begin"}),
                    t("views:periodicity", {context: "range_end"}),
                    t("views:periodicity", {context: "original_date"}),
                ]}/>
            <BullGestionContent
                title={t("views:costs")}
                data={[interv.cost.currency,
                    interv.cost.movement,
                    interv.cost.immobilization,
                    interv.cost.human,
                    interv.cost.intervention,
                    interv.cost.consumables,
                    interv.cost.total
                ]}
                names={[t("views:costs", {context: "currency"}),
                    t("views:costs", {context: "movement"}),
                    t("views:costs", {context: "immobilization"}),
                    t("views:costs", {context: "human"}),
                    t("views:costs", {context: "intervention"}),
                    t("views:costs", {context: "consumables"}),
                    t("views:costs", {context: "total"})]}/>
        </div>
    )
}

export {ContentGestion}
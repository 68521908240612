import React, {useEffect, useRef} from "react";
import ReactDom from "react-dom";


function ModalPopup(props) {
    const modalRef = useRef()
    const Content = props.content
    let id = props.id ? props.id : ""
    useEffect(() => {
        document.body.classList.add("overflow-hidden")
        return () => {
            if(props.removeScrollBarOnClose) {
                document.body.classList.remove("overflow-hidden");
            }
        }
    }, [])

    const closeModal = (e) => {
        if (e.target === modalRef.current && props.closeCLicked) {
            props.setShowModal(false);
        }
    }
    return ReactDom.createPortal(
        <div className="container" id={"container-modal" + id} ref={modalRef} onClick={closeModal}>{<Content
            propsAdd={props.propsAdd} setShowModal={props.setShowModal}/>}</div>,
        document.getElementById("portal")
    )
}

export default ModalPopup
import React from "react";

function LoadingPage() {
    return (
        <>
        <div className="page-loading">
            <div className="container-loading">
                <div className="dot dot-1"></div>
                <div className="dot dot-2"></div>
                <div className="dot dot-3"></div>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="d-none">
                <defs>
                    <filter id="goo">
                        <fegaussianblur in="SourceGraphic" stdDeviation="10" result="blur"/>
                        <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"/>
                    </filter>
                </defs>
            </svg>
        </div>
        </>

    )
}

export {LoadingPage}
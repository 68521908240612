import {useEffect, useRef, useState} from "react";

function InputRenaming(props) {
    const [switchComponentRenaming, setSwitchComponentRenaming] = useState({input: false})
    const [valueTitle, setValueTitle] = useState("")
    const [value, setValue] = useState("")
    const valueRef = useRef("")
    const titleLength = useRef(0)
    useEffect(() => {
        if (props.name == "")
            return
        let element = document.getElementById("remaning-title")
        if (element == null)
            return
        if (props.isOwner) {
            element.style.cursor = "pointer"
            element.style.pointerEvents = "auto"
        } else {
            element.style.cursor = "default"
            element.style.pointerEvents = "none"
        }
    }, [props.isOwner])

    useEffect(() => {
        setValueTitle(props.name)
        valueRef.current = props.name
    }, [props.name])

    useEffect(() => {
        let input = document.getElementById("renaming-input")
        if (input != undefined) {
            input.focus()
            input.style.width = 30 + "ch"
            input.addEventListener('blur', () => {
                input.style.width = input.value.length + "ch"
                setTimeout(onClickRenaming, 300)
            })
        }

    }, [switchComponentRenaming.input])

    useEffect(() => {
        setSwitchComponentRenaming(previousState => {
            let obj = {
                input: previousState.input,
                compoment: previousState.compoment
            }
            if (!obj.input) {
                obj.compoment = <h1 id={"remaning-title"} style={{"cursor": "pointer"}}
                                    onClick={onClickRenaming}>{valueRef.current}</h1>
            }
            if (obj.input) {
                obj.compoment =
                    <input id={"renaming-input"} onChange={handleChangeInput} value={valueTitle}
                           onKeyPress={validateName} type="text"
                           name="renaming" style={{"width": 30 + "ch"}} className="renaming-input"/>
            }

            return {...previousState, compoment: obj.compoment}
        })
    }, [valueTitle])

    let handleChangeInput = (e) => {
        if (e.target.value.length > 40)
            return
        setValueTitle(e.target.value)
    }

    let onClickRenaming = () => {
        if (!props.isOwner)
            return
        let titleTemp = null
        setValueTitle(prevState => {
            titleTemp = prevState
            return prevState
        })
        setSwitchComponentRenaming(previousState => {
            let obj = {
                input: previousState.input,
                compoment: previousState.compoment
            }
            if (obj.input) {
                obj.input = false
                setValueTitle(valueRef.current)
                obj.compoment =
                    <h1 id={"remaning-title"} style={{"pointer": "pointer"}}
                        onClick={onClickRenaming}>{valueRef.current}</h1>
            } else {
                let titleRect = document.getElementById("remaning-title").getBoundingClientRect()
                titleLength.current = titleRect.width
                obj.input = true
                obj.compoment =
                    <input id={"renaming-input"} onChange={handleChangeInput} value={titleTemp}
                           onKeyPress={validateName} type="text"
                           name="renaming" style={{"width": titleLength.current + "px"}} className="renaming-input"/>
            }

            return {...previousState, input: obj.input, compoment: obj.compoment}
        })
    }
    let validateName = (e) => {
        if (e.target.value.length == 0)
            return
        if (e.key === "Enter") {
            valueRef.current = e.target.value
            setValueTitle(e.target.value)
            props.functionValidate(e)
            let input = document.getElementById("renaming-input")
            input.blur()
        }
    }


    return (
        <>
            {switchComponentRenaming.compoment}
        </>
    )
}

export {InputRenaming}
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function DropdownPeriode(props) {
    const [value, setValue] = useState(props.value)
    const [editModal, setEditModal] = useState(false)
    const {t, i18n} = useTranslation()
    let dataSource = [
        {id: "OVER DEFAULT", text: t("chart:period", {context: "default"})},
        {id: "OVER YEARS", text: t("chart:period", {context: "year"})},
        {id: "OVER MONTHS", text: t("chart:period", {context: "month"})},
        {id: "OVER WEEKS", text: t("chart:period", {context: "week"})},
        {id: "OVER MONTHS, YEARS", text: t("chart:period", {context: "month_year"})},
        {id: "OVER DAYS, MONTHS, YEARS", text: t("chart:period", {context: "day_month_year"})},
        {id: "OVER WEEKS, YEARS", text: t("chart:period", {context: "week_year"})},
        {id: "OVER DAYS", text: t("chart:period", {context: "day"})}
    ]

    let handleChange = (e) => {
        setValue(e.value)
        props.handleChange(e)
    }

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {


        if (!props.show) {
            return setEditModal(false)
        } else {
            if (props.type == "Number" || props.type == "Grid") {
                return setEditModal(false)
            }
            return setEditModal(true)
        }


    }, [props.show])

    return (
        <>
            {editModal ? <DropDownListComponent id={'dropdown-period-' + props.id}
                placeholder='' value={value} change={handleChange} dataSource={dataSource}
                fields={{text: "text", value: "id"}} popupHeight="220px"/> : null}
        </>
    )
}

export {DropdownPeriode}
import React, {useEffect, useRef, useState} from 'react'
import {TreeViewComponent} from '@syncfusion/ej2-react-navigations'
import ReactDOM from 'react-dom'

function TreeViewProperty(props) {
    const [value, setValue] = useState("")
    const [showTreeView, setTreeView] = useState(false)
    const treeView = useRef()

    useEffect(async () => {
        setValue(props.value)
        createDiv(props.idTv, props.idModal)

    }, [])
    useEffect(() => {
        let queryTable = document.getElementById('query-table-id')
        let overflowVertical = document.getElementById('query-overflow-vertical')
        if (queryTable != null) {
            queryTable.addEventListener('scroll', (event) => {
                handleCreated()
            })
        }
        if (overflowVertical != null) {

            let tree = document.getElementById(props.idTv)
            overflowVertical.addEventListener('scroll', (event) => {
                if (tree != null) {
                    if (tree.getBoundingClientRect().y > overflowVertical.getBoundingClientRect().y) {
                        handleCreated()
                    }
                } else {
                    handleCreated()
                }
            })
        }
    }, [props.idTv])
    useEffect(() => {
        if(props.value == undefined)
            return
        setValue(props.value)
    }, [props.value])

    window.addEventListener('click', function (e) {
        let id = props.idTv.toString().split('-')[0] 
        if(document.querySelectorAll('[id^="treeview'+id+'"]').length == 0)
            return
        if(document.querySelectorAll('[id^="treeview'+id+'"]')[0].id.split('-')[1]!=props.idTv.toString().split('-')[1])
            return
        if (e.target.id != 'treeViewSelect' + props.idTv) {
            if (e.target.className != 'e-icons e-process interaction e-icon-collapsible') {
                if (e.target.className != 'e-icons interaction e-process e-icon-expandable') {
                    if (e.target.className != 'query-field e-control e-treeview e-lib e-fullrow-wrap e-touch e-keyboard') {
                        if (e.target.className != 'e-fullrow') {
                            setTreeView(false)
                        }
                    }
                }
            }
        }
    }, false)
    window.addEventListener('resize', function (e) {
        setTreeView(false)
    })

    let handleSelected = (e) => {
        let navigationValue = getPath(treeView.current, e.nodeData, props.data, false).split('.')
        let columnsTemp = props.data
        for (let i = 0; i < navigationValue.length; i++) {
            if (columnsTemp.columns != undefined)
                columnsTemp = columnsTemp.columns.find(col => col.field == navigationValue[i])
            else
                columnsTemp = columnsTemp.find(col => col.field == navigationValue[i])
        }
        if (columnsTemp.type == undefined)
            return
        //setValue(getPath(treeView.current, e.nodeData, props.data, true))
        props.handle(columnsTemp.type, e, columnsTemp.values, columnsTemp.autocompletion)
        setTreeView(false)
    }

    let handleChangeValue = (e) => {
        setValue(e.target.value)
    }

    let handleClickTreeView = (e) => {
        if (props.eventTriggerSelected == null) {
            setTreeView(showTreeView ? false : true)
            props.setEventTriggerSelected(e)
            return
        }
        if (e.target.id != props.eventTriggerSelected.target.id) {
            setTreeView(true)
            props.setEventTriggerSelected(e)
        }
        else
            setTreeView(showTreeView ? false : true)
    }

    useEffect(() => {
        if (showTreeView) {
            ReactDOM.render(
                <TreeViewComponent created={handleCreated} className="query-field" id={props.idTv} ref={treeView}
                                   nodeSelected={(e) => handleSelected(e)}
                                   fields={{dataSource: props.data, id: 'id', text: 'label', child: 'columns'}}/>,
                document.getElementById('treeview' + props.idTv)
            )
        }
        else {
            ReactDOM.render(
                null,
                document.getElementById('treeview' + props.idTv)
            )
        }
    }, [showTreeView])
    useEffect(() => {
        setValue(props.valueProperty)
    }, [props.valueProperty])
    useEffect(() => {
        let id = props.idTv.toString().split('-')[0] 
        if(document.querySelectorAll('[id^="treeview'+id+'"]').length > 0){
            document.querySelectorAll('[id^="treeview'+id+'"]').forEach(elem => {
                elem.setAttribute("id", 'treeview' + props.idTv)
            })
        }
    }, [props.idTv])

    let handleCreated = () => {
        let elem = document.getElementById('treeview' + props.idTv)
        if (elem == null)
            return
        let tree = elem.firstElementChild
        if (tree == null)
            return
        let selectTree = document.getElementById('treeViewSelect' + props.idTv)
        tree.style.top = selectTree.getBoundingClientRect().top + selectTree.getBoundingClientRect().height + 3 + 'px'
        tree.style.left = selectTree.getBoundingClientRect().left + 'px'
        tree.style.width = selectTree.getBoundingClientRect().width < 275 ? 275 + 'px' : selectTree.getBoundingClientRect().width + 'px'
        tree.style.display = "block"
    }
    return(
        <>
            <div className={showTreeView ? "query-field-div anim" : "query-field-div"}
                 style={props.styleField}
                 onClick={(e) => handleClickTreeView(e)} id={"treeViewSelect" + props.idTv}>
                {value}
                {props.loadingDataTreeView ?
                    <span className={"treeview-spinner"}></span> : null}
            </div>
        </>
    )
}

function createDiv(id, idModal) {
    let div = document.createElement('div');
    div.id = 'treeview' + id
    if (idModal != undefined) {
        document.getElementById(idModal).appendChild(div)
    } else {
        document.getElementById('container-modal').appendChild(div)
    }

}

function getPath(treeView, node, data, trad) {
    let path = trad ? node.text : getFind(node.id, data, "id").field
    let parentNode = treeView.getNode(node.parentID)
    while (parentNode.hasChildren) {
        path += "."
        path += trad ? parentNode.text : getFind(parentNode.id, data, "id").field
        parentNode = treeView.getNode(parentNode.parentID)
    }
    let pathReverse = path.split('.').reverse().join('.')
    return pathReverse
}

function getFind(id, data, propertie) {
    for (let i = 0; i < data.length; i++) {
        if (data[i][propertie] == id)
            return data[i]

        if (data[i].columns != undefined) {

            let result = getFind(id, data[i].columns, propertie)

            if (result) {
                return result
            }
        }
    }

    return false
}

function getTrad(text, data) {
    let split = text.split('.')
    let result = ""
    let columns = data
    let index = 0

    while(columns != undefined && index < split.length){
        result += `${getFind(split[index], columns, "field").label}.`
        columns = getFind(split[index], columns, "field").columns
        index++
    }

    return result.slice(0, -1)
}

function getColumnType(data, navigationValue) {
    if (data == undefined || navigationValue == null)
        return
    let columnsTemp = data
    for (let i = 0; i < navigationValue.length; i++) {
        if (columnsTemp.columns != undefined)
            columnsTemp = columnsTemp.columns.find(col => col.field == navigationValue[i])
        else
            columnsTemp = columnsTemp.find(col => col.field == navigationValue[i])
    }
    return columnsTemp.type
}

function getType(filter, t) {
    let split = filter.split('.')
    let replace = split[0].replaceAll('(', '')

    return {
        field: replace,
        text: t ? t(`${replace.toLowerCase()}`) : undefined
    }

}

function deleteDiv() {
    let divs = document.querySelectorAll(`[id^="treeview"]`)
    for (let i = 0; i < divs.length; i++) {
        divs[i].parentNode.removeChild(divs[i])
    }
}

function getNodeByPath(arrayByPath, data){
    let found = data.find(d => d.field == arrayByPath[0])
    if(found != undefined){
        if(arrayByPath.length == 1)
            return found
        arrayByPath.splice(0,1)
        return getNodeByPath(arrayByPath,found.columns)
    }
}


export {TreeViewProperty, getPath, getType, getColumnType, getTrad, deleteDiv,getFind,getNodeByPath}

import {useTranslation} from "react-i18next";

function NoRightBlock(props) {
    const {t, i18n} = useTranslation()
    return (
        <div className="noright">
        <svg enableBackground="new 0 0 250 250" version="1.1" viewBox="0 0 250 250">

            <path
                d="m142.8 200.7v-26.5c0-1.2-0.4-2.4-1.3-3.3-0.8-0.9-2-1.3-3.1-1.3h-26.8c-1.2 0-2.3 0.5-3.1 1.3-0.9 0.9-1.3 2-1.3 3.3v26.5c0 1.2 0.4 2.4 1.3 3.3 0.8 0.9 2 1.3 3.1 1.3h26.7c1.2 0 2.3-0.5 3.1-1.3 1-0.9 1.5-2.1 1.4-3.3zm-0.2-52.1 2.5-64c0-1.1-0.5-2.1-1.4-2.7-0.9-0.9-2.1-1.4-3.3-1.5h-30.7c-1.3 0.1-2.4 0.6-3.3 1.5-0.9 0.7-1.5 1.8-1.4 2.9l2.4 63.7c0 1 0.5 1.8 1.4 2.3 1 0.6 2.2 0.9 3.3 0.9h25.8c1.2 0 2.3-0.3 3.3-0.9 0.7-0.5 1.3-1.3 1.4-2.2zm-2-130.2 107 196.2c3.3 5.4 3.1 12.2-0.3 17.6-3.2 5.4-9 8.8-15.3 8.8h-214c-6.3 0-12.1-3.3-15.3-8.8-3.4-5.3-3.5-12.1-0.3-17.6l107-196.2c1.5-2.8 3.8-5.2 6.6-6.8 5.6-3.3 12.5-3.3 18.1 0 2.7 1.6 5 4 6.5 6.8z"/>
        </svg>
        <p>{t("dashboard:forbbiden")}</p>
        </div>
    )
}

export {NoRightBlock}
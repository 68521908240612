import {getFind, getNodeByPath, getPath, TreeViewProperty} from "../Utilities/treeviewProperty";
import {useEffect, useRef, useState} from "react";
import {TreeViewComponent} from "@syncfusion/ej2-react-navigations";
import {useTranslation} from "react-i18next";
import {seriesRender} from "@syncfusion/ej2-charts";

function AddMultipleSerie(props){
    const {t, i18n} = useTranslation()
    const treeView = useRef()
    const [data, setData] = useState(null)
    const alreadyChecked = useRef([])

    useEffect(()=>{
        props.propsAdd.chartValue.current.dataChart.forEach(serie => {
            if(serie.groupBy != null && serie.groupBy != "") {
                let found = getNodeByPath(serie.groupBy.split('.'), props.propsAdd.data);
                found.isChecked = true;
                alreadyChecked.current.push(found.id);
            }
        })
        setData(props.propsAdd.data)
        return () => {
            props.propsAdd.chartValue.current.dataChart.forEach(serie => {
                if(serie.groupBy != null) {
                    let found = getNodeByPath(serie.groupBy.split('.'), props.propsAdd.data);
                    found.isChecked = false;
                }
            })
        }
    },[])
    useEffect(()=>{
        treeView.current.expandAll()
        treeView.current.collapseAll()
    },[data])
    let handleValidate = (e) => {
        treeView.current.expandAll()
        let nodesChecked = treeView.current.getAllCheckedNodes()
        for(let node of nodesChecked){
            if(alreadyChecked.current.includes(node))
                continue
            let nodeFound = treeView.current.getNode(node)
            let nodeData = getFind(nodeFound.id, props.propsAdd.data, "id")
            if(nodeData.columns)
                continue
            let path = getPath(treeView.current,nodeFound,props.propsAdd.data, false)
            let trad = getPath(treeView.current,nodeFound,props.propsAdd.data, true).replaceAll('.', " ➧ ")

            let findOpenPageIndex = props.propsAdd.pages.current.findIndex(page => page.isOpen)

            let newChart = {
                id: props.propsAdd.chartValue.current.dataChart.length + 1,
                data: [],
                dataCenter: null,
                color: ["#0db1e7"],
                groupBy: path,
                aggregateOn: null,
                aggregator: null,
                totalAggregator: null,
                name: trad.split(' ➧ ')[trad.split(' ➧ ').length-1],
                filter: props.propsAdd.chartValue.current.dataChart[findOpenPageIndex].filter,
                dataTreeView: props.propsAdd.chartValue.current.dataChart[findOpenPageIndex].dataTreeView,
                trendLine: {
                    isChecked: false,
                    data: null,
                },
                limit: {
                    min: null,
                    max: null,
                    objective: null,
                },
                ids: []
            }
            props.propsAdd.chartValue.current.dataChart.push(newChart)

            let newPage = {
                id: props.propsAdd.pages.current.length + 1,
                isOpen: false,
                content: {
                    nameLegend: trad.split(' ➧ ')[trad.split(' ➧ ').length-1],
                    type: {
                        aggregation: "",
                        groupBy: "",
                    },
                    groupBy: trad,
                    dataTreeView: props.propsAdd.data,
                    isTrendLine: false,
                    limit: {
                        min: "",
                        max: "",
                        objective: ""
                    },
                    aggregation: {
                        operation: "",
                        property: "",
                        data: []
                    },
                }
            }
            props.propsAdd.pages.current.push(newPage)
        }
        deleteSerie(nodesChecked);
        props.propsAdd.setPageOpened({...props.propsAdd.pages.current[0]})
        props.propsAdd.setJsonChart({...props.propsAdd.chartValue.current})
        props.propsAdd.handleSendDataChart()
        props.setShowModal(false)
    }

    let deleteSerie = (nodesChecked) => {
        let deteletNodes = []
        for(let nodeAlreadyChecked of alreadyChecked.current){
            if(!nodesChecked.includes(nodeAlreadyChecked)){
                deteletNodes.push(nodeAlreadyChecked)
            }
        }
        for(let nodeAlreadyChecked of deteletNodes) {
            if (!alreadyChecked.current.includes(nodesChecked)){
                let nodeFound = treeView.current.getNode(nodeAlreadyChecked)
                let pathTrad = getPath(treeView.current,nodeFound,props.propsAdd.data, true).replaceAll('.', " ➧ ")
                let page = props.propsAdd.pages.current.find(page => page.content.groupBy === pathTrad)
                props.propsAdd.deleteTab({target: {id: page.id}})
            }
        }
    }

    return(
        <div className="modal-multiple-serie">
            <div className="modal-box-button">
                <button onClick={() => props.setShowModal(false)} className="close">
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
            </div>
            <h1 className="m-0">Ajouter plusieurs séries</h1>
            <div className="overflow-auto mt-1 mb-1">
                <TreeViewComponent showCheckBox={true} className="" id={"treeviewMultipleSeries"} ref={treeView}
                               fields={{dataSource: props.propsAdd.data, id: 'id', text: 'label', child: 'columns'}}/>
            </div>
            <button onClick={handleValidate} className="bouton">{t("default:proceed")}</button>
        </div>

    )
}

export {AddMultipleSerie}
import React from "react";

function BullGestionContent(props) {
    let divBull = []
    for (let i = 0; i < props.data.length; i++) {
        divBull.push(<div key={i} className="wrapper d-flex justify-content-center gap-15">
            <p className="fw-bold w-45 text-right mb-0">{props.names[i]}</p>
            <p className="w-45 mb-0">{props.data[i]}</p>
        </div>)
    }
    return (
        <div className="box box-shadow-mini pb-3 mt-2 mb-2 gestion-flex">
            <div className="gestion-title">
                <h4>{props.title}</h4>
            </div>
            {divBull}
        </div>

    )
}

export {BullGestionContent}
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function DropdownAggregation(props) {
    const [data, setData] = useState([])
    const [valueDp, setValueDp] = useState(undefined)
    const {t, i18n} = useTranslation()
    useEffect(() => {
        let dataDp = []
        setData(dataDp)
        if (props.type == "")
            return
        dataDp = [{id: "Count", text: t("chart:aggregation", {context: "count"})}]
        if (props.type == "number") {
            dataDp.push({id: "Sum", text: t("chart:aggregation", {context: "sum"})})
            dataDp.push({id: "Avg", text: t("chart:aggregation", {context: "avg"})})
            dataDp.push({id: "Min", text: t("chart:aggregation", {context: "min"})})
            dataDp.push({id: "Max", text: t("chart:aggregation", {context: "max"})})
        }
        setData([...dataDp])
    }, [props.type, props.value])

    useEffect(() => {
        if (props.data == undefined)
            return
        setData(props.data)
    })

    useEffect(() => {
        let dp = <DropDownListComponent id={props.id} value={props.value} noRecordsTemplate={t("chart:aggregation", {context: "unknown"})}
                                        placeholder='' change={props.handle} dataSource={data}
                                        fields={{text: "text", value: "id"}} popupHeight="220px"/>
        setValueDp(dp)
    }, [data])

    return (
        <>
            {valueDp}
        </>
    )
}

export {DropdownAggregation}
import {ListViewComponent} from "@syncfusion/ej2-react-lists";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";

function CsvExportGridViewModal(props) {
    const listView = useRef()
    const {t, i18n} = useTranslation()

    let handleValidate = async (e) => {
        let selectedItems = []
        listView.current.getSelectedItems().data.forEach(i => {
            selectedItems.push(i.id)
        })
        for (let i of listView.current.getSelectedItems().data) {
            await axios.get("/eData/dashboard/export/grid/" + props.propsAdd.chartId + "/" + i.id, {responseType: 'blob'}).then(res => {
                let url = window.URL.createObjectURL(res.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = i.text + ".csv"
                a.click();
            }).finally(() => {
            })
        }
    }
    let closeModal = () => {
        props.setShowModal(false)
    }

    return (
        <div className="modal-box-confirm">
            <div className="box-title d-flex align-items-center justify-content-between">
                <p className="m-0 fw-bold">Liste des grilles de données à exporter</p>
                <button onClick={closeModal}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path
                            d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"></path>
                    </svg>
                </button>
            </div>
            <div className="confirm-container">
                <ListViewComponent ref={listView} showCheckBox={true}
                               dataSource={props.propsAdd.grids} id={"list"}/>
            </div>
            <button onClick={handleValidate} className="bouton">{t("default:proceed")}</button>
        </div>

    )
}

export {CsvExportGridViewModal}
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {convertJsonChart, generateChartQuery, loadChartData} from "../charts/CustomChartWindow/utilities";
import {PreviewChartRender} from "../charts/CustomChartWindow/previewChartRender";
import ModalPopup from "../Modal/modal";
import {FullScreenChart} from "../Modal/FullScreenChart";
import {ConfirmModalView} from "../Modal/confirmModal";
import {RenderViewCustomChart} from "../charts/CustomChartWindow/renderViewCustomChart";
import {GridViewModal} from "../Modal/gridViewModal";

function ChartBlock(props) {
    const {t, i18n} = useTranslation()
    const [chartView, setChartView] = useState(props.block.jsonChart)
    const [dateSnap, setDateSnap] = useState(t("chart:snapshot", {context: "current"}))
    const [disabledButton, setDisabledButton] = useState({
        prev: false,
        next: true
    })
    const [deletedSap, setDeletedSnap] = useState(false)
    const actualIndex = useRef(-1)
    const [showList, setShowList] = useState(false)
    const [snapList, setSnapList] = useState([])
    const [fullScreenModal, setFullScreenModal] = useState(false)
    const [modalDeleteSnapshot, setModalDeleteSnapshot] = useState(false)
    const [modalDeleteBlock, setModalDeleteBlock] = useState(false)
    const [modalCustomChart, setModalCustomChart] = useState(false)
    const [modalGridView, setModalGridView] = useState(false)
    const [showButtonGridView, setShowButtonGridView] = useState(true)

    let getSnapshot = async (e) => {
        await axios.post(`/eData/dashboard/new/snapshot/${props.block.jsonChart.id}`)
        await axios.get(`/eData/dashboard/get/snapshots/${props.block.jsonChart.id}`).then(res => props.block.snapshots = res.data)
        prevSnapshot(null)
        if (props.fullScreen || props.category)
            props.cancelEdit(chartView)
    }

    useEffect(() => {
        if (props.block.snapshots.length == 0) {
            disabledButton.prev = true
            setDisabledButton({...disabledButton})
        }
        if (chartView == null)
            return setShowButtonGridView(false)
        chartView.dataChart.forEach(dataChart => {
            if (dataChart.aggregator != "Count") {
                setShowButtonGridView(false)
            }
        })
        if (chartView.type == "Note" || chartView.type == "Grid") {
            setShowButtonGridView(false)
        }
    }, [chartView])

    useEffect(() => {
        if (props.chart != undefined) {
            if (props.chart.index != -1) {
                handleClickSnap(null, props.block.snapshots[props.chart.index].id)
            } else {
                setChartView(props.chart.value)
            }
        }

    }, [props.chart])

    useEffect(() => {
        if (props.chart == undefined) {
            setChartView(props.block.jsonChart)
        }
    }, [props.block])

    let nextSnapshot = async (e) => {
        if (actualIndex.current == props.block.snapshots.length - 1) {
            backChart()
            return
        }

        if (actualIndex.current == -1) {
            actualIndex.current = 0
        } else {
            disabledButton.prev = false
            setDisabledButton({...disabledButton})
            actualIndex.current++
        }
        getSnapshotChart(props.block.snapshots[actualIndex.current].id)
    }

    let getSnapshotChart = async (id) => {
        let chartData = null
        await axios.get(`/eData/dashboard/get/snapshot/${id}`).then(res => chartData = res.data)
        setDateSnap(chartData.dateTime)
        setChartView(convertJsonChart(chartData.data))
    }

    let prevSnapshot = async (e) => {
        if (actualIndex.current == 0) {
            return
        }
        if (actualIndex.current == -1) {
            actualIndex.current = props.block.snapshots.length - 1
            disabledButton.next = false
            setDisabledButton({...disabledButton})
            setDeletedSnap(true)
        } else {
            actualIndex.current--
        }
        if (actualIndex.current == 0) {
            disabledButton.prev = true
            setDisabledButton({...disabledButton})
        }
        getSnapshotChart(props.block.snapshots[actualIndex.current].id)
    }

    let deleteSnapShot = async () => {
        let getIndex = props.block.snapshots.findIndex(snap => snap.id == props.block.snapshots[actualIndex.current].id)

        await axios.delete(`/eData/dashboard/delete/snapshot/${props.block.jsonChart.id}/${actualIndex.current}`)
        if (props.fullScreen || props.category) {
            props.cancelEdit(chartView)
        }
        if (getIndex == props.block.snapshots.length - 1) {
            await axios.get(`/eData/dashboard/get/snapshots/${props.block.jsonChart.id}`).then(res => props.block.snapshots = res.data)
            backChart()
            return
        }
        await axios.get(`/eData/dashboard/get/snapshots/${props.block.jsonChart.id}`).then(res => props.block.snapshots = res.data)
        getSnapshotChart(props.block.snapshots[actualIndex.current].id)
    }

    let handleShowList = () => {
        let tempList = []
        if (actualIndex.current != -1) {
            tempList.push(<p key={actualIndex.current} onClick={handleClickSnap} id={-1}
                             className="m-0">{t("chart:snapshot", {context: "current"})}</p>)
        }
        for (let i = 0; i < props.block.snapshots.length; i++) {
            if (i != actualIndex.current) {
                tempList.push(<p key={i} onClick={handleClickSnap} id={props.block.snapshots[i].id}
                                 className="m-0">{props.block.snapshots[i].dateTime}</p>)
            }
        }
        setSnapList([...tempList])
        setShowList(showList ? false : true)
    }

    let handleClickSnap = (e, id) => {
        let idTemp = id == undefined ? e.target.id : id
        if (idTemp == -1) {
            backChart()
            setShowList(false)
            return
        }
        let getIndex = props.block.snapshots.findIndex(snap => snap.id == idTemp)
        actualIndex.current = getIndex
        setDeletedSnap(true)
        if (getIndex == 0) {
            disabledButton.next = false
            disabledButton.prev = true
            setDisabledButton({...disabledButton})
        } else {
            disabledButton.next = false
            disabledButton.prev = false
            setDisabledButton({...disabledButton})
        }

        getSnapshotChart(idTemp)
        setShowList(false)
    }

    let backChart = () => {
        setChartView(props.block.jsonChart)
        if (props.block.snapshots.length == 0) {
            disabledButton.next = true
            disabledButton.prev = true
        } else {
            disabledButton.next = true
            disabledButton.prev = false
        }
        setDisabledButton({...disabledButton})
        setDateSnap(t("chart:snapshot", {context: "current"}))
        actualIndex.current = -1
        setDeletedSnap(false)
    }

    let openFullScreen = (e) => {
        setFullScreenModal(true)
    }

    let openModalDelete = (e) => {
        setModalDeleteSnapshot(true)
    }

    let openModalDeleteBlock = (e) => {
        setModalDeleteBlock(true)
    }

    let openModalGridView = (e) => {
        setModalGridView(true)
    }

    let csvExport = async () => {
        if (actualIndex.current == -1) {
            await axios.get("/eData/dashboard/export/chart/" + chartView.id, {responseType: 'blob'}).then(res => {
                let url = window.URL.createObjectURL(res.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = chartView.name + ".csv"
                a.click();
            }).finally(() => {
            })
        } else {
            await axios.get("/eData/dashboard/export/snapshot/" + props.block.snapshots[actualIndex.current].id, {responseType: 'blob'}).then(res => {
                let url = window.URL.createObjectURL(res.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = chartView.name + ".csv"
                a.click();
            }).finally(() => {
            })
        }

    }

    let handleChangePeriod = async (e) => {
        props.block.jsonChart.dateValue = e.value
        let query = {
            query: generateChartQuery(props.block.jsonChart)
        }
        await axios.put("/eData/dashboard/edit/chart/" + props.block.jsonChart.id, query)
        let chartTemp = await loadChartData(props.block.jsonChart)
        props.block.jsonChart = {...chartTemp}
        setChartView({...chartTemp})
    }

    window.addEventListener('click', function (e) {
        if (e.target.className == "m-0")
            return
        if (e.target.className == "center-txt")
            return
        setShowList(false)
    }, false)

    let closeButton = () => {
        if (props.fullScreen) {
            props.closeModale(props.fullScreen)
        } else {
            openModalDeleteBlock(props.block.jsonChart.id)
        }
    }

    let validateModif = async (jsonChart, pages) => {
        props.block.pages = pages
        props.block.jsonChart = jsonChart
        setChartView({...jsonChart})
        if (props.category || props.fullScreen)
            props.cancelEdit(jsonChart)
    }

    let openModalCustom = () => {
        setModalCustomChart(true)
    }


    return (
        <>
            <div className="button-panel">
                {showButtonGridView ?
                    <button onClick={openModalGridView} title={t("dashboard:block", {context: "grid"})}>
                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <circle cx="4.6" cy="7.3" r="2.8"/>
                            <path
                                d="m26.3 9.3h-14.3c-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.9-1.9 1.9-1.9h14.2c1.1 0 1.9 0.9 1.9 1.9 0.1 1-0.8 1.9-1.8 1.9z"/>
                            <circle cx="4.6" cy="15" r="2.8"/>
                            <path
                                d="m26.3 16.9h-14.3c-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.9-1.9 1.9-1.9h14.2c1.1 0 1.9 0.9 1.9 1.9 0.1 1.1-0.8 1.9-1.8 1.9z"/>
                            <circle cx="4.6" cy="22.7" r="2.8"/>
                            <path
                                d="m26.3 24.6h-14.3c-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.9-1.9 1.9-1.9h14.2c1.1 0 1.9 0.9 1.9 1.9 0.1 1.1-0.8 1.9-1.8 1.9z"/>
                        </svg>
                    </button> : null}
                {props.editable && chartView != null ? deletedSap ?
                    <button id={props.block.id} onClick={openModalDelete} className="view-fullscreen"
                            title={t("chart:snapshot", {context: "delete"})}>
                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path
                                d="m22.3 9.5h-14.6c-1 0-1.9 0.8-1.9 1.9v16.6c0 1 0.8 1.9 1.9 1.9h14.6c1 0 1.9-0.8 1.9-1.9v-16.7c0-1-0.8-1.8-1.9-1.8zm-9.5 17c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8zm7.2 0c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8z"/>
                            <path className="trash-animation"
                                  d="m27.3 4.8h-4.9v-3.3c0-0.7-0.6-1.2-1.2-1.2h-12.3c-0.7 0-1.2 0.6-1.2 1.2v3.4h-5c-0.4 0-0.7 0.3-0.7 0.7v1.4c0 0.4 0.3 0.7 0.7 0.7h24.5c0.4 0 0.7-0.3 0.7-0.7v-1.5c0-0.4-0.3-0.7-0.6-0.7zm-16.9 0c-0.4 0-0.6-0.3-0.6-0.6v-1.2c0-0.4 0.3-0.6 0.6-0.6h9.1c0.4 0 0.6 0.3 0.6 0.6v1.2c0 0.4-0.3 0.6-0.6 0.6h-9.1z"/>
                        </svg>
                    </button> : <button id={props.block.id} onClick={getSnapshot} className="view-fullscreen"
                                        title={t("chart:snapshot", {context: "new"})}>
                        <svg id={props.block.id} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <circle id={props.block.id} cx="15" cy="15.9" r="5.5"/>
                            <path id={props.block.id}
                                  d="m26.8 5.8h-5.9v-0.1-1.6c0-1-0.8-1.9-1.9-1.9h-8c-1 0-1.9 0.8-1.9 1.9v1.6 0.1h-5.9c-1.8 0-3.2 1.4-3.2 3.2v13.8c0 1.7 1.4 3.2 3.2 3.2h23.7c1.7 0 3.2-1.4 3.2-3.2v-13.8c-0.1-1.8-1.5-3.2-3.3-3.2zm-11.8 17.7c-4.2 0-7.7-3.4-7.7-7.7s3.4-7.7 7.7-7.7 7.7 3.4 7.7 7.7-3.5 7.7-7.7 7.7z"/>
                        </svg>
                    </button> : null
                }
                {props.fullScreen || chartView == null ? null :
                    <button id={props.block.id} onClick={openFullScreen} className="view-fullscreen"
                            title={t("chart:preview", {context: "fullscreen"})}>
                        <svg id={props.block.id} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path id={props.block.id}
                                  d="m15 10.1c-0.5 0-1 0.1-1.4 0.2 0.8 0.4 1.3 1.3 1.3 2.3 0 1.5-1.1 2.7-2.6 2.7-0.9 0-1.7-0.5-2.1-1.1-0.1 0.3-0.1 0.6-0.1 0.9 0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9-2.2-5-4.9-5z"/>
                            <path id={props.block.id}
                                  d="m28.5 12.3c-2.9-2.7-7.8-6.3-13.5-6.3s-10.6 3.6-13.5 6.3c-1.7 1.6-1.7 3.9 0 5.5 2.9 2.6 7.8 6.2 13.5 6.2s10.6-3.6 13.5-6.3c1.7-1.5 1.7-3.9 0-5.4zm-13.5 9.7c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7c0 3.8-3.1 7-7 7z"/>
                        </svg>
                    </button>}
                {chartView == null ? null : chartView.type != "Note" ?
                    <button onClick={csvExport} title={t("chart:export", {context: "csv"})} className="view-fullscreen">
                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path
                                d="m26.8 10.4h-23.6c-1.3 0-2.3 1.1-2.3 2.3v9.9c0 1.3 1.1 2.3 2.3 2.3h23.6c1.3 0 2.3-1.1 2.3-2.3v-9.9c0.1-1.2-1-2.3-2.3-2.3zm-18.7 10.6c0.6 0 1.1-0.1 1.6-0.4 0.4-0.2 0.9-0.1 1.1 0.3s0.1 0.9-0.3 1.1c-0.7 0.4-1.5 0.6-2.4 0.6-2.7 0-5-2.2-5-4.9s2.2-5 5-5c0.8 0 1.6 0.2 2.4 0.6 0.4 0.2 0.6 0.7 0.3 1.1-0.2 0.4-0.7 0.6-1.1 0.3-0.5-0.3-1-0.4-1.6-0.4-1.8 0-3.3 1.5-3.3 3.3 0 1.9 1.5 3.4 3.3 3.4zm10.2-1.5c0 0.8-0.3 1.6-0.9 2.2s-1.5 1-2.4 1h-0.1c-1.2 0-2.2-0.7-2.7-1.8-0.1-0.1-0.1-0.2-0.1-0.4 0-0.3 0.2-0.6 0.5-0.7 0.1-0.1 0.2-0.1 0.3-0.1 0.3 0 0.6 0.2 0.8 0.5 0.2 0.5 0.7 0.8 1.3 0.8 0.5 0 0.9-0.2 1.2-0.5s0.4-0.6 0.4-1v-0.1c0-0.7-1.3-1.2-1.8-1.4h-0.1c-1.5-0.5-2.2-1.4-2.2-2.7v-0.1c0-0.7 0.4-1.4 1-1.8 0.5-0.4 1.2-0.6 1.9-0.6h0.1c0.6 0 1.2 0.3 1.7 0.6 0.2 0.2 0.3 0.4 0.3 0.7 0 0.2-0.1 0.4-0.2 0.5-0.2 0.2-0.4 0.3-0.7 0.3-0.2 0-0.3 0-0.5-0.2s-0.5-0.3-0.7-0.3h-0.1c-0.7 0-1.2 0.4-1.2 0.9v0.1c0 0.7 0.5 0.9 1.1 1.2 0.4 0.1 2.9 0.9 3 2.9zm8.5-5.6-3.3 8.2c-0.1 0.3-0.4 0.5-0.8 0.5-0.3 0-0.7-0.2-0.8-0.5l-3.3-8.2c0-0.1-0.1-0.2-0.1-0.3 0-0.4 0.3-0.8 0.8-0.8 0.3 0 0.6 0.2 0.8 0.5l2.5 6.3 2.5-6.3c0.1-0.3 0.4-0.5 0.8-0.5 0.5 0 0.8 0.4 0.8 0.8 0.1 0.1 0.1 0.2 0.1 0.3z"/>
                            <path
                                d="m6.1 3.5c0-0.7 0.6-1.3 1.3-1.3h10.5v-1c0-0.7 0.6-1.2 1.2-1.1h-11.7c-1.8 0-3.3 1.5-3.3 3.3v7h2v-6.9z"/>
                            <path d="m24.7 8h-0.8v2.4h1.9v-3.4c-0.1 0.6-0.4 1-1.1 1z"/>
                            <path
                                d="M23.9,26.6c0,0.7-0.6,1.3-1.3,1.3H7.4c-0.7,0-1.3-0.6-1.3-1.3V25h-2v1.6c0,1.8,1.5,3.3,3.3,3.3h15.1 c1.8,0,3.3-1.5,3.3-3.3V25h-1.9V26.6z"/>
                            <path
                                d="m25.5 6.2-5.7-5.7c-0.2-0.2-0.5-0.3-0.7-0.4-0.6-0.1-1.2 0.4-1.2 1.1v5.3c0 0.8 0.7 1.5 1.5 1.5h5.3c0.6 0 1-0.5 1.1-1 0-0.2-0.1-0.5-0.3-0.8z"/>
                        </svg>
                    </button> : null}
                {props.editable && !props.block.jsonChart?.isDefault && !deletedSap && chartView != null ?
                    <button id={props.block.id} onClick={openModalCustom} className="edit"
                            title={t("createBlock:chart", {context: "edit"})}>
                        <svg id={props.block.id} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path id={props.block.id}
                                  d="m28.8 18.5-2.5-1.5c0.3-1.4 0.3-2.8 0-4.2l2.5-1.5c0.3-0.2 0.4-0.5 0.3-0.8-0.7-2.1-1.8-4-3.3-5.6-0.2-0.2-0.6-0.3-0.9-0.1l-2.5 1.5c-1.1-0.9-2.3-1.6-3.6-2.1v-2.9c0-0.3-0.2-0.6-0.6-0.7-2.2-0.5-4.4-0.5-6.5 0-0.3 0.1-0.6 0.4-0.6 0.7v2.9c-1.2 0.5-2.5 1.2-3.5 2.1l-2.6-1.4c-0.2-0.2-0.6-0.2-0.8 0.1-1.5 1.6-2.6 3.5-3.3 5.6-0.1 0.3 0 0.7 0.3 0.8l2.5 1.5c-0.3 1.4-0.3 2.8 0 4.2l-2.5 1.5c-0.3 0.2-0.4 0.5-0.3 0.8 0.7 2.1 1.8 4 3.3 5.6 0.2 0.2 0.6 0.3 0.9 0.1l2.5-1.5c1.1 0.9 2.3 1.6 3.6 2.1v2.9c0 0.3 0.2 0.6 0.6 0.7 2.2 0.5 4.4 0.5 6.5 0 0.3-0.1 0.6-0.4 0.6-0.7v-2.9c1.3-0.5 2.5-1.2 3.6-2.1l2.5 1.5c0.3 0.2 0.7 0.1 0.9-0.1 1.5-1.6 2.6-3.5 3.3-5.6 0-0.3-0.1-0.7-0.4-0.9zm-13.8 1.3c-2.6 0-4.8-2.1-4.8-4.8s2.1-4.8 4.8-4.8 4.8 2.1 4.8 4.8-2.2 4.8-4.8 4.8z"/>
                        </svg>
                    </button> : null}
                {props.editable || props.fullScreen ? <button id={props.block.id} onClick={!props.fullScreen ? () => {
                    props.deletePanel(props.block.id)
                } : closeButton} className="close"
                                                              title={props.fullScreen ? t("chart:preview", {context: "close"}) : t("dashboard:block", {context: "delete"})}>
                    <svg id={props.block.id} enableBackground="new 0 0 31.4 31.4" version="1.1"
                         viewBox="0 0 31.4 31.4">
                        <path id={props.block.id} className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button> : null}
            </div>
            <PreviewChartRender zoom={props.zoom} inDashboard={true} setRefreshForced={() => {
            }} refreshForced={false} refreshAuto={true} queryBuilder={props.block.dataQuery}
                                chart={chartView} eventChange={handleChangePeriod} snapshot={deletedSap}
                                randomId={props.randomId} editNote={false}/>
            {(disabledButton.prev && disabledButton.next) || chartView == null ? null : <div className="div-snapshot">
                <button id="prev-button" className={disabledButton.prev ? "disabled" : ""} onClick={prevSnapshot}>
                    <svg enableBackground="new 0 0 4.9 7.7" version="1.1" viewBox="0 0 4.9 7.7">
                        <path
                            d="m0 3.9c0-0.3 0.1-0.5 0.3-0.7l2.9-2.9c0.4-0.4 1-0.4 1.4 0s0.4 1 0 1.4l-2.2 2.2 2.2 2.1c0.4 0.4 0.4 1 0 1.4s-1 0.4-1.4 0l-2.9-2.8c-0.2-0.2-0.3-0.5-0.3-0.7z"/>
                    </svg>
                </button>
                <p className={"center-txt"} onClick={handleShowList}>{dateSnap}</p>
                <button className={disabledButton.next ? "disabled" : ""} onClick={nextSnapshot}>
                    <svg enableBackground="new 0 0 4.9 7.7" version="1.1" viewBox="0 0 4.9 7.7">
                        <path
                            d="m4.9 3.8c0 0.3-0.1 0.5-0.3 0.7l-2.9 2.9c-0.4 0.4-1 0.4-1.4 0s-0.4-1 0-1.4l2.2-2.2-2.2-2.1c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l2.9 2.8c0.2 0.2 0.3 0.5 0.3 0.7z"/>
                    </svg>
                </button>
            </div>}
            <div id="div-list-snap"
                 className={showList ? "snapshot-list snapshot-list-anim snapshot-list-transition-open" : "snapshot-list snapshot-list-transition-close"}>
                {snapList}
            </div>
            {fullScreenModal ?
                <ModalPopup propsAdd={{
                    chart: chartView,
                    index: actualIndex.current,
                    deletePanel: props.deletePanel,
                    editable: props.editable,
                    editablePanel: props.editablePanel,
                    b: props.block,
                    cancelEdit: props.cancelEdit
                }}
                            closeCLicked={false}
                            id={"fullscreen"}
                            content={FullScreenChart}
                            setShowModal={setFullScreenModal}/> : null}
            {modalDeleteSnapshot ?
                <ModalPopup propsAdd={{
                    function: deleteSnapShot,
                    id: props.block.snapshots[actualIndex.current].id,
                    txt: t("chart:snapshot", {context: "delete_confirmation"}),
                    title: t("default:deletion")
                }} closeCLicked={true}
                            content={ConfirmModalView}
                            setShowModal={setModalDeleteSnapshot}/> : null}
            {modalDeleteBlock ?
                <ModalPopup propsAdd={{
                    function: props.deletePanel,
                    id: props.block.id,
                    txt: t("dashboard:block", {context: "delete_confirmation"}),
                    title: t("default:deletion")
                }} closeCLicked={true}
                            content={ConfirmModalView}
                            setShowModal={setModalDeleteSnapshot}/> : null}
            {modalCustomChart ?
                <ModalPopup
                    propsAdd={{chart: props.block, addChart: validateModif, cancelEdit: props.cancelEdit}}
                    closeCLicked={false}
                    content={RenderViewCustomChart}
                    setShowModal={setModalCustomChart}/> : null}
            {modalGridView ?
                <ModalPopup
                    propsAdd={{id: chartView.id}}
                    closeCLicked={false}
                    content={GridViewModal}
                    setShowModal={setModalGridView}/> : null}
        </>
    )
}


export {ChartBlock}
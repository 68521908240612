import {FieldManager} from "../../../Utilities/FieldManager";
import {TableTest} from "../../Common/Table/newTable";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function Datagrid(props) {
    const [dataGrid, setDataGrid] = useState(null)
    const {t, i18n} = useTranslation()
    useEffect(() => {
        let dataGrid = {
            data: createOptions(props.tableData.series),
        }
        dataGrid.clickableColumns = createClickableColumns(dataGrid.data.rows, props.tableData.series)
        setDataGrid({...dataGrid})
    }, [props.tableData])
    return (
        <div className="container-grid">
            <h2 id={"charts" + props.id + "_" + "ChartTitle"}>{props.tableData.name}</h2>
            {dataGrid == null ? null : <TableTest
                data={dataGrid.data.rows}
                col={dataGrid.data.cols}
                clickableColumn={dataGrid.clickableColumns}
                classtd={dataGrid.data.class}
                ope={dataGrid.data.operators}
                cause={dataGrid.data.causes}
                path={dataGrid.data.pathArray}
                isCaract={false}
            />}
        </div>
    )
}

export {Datagrid}

function createData(series, j, json) {
    for (let i = 0; i < getMaxLength(series); i++) {
        let row = j == 0 ? {} : json[i]
        if (row == undefined) {
            row = {}
            for (let k = 0; k < series.findIndex(serie => series[j].id == serie.id) + 1; k++) {
                row = setRowData(row, series[k])
            }
            row = setRowData(row, series[j], i)
            json.push(row)
        } else {
                row = setRowData(row, series[j], i)
            }
            if (j == 0) {
                json.push(row)
            }
        }
    return json
}

function getMaxLength(series) {
    let max = 0
    for (let j = 0; j < series.length; j++) {
        if (series[j].value.data.length > max) {
            max = series[j].value.data.length
        }
    }
    return max
}

function setRowData(row, serie, i) {
    if (i > serie.value.data.length - 1) {
        row[serie.target.replaceAll('.', '') + serie.id] = ""
        if (serie.value.isClickable) {
            row[serie.target.replaceAll('.', '') + +serie.id + "Id"] = 0
        }
    } else {
        row[serie.target.replaceAll('.', '') + serie.id] = i == undefined ? "" : serie.value.data[i].value
        if (serie.value.isClickable) {
            row[serie.target.replaceAll('.', '') + serie.id + "Id"] = i == undefined ? 0 : serie.value.data[i].id
        }
    }
    return row
}

function createClickableColumns(data, series) {
    let clickableColumns = series.filter(serie => serie.value.isClickable)
    let pos = []
    let values = []

    for (let i = 0; i < clickableColumns.length; i++) {
        pos.push(series.findIndex(serie => serie.id == clickableColumns[i].id))
        values.push(clickableColumns[i].target.replaceAll('.', '') + clickableColumns[i].id + "Id")
    }

    return FieldManager.generateClickableColumns(data, pos, values)
}

function createOptions(series) {
    let operator = []
    let cause = []
    let classTd = []
    let path = []
    let columns = []
    let rows = []
    for (let i = 0; i < series.length; i++) {
        if (series[i].value.type == "operator") {
            operator.push(i)
        }
        if (series[i].value.type == "cause") {
            cause.push(i)
        }
        if (series[i].value.isClickable) {
            classTd.push(series[i].value.enclosure.toLowerCase() + "-color")
            path.push("/" + series[i].value.enclosure.toLowerCase())
        } else {
            classTd.push("")
            path.push("")
        }
        let row = [series[i].name, series[i].target.replaceAll('.', '') + series[i].id, series[i].value.type]
        columns.push(row)
        rows = createData(series, i, rows)
    }
    return {
        operators: operator,
        causes: cause,
        class: classTd,
        pathArray: path,
        cols: FieldManager.generateColumns(columns),
        rows: rows
    }
}
import {Link} from "react-router-dom";
import React from "react";

function Cause(props) {
    let print = props.id != -1 ?
        <Link to={`${props.path}/?id=${props.id}`} className={props.css}>{props.name}</Link> : props.name
    return (
        <span>
            {print}
        </span>)
}

export {Cause}
import {getColumnType, getType, TreeViewProperty} from "../../Utilities/treeviewProperty";
import React, {useEffect, useRef, useState} from "react";
import {DropdownAggregation} from "./dropdownAggregation";
import {ColorPicker} from "./colorPicker";
import {useTranslation} from "react-i18next";
import ModalPopup from "../../Modal/modal";
import {GridBuilder} from "../../Modal/queryBuilder";
import axios from "axios";
import {setAggregationData} from "./utilities";
import {CalculatedAggregation} from "../../Modal/calculatedAggregation";
import {AddMultipleSerie} from "../../Modal/addMultipleSerie";

function AggregationTreeview(props) {
    const [eventTriggerSelected, setEventTriggerSelected] = useState(null)
    const [buttonAdds, setButtonAdds] = useState([])
    const [modularField, setModularField] = useState(null)
    const [modalQueryBuilder, setModalQueryBuilder] = useState(false)
    const [valueFilter, setValueFilter] = useState("")
    const {t, i18n} = useTranslation()
    const entities = useRef([
        {id: "Element", text: t("default:element")},
        {id: "Intervention", text: t("default:intervention")},
        {id: "Anomaly", text: t("default:anomaly")},
        {id: "Operator", text: t("default:operator")},
        {id: "OperatorGroup", text: t("default:operatorgroup")},
        {id: "ElementType", text: t("default:elementtype")},
        {id: "AnomalyType", text: t("default:anomalytype")},
        {id: "InterventionType", text: t("default:interventiontype")},
        {id: "Characteristic", text: t("default:characteristic")},
        {id: "Company", text: t("default:company")}

    ])
    const [cssInput, setCssInput] = useState("")
    const [loadingDataTreeView, setLoadingDataTreeView] = useState(false)
    const [valueLimit, setValueLimit] = useState({max: "", min: "", objective: ""})
    const [sameFilterType, setSameFilterType] = useState(true)
    const [calculatedModal, setCalculatedModal] = useState(false)
    const [valueCalculated, setValueCalculated] = useState("")
    const [openAddMultipleSeries, setOpenAddMultipleSeries] = useState(false)

    let onChangeLimit = (e, param) => {
        if (!onlyNumberKey(e.target.value) && e.target.value != "") {
            valueLimit[param] = valueLimit[param] == null ? "" : valueLimit[param]
            setValueLimit({...valueLimit})
            return
        }
        valueLimit[param] = e.target.value
        setValueLimit({...valueLimit})
    }

    let refreshButtonsList = (pages) => {
        let maxTab = 7
        if (props.jsonChart.type == "Pie" || props.jsonChart.type == "Doughnut")
            maxTab = 1
        if (props.jsonChart.type == "Grid")
            maxTab = 1000
        let newButtons = []

        let lengthFor = pages.length > maxTab ? maxTab : pages.length

        for (let i = 0; i < lengthFor; i++) {
            newButtons.push(<button key={pages[i].id} id={pages[i].id} onClick={openPage}
                                    className={pages[i].isOpen ? "bouton-page-group-by tab-open" : "bouton-page-group-by"}>{pages[i].id}</button>)
        }

        if (pages.length < maxTab && props.jsonChart.type != "Pie" && props.jsonChart.type != "Doughnut") {
            newButtons.push(<button key={"add"} id="add" onClick={addPage} className="bouton-page-group-by" title={t("chart:serie", {context:"add"})}>
                <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                    <path
                        d="m16.9 17h10.8c1.1 0 1.9-0.9 1.9-1.9s-0.9-1.9-1.9-1.9h-10.8v-10.8c0-1.1-0.8-2-1.9-2s-2 0.8-2 1.9v0.1 10.8h-10.8c-1.1 0-1.9 0.9-1.9 1.9 0 1.1 0.9 1.9 1.9 1.9h10.8v10.8c0 1.1 0.9 1.9 1.9 1.9s1.9-0.9 1.9-1.9l0.1-10.8z"/>
                </svg>
            </button>)
        }
        return newButtons
    }

    let handleRetrieveValueLastSerie = (params) => {
        if (props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView.length == 0)
            return
        props.pageOpenedValue.current.content[params] = props.pages.current[props.pageOpenedValue.current.id - 2].content[params]
        props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1][params] = props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 2][params]
        props.pageOpenedValue.current.content.aggregation.data = [setAggregationData(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 2].groupBy.split(".")[0], props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView, t)]
        props.setPageOpened({...props.pageOpenedValue.current})
        if ((props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].aggregateOn != null
                && props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].aggregator != null)
            || props.chartValue.current.type == "Grid") {
            props.handleSendDataChart()
        }
    }

    let handleRetrieveFilter = () => {
        props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter = props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 2].filter
        setValueFilter(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 2].filter)
        handleBlur(null, props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter)
    }

    let addPage = (e) => {
        props.pages.current.forEach(page => page.isOpen = false)

        let newPage = {
            id: props.pages.current.length + 1,
            isOpen: true,
            content: {
                nameLegend: "",
                type: {
                    aggregation: "",
                    groupBy: "",
                },
                groupBy: "",
                dataTreeView: [],
                isTrendLine: false,
                limit: {
                    min: props.pages.current.length > 0 ? props.pages.current[0].content.limit.min : "",
                    max: props.pages.current.length > 0 ? props.pages.current[0].content.limit.max : "",
                    objective: props.pages.current.length > 0 ? props.pages.current[0].content.limit.objective : ""
                },
                aggregation: {
                    operation: "",
                    property: "",
                    data: []
                },
            }
        }
        props.pages.current.push(newPage)

        for (var name in props.saveGroupBy.current) {
            if (props.saveGroupBy.current[name] != null)
                props.saveGroupBy.current[name].pages.push({...newPage})
        }

        props.pageOpenedValue.current = newPage
        props.setPageOpened({...props.pageOpenedValue.current})
        props.initialiseDataChartAddedTab()
        setButtonAdds(refreshButtonsList(props.pages))
        props.setLoadDataTreeView(true)

    }

    let openPage = (e) => {
        props.pages.current.forEach(page => page.isOpen = false)
        let selectedPage = props.pages.current.find(page => page.id == e.target.id)
        selectedPage.isOpen = true
        props.pageOpenedValue.current = selectedPage
        setButtonAdds(refreshButtonsList(props.pages.current))
        setValueFilter(props.chartValue.current.dataChart[selectedPage.id - 1].filter)
        handleBlur(null, props.chartValue.current.dataChart[selectedPage.id - 1].filter, true)
        props.setPageOpened({...props.pageOpenedValue.current})
    }

    let deleteTab = (e) => {
        let selectedPageIndex = props.pages.current.findIndex(page => page.id == e.target.id)
        if (selectedPageIndex == 0) {
            props.pages.current[selectedPageIndex + 1].isOpen = true
        } else {
            props.pages.current[selectedPageIndex - 1].isOpen = true
        }
        props.pages.current.splice(selectedPageIndex, 1)
        props.pageOpenedValue.current = props.pages.current.find(page => page.isOpen)
        props.chartValue.current.dataChart.splice(selectedPageIndex, 1)
        if (props.chartValue.current.type == "Grid") {
            props.chartValue.current.series?.splice(selectedPageIndex, 1)
        }
        for (var name in props.saveGroupBy.current) {
            if (props.saveGroupBy.current[name] != null) {
                props.saveGroupBy.current[name].pages.splice(selectedPageIndex, 1);
                props.saveGroupBy.current[name].dataChart.splice(selectedPageIndex, 1)
            }
        }
        buttonAdds.splice(selectedPageIndex, 1)
        for (let i = 0; i < props.pages.current.length; i++) {
            props.pages.current[i].id = i + 1
        }
        setButtonAdds(refreshButtonsList(props.pages.current))
        setValueFilter(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter)
        if (getColumnType(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView.all, props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].groupBy?.split('.')) == "date") {
            props.chartValue.current.dateValue = "OVER YEARS"
        }
        props.setPageOpened({...props.pageOpenedValue.current})
        props.setJsonChart({...props.chartValue.current})
        handleBlur(null, props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter)
    }

    let showModularField = () => {
        if (props.chartValue.current.type == "Doughnut")
            setModularField(<><p>{t("chart:center_value")}</p>
                <div className="d-flex align-items-center centre-donut">
                    <input id={props.pageOpenedValue.current.id} checked={props.jsonChart.isCenter} type="checkbox"
                           onChange={props.handleCheckCenterText} className="checkbox-style filled mr-10"/>
                    <DropdownAggregation type={"number"} value={props.chartValue.current.dataChart[0].totalAggregator}
                                         handle={props.handleOperatorCenterText}
                                         id={props.pageOpenedValue.current.id}/>
                </div>
            </>)
        else if (props.chartValue.current.type == "Pie")
            setModularField(null)
        else if (props.chartValue.current.type == "Grid" || props.chartValue.current.type == "Number") {
            setModularField(<>
                <p>{t("chart:legend")}</p>
                <input id={props.pageOpenedValue.current.id} value={props.pageOpenedValue.current.content.nameLegend}
                       onChange={props.handleNameLegend} type="text" className="input-text"/>

            </>)
        } else
            setModularField(<>
                <p>{t("chart:legend")}</p>
                <input id={props.pageOpenedValue.current.id} value={props.pageOpenedValue.current.content.nameLegend}
                       onChange={props.handleNameLegend} type="text" className="input-text"/>

            </>)
    }

    let queryBuilderOpen = () => {
        let modalElem = document.getElementById('container-modal')
        if (modalElem != null)
            modalElem.id = 'temp'
        setModalQueryBuilder(true)
    }

    let validateQuery = (query) => {
        setValueFilter(query)
        backEvent()
        handleBlur(true, query)
    }

    let backEvent = () => {
        let modalElem = document.getElementById('temp')
        if (modalElem != null)
            modalElem.id = 'container-modal'
        setModalQueryBuilder(false)
    }

    let handleBlur = async (e, query, load) => {
        setLoadingDataTreeView(true)
        if (e != null) {
            props.chartValue.current.dataChart[props.pageOpened.id - 1].filter = query ? query : valueFilter
            props.setChartQuery(props.createQuery())
        }
        let json = {
            query: query !== undefined ? query : valueFilter//document.getElementById("input-search").value
        }
        let result = null
        await axios.post("helpers/query/Validate", json, {doNotCatch: true}).then(res => {
            result = res.data
        }).catch(error => {
            if (error.response.status == 400) {
                setCssInput("query-rouge")
            }
        })

        if (result.isValid) {
            if (load == undefined) {
                await props.loadData(json.query, props.pageOpenedValue.current.id, true)
            }
            if (props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView != undefined) {
                props.pageOpenedValue.current.content.dataTreeView = props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView.all
            }
            for (var name in props.saveGroupBy.current) {
                if (props.saveGroupBy.current[name] != null) {
                    props.saveGroupBy.current[name].dataChart[props.pageOpenedValue.current.id - 1].filter = props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter
                    props.saveGroupBy.current[name].dataChart[props.pageOpenedValue.current.id - 1].dataTreeView = props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView
                    props.saveGroupBy.current[name].pages[props.pageOpenedValue.current.id - 1].content.dataTreeView = props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView.all
                }
            }
            props.setPageOpened({...props.pageOpenedValue.current})
            setCssInput("query-vert")
        } else {
            setCssInput("query-rouge")
        }
        setLoadingDataTreeView(false)
    }

    useEffect(() => {
        addPage()
    }, [])
    useEffect(() => {
        if (props.chartValue.current.dataChart.length > 0) {
            let valueLimit = {
                min: props.chartValue.current.dataChart[0].limit.min,
                max: props.chartValue.current.dataChart[0].limit.max,
                objective: props.chartValue.current.dataChart[0].limit.objective,
            }
            setValueLimit({...valueLimit})
        }

        if (props.chartValue.current.dataChart.length > 1 && props.pageOpenedValue.current.id > 1) {
            if (getType(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter, t).field != getType(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 2].filter, t).field) {
                setSameFilterType(false)
            } else {
                setSameFilterType(true)
            }
        }
        if (props.chartValue.current.type == "Pie" || props.chartValue.current.type == "Doughnut") {
            for (let i = 1; i < props.pages.current.length; i++) {
                props.pages.current[i].isOpen = false
            }
            props.pages.current[0].isOpen = true
            props.pageOpenedValue.current = props.pages.current[0]
        }
        showModularField()
        setButtonAdds(refreshButtonsList(props.pages.current))
        if (props.chartValue.current.dataChart.length > 0) {
            if (props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView != undefined) {
                let button = document.getElementById("groupBy-retreive-button")
                if (props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView.length == 0) {
                    if (button != null) {
                        button.style.display = "none"
                    }
                } else {
                    if (button != null) {
                        button.style.display = "block"
                    }
                }
            }
        }
        if (props.jsonChart.dataChart.length > 0) {
            if (props.jsonChart.dataChart[props.pageOpenedValue.current.id - 1].groupBy != null) {
                if (props.jsonChart.dataChart[props.pageOpenedValue.current.id - 1].aggregator == "Calc") {
                    setValueCalculated(props.jsonChart.dataChart[props.pageOpenedValue.current.id - 1].aggregateOn)
                } else {
                    if (props.jsonChart.type == "Number")
                        return
                    props.pageOpenedValue.current.content.type.groupBy = getColumnType(props.jsonChart.dataChart[props.pageOpenedValue.current.id - 1].dataTreeView.all, props.jsonChart.dataChart[props.pageOpenedValue.current.id - 1].groupBy.split('.'))
                }
            }
        }

    }, [props.jsonChart, props.pageOpened])

    useEffect(() => {
        if (props.loadDataTreeView == false)
            return
        setValueFilter(props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter)
        handleBlur(null, props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].filter, false)
        props.setLoadDataTreeView(false)
    }, [props.loadDataTreeView])

    let handleOnChangeFilter = (e) => {
        setValueFilter(e.target.value)
    }

    let deleteValueCalculated = (e) => {
        setValueCalculated("")
        props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].aggregateOn = null
        props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].aggregator = "Count"
        props.pageOpenedValue.current.content.aggregation.property = ""
        props.pageOpenedValue.current.content.aggregation.operation = "Count"
        props.setJsonChart({...props.chartValue.current})
        props.setPageOpened({...props.pageOpenedValue.current})
    }

    useEffect(() => {
        if (valueCalculated == "")
            return
        props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].aggregateOn = valueCalculated
        props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1].aggregator = "Calc"
        props.pageOpenedValue.current.content.aggregation.operation = "Calc"
        props.setJsonChart({...props.chartValue.current})
        props.handleSendDataChart()
    }, [valueCalculated])


    return (
        <>
            {buttonAdds}
            {props.chartValue.current.type == "Grid" && props.chartValue.current.dataChart[props.pageOpenedValue.current.id-1]?.filter != "" ? <button onClick={()=> setOpenAddMultipleSeries(true)} className="bouton-page-group-by multiple-add">
                <svg enableBackground="new 0 0 66.1 30" version="1.1" viewBox="0 0 66.1 30">
                    <path d="M16.6,17h10.8c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9H16.6V2.4c0-1.1-0.8-2-1.9-2s-2,0.8-2,1.9v0.1  v10.8H1.9c-1.1,0-1.9,0.9-1.9,1.9C0,16.2,0.9,17,1.9,17h10.8v10.8c0,1.1,0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9L16.6,17z"/>
                    <path d="m53.4 17h10.8c1.1 0 1.9-0.9 1.9-1.9s-0.9-1.9-1.9-1.9h-10.8v-10.8c0-1.1-0.8-2-1.9-2s-2 0.8-2 1.9v10.9h-10.8c-1.1 0-1.9 0.9-1.9 1.9 0 1.1 0.9 1.9 1.9 1.9h10.8v10.8c0 1.1 0.9 1.9 1.9 1.9s1.9-0.9 1.9-1.9l0.1-10.8z"/>
                </svg>
            </button> : null}
            {props.pageOpened == null ? null :
                <div className={props.pageOpenedValue.current.id == 1 ? "group-by-box radius" : "group-by-box"}>
                    <p>{t("chart:serie", {context: "filter"})}</p>
                    <div className="d-flex align-items-center w-100">
                        <div
                            className={"mr-3 position-relative recherche-champ d-flex align-items-center w-100 " + cssInput}>
                            <input id={"input-search"} onBlur={handleBlur}
                                   value={valueFilter}
                                   onChange={handleOnChangeFilter}
                                   type="text"
                                   name="recherche"
                                   className={"w-100"}/>
                            <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <path
                                    d="m13.5 16.4v0.6c0 0.3 0.1 0.6 0.3 0.9l4.7 5.4c0.2 0.2 0.3 0.5 0.3 0.9v3.1c0 0.7 0.6 1.3 1.3 1.3h1.3c0.7 0 1.3-0.6 1.3-1.3v-3.1c0-0.3 0.1-0.6 0.3-0.9l4.7-5.4c0.2-0.2 0.3-0.5 0.3-0.9v-0.6c0-0.7-0.6-1.3-1.3-1.3h-11.9c-0.7 0-1.3 0.6-1.3 1.3z"/>
                                <path
                                    d="M26.2,6.2H3.8C3,6.2,2.3,5.5,2.3,4.7S3,3.2,3.8,3.2h22.4c0.8,0,1.5,0.7,1.5,1.5S27,6.2,26.2,6.2z"/>
                                <path
                                    d="M26.2,12.2H3.8c-0.8,0-1.5-0.7-1.5-1.5S3,9.2,3.8,9.2h22.4c0.8,0,1.5,0.7,1.5,1.5S27,12.2,26.2,12.2z"/>
                                <path
                                    d="m10.6 24.1h-6.8c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h6.8c0.8 0 1.5 0.7 1.5 1.5s-0.6 1.5-1.5 1.5z"/>
                                <path
                                    d="m7.7 18.1h-3.9c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h3.9c0.8 0 1.5 0.7 1.5 1.5 0 0.9-0.7 1.5-1.5 1.5z"/>
                            </svg>
                        </div>
                        <button onClick={queryBuilderOpen} className="bouton-recherche-avancee"
                                title={t("chart:query", {context: "builder"})}>
                            <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <path
                                    d="m0.8 26.9c0.6 0.7 1.7 0.7 2.3 0.1l6.2-6.2c4.1 3.1 9.8 3.1 13.9 0 5.1-3.8 6.2-11.1 2.3-16.2s-11-6.1-16.1-2.3-6.2 11.1-2.3 16.2l-6.2 6.2c-0.6 0.7-0.6 1.6-0.1 2.2zm15.5-7c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3 8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3z"/>
                                <circle className="default-green" cx="14.5" cy="27.5" r="2.5"/>
                                <circle className="default-green" cx="20.8" cy="27.5" r="2.5"/>
                                <circle className="default-green" cx="27.1" cy="27.5" r="2.5"/>
                            </svg>
                        </button>
                        {props.pageOpenedValue.current.id == 1 ? null :
                            <button onClick={handleRetrieveFilter} className="bouton-recup"
                                    title={t("chart:serie", {context: "reuse_previous"})}>
                                <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                    <path
                                        d="m0.5 17.6c-0.2 1.6-0.1 3.3 0.3 4.9 0.1 0.6 0.7 1.1 1.3 1.2h0.5c0.8-0.2 1.3-0.9 1.1-1.8-0.3-1.2-0.4-2.5-0.2-3.8 0.8-5.7 6.1-9.8 11.8-9 0.2 0 0.4 0.1 0.6 0.1l-0.9 1.3c-0.5 0.7 0 1.6 0.8 1.7l6.6 0.5c0.9 0 1.5-0.8 1.1-1.6l-2.8-5.9c-0.4-0.8-1.4-0.9-1.9-0.2l-1 1.5c-0.7-0.2-1.4-0.4-2.1-0.5-7.4-0.9-14.2 4.2-15.2 11.6z"/>
                                    <path
                                        d="m27.1 25.4h-15.3c-1.4 0-2.6-1.2-2.6-2.6v-3c0-1.4 1.2-2.6 2.6-2.6h15.3c1.4 0 2.6 1.2 2.6 2.6v3c0 1.5-1.2 2.6-2.6 2.6z"/>
                                </svg>
                            </button>}
                    </div>
                    {props.pages.current.length == 1 || props.jsonChart.type == "Pie" || props.jsonChart.type == "Doughnut"  ? null :
                        <button id={props.pageOpened.id} onClick={deleteTab} className="close" title={t("chart:serie", {context:"delete"})}>
                            <svg id={props.pageOpened.id} enableBackground="new 0 0 31.4 31.4" version="1.1"
                                 viewBox="0 0 31.4 31.4">
                                <path id={props.pageOpened.id} className="st0"
                                      d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                            </svg>
                        </button>}
                    {modularField}
                    <div className={"w-100"}>
                        <div style={{display: props.jsonChart.type == "Number" ? "none" : "block"}}>
                            <p>{props.chartValue.current.type == "Grid" ? t("chart:grid", {context: "columns"}) : t("chart:groupby")}</p>
                            <div className="d-flex align-items-center w-100">
                                <TreeViewProperty value={props.pageOpened.content.groupBy}
                                                  data={props.pageOpened.content.dataTreeView}
                                                  eventTriggerSelected={eventTriggerSelected}
                                                  calculated={false}
                                                  setEventTriggerSelected={setEventTriggerSelected}
                                                  handle={props.handleSelectGroupBy}
                                                  idTv={"groupby-" + props.pageOpened.id}
                                                  idModal={props.idModal}
                                                  loadingDataTreeView={loadingDataTreeView}
                                                  styleField={{'pointerEvents': loadingDataTreeView ? 'none' : 'initial'}}/>
                                {props.pageOpenedValue.current.id == 1 || props.chartValue.current.type == "Grid" || !sameFilterType ? null :
                                    <button id={"groupBy-retreive-button"} onClick={() => {
                                        handleRetrieveValueLastSerie("groupBy")
                                    }
                                    } className="bouton-recup ml-3"
                                            title={t("chart:serie", {context: "reuse_previous"})}>
                                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                            <path
                                                d="m0.5 17.6c-0.2 1.6-0.1 3.3 0.3 4.9 0.1 0.6 0.7 1.1 1.3 1.2h0.5c0.8-0.2 1.3-0.9 1.1-1.8-0.3-1.2-0.4-2.5-0.2-3.8 0.8-5.7 6.1-9.8 11.8-9 0.2 0 0.4 0.1 0.6 0.1l-0.9 1.3c-0.5 0.7 0 1.6 0.8 1.7l6.6 0.5c0.9 0 1.5-0.8 1.1-1.6l-2.8-5.9c-0.4-0.8-1.4-0.9-1.9-0.2l-1 1.5c-0.7-0.2-1.4-0.4-2.1-0.5-7.4-0.9-14.2 4.2-15.2 11.6z"/>
                                            <path
                                                d="m27.1 25.4h-15.3c-1.4 0-2.6-1.2-2.6-2.6v-3c0-1.4 1.2-2.6 2.6-2.6h15.3c1.4 0 2.6 1.2 2.6 2.6v3c0 1.5-1.2 2.6-2.6 2.6z"/>
                                        </svg>
                                    </button>}
                            </div>
                        </div>
                        <div style={{display: props.jsonChart.type == "Grid" ? "none" : "block"}}>
                            <p>{t("chart:aggregateby")}</p>
                            <div className="d-flex align-item-center">
                                <div style={{display: valueCalculated != "" ? "block" : "none", width: "100%"}}
                                     className="value-calculated">{valueCalculated}
                                    <button onClick={deleteValueCalculated} className="value-calculated-trash">
                                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                            <path
                                                d="m22.3 9.5h-14.6c-1 0-1.9 0.8-1.9 1.9v16.6c0 1 0.8 1.9 1.9 1.9h14.6c1 0 1.9-0.8 1.9-1.9v-16.7c0-1-0.8-1.8-1.9-1.8zm-9.5 17c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8zm7.2 0c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8z"/>
                                            <path className="trash-animation"
                                                  d="m27.3 4.8h-4.9v-3.3c0-0.7-0.6-1.2-1.2-1.2h-12.3c-0.7 0-1.2 0.6-1.2 1.2v3.4h-5c-0.4 0-0.7 0.3-0.7 0.7v1.4c0 0.4 0.3 0.7 0.7 0.7h24.5c0.4 0 0.7-0.3 0.7-0.7v-1.5c0-0.4-0.3-0.7-0.6-0.7zm-16.9 0c-0.4 0-0.6-0.3-0.6-0.6v-1.2c0-0.4 0.3-0.6 0.6-0.6h9.1c0.4 0 0.6 0.3 0.6 0.6v1.2c0 0.4-0.3 0.6-0.6 0.6h-9.1z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div style={{display: valueCalculated == "" ? "flex" : "none", width : "100%"}}><TreeViewProperty
                                    value={props.pageOpened.content.aggregation.property}
                                    data={props.pageOpened.content.aggregation.data}
                                    eventTriggerSelected={eventTriggerSelected}
                                    setEventTriggerSelected={setEventTriggerSelected}
                                    handle={props.handleSelectAggregationProperty}
                                    idTv={"AggregationProperty-" + props.pageOpened.id}
                                    idModal={props.idModal}
                                    loadingDataTreeView={loadingDataTreeView}
                                    styleField={{'pointerEvents': loadingDataTreeView ? 'none' : 'initial'}}/>
                                    <DropdownAggregation value={props.pageOpened.content.aggregation.operation}
                                                         type={props.pageOpened.content.type.aggregation}
                                                         handle={props.handleDropdownOperation}
                                                         id={props.pageOpened.id}/>
                                <button onClick={() => setCalculatedModal(true)} className="bouton-recup" title={t("chart:aggregateby", {context:"calc"})}>
                                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                        <path
                                            d="M12.6,6.5H8.9V2.8c0-0.7-0.6-1.2-1.3-1.2S6.4,2.1,6.4,2.8v3.7H2.7C2,6.5,1.5,7,1.5,7.7S2,9,2.7,9h3.7v3.7   c0,0.7,0.6,1.2,1.2,1.2s1.3-0.6,1.3-1.2V9h3.7c0.7,0,1.2-0.6,1.2-1.3S13.2,6.5,12.6,6.5z"/>
                                        <path
                                            d="m10.2 18.1-2.6 2.6-2.6-2.6c-0.5-0.5-1.3-0.5-1.8 0s-0.5 1.3 0 1.8l2.6 2.6-2.5 2.5c-0.5 0.5-0.5 1.3 0 1.8s1.3 0.5 1.8 0l2.6-2.6 2.6 2.6c0.5 0.5 1.3 0.5 1.8 0s0.5-1.3 0-1.8l-2.6-2.6 2.6-2.6c0.5-0.5 0.5-1.3 0-1.8-0.6-0.4-1.4-0.4-1.9 0.1z"/>
                                        <path
                                            d="M27.3,9h-9.8c-0.7,0-1.2-0.6-1.2-1.3s0.6-1.2,1.2-1.2h9.8c0.7,0,1.2,0.6,1.2,1.2S28,9,27.3,9z"/>
                                        <path
                                            d="m27.3 23.7h-9.8c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.2-1.2h9.8c0.7 0 1.2 0.6 1.2 1.2s-0.5 1.2-1.2 1.2z"/>
                                        <circle cx="22.5" cy="18.1" r="1.7"/>
                                        <circle cx="22.5" cy="26.8" r="1.7"/>
                                    </svg>
                                </button>
                                </div>
                                <button style={{display: valueCalculated == "" ? "none" : "block"}} onClick={() => setCalculatedModal(true)} className="bouton-recup">
                                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                        <path
                                            d="M12.6,6.5H8.9V2.8c0-0.7-0.6-1.2-1.3-1.2S6.4,2.1,6.4,2.8v3.7H2.7C2,6.5,1.5,7,1.5,7.7S2,9,2.7,9h3.7v3.7   c0,0.7,0.6,1.2,1.2,1.2s1.3-0.6,1.3-1.2V9h3.7c0.7,0,1.2-0.6,1.2-1.3S13.2,6.5,12.6,6.5z"/>
                                        <path
                                            d="m10.2 18.1-2.6 2.6-2.6-2.6c-0.5-0.5-1.3-0.5-1.8 0s-0.5 1.3 0 1.8l2.6 2.6-2.5 2.5c-0.5 0.5-0.5 1.3 0 1.8s1.3 0.5 1.8 0l2.6-2.6 2.6 2.6c0.5 0.5 1.3 0.5 1.8 0s0.5-1.3 0-1.8l-2.6-2.6 2.6-2.6c0.5-0.5 0.5-1.3 0-1.8-0.6-0.4-1.4-0.4-1.9 0.1z"/>
                                        <path
                                            d="M27.3,9h-9.8c-0.7,0-1.2-0.6-1.2-1.3s0.6-1.2,1.2-1.2h9.8c0.7,0,1.2,0.6,1.2,1.2S28,9,27.3,9z"/>
                                        <path
                                            d="m27.3 23.7h-9.8c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.2-1.2h9.8c0.7 0 1.2 0.6 1.2 1.2s-0.5 1.2-1.2 1.2z"/>
                                        <circle cx="22.5" cy="18.1" r="1.7"/>
                                        <circle cx="22.5" cy="26.8" r="1.7"/>
                                    </svg>
                                </button>
                            </div>
                            {props.chartValue.current.type == "Grid" ||
                            props.chartValue.current.type == "Number" ||
                            props.chartValue.current.type == "Doughnut" ||
                            props.chartValue.current.type == "Pie" ?
                                null :
                                <>
                                    <p>{t("chart:thresholds")}</p>
                                    <div className="d-flex align-items-center seuil">
                                        <input id={props.pageOpenedValue.current.id} value={valueLimit.min}
                                               onBlur={e => {
                                                   props.handleLimit(e, "min")
                                               }}
                                               onChange={e => {
                                                   onChangeLimit(e, "min")
                                               }}
                                               type="text" className="input-text" placeholder={t("chart:threshold", {context: "min"})}/>
                                        <input id={props.pageOpenedValue.current.id} value={valueLimit.max}
                                               onBlur={e => {
                                                   props.handleLimit(e, "max")
                                               }}
                                               onChange={e => {
                                                   onChangeLimit(e, "max")
                                               }} type="text" className="input-text" placeholder={t("chart:threshold", {context: "max"})}/>
                                        <input id={props.pageOpenedValue.current.id} value={valueLimit.objective}
                                               onBlur={e => {
                                                   props.handleLimit(e, "objective")
                                               }}
                                               onChange={e => {
                                                   onChangeLimit(e, "objective")
                                               }} type="text" className="input-text" placeholder={t("chart:threshold", {context: "obj"})}/>
                                    </div>
                                    {props.pageOpened.content.type.groupBy == "date" || props.pageOpened.content.type.groupBy == "number" ?
                                        <>
                                        <p>{t("chart:trend")}</p>
                                        <input id={props.pageOpenedValue.current.id}
                                               checked={props.pageOpened.content.isTrendLine}
                                               type="checkbox"
                                               onChange={props.handleCheckedTrendLine}
                                               className="checkbox-style filled mr-10"/></> : null}
                                </>}
                            <p>{t("chart:colors")}</p>
                            <div className="color-picker-grid">
                                <ColorPicker chartValue={props.chartValue} pageOpenedValue={props.pageOpenedValue}
                                             pageOpened={props.pageOpened}
                                             setJsonChart={props.setJsonChart}
                                             jsonChart={props.jsonChart}/>
                            </div>
                        </div>
                    </div>
                </div>}
            {modalQueryBuilder ?
                <ModalPopup propsAdd={{
                    entities: entities.current,
                    validateEvent: validateQuery,
                    backEvent: backEvent,
                    showButtonDelete: true
                }}
                            closeCLicked={false}
                            content={GridBuilder}
                            setShowModal={setModalQueryBuilder}/> : null}
            {calculatedModal ?
                <ModalPopup propsAdd={{
                    data: props.pageOpened.content.aggregation.data,
                    pageOpened: props.pageOpened,
                    setValueCalculated: setValueCalculated
                }}
                            closeCLicked={false}
                            id={'calculatedModal'}
                            content={CalculatedAggregation}
                            setShowModal={setCalculatedModal}/> : null}
            {openAddMultipleSeries ?
                <ModalPopup propsAdd={{
                    data: props.pageOpened.content.dataTreeView,
                    setPageOpened: props.setPageOpened,
                    chartValue: props.chartValue,
                    setJsonChart:props.setJsonChart,
                    pages:props.pages,
                    handleSendDataChart:props.handleSendDataChart,
                    deleteTab:deleteTab,
                }}
                            closeCLicked={false}
                            content={AddMultipleSerie}
                            setShowModal={setOpenAddMultipleSeries}/> : null}

        </>
    )
}

function onlyNumberKey(value) {
    const reg = new RegExp('^[0-9]+$')
    return reg.test(value)
}


export {AggregationTreeview, onlyNumberKey}
import {useEffect, useRef, useState} from "react";
import {ColorPickerRecompose} from "./colorPickerRecompose";

function ColorPicker(props) {
    const [picker, setPicker] = useState([])
    const [idPicker, setIdPicker] = useState(-1)
    const pickerTest = useRef()
    useEffect(() => {
        if (props.chartValue.current.type == null)
            return
        if (props.chartValue.current.dataChart.length == 0)
            return
        let pickersTemps = []
        if (props.chartValue.current.type == "Pie" || props.chartValue.current.type == "Doughnut") {
            if (props.chartValue.current.dataChart[0].data != null) {

                if (props.chartValue.current.dataChart[0].color.length == 1 || props.chartValue.current.dataChart[0].color.length < props.chartValue.current.dataChart[0].data.length) {
                    for (let i = 0; i < props.chartValue.current.dataChart[0].data.length - 1; i++)
                        props.chartValue.current.dataChart[0].color.push("#" + (Math.floor(Math.random() * 0xFFFFFF)).toString(16))
                }
                for (let i = 0; i < props.chartValue.current.dataChart[0].data.length; i++) {
                    pickersTemps.push(<ColorPickerRecompose key={"colorPicker-" + i}
                                                            showName={true}
                                                            name={props.chartValue.current.dataChart[0].data[i].x}
                                                            value={props.chartValue.current.dataChart[0].color[i]}
                                                            handleColorPicker={handleColorPicker}
                                                            id={i}
                                                            setPicker={setIdPicker}/>)
                }
            }
        } else {

            pickersTemps.push(<ColorPickerRecompose key={"colorPicker"}
                                                    showName={false}
                                                    value={props.chartValue.current.dataChart[props.pageOpenedValue.current.id - 1]?.color[0]}
                                                    handleColorPicker={handleColorPicker}
                                                    id={"colorPicker"}
                                                    setPicker={setIdPicker}/>)
        }
        setPicker(pickersTemps)
    }, [props.pageOpened, props.jsonChart.dataChart, props.jsonChart.type])

    let handleColorPicker = (e) => {
        let idPickerTemp = 0
        setIdPicker(previousState => {
            idPickerTemp = previousState
            return {
                ...previousState
            }
        })
        let chart = props.chartValue.current.dataChart[props.pageOpened.id - 1]
        if (chart == undefined)
            return
        if (props.chartValue.current.type == "Pie" || props.chartValue.current.type == "Doughnut") {
            chart.color[idPickerTemp] = e.value
        } else {
            chart.color = []
            chart.color.push(e.value)
        }
        props.setJsonChart({...props.chartValue.current})

    }

    return (
        <>
            {picker}
        </>
    )

}

export {ColorPicker}
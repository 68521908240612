import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import '../../style/chartsMenu.css'
import axios from "axios";
import {CategoryChartMenu} from "./categoryChartMenu";
import ModalPopup from "./modal";
import {ConfirmModalView} from "./confirmModal";
import {convertJsonChart, convertJsonPage} from "../charts/CustomChartWindow/utilities";
import {useTranslation} from "react-i18next";
import {RenderViewCustomChart} from "../charts/CustomChartWindow/renderViewCustomChart";
import {InputRenaming} from "../Dashboard/inputRenaming";
import {FullScreenChart} from "./FullScreenChart";
import {cumulatedcurveSVG, ListSVG, noteSVG} from "../../style/chartSVG";
import {LineSeries} from "@syncfusion/ej2-react-charts";

function ChartsMenuView(props) {
    const {t, i18n} = useTranslation()
    const [chartsBlocks, setChartsBlocks] = useState([])
    const [query, setQuery] = useState()
    const [content, setContent] = useState(null)
    const [visu, setVisu] = useState(false)
    const [dpSource, setDpSource] = useState([
        {id: "all", name: t("chart:type", {context: "all"})},
        {id: "Column", name: t("chart:type", {context: "column"})},
        {id: "Bar", name: t("chart:type", {context: "bar"})},
        {id: "StackingColumn", name: t("chart:type", {context: "stackedcolumn"})},
        {id: "Line", name: t("chart:type", {context: "line"})},
        {id: "Cumulative", name: t("chart:type", {context: "cumulative"})},
        {id: "Pie", name: t("chart:type", {context: "pie"})},
        {id: "Doughnut", name: t("chart:type", {context: "doughnut"})},
        {id: "Number", name: t("chart:type", {context: "number"})},
        {id: "Grid", name: t("chart:type", {context: "grid"})},
        {id: "Note", name: t("chart:type", {context: "note"})}
    ])
    const [typeSelected, setTypeSelected] = useState("all")
    const [confirmModal, setConfirmModal] = useState(false)
    const [ConfirmModalCategory, setConfirmModalCategory] = useState(false)
    const [chartOpened, setChartOpened] = useState()
    const [modalCustomChart, setModalCustomChart] = useState()
    const [listChartEdited, setListChartEdited] = useState(null)
    const [nameTitle, setNameTitle] = useState("")
    const [showBlocks, setShowBlocks] = useState(false)
    const [fullScreenModal, setFullScreenModal] = useState(false)
    const chartsList = useRef([])

    let add = async (jsonChart, dataQuery, pages, pos) => {
        listChartEdited.jsonChart = jsonChart
        setListChartEdited({...listChartEdited})
        refreshEditedCharts(jsonChart)
        let chartSelected = chartsList.current.find(c => c.jsonChart.id == jsonChart.id) //chartsBlocks.find(c => c.jsonChart.id == jsonChart.id)
        chartSelected.jsonChart = jsonChart
        setChartOpened({...chartSelected})
    }

    useEffect(() => {
        if (chartOpened == null)
            return
        setFullScreenModal(true)
    }, [chartOpened])

    let handleClickView = (e) => {
        getDataSeries(e.target.id)
    }

    let deleteCategory = async () => {
        await axios.delete("/eData/Dashboard/delete/category/" + props.catId)
        backMenu()
    }

    let closeModal = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    let backMenu = () => {
        props.setContent(
            <CategoryChartMenu setShowModal={props.setShowModal} addChart={props.addChart}
                               setContent={props.setContent} listBlocks={props.listBlocks}/>
        )
    }

    let deleteChart = async (id) => {
        let chartSelectedIndex = chartsList.current.findIndex(c => c.jsonChart.id == id) //chartsBlocks.findIndex(c => c.jsonChart.id == id)
        await axios.delete("/eData/dashboard/delete/chart/" + chartsList.current[chartSelectedIndex].jsonChart.id)

        chartsList.current.splice(chartSelectedIndex, 1)//chartsBlocks.splice(chartSelectedIndex, 1)
        setChartsBlocks([...chartsList.current])
    }

    let addChart = async (e) => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
        let chartSelected = await getDataSeries(e.target.id)
        props.addChart(chartSelected.jsonChart, chartSelected.dataQuery, chartSelected.pages, chartSelected.panel)

    }

    let refreshEditedCharts = (chart) => {
        if (listChartEdited == null)
            return
        if (props.listBlocks == undefined)
            return
        let checkExist = props.listBlocks.findIndex(b => b.jsonChart.id == chart.id)
        if (checkExist != -1) {
            props.addChart(chart, listChartEdited.dataQuery, listChartEdited.pages, listChartEdited.panel)
        }
    }

    let getDataSeries = async (id) => {
        let chartSelected = chartsList.current.find(c => c.jsonChart.id == id)

        await axios.get("/eData/dashboard/process/chart2/" + chartSelected.jsonChart.id).then(res => {
            let series = res.data.series
            if (series.length == 0) {
                return
            }
            chartSelected.jsonChart.abscissaType = res.data.abscissaType
            for (let i = 0; i < chartSelected.jsonChart.dataChart.length; i++) {
                chartSelected.jsonChart.dataChart[i].data = series[i].value.groups
                chartSelected.jsonChart.dataChart[i].dataCenter = series[i].value.total
                chartSelected.jsonChart.dataChart[i].color = series[i].value.colors
                chartSelected.jsonChart.dataChart[i].trendLine.isChecked = series[i].value.trend != null ? true : false
                chartSelected.jsonChart.dataChart[i].trendLine.data = series[i].value.trend
                chartSelected.pages[i].content.isTrendLine = series[i].value.trend != null ? true : false

                if (chartSelected.jsonChart.type == "Grid") {
                    chartSelected.jsonChart.dataChart[i].groupBy = series[i].target
                    chartSelected.jsonChart.dataChart[i].color = ["#0db1e7"]
                    chartSelected.jsonChart.dataChart[i].aggregator = null
                    chartSelected.jsonChart.dataChart[i].aggregateOn = null
                }
            }

            if (chartSelected.jsonChart.type == "Grid") {
                chartSelected.jsonChart.series = series
            }
        })
        setChartOpened({...chartSelected})
        return chartSelected
    }

    let openModalConfirmDelete = (e) => {
        setConfirmModal({show: true, id: e.target.id})
    }

    let openModalConfirmDeleteCategory = (e) => {
        setConfirmModalCategory({show: true, id: e.target.id})
    }

    useEffect(async () => {
        refreshBlocksData(showBlocks)
    }, [showBlocks])

    let refreshBlocksData = async (showBlocks) => {
        chartsList.current = []
        let charts = null
        await axios.get("/eData/dashboard/get/charts/" + props.catId).then(res => {
            charts = res.data
        })
        charts.forEach(d => {
            let chart = createChart(d, handleClickView, openModalConfirmDelete, addChart)
            chartsList.current.push(chart)
        })
        chartsList.current = showBlocks ? chartsList.current : chartsList.current.filter(chart => {
            for (let i = 0; i < props.listBlocks.length; i++) {
                if (props.listBlocks[i].jsonChart?.id == chart.jsonChart.id)
                    return false
            }
            return true
        })
        setChartsBlocks([...chartsList.current])

        let div = document.getElementById('container-modal')
        if (div != null)
            div.id = 'container-modaladdBlock'
        setNameTitle(props.title)
    }


    let ValidateQueryBuilder = (resultQuery) => {
        let div = document.getElementById('container-modaladdBlock')
        div.id = 'container-modalCustomBlock'
        props.setContent(<RenderViewCustomChart title={props.title} setContent={props.setContent}
                                                catId={props.catId} addChart={props.addChart} queryBuilder={resultQuery}
                                                setShowModal={props.setShowModal}
                                                refreshBlock={props.refreshBlock}
                                                isDefault={props.isDefault}
                                                canBack={true}
                                                idModal={"container-modalCustomBlock"}
                                                listBlocks={props.listBlocks}/>)
    }

    let createChartButton = (e) => {
        ValidateQueryBuilder("")
    }

    let handleChangeDp = (e) => {
        setTypeSelected(e.value)
    }

    let handleShowBlocks = (e) => {
        setShowBlocks(e.target.checked)
    }

    let contentDivCharts = <>
        <div className="d-flex align-items-center">
            <DropDownListComponent change={handleChangeDp} value={"all"} dataSource={dpSource} id="dropdown_list_chart" placeholder=''
                                   fields={{text: "name", value: "id"}}
                                   popupHeight="220px"/>
            <button className="d-flex align-items-center button-graph" onClick={createChartButton}
                    title={t("createBlock:chart", {context: "create"})}>
                <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                    <path
                        d="m16.9 17h10.8c1.1 0 1.9-0.9 1.9-1.9s-0.9-1.9-1.9-1.9h-10.8v-10.8c0-1.1-0.8-2-1.9-2s-2 0.8-2 1.9v0.1 10.8h-10.8c-1.1 0-1.9 0.9-1.9 1.9 0 1.1 0.9 1.9 1.9 1.9h10.8v10.8c0 1.1 0.9 1.9 1.9 1.9s1.9-0.9 1.9-1.9l0.1-10.8z"/>
                </svg>
                <p>{t("createBlock:chart", {context: "create"})}</p>
            </button>
        </div>
        <div className="d-flex align-items-center mb-1">
            <input onChange={handleShowBlocks} id="switch-flat-add" className="switch-refresh switch--flat--refresh"
                   type="checkbox"
                   checked={showBlocks}/>
            <label htmlFor="switch-flat-add"></label>
            <p>{t("createBlock:chart", {context: "view_existant"})}</p>
        </div>
        
        <div className="container-graph">
            <div className="d-grid grid-column-3 gap-50">
                {chartsBlocks.map((chart) => {
                    if (typeSelected == "all")
                        return chart.content
                    if (typeSelected == chart.jsonChart.type)
                        return chart.content
                })}
            </div>
        </div>
    </>
    useEffect(() => {
        setContent(contentDivCharts)
    }, [chartsBlocks, typeSelected])

    let viewMenu = {
        title: nameTitle,
        button: props.isDefault ? null : <button onClick={openModalConfirmDeleteCategory}
                                                 className="d-flex align-items-center button-delete-dashboard"
                                                 title={t("createBlock:category_delete")}>
            <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                <path
                    d="m22.3 9.5h-14.6c-1 0-1.9 0.8-1.9 1.9v16.6c0 1 0.8 1.9 1.9 1.9h14.6c1 0 1.9-0.8 1.9-1.9v-16.7c0-1-0.8-1.8-1.9-1.8zm-9.5 17c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8zm7.2 0c0 0.8-0.6 1.4-1.4 1.4s-1.4-0.6-1.4-1.4v-13.8c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v13.8z"/>
                <path className="trash-animation"
                      d="m27.3 4.8h-4.9v-3.3c0-0.7-0.6-1.2-1.2-1.2h-12.3c-0.7 0-1.2 0.6-1.2 1.2v3.4h-5c-0.4 0-0.7 0.3-0.7 0.7v1.4c0 0.4 0.3 0.7 0.7 0.7h24.5c0.4 0 0.7-0.3 0.7-0.7v-1.5c0-0.4-0.3-0.7-0.6-0.7zm-16.9 0c-0.4 0-0.6-0.3-0.6-0.6v-1.2c0-0.4 0.3-0.6 0.6-0.6h9.1c0.4 0 0.6 0.3 0.6 0.6v1.2c0 0.4-0.3 0.6-0.6 0.6h-9.1z"/>
            </svg>
        </button>
    }

    let backChartsChoice = async () => {
        if (listChartEdited != null) {
            let blockEdited = chartsList.current.find(b => b.jsonChart.id == listChartEdited.jsonChart.id)
            blockEdited.jsonChart = listChartEdited.jsonChart
            blockEdited.content = getContent(blockEdited.jsonChart, handleClickView, openModalConfirmDelete, addChart)
        }
        setChartsBlocks([...chartsList.current])
        setViewUI(viewMenu)
        setVisu(false)
    }

    let validateRenaming = (e) => {
        setNameTitle(e.target.value)
        let txt = {
            name: e.target.value
        }
        axios.put("eData/Dashboard/rename/category/" + props.catId, txt)
    }

    let ValidVisu = (chart) => {
        refreshBlocksData(showBlocks)
        let findBlock = props.listBlocks.find(bloc => bloc.jsonChart.id == chart.id)
        if (findBlock == undefined)
            return
        props.refreshBlock(chart)

    }

    const [viewUI, setViewUI] = useState(viewMenu)

    return (
        <>
            <div className="modal-box">
                <div className="container-default">
                    <div className="d-flex align-items-center mb-1">
                        <button onClick={visu ? backChartsChoice : backMenu} className="previous-button">
                            <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <path
                                    d="m27.4 14.9v11.7c0 1.8-1.9 2.9-3.5 2l-10.1-5.8-10.1-5.9c-1.5-0.9-1.5-3.1 0-4l20.2-11.6c1.5-0.9 3.5 0.2 3.5 2v11.6z"/>
                            </svg>
                        </button>
                        <InputRenaming isOwner={props.isDefault ? false : true} name={nameTitle}
                                       functionValidate={validateRenaming}/>
                        {viewUI.button}
                        <button onClick={closeModal} className="close">
                            <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                                <path className="st0"
                                      d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                            </svg>
                        </button>
                    </div>
                    {content}
                    {confirmModal.show ?
                        <ModalPopup propsAdd={{
                            function: deleteChart,
                            id: confirmModal.id,
                            txt: t("createBlock:chart", {context: "delete_confirmation"})
                        }} closeCLicked={true}
                                    content={ConfirmModalView}
                                    setShowModal={setConfirmModal}/> : null}
                    {ConfirmModalCategory.show ?
                        <ModalPopup propsAdd={{
                            function: deleteCategory,
                            id: confirmModal.id,
                            txt: t("createBlock:category_delete_confirmation"),
                            title: t("default:deletion")
                        }} closeCLicked={true}
                                    content={ConfirmModalView}
                                    setShowModal={setConfirmModalCategory}/> : null}
                    {modalCustomChart ?
                        <ModalPopup propsAdd={{chart: chartOpened, addChart: add, cancelEdit: refreshEditedCharts}}
                                    closeCLicked={false}
                                    content={RenderViewCustomChart}
                                    setShowModal={setModalCustomChart}/> : null}
                    {fullScreenModal ?
                        <ModalPopup propsAdd={{
                            chart: chartOpened.jsonChart,
                            index: -1,
                            editable: true,
                            category: true,
                            b: chartOpened,
                            cancelEdit: ValidVisu,
                            inModal: true
                        }}
                                    closeCLicked={false}
                                    id={"fullscreen"}
                                    content={FullScreenChart}
                                    setShowModal={setFullScreenModal}/> : null}
                </div>
            </div>
        </>
    )
}

function getImg(type, id) {
    switch (type) {
        case "Column":
            return <img id={id} src={require('../../img/diagramme-barres-verticales.png')}/>
        case "Grid":
            return <img id={id} src={require('../../img/diagramme-datagrid.png')}/>
        case "Bar":
            return <img id={id} src={require('../../img/diagramme-barres-horizontales.png')}/>
        case "StackingColumn":
            return <img id={id} src={require('../../img/diagramme-barres-empilees.png')}/>
        case "Line":
            return <img id={id} src={require('../../img/diagramme-courbe.png')}/>
        case "Pie":
            return <img id={id} src={require('../../img/diagramme-camembert.png')}/>
        case "Doughnut":
            return <img id={id} src={require('../../img/diagramme-donut.png')}/>
        case "Number":
            return <img id={id} src={require('../../img/diagramme-nombre.png')}/>
        case "Note":
            return <img id={id} src={require('../../img/diagramme-note.png')}/>
        case "Cumulative":
            return <img id={id} src={require('../../img/diagramme-courbe-cumulee.png')}/>
    }
}

function createChart(chartSelect, handleClickView, openModalConfirmDelete, addChart) {
    let chart = {
        jsonChart: convertJsonChart(chartSelect),
        pages: convertJsonPage(chartSelect),
        panel: {
            sizeX: 2,
            sizeY: 2,
            col: 0,
            row: 0
        },
        snapshots: chartSelect.snapshots,
        dataQuery: chartSelect.filter,
    }

    chart.content = getContent(chartSelect, handleClickView, openModalConfirmDelete, addChart)

    return chart
}

function getContent(chartSelect, handleClickView, openModalConfirmDelete, addChart) {

    let blockChart = <div key={chartSelect.id} id={chartSelect.id} className="bloc-graph">
        <div className="bloc-graph-button">
            <button id={chartSelect.id} onClick={handleClickView}>
                <svg id={chartSelect.id} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                    <path id={chartSelect.id}
                          d="m15 10.1c-0.5 0-1 0.1-1.4 0.2 0.8 0.4 1.3 1.3 1.3 2.3 0 1.5-1.1 2.7-2.6 2.7-0.9 0-1.7-0.5-2.1-1.1-0.1 0.3-0.1 0.6-0.1 0.9 0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9-2.2-5-4.9-5z"/>
                    <path id={chartSelect.id}
                          d="m28.5 12.3c-2.9-2.7-7.8-6.3-13.5-6.3s-10.6 3.6-13.5 6.3c-1.7 1.6-1.7 3.9 0 5.5 2.9 2.6 7.8 6.2 13.5 6.2s10.6-3.6 13.5-6.3c1.7-1.5 1.7-3.9 0-5.4zm-13.5 9.7c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7c0 3.8-3.1 7-7 7z"/>
                </svg>
            </button>
            {chartSelect.isDefault ? null : <button id={chartSelect.id} onClick={openModalConfirmDelete}>
                <svg id={chartSelect.id} enableBackground="new 0 0 31.4 31.4" version="1.1"
                     viewBox="0 0 31.4 31.4">
                    <path id={chartSelect.id} className="st0"
                          d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                </svg>
            </button>}
        </div>
        <div className="d-flex flex-column bloc-title-graph" onClick={addChart}>
            <h3 id={chartSelect.id}>{chartSelect.name}</h3>
            <div id={chartSelect.id} className="bloc-graph-img">
                {getImg(chartSelect.type, chartSelect.id)}
            </div>
        </div>
    </div>

    return blockChart
}


export {ChartsMenuView}
import {getPath, getType} from "../../Utilities/treeviewProperty";
import {BarSeries, ColumnSeries, LineSeries, PieSeries, StackingColumnSeries} from "@syncfusion/ej2-react-charts";
import axios from "axios";

function setAggregationData(key, dataTreeView, t) {
    return {
        id: "9999999",
        field: getType(key, t).field,
        label: getType(key, t).text,
        type: "collection",
        columns: dataTreeView.numeric[0].columns
    }
}

function setValueTreeView(property, e, jsonChart, params) {
    let chartFind = jsonChart.dataChart[e.node.offsetParent.id.split('-')[1] - 1]
    let dataTreeView = jsonChart.dataChart[e.node.offsetParent.id.split('-')[1] - 1].dataTreeView
    chartFind[property] = getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, dataTreeView[params], false)
    return chartFind
}

function setCornerRadius(type, pos, last) {
    switch (type) {
        case "Column" :
            return {bottomLeft: 0, bottomRight: 0, topLeft: 4, topRight: 4}
        case "StackingColumn" :
            if (pos == last)
                return {bottomLeft: 0, bottomRight: 0, topLeft: 4, topRight: 4}
            else
                return {bottomLeft: 0, bottomRight: 0, topLeft: 0, topRight: 0}

        case "Bar" :
            return {bottomLeft: 0, bottomRight: 4, topLeft: 0, topRight: 4}
    }
}

function getOpositeColor(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    let color = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    };
    let OpositeColor = ((0.3 * (color.r)) + (0.59 * (color.g)) + (0.11 * (color.b)) <= 110) ? '#FFF' : '#2b587b';
    return OpositeColor
}

function getService(type) {
    switch (type) {
        case "Column":
            return ColumnSeries
        case "Bar":
            return BarSeries
        case "StackingColumn":
            return StackingColumnSeries
        case "Cumulative":
        case "Line":
            return LineSeries
        case "Doughnut":
            return PieSeries
        case "Pie":
            return PieSeries
    }
}

function convertJsonChart(chart, options) {
    if (chart == null)
        return chart
    let jsonChart = {
        id: chart.id,
        name: chart.name,
        type: chart.type,
        dateValue: chart.period,
        label: chart.label,
        service: getService(chart.type),
        isCenter: chart.hasCenterValue,
        isDefault: chart.isDefault,
        abscissaType: chart.abscissaType,
        dataChart: [],
        series: chart.type == "Grid" ? chart.series : null
    }
    chart.series.forEach(s => {
        let newSeries = {
            id: s.id,
            data: s.value == undefined ? null : s.value.groups,
            dataCenter: s.value == undefined ? null : s.value.total,
            color: s.value == undefined ? null : s.value.colors,
            groupBy: s.groupBy == "" ? null : s.groupBy,
            aggregateOn: s.target,
            aggregator: s.aggregator,
            totalAggregator: s.totalAggregator,
            name: s.name,
            filter: s.filter,
            trendLine: {
                isChecked: s.value == undefined ? null : s.value.trend != null ? true : false,
                data: s.value == undefined ? null : s.value.trend,
            },
            limit: {
                min: s.thresholds.min == null ? null : s.thresholds.min.value,
                max: s.thresholds.max == null ? null : s.thresholds.max.value,
                objective: s.thresholds.obj == null ? null : s.thresholds.obj.value,
            },
            ids: []
        }

        if (chart.type == "Grid") {
            newSeries.groupBy = s.target
            newSeries.color = ["#0db1e7"]
            newSeries.aggregator = null
            newSeries.aggregateOn = null
        }

        if(chart.abscissaType == "DateTime"){
            newSeries.data = convertDatetime(s.value.groups)
        }
        jsonChart.dataChart.push(newSeries)
    })
    if(options){
        jsonChart.yScale = options.yScale
        jsonChart.xScale = options.xScale
    }
    return jsonChart
}

function convertDatetime(data){
    let list = []
    for(let d of data){
        let newData = {
            x: new Date(d.x.year,d.x.month,d.x.day),
            y:d.y
        }
        list.push(newData)
    }
    return list
}

function convertJsonPage(chart) {
    if (chart == null)
        return chart
    let pages = []
    let i = 1
    chart.series.forEach(s => {
        let page = {
            id: i,
            isOpen: true,
            content: {
                title: chart.name,
                nameLegend: s.name,
                type: {
                    aggregation: chart.type,
                    groupBy: "",
                },
                groupBy: s.groupBy,
                isTrendLine: s.value == undefined ? null : s.value.trend != null ? true : false,
                limit: {
                    min: s.thresholds.min == null ? "" : s.thresholds.min.value,
                    max: s.thresholds.max == null ? "" : s.thresholds.max.value
                },
                aggregation: {
                    operation: s.aggregator,
                    property: s.target,
                    data: []
                },
            }
        }
        if (chart.type == "Grid") {
            page.content.aggregation.property = ""
            page.content.groupBy = s.target
        }
        i++
        pages.push(page)
    })

    return pages
}

function checkChart(chart) {
    let error = true
    if (chart.type == null) {
        error = false
    }
    chart.dataChart.forEach(d => {
        if (chart.type != "Note") {
            if (chart.type != "Number") {
                if (d.groupBy == null) {
                    error = false
                }
            }
            if (chart.type != "Grid") {
                if (d.aggregateOn == null) {
                    error = false
                }
                if (d.aggregator == null) {
                    error = false
                }
            }

            if (d.filter == "") {
                error = false
            }
        }
    })

    return error
}

function generateChartQuery(chartTemp) {
    let center = chartTemp.isCenter ? "CENTERED " : ""
    let date = chartTemp.dateValue == "" ? "" : chartTemp.dateValue
    let label = chartTemp.type == "Note" ? `"${chartTemp.label}" AS LABEL` : ""
    let text = center + `${chartTemp.type} CHART "${chartTemp.name}" ${date} WITH ${label}`


    if (chartTemp.type != "Note") {
        chartTemp.dataChart.forEach(d => {
            text += "\n" + generateSerieQuery(d, chartTemp)
        })
    }

    return text
}

function generateSerieQuery(d, chartValue) {
    let entity = getType(d.filter).field
    let total = d.totalAggregator != null && d.totalAggregator != "" ? d.totalAggregator + " TOTAL" : "NO TOTAL"
    let groupBy = d.groupBy == null || d.groupBy == undefined || d.groupBy == "" ? "" : "GROUP BY " + d.groupBy
    let trendLine = d.trendLine.isChecked ? "TREND" : ""
    let filter = `FILTER BY "${d.filter}" THEN`
    let min = d.limit.min == null ? "" : `MIN ${d.limit.min}`
    let max = d.limit.max == null ? "" : `MAX ${d.limit.max}`
    let obj = d.limit.objective == null ? "" : `OBJ ${d.limit.objective}`
    let limit = min == "" && max == "" && obj == "" ? '' : `THRESHOLDS ${min} ${max} ${obj}`
    if (chartValue.type == "Grid")
        return `SERIE "${d.name}", (${d.color.toString()}) ON ${entity} ${filter}  LIST(${d.groupBy})`
    else
        return `SERIE "${d.name}", (${d.color.toString()}) ON ${entity} ${filter}  ` + groupBy + ` ${d.aggregator}(${d.aggregateOn}) ${total} ${trendLine} ${limit}`
}

async function loadChartData(chartValue) {
    if (!checkChart(chartValue))
        return
    let jsonChartTemp = null
    await axios.post("/eData/dashboard/process/chart", {chart: generateChartQuery(chartValue)}).then(res => jsonChartTemp = res.data)

    let chartTemp = convertJsonChart(jsonChartTemp)
    chartTemp.id = chartValue.id

    for (let i = 0; i < chartTemp.dataChart.length; i++) {
        chartTemp.dataChart[i].dataTreeView = chartValue.dataChart[i].dataTreeView
    }

    return chartTemp
}


export {
    checkChart,
    getService,
    setAggregationData,
    convertJsonPage,
    convertJsonChart,
    setValueTreeView,
    getOpositeColor,
    setCornerRadius,
    generateChartQuery,
    loadChartData
}
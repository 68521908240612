import {useEffect, useState} from "react";
import axios from "axios";
import {FieldManager} from "../../Utilities/FieldManager";
import {useTranslation} from "react-i18next";
import {TableTest} from "../Common/Table/newTable";

function SignatureRenderView(props) {
    const [data, setData] = useState([])
    const {t, i18n} = useTranslation()
    useEffect(async () => {
        let dataTemp = null
        await axios.get('/eData/interventions/signatures/' + props.propsAdd.id).then(res => dataTemp = res.data)
        dataTemp.forEach(data => {
            data.cssState = ""
            if (data.isValidating && data.isRejecting) {
                data.cssState = "color-red-light"
            } else if (!data.isValidating && data.isRejecting) {
                data.cssState = "color-red-dark"
            }
        })
        setData(dataTemp)
    }, [])

    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }
    return (
        <div className="modal-box">
            <div className="box-title d-flex align-items-center">
                <img src={require('../../img/signature.png')}/>
                <h3 className="signature-color pl-2">{t("intervention:signature")}</h3>
                <button onClick={closeButton}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path
                            d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
            </div>
            <div className="modal-contain">
                <TableTest
                    data={data}
                    col={FieldManager.generateColumns([[t("views:operator"), 'operator'], [t("intervention:signature", {context: "behalf"}), 'behalf'], [t("views:date"), 'dateTime'], [t("intervention:signature", {context: "comment"}), 'comment']])}
                    clickableColumn={undefined}
                    classtd={['', '', '', '', '']}
                    ope={[0]}
                    cause={[]}
                    path={["", "", "", "", ""]}
                    isCaract={false}
                />
            </div>
        </div>
    )
}

export {SignatureRenderView}
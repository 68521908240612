import {FieldManager} from "../../Utilities/FieldManager";
import React from "react";
import {useTranslation} from "react-i18next";
import {TableTest} from "../Common/Table/newTable";

function ContentPlanning(props) {
    const {t, i18n} = useTranslation()
    return (
        <ul className="planning">
            <li className="fw-bold">{t("element:planned_interventions")}</li>
            <TableTest
                data={props.dataIntervention}
                col={FieldManager.generateColumns([[t("view:nature"), 'nature'], [t("default:date"), 'date', "date"]])}
                clickableColumn={undefined}
                classtd={['', '', '', '', '']}
                ope={[]}
                cause={[]}
                path={["", ""]}
                isCaract={false}
            />
            <li className="fw-bold table-prets mt-1">{t("element:loans")}</li>
            <TableTest
                data={props.dataLoans}
                col={FieldManager.generateColumns([[t("views:requestor"), 'requestor'], [t("element:loans", {context: "begin_date"}), 'beginDate', "date"], [t("element:loans", {context: "end_date"}), 'endDate', "date"], [t("element:loans", {context: "date"}), 'creationDate', "date"], [t("element:loans", {context: "place"}), 'designation']])}
                clickableColumn={undefined}
                classtd={['', '', '', '', '']}
                ope={[0]}
                cause={[]}
                path={["", "", "", "", ""]}
                isCaract={false}
            />
        </ul>
    )
}

export {ContentPlanning}
import React, {useEffect, useRef, useState} from 'react'
import {Menu} from '../Menu/menu'
import axios from 'axios'
import {FieldManager} from '../../Utilities/FieldManager'
import {generateKey} from "../Common/utilities";
import {useTranslation} from "react-i18next";
import {TableTest} from "../Common/Table/newTable";
import {GridBuilder} from "../Modal/queryBuilder";
import ModalPopup from "../Modal/modal";

function RenderSearchView() {
	const [reference, setReference] = useState("")
	const [data, setData] = useState(null)
	const [option, setOption] = useState("Element")
	const [showTable, setShowTable] = useState(<></>)
	const [optionTemp, setOptionTemp] = useState("Element")
	const [isLoading, setIsLoading] = useState(false)
	const {t, i18n} = useTranslation()
	const entities = [
		{id: "Element", text: t("default:element")},
		{id: "Intervention", text: t("default:intervention")},
		{id: "Anomaly", text: t("default:anomaly")}
	]
	const entitiesQueryBuiler = useRef()
	const [modalSearchAdvanced, setModalSearchAdvanced] = useState(false)
	const [cssInput, setCssInput] = useState("")

	useEffect(() => {
		document.title = t("default:search")
	}, [])

	const handleSearchChange = event => {
		setReference(event.target.value)
	}
	const handleOptionChange = event => {
		setOptionTemp(event.target.value)
	}
	
	let enterValidate = (e) => {
		if (e.target.value.length == 0){
			return
		}
        if (e.key === "Enter") {
			handleSubmit()
        }
    }
	const handleSubmit = event => {
		if (cssInput == "query-rouge")
			return
		if (reference == "")
			return
		setIsLoading(true)
		setOption(optionTemp)
		let txt = {
			text: reference
		}
	
		if (optionTemp == "Anomaly") {
			axios.post('/eData/search/anomalies/', txt)
				.then(res => {
					setData(res.data)
					setShowTable(true)
				})
				.finally(() => {
					setIsLoading(false)
				});
		} else if (optionTemp == "Intervention") {
			axios.post('/eData/search/interventions/', txt)
				.then(res => {
					setData(res.data)
					setShowTable(true)
				})
				.finally(() => {
					setIsLoading(false)
				});
		} else if (optionTemp == "Element") {
			axios.post('/eData/search/elements', txt)
				.then(res => {
					setData(res.data)
					setShowTable(true)
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}

	let openQueryBuilder = () => {
		let entitiesTemp = entities.filter(entity => entity.id == optionTemp)
		entitiesQueryBuiler.current = entitiesTemp
		setShowTable(null)
		setModalSearchAdvanced(true)
	}

	let validateQuery = (query) => {
		document.body.classList.remove("overflow-hidden")
		document.getElementById("input-search").value = query
		setReference(query)
		setModalSearchAdvanced(false)
		handleBlur()
	}
	let backEvent = () => {
		document.body.classList.remove("overflow-hidden")
		setModalSearchAdvanced(false)
	}

	let handleBlur = async (e) => {
		let json = {
			query: document.getElementById("input-search").value
		}
		let resultat = null
		await axios.post("helpers/query/Validate", json).then(res => {
			resultat = res.data
		})
		if (!resultat.isQuery) {
			setCssInput("")
			return
		}
		if (resultat.isValid) {
			setCssInput("query-vert")
		} else {
			setCssInput("query-rouge")
		}

	}

	useEffect(() => {
		if (data == null)
			return
		if (data.length > 0)
			setShowTable(<ShowElemList option={option} data={data}/>)
		else
			setShowTable(<p className="text-center mt-3">{t("search:empty")}</p>)
	}, [data])
	return (
    <>
	<Menu search={true} general={true} caract={true} controleCard={true} lifesheet={true} tableau={true} controlCard={true}/>
		<div className="h-35 padding-constraint text-center recherche-logo">
					<svg enableBackground="new 0 0 80 80" version="1.1" viewBox="0 0 80 80">
						<linearGradient id="a" x1="7.3067" x2="72.852" y1="52.836" y2="52.836" gradientTransform="matrix(1 0 0 -1 0 82)" gradientUnits="userSpaceOnUse">
							<stop stopColor="#77C5B7" offset=".2976"/>
							<stop stopColor="#72C4BA" offset=".3848"/>
							<stop stopColor="#43BAC2" offset="1"/>
						</linearGradient>
						<path className="st5" d="m18.9 17.6v-2.9h2.9v2.9h-2.9zm-2.9 0v2.9h2.9v-2.9h-2.9zm-2.8 5.8h2.8v-2.9h-2.9v2.9zm2.8 5.7h2.9v-2.9h-2.9v2.9zm0 23.2h-2.9v2.9h2.9v-2.9h2.9v-2.9h-2.9v2.9zm-2.8-20.2v2.9h2.8v-2.9h-2.8zm-5.9-8.7h2.9v-2.9h-2.9v2.9zm5.9-8.7h-5.9v3h2.9v2.9h2.8v-5.9h0.2zm0-8.6v2.9h2.8v-2.9h-2.8zm-3 0h-2.9v2.9h2.9v-2.9zm8.7-2.9v2.9h2.9v-2.9h-2.9zm54 26c0 4.6-1.1 9-3.4 13-2.2 4.1-5.5 7.2-9.8 9.5s-9.5 3.3-15.7 3.3h-16.4v-2.9h2.9v-2.9h-2.9v2.9h-2.9v-5.7h-2.9v-2.9h-3v2.9h-5.7v-2.9h-3v-2.9h2.9v2.9h5.9v-2.9h2.9v2.9h2.9v2.9h3v-5.7h-5.8v-2.9h-5.9v-2.8h2.9v-2.9h2.9v2.9h2.9v-3h-2.9v-5.8h2.9v-2.9h-2.9v2.9h-2.9v-5.8h2.9v-2.9h2.9v-2.9h-2.9v-3h-2.9v2.9h-5.8v-2.9h2.9v-2.7h5.8v-2.8h2.9v-2.9h3v2.9h-2.9v2.9h2.9v-2.9h2.9v-2.9h14.2c9.1 0 15.9 2.5 20.9 7.6 4.8 5.1 7.2 11.2 7.2 18.4zm-42.4-17.3v2.9h2.9v-2.9h-2.9zm-2.9 0h2.9v-2.8h-2.9v2.8zm0 11.5v2.9h2.9v-2.9h-2.9v-2.9h2.9v-2.9h-2.9v2.9h-2.9v3h2.9v-0.1zm2.9 2.8v2.9h2.9v-2.9h-2.9zm0 11.6h-2.9v2.9h2.9v-2.9zm2.8-2.8v-2.9h-2.9v2.9h2.9zm2.9 8.7h-2.9v2.9h2.9v-2.9zm18.7-14.7c0-8.3-4.7-12.6-14.3-12.6h-3v25.7h1.2c5.7 0 9.8-1 12.3-3 2.5-2.2 3.8-5.5 3.8-10.1zm-27.3-5.6zm0 28.9z"/>
						<path className="st1" d="m10 65c2.9 0 4 1.4 4 3.6s-1.1 3.4-4 3.4h-5.6v0.9c0 1 0.5 1.6 1.9 1.6h7.2v2.4h-7.3c-3 0-4.1-1.6-4.1-3.9v-4.1c0-2.2 1.1-3.9 4.1-3.9h3.8zm-5.6 5.2h5.6c1.2 0 1.7-0.5 1.7-1.4s-0.5-1.5-1.7-1.5h-3.8c-1.2 0-1.9 0.7-1.9 1.6v1.2h0.1z"/>
						<path className="st1" d="M24,68.6v2.2h-8.1v-2.2H24z"/>
						<path className="st1" d="m33.1 61.6c4.5 0 6.9 2.5 6.9 7.6s-2.4 7.6-6.9 7.6h-5.4c-0.9 0-1.2-0.4-1.2-1.2v-12.7c0-0.9 0.4-1.2 1.2-1.2h5.4zm-3.9 12.3c0 0.2 0.1 0.2 0.2 0.2h3.6c3 0 4.2-1.5 4.2-5s-1.2-5-4.2-5h-3.6c-0.2 0-0.2 0.1-0.2 0.2v9.6z"/>
						<path className="st1" d="m50.2 65c3 0 4.1 1.6 4.1 3.9v5.6c0 1.7-0.5 2.4-2.5 2.4h-5.5c-2.9 0-4-1.4-4-3.6 0-2.1 1.1-3.4 4-3.4h5.6v-0.9c0-0.9-0.5-1.6-1.9-1.6h-7.1v-2.4h7.3zm1.8 8.9v-2.2h-5.6c-1.2 0-1.7 0.5-1.7 1.4s0.5 1.5 1.6 1.5h5c0.5 0 0.7-0.3 0.7-0.7z"/>
						<path className="st1" d="m58.1 65v-2.9h2.4v2.9h3.2v2.2h-3.2v5.6c0 1.4 0.5 1.7 1.6 1.7h1.6v2.2h-2.1c-2.5 0-3.5-1-3.5-4.1v-5.5h-1.6v-2.1h1.6z"/>
						<path className="st1" d="m73.8 65c3 0 4.1 1.6 4.1 3.9v5.6c0 1.7-0.5 2.4-2.5 2.4h-5.4c-2.9 0-4-1.4-4-3.6 0-2.1 1.1-3.4 4-3.4h5.6v-0.9c0-0.9-0.5-1.6-1.9-1.6h-7.1v-2.4h7.2zm1.8 8.9v-2.2h-5.6c-1.2 0-1.7 0.5-1.7 1.4s0.5 1.5 1.6 1.5h5c0.5 0 0.7-0.3 0.7-0.7z"/>
					</svg>
		</div>
		<div className="bg-bleu">
			<div className="margin-constraint mx-auto recherche">
				<h1 className="w-60 text-center mx-auto">{t("search:header")}</h1>
				<div className="box-recherche w-60 p-3 mx-auto mt-2">
						<div className="mx-auto">
							<label htmlFor="recherche" className="d-block"><b>{t("search:field_label")}</b></label>
							<div className="d-flex align-items-center justify-content-between">
								<ComboBoxType optionSelected={optionTemp}
											  options={entities}
											  handleChangeValue={handleOptionChange}/>
								<div
									className={"position-relative recherche-champ d-flex align-items-center " + cssInput}>
									<input id={"input-search"} onKeyPress={enterValidate} onBlur={handleBlur} onChange={handleSearchChange}
										   type="text"
										   name="recherche"
										   className={"w-100"}/>
									<svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
										<path
											d="m13.5 16.4v0.6c0 0.3 0.1 0.6 0.3 0.9l4.7 5.4c0.2 0.2 0.3 0.5 0.3 0.9v3.1c0 0.7 0.6 1.3 1.3 1.3h1.3c0.7 0 1.3-0.6 1.3-1.3v-3.1c0-0.3 0.1-0.6 0.3-0.9l4.7-5.4c0.2-0.2 0.3-0.5 0.3-0.9v-0.6c0-0.7-0.6-1.3-1.3-1.3h-11.9c-0.7 0-1.3 0.6-1.3 1.3z"/>
										<path
											d="M26.2,6.2H3.8C3,6.2,2.3,5.5,2.3,4.7S3,3.2,3.8,3.2h22.4c0.8,0,1.5,0.7,1.5,1.5S27,6.2,26.2,6.2z"/>
										<path
											d="M26.2,12.2H3.8c-0.8,0-1.5-0.7-1.5-1.5S3,9.2,3.8,9.2h22.4c0.8,0,1.5,0.7,1.5,1.5S27,12.2,26.2,12.2z"/>
										<path
											d="m10.6 24.1h-6.8c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h6.8c0.8 0 1.5 0.7 1.5 1.5s-0.6 1.5-1.5 1.5z"/>
										<path
											d="m7.7 18.1h-3.9c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h3.9c0.8 0 1.5 0.7 1.5 1.5 0 0.9-0.7 1.5-1.5 1.5z"/>
									</svg>
								</div>
								<button onClick={openQueryBuilder} className="bouton-recherche-avancee"
										title={t("search:query_builder")}>
									<svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
										<path
											d="m0.8 26.9c0.6 0.7 1.7 0.7 2.3 0.1l6.2-6.2c4.1 3.1 9.8 3.1 13.9 0 5.1-3.8 6.2-11.1 2.3-16.2s-11-6.1-16.1-2.3-6.2 11.1-2.3 16.2l-6.2 6.2c-0.6 0.7-0.6 1.6-0.1 2.2zm15.5-7c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3 8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3z"/>
										<circle className="default-green" cx="14.5" cy="27.5" r="2.5"/>
										<circle className="default-green" cx="20.8" cy="27.5" r="2.5"/>
										<circle className="default-green" cx="27.1" cy="27.5" r="2.5"/>
									</svg>
								</button>
							</div>
						</div>
					<button onClick={handleSubmit} type="submit" id="submit-recherche"
							className={isLoading ? "bouton mt-3 px-3 pt-1 pb-1 loading" : "bouton mt-3 px-3 pt-1 pb-1"}>{isLoading ?
						<span className="spinner"></span> : t("default:proceed")}</button>
					{showTable}
				</div>
				<p className="fw-bold copyright mt-0 mb-0">© IMPLEX 2023</p>
			</div>
		</div>
		{modalSearchAdvanced ?
			<ModalPopup propsAdd={{
				entities: entitiesQueryBuiler.current,
				validateEvent: validateQuery,
				backEvent: backEvent,
				option: optionTemp,
				showButtonDelete: false
			}}
						closeCLicked={false}
						content={GridBuilder}
						setShowModal={setModalSearchAdvanced}/> : null}
	</>
	)
}
export default RenderSearchView

function ShowElemList(props){
	const {t, i8n} = useTranslation()
	let path = ""
	let css = ""
	let columns = []
	let clickableColumns = []
	switch (props.option) {
		case "Element":
			path = ["/element", "", ""]
			css = ["element-color", "", ""]
			columns = [[t("views:designation"), 'designation'], [t("views:reference"), 'reference'], [t("views:type"), 'type']]
			clickableColumns = FieldManager.generateClickableColumns(props.data, [0], ["id"])
			break;
		case "Intervention":
			path = ["", "/intervention", "/element"]
			css = ["", "intervention-color", "element-color"]
			columns = [[t("views:type"), 'type'], [t("views:designation"), 'designation'], [t("search:linked_designation"), 'element.designation']]
			clickableColumns = FieldManager.generateClickableColumns(props.data, [1, 2], ["id", "element.id"])
			break;
		case "Anomaly":
			path = ["", "/anomaly", "/element"]
			css = ["", "anomalie-color", "element-color"]
			columns = [[t("views:type"), 'type'], [t("views:designation"), 'designation'], [t("search:linked_designation"), 'element.designation']]
			clickableColumns = FieldManager.generateClickableColumns(props.data, [1, 2], ["id", "element.id"])
			break;
		default:
			break;
	}
	return (
		<div className="recherche-table mt-3">
			<TableTest
				key={props.data}
				data={props.data}
				col={FieldManager.generateColumns(columns)}
				clickableColumn={clickableColumns}
				classtd={css}
				ope={[]}
				cause={[]}
				path={path}
				isCaract={false}
			/>
		</div>
	) 
}

function ComboBoxType(props) {
	const [options, setOptions] = useState([])
	useEffect(() => {
		let optionsTemps = []
		for (let i = 0; i < props.options.length; i++) {
			optionsTemps.push(<option value={props.options[i].id}
									  key={generateKey(props.options[i].id)}>{props.options[i].text}</option>)
		}
		setOptions(optionsTemps)
	}, [props.options])

	return (
		<>
			<select value={props.optionSelected} onChange={props.handleChangeValue} className="recherche-combo">
				{options}
			</select>
		</>
	)
}
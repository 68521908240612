import {LIFESHEET_SVG} from "../../style/menuSVG";
import {FieldManager} from "../../Utilities/FieldManager";
import React, {useEffect, useState} from "react";
import {BullContainer} from "../Common/bullContainer";
import {useTranslation} from "react-i18next";
import {TableTest} from "../Common/Table/newTable";
import axios from "axios";

function LifeSheet(props) {
    const {t, in18} = useTranslation()
    const [data, setData] = useState ([])
    const [option, setOption] = useState("normal")
    const [valid, setValid] = useState(false)

    let handleChangeCheckBox = (e) => {
        setValid(e.target.checked)
    }
    
    useEffect(async () =>  {
        if (props.id == null)
            return 
        await axios.get(`/eData/elements/lifeSheet/${props.id}/${option}/${valid}`).then(res => {
            setData(res.data)
        }) 
    }, [props.id, option, valid])
    
    return (
        <div id="lifesheet">
            <BullContainer title={t("default:lifesheet")} img={LIFESHEET_SVG} content={
                <TableTest
                    data={data}
                    col={FieldManager.generateColumns([[t("default:date"), 'date', "date"], [t("views:designation"), 'designation'], [t("views:operator"), 'operator', "operator"], [t("views:periodicity"), 'periodicity'], [t("views:periodicity", {context: "next_date"}), 'nextDate', "date"]])}
                    clickableColumn={FieldManager.generateClickableColumns(data, [1], ["id"])}
                    classtd={['', '', '', '', '']}
                    ope={[2]}
                    cause={[]}
                    path={["", "", "", "", ""]}
                    isCaract={false}
                    setSearchParams={props.setSearchParams}
                    setOption={setOption}
                    option={option}
                    handleChangeCheckBox={handleChangeCheckBox}
                    actionOperators={false}
                    lifeSheetOptions={true}
                />
            }/>
        </div>
    )
}

export {LifeSheet}
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ListViewComponent} from "@syncfusion/ej2-react-lists";
import '../../style/generateReport.css'
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {useTranslation} from "react-i18next";

function GenerateReport(props) {
    const [reports, setReports] = useState([])
    const [loading, setLoading] = useState(false)
    const [reportSelected, setReportSelected] = useState(null)
    const [reportDefault, setReportDefault] = useState(null)
    const [formatSelected, setFormatSelected] = useState("PDF")
    const listView = useRef()
    const {t, i18n} = useTranslation()

    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    let handleClick = async (e) => {
        if (reportDefault == null && reportSelected == null)
            return alert("Sélectionner un rapport avant de valider")
        setLoading(true)
        await axios.get('/helpers/documents/generateReport/' + props.propsAdd.name + "/" + props.propsAdd.id + "/" + reportSelected.id + "/" + formatSelected, {responseType: 'blob'}).then(res => {
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = reportSelected.name + '.' + formatSelected.toLowerCase();
            a.click();
        }).finally(() => {
            setLoading(false)
        })

    }

    let handleFormat = (e) => {
        setFormatSelected(e.value)
    }

    let onChangeListReports = async (e) => {
        setReportSelected({id: e.data.id, name: e.data.text})
        if (reportDefault == null)
            return
        let divReport = document.getElementById(reportDefault.id + "-" + reportDefault.name)
        divReport.classList.remove("rapport-actif")
    }

    let selectedDefaultReport = (e) => {
        if (reportSelected != null)
            document.getElementById("report-list_" + reportSelected.id).classList.remove("e-active")
        let divReport = document.getElementById(reportDefault.id + "-" + reportDefault.name)
        divReport.classList.add("rapport-actif")
        setReportSelected(reportDefault)
    }

    useEffect(async () => {
        let dataReport = null

        await axios.get('/helpers/documents/reports/' + props.propsAdd.name + "/" + props.propsAdd.id).then(res => dataReport = res.data)
        let reportsTemp = []
        dataReport.all.forEach(data => {
            let report = {
                id: data.id.toString(),
                text: data.name
            }
            if (dataReport.default != null) {
                if (data.id != dataReport.default.id)
                    reportsTemp.push(report)
            } else {
                reportsTemp.push(report)
            }
        })
        setReportDefault(dataReport.default)
        setReports(reportsTemp)
    }, [])
    useEffect(() => {
        if (reportDefault != null)
            selectedDefaultReport()
    }, [reportDefault])

    return (
        <div className="modal-box modal-box-report">
            <div className="box-title d-flex align-items-center">
                <img src={require('../../img/pdf.png')}/>
                <h3 className="pdf-color pl-2">{t("views:report", {context: "header"})}</h3>
                <button onClick={closeButton}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
            </div>
            <div className="modal-contain">
                <div className="default-report">
                    <h4>{t("views:report", {context: "default"})}</h4>
                    <div style={{pointerEvents: reportDefault == null ? "none" : "initial"}}
                         id={reportDefault == null ? "null" : reportDefault.id + "-" + reportDefault.name}
                         onClick={selectedDefaultReport}>{reportDefault == null ? t("views:no_report_default") : reportDefault.name}</div>
                </div>
                <div className="list-report">
                    <h4>{t("views:report", {context: "list"})}</h4>
                    {reports.length == 0 ? <p>{t("views:no_report")}</p> : null}
                    <div className="overflow-auto">
                        <ListViewComponent ref={listView} id="report-list" select={onChangeListReports}
                                           dataSource={reports}/>
                    </div>
                </div>
                <div className="format">
                    <h4>{t("views:report", {context: "format"})}</h4>
                    <DropDownListComponent id={"dp-select-format"} value={formatSelected}
                                           placeholder='' change={handleFormat}
                                           dataSource={[{text: "PDF", id: "PDF"}, {text: "DocX", id: "DocX"}]}
                                           fields={{text: "text", value: "id"}} popupHeight="220px"/>
                </div>
                <button onClick={handleClick}
                        className={loading ? "bouton-connexion bouton px-10 pt-1 pb-1 loading" : "bouton-connexion bouton px-10 pt-1 pb-1"}>{loading ?
                    <span className="spinner"></span> : t("views:report", {context: "proceed"})}</button>
            </div>
        </div>
    )

}

export {GenerateReport}
import {useEffect, useState} from "react";
import {getOpositeColor} from "./utilities";

function NumberBlock(props) {
    const [blocks, setBlocks] = useState([])
    const [blocksJson, setBlocksJson] = useState([])

    useEffect(() => {
        let blocksTemp = []
        for (let i = 0; i < blocksJson.length; i++) {
            blocksTemp.push(<div key={"blocknumber" + blocksJson[i].id}
                                 style={{"background": blocksJson[i].color}}
                                 id={"block-number" + blocksJson[i].id} className="bloc-nombre">
                <svg width="100%" height="100%" viewBox="0 -20 500 150" preserveAspectRatio="xMidYMid meet"
                     xmlns='http://www.w3.org/2000/svg'>
                    <text style={{"fill": blocksJson[i].colorText}} dominantBaseline={"central"} textAnchor="middle"
                          x="50%">{blocksJson[i].name}</text>
                    <text style={{"fill": blocksJson[i].colorText}} dominantBaseline={"central"} textAnchor="middle"
                          x="50%" y="50%">{blocksJson[i].dataCenter}</text>
                </svg>
            </div>)
        }
        setBlocks(blocksTemp)
    }, [blocksJson])

    useEffect(() => {
        let blocksJsonTemp = []
        for (let i = 0; i < props.data.length; i++) {
            let newBlock = {
                id: Math.ceil(Math.random() * 1000000000),
                color: props.color[i],
                name: props.data[i].name,
                dataCenter: props.data[i].dataCenter,
                colorText: getOpositeColor(props.color[i])
            }
            blocksJsonTemp.push(newBlock)
        }
        setBlocksJson(blocksJsonTemp)

    }, [props.data, props.color])
    return (
        <div className="container-nombre">
            <h2 id={"charts" + props.id + "_" + "ChartTitle"}>{props.title}</h2>
            <div className="d-flex nombre-media-query">
                {blocks}
            </div>
        </div>
    )
}

export {NumberBlock}
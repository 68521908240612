import React from "react"
import {
    AccumulationChartComponent,
    AccumulationDataLabel,
    AccumulationLegend,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    Inject,
    PieSeries
} from '@syncfusion/ej2-react-charts'
import {colorNameToHex, convertHexToColor} from '@syncfusion/ej2-charts'

function Doughnut(props) {

    let handleLoad = (e) => {
        e.chart.height = '95%'
        e.chart.width = '95%'

        e.chart.accumulationDataLabelModule.getSaturatedColor = function (point, color) {
            var saturatedColor;
            if (this.marginValue >= 1) {
                saturatedColor = color === 'transparent' ? this.getLabelBackground(point) : color;
            } else {
                saturatedColor = this.getLabelBackground(point);
            }
            saturatedColor = (saturatedColor === 'transparent') ? window.getComputedStyle(document.body, null).backgroundColor : saturatedColor;
            var rgbValue = convertHexToColor(colorNameToHex(saturatedColor));
            var contrast = Math.round((rgbValue.r * 299 + rgbValue.g * 587 + rgbValue.b * 114) / 1000);
            return contrast >= 128 ? '#2b587b' : 'white';
        };
    }
    let onAnimationComplete = (e) => {
        centerDiv(props.id, props.contentCenterTxt, props.showCenterTxt)
    }

    let handleResized = (e) => {
        e.chart.refresh()
    }

    return (
        <>
            <AccumulationChartComponent resized={handleResized} ref={props.chartRef} className="component-center"
                                        load={handleLoad}
                                        style={{"height": "calc(100% - 50px)", "width": "95%"}} id={"charts" + props.id}
                                        title={props.title}
                                        legendSettings={{
                                            visible: true,
                                            position: 'Bottom',
                                            shapeHeight: 15,
                                            shapeWidth: 25
                                        }}

                                        enableSmartLabels={true}
                                        tooltip={{
                                            enable: true,
                                            enableAnimation: true,
                                            fadeOutDuration: 0,
                                        }}
                                        animationComplete={(e) => onAnimationComplete(e)}
                    >
                        <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
                        <AccumulationSeriesCollectionDirective>
                            <AccumulationSeriesDirective legendShape="Rectangle" palettes={props.colors}
                                                         name={props.name} dataSource={props.data} xName='x' yName='y'
                                                         innerRadius={props.radius + '%'} startAngle={0}
                                                         endAngle={360} radius='70%' explode={true} explodeOffset='5%'
                                                         dataLabel={{
                                                             visible: true,
                                                             name: 'text',
                                                             position: 'Inside',
                                                             font: {
                                                                 fontWeight: '800',
                                                             }
                                                         }}
                            >
                            </AccumulationSeriesDirective>
                        </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>

        </>
    )
}

function centerDiv(id, contentCenterText, isCenter) {
    let checkText = document.getElementById(`center_title${id}`)
    if (checkText != null)
        return
    let group = document.getElementById(`charts${id}_Series_0`);

    let newText = document.createElementNS("http://www.w3.org/2000/svg", "text");
    newText.setAttributeNS(null, "id", `center_title${id}`);
    newText.setAttributeNS(null, "text-anchor", "middle");
    newText.setAttributeNS(null, "dominant-baseline", "central");
    newText.setAttributeNS(null, "font-weight", "bold");
    newText.setAttributeNS(null, "class", "centre-texte")

    let textNode = document.createTextNode(isCenter ? contentCenterText : "")
    newText.appendChild(textNode)

    let gbb = group.getBBox();

    let sizeFont = (gbb.width + gbb.height) / (4 * contentCenterText.toString().replaceAll('.', '').length)
    if (sizeFont < 0)
        sizeFont = 0

    newText.setAttributeNS(null, "font-size", (sizeFont))
    newText.setAttributeNS(null, "x", gbb.x + gbb.width / 2);
    newText.setAttributeNS(null, "y", gbb.y + gbb.height / 2);

    group.appendChild(newText);
}


export {Doughnut}
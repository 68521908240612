import React, {useState} from "react"
import ModalPopup from "../Modal/modal"

function ButtonBanner(props){
    const [showModal, setShowModal] = useState(false)
    return (
        <>
        <button onClick={() => setShowModal(true)} title={props.tooltip}>{props.img}</button>
            {showModal ?
                <ModalPopup removeScrollBarOnClose={true} closeCLicked={false} propsAdd={{id: props.id, name: props.name}} content={props.content}
                            setShowModal={setShowModal}/> : null}
        </>
    )
}

export {ButtonBanner}
import {sort_by} from "./SortManager"

export class FieldManager {
    constructor() {
    }

    static generateColumns(nameColumns) {
        let columns = []
        for (var j = 0; j < nameColumns.length; ++j) {
            let column = {
                isSorted: false,
                isSortedDesc: false
            }
            column.Header = nameColumns[j][0]
            column.accessor = nameColumns[j][1]
            if (nameColumns[j][2] == "operator") {
                column.sortType = (id, desc) => {
                    return sort_by(id, desc, (a) => a.toUpperCase(), "name")
                }
            }
            if (nameColumns[j][2] == "cause") {
                column.sortType = (id, desc) => {
                    return sort_by(id, desc, (a) => a.toUpperCase(), "designation")
                }
            }
            if (nameColumns[j][2] == "format" || nameColumns[j][2] == "integer") {
                column.sortType = (id, desc) => {
                    return sort_by(id, desc, (a) => {
                        if (a == null)
                            return -1
                        else
                            return parseInt(a)
                    })
                }
            }
            if (nameColumns[j][2] == "cout" || nameColumns[j][2] == "float") {
                column.sortType = (id, desc) => {
                    return sort_by(id, desc, parseFloat)
                }
            }
            if (nameColumns[j][2] == "date") {
                column.sortType = (id, desc) => {
                    return sort_by(id, desc, (a) => {
                        if (a == "")
                            return 0
                        let valueParse = a.split("/")
                        let SeparateDate = valueParse[2].split(' ')
                        let hoursValue = SeparateDate[1].split(":")
                        let date = new Date(SeparateDate[0], parseInt(valueParse[1]) - 1, valueParse[0], hoursValue[0], hoursValue[1], 0)
                        return date.getTime()
                    })
                }
            }
            if (nameColumns[j][2] == "caract") {
                column.sortType = (id, desc) => {
                    return sort_by(id, desc, (a) => {
                        if (a[0].value)
                            return a[0].value.toUpperCase()
                        else
                            return a[0].toString().toUpperCase()
                    })
                }
            }
            columns.push(column)
        }
        return columns
    }

    static generateClickableColumns(data, pos, value) {
        let clickableColumns = []
        for (let j = 0; j < data.length; j++) {
            let cl = {}
            for (let i = 0; i < pos.length; i++) {
                cl[pos[i]] = [getValueJsonChild(data[j], value[i]), value[i]]
            }
            clickableColumns.push(cl)
        }
        let objClickableColumns = {
            positions: clickableColumns,
            namesIds: value
        }
        return objClickableColumns
    }
    static generateJsonSummaryField(href, name, value, css, cssName, listId,link) {
        let field = {}
        field.href = href
        field.name = name
        field.value = value
        field.css = css
        field.cssName = cssName
        field.listId = listId
        field.link = link
        return field
    }
    static generateJsonBannerField(href, css, value, img, name) {
        let field = {}
        field.href = href
        field.css = css
        field.value = value
        field.img = img
        field.name = name
        return field
    }
    static deleteField(data, deletedFields) {
        let dataClone = JSON.parse(JSON.stringify(data))
        for (let i = 0; i < dataClone.length; i++) {
            deletedFields.forEach(deleteField => {
                delete dataClone[i][deleteField]
            })
        }
        return dataClone
    }
    static generateCharacteristicsField(data){
        let fields = []
        for (let i = 0; i < data.length; i++) {
            let field = {}
            let mutliValue = true
            let nameDiff = false
            field.name = [data[i].name]
            field.format = data[i].format
            field.description = data[i].description
            field.values = []
            if (mutliValue)
                field.values = [""]
            data[i].values.forEach(caract => {
                if (data[i].values.length == 1)
                    mutliValue = false
                if ((field.name != caract.name && caract.name != '') || mutliValue) {
                    field.name.push(caract.name)
                    nameDiff = true
                }
                if (data[i].format == 3)
                    field.values.push({
                        id: caract.id,
                        value: caract.value,
                        isInternal: caract.isInternal,
                    })
                    else
                    field.values.push(caract.value)
                })
                if(!mutliValue && !nameDiff){
                    if (data[i].format == 3)
                        field.values = [
                            {
                                id: data[i].values[0].id,
                                value: data[i].values[0].value,
                                isInternal: data[i].values[0].isInternal,
                            }
                        ]
                    else
                        field.values = [data[i].values[0].value]
                }
            fields.push(field)
        }
        return fields
    }
}

function getValueJsonChild(data, value) {
    let arrayValues = value.split(".")

    let dataTemp = data
    for (let k = 0; k < arrayValues.length; k++) {
        dataTemp = dataTemp[arrayValues[k]]
    }
    return dataTemp
}

export {getValueJsonChild}
import React, {useEffect, useRef, useState} from "react";
import {
    barSVG,
    columnSVG,
    cumulatedcurveSVG,
    curveSVG,
    doughnutSVG,
    ListSVG,
    noteSVG,
    numberSVG,
    pieSVG,
    stackbarSVG
} from "../../../style/chartSVG";
import {BarSeries, ColumnSeries, LineSeries, PieSeries, StackingColumnSeries} from "@syncfusion/ej2-react-charts";
import {ListViewComponent} from "@syncfusion/ej2-react-lists";
import {useTranslation} from "react-i18next";
import {getType} from "../../Utilities/treeviewProperty";
import {checkChart} from "./utilities";

function ListViewCharts(props) {
    const listView = useRef()
    const {t, i18n} = useTranslation()
    const [selectedElem, setSelectedElem] = useState(null)
    let chartsSource = [
        {id: "Column", text: t("chart:type", {context: "column"}), icone: columnSVG, service: ColumnSeries},
        {id: "Bar", text: t("chart:type", {context: "bar"}), icone: barSVG, service: BarSeries},
        {
            id: "StackingColumn",
            text: t("chart:type", {context: "stackedcolumn"}),
            icone: stackbarSVG,
            service: StackingColumnSeries
        },
        {id: "Line", text: t("chart:type", {context: "line"}), icone: curveSVG, service: LineSeries},
        {id: "Cumulative", text: t("chart:type", {context: "cumulative"}), icone: cumulatedcurveSVG, service: LineSeries},
        {id: "Pie", text: t("chart:type", {context: "pie"}), icone: pieSVG, service: PieSeries},
        {id: "Doughnut", text: t("chart:type", {context: "doughnut"}), icone: doughnutSVG, service: PieSeries},
        {id: "Number", text: t("chart:type", {context: "number"}), icone: numberSVG},
        {id: "Grid", text: t("chart:type", {context: "grid"}), icone: ListSVG},
        {id: "Note", text: t("chart:type", {context: "note"}), icone: noteSVG},
    ]

    let templateListView = (data) => {
        return (
            <>
                {data.icone}
                <p>{data.text}</p>
            </>
        )
    }
    useEffect(() => {
        if (selectedElem != null)
            document.getElementById(selectedElem.id).classList.add("e-active")
    }, [selectedElem])

    let addSaveTemp = (tempDatachart, chart) => {
        if (!checkChart(chart))
            return
        if (tempDatachart.type == null)
            return
        props.saveGroupBy.current[tempDatachart.type] = {
            dateValue: chart.dateValue,
            dataChart: tempDatachart.dataChart,
            pages: tempDatachart.pages
        }
    }

    let initializeTemp = () => {
        let tempDatachart = {
            type: props.chartValue.current.type != "Note" && props.chartValue.current.type != "Grid" && props.chartValue.current.type != "Number" & props.chartValue.current.type != null ? "anyone" : props.chartValue.current.type,
            dataChart: [],
            pages: []
        }
        props.chartValue.current.dataChart.forEach(data => {
            tempDatachart.dataChart.push(JSON.parse(JSON.stringify(data)))
        })
        props.pages.current.forEach(page => {
            tempDatachart.pages.push(JSON.parse(JSON.stringify(page)))
        })
        return tempDatachart
    }

    let onChangeListCharts = (e) => {
        if (!props.refresh)
            props.setRefreshForced(true)

        let reloadData = false
        if (e.data.id == "Cumulative")
            reloadData = true
        if (props.chartValue.current.type == "Cumulative")
            reloadData = true

        let saveCurrentChart = {...props.chartValue.current}
        let tempDatachart = initializeTemp()
        let typeLoad = "anyone"
        props.chartValue.current.type = e.data.id
        props.chartValue.current.service = e.data.service
        props.chartValue.current.isCenter = false
        if (props.chartValue.current.dataChart[0].color.length == 0) {
            props.chartValue.current.dataChart[0].color.push("#0db1e7")
        }

        if (props.chartValue.current.type != "Pie" || props.chartValue.current.type != "Doughnut") {
            if (props.chartValue.current.dataChart[0].color.length > 1) {
                props.chartValue.current.dataChart[0].color.splice(1)
            }
        }

        if (props.chartValue.current.type == "Number") {
            let loadData = false
            if (props.saveGroupBy.current[typeLoad] == null) {
                typeLoad = props.chartValue.current.type
            }
            if (props.saveGroupBy.current[typeLoad] != null) {
                props.chartValue.current.dataChart = cloneObjectInList(props.saveGroupBy.current[typeLoad].dataChart)
                props.pages.current = cloneObjectInList(props.saveGroupBy.current[typeLoad].pages)
            }
            props.setPageOpened(props.pages.current.find(page => page.isOpen))

            addSaveTemp(tempDatachart, saveCurrentChart)

            for (let i = 0; i < props.chartValue.current.dataChart.length; i++) {
                props.chartValue.current.dateValue = ""
                props.chartValue.current.dataChart[i].groupBy = null
                props.chartValue.current.dataChart[i].totalAggregator = null
                props.pages.current[i].content.groupBy = ""
                if (props.chartValue.current.dataChart[i].filter != null && props.chartValue.current.dataChart[i].filter != "") {
                    props.loadData(props.chartValue.current.dataChart[i].filter, i + 1, true)
                }
                if (props.chartValue.current.dataChart[i].aggregator != null && props.chartValue.current.dataChart[i].aggregateOn != null)
                    loadData = true
            }
            if (loadData)
                props.handleSendDataChart()
        } else if (props.chartValue.current.type == "Grid") {
            addSaveTemp(tempDatachart, saveCurrentChart)
            let loadData = false
            if (props.saveGroupBy.current[typeLoad] == null) {
                typeLoad = props.chartValue.current.type
            }
            if (props.saveGroupBy.current[typeLoad] != null) {
                props.chartValue.current.dataChart = cloneObjectInList(props.saveGroupBy.current[typeLoad].dataChart)
                props.pages.current = cloneObjectInList(props.saveGroupBy.current[typeLoad].pages)
            }
            for (let i = 0; i < props.chartValue.current.dataChart.length; i++) {
                if (props.chartValue.current.dataChart[i].groupBy != null)
                    loadData = true
            }
            props.setPageOpened(props.pages.current.find(page => page.isOpen))
            if (loadData)
                props.handleSendDataChart()

        } else if (props.chartValue.current.type == "Note") {
            addSaveTemp(tempDatachart, saveCurrentChart)
        } else {
            if (reloadData) {
                if (loadDataAnyOne(props.chartValue.current)) {
                    props.handleSendDataChart();
                }
            }
            if (props.saveGroupBy.current[typeLoad] != null) {
                let loadData = false
                checkFilter(props.chartValue.current, props.saveGroupBy.current[typeLoad].dataChart, props.saveGroupBy.current[typeLoad].pages)
                props.chartValue.current.dateValue = props.saveGroupBy.current[typeLoad].dateValue ? props.saveGroupBy.current[typeLoad].dateValue : ""
                props.chartValue.current.dataChart = cloneObjectInList(props.saveGroupBy.current[typeLoad].dataChart)
                props.pages.current = cloneObjectInList(props.saveGroupBy.current[typeLoad].pages)
                if (loadDataAnyOne(props.chartValue.current))
                    props.handleSendDataChart()
                for (let i = 0; i < props.pages.current.length; i++) {
                    props.pages.current[i].isOpen = false
                }
                props.pages.current[props.pages.current.length - 1].isOpen = true
            }
        }
        for (let i = 0; i < props.pages.current.length; i++) {
            if (props.chartValue.current.dataChart[i].aggregateOn == null) {
                props.pages.current[i].content.aggregation.property = ""
            }
            if (props.pages.current[i].content.aggregation.operation == "Count") {
                props.chartValue.current.dataChart[i].aggregator = "Count"
            }
            if (props.pages.current[i].content.groupBy === "undefined") {
                props.pages.current[i].content.groupBy = ""
            }
        }
        props.setPageOpened(props.pages.current.find(page => page.isOpen))
        setSelectedElem(e.item)
        props.setJsonChart({...props.chartValue.current})
    }

    useEffect(() => {
        if (props.chartValue.current.type != null)
            document.getElementById("charts-list_" + props.chartValue.current.type).classList.add("e-active")
    })

    return (
        <div className="graph-type">
            <p className="mt-0">{t("chart:type")}</p>
            <ListViewComponent ref={listView} id="charts-list" select={onChangeListCharts} template={templateListView}
                               dataSource={chartsSource}/>
        </div>
    )
}

function cloneObjectInList(list) {
    let tempList = []
    list.forEach(data => {
        tempList.push(JSON.parse(JSON.stringify(data)))
    })
    return tempList
}

function checkFilter(chart, saveChart, savePages) {
    for (let i = 0; i < chart.dataChart.length; i++) {
        let type = getType(chart.dataChart[i].filter.split(" ")[0]).field
        if (type != getType(saveChart[i].groupBy).field) {
            saveChart[i].groupBy = null
            savePages[i].content.groupBy = ""
            saveChart[i].data = []
        }
        if(saveChart[i].aggregator == "Calc"){
            let index = saveChart[i].aggregateOn.indexOf(type)
            if(index == -1){
                let point = saveChart[i].aggregateOn.substring(index-1, index)
                if(point == "."){
                    saveChart[i].aggregateOn = null
                    savePages[i].content.aggregation.property = ""
                    saveChart[i].data = []
                }
            }
        }
        else{
            if (type != getType(saveChart[i].aggregateOn).field) {
                saveChart[i].aggregateOn = null
                savePages[i].content.aggregation.property = ""
                saveChart[i].data = []
            }
        }
    }
}

function loadDataAnyOne(chartValue) {
    let loadData = false
    for (let i = 0; i < chartValue.dataChart.length; i++) {
        if (chartValue.dataChart[i].aggregator != null && chartValue.dataChart[i].aggregateOn != null && chartValue.dataChart[i].groupBy != null)
            loadData = true
    }
    return loadData
}

export {ListViewCharts, cloneObjectInList}
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Menu} from "../Menu/menu";
import {ButtonBanner} from "../Common/buttonBanner";
import {Information} from "../Modal/information";
import {GenerateReport} from "../Modal/generateReport";
import {SignatureRenderView} from "../Modal/signature";
import {FieldManager} from "../../Utilities/FieldManager";
import {caracSVG, etalonSVG, gestionSVG, summarySVG} from "../../style/logoSVG";
import {SpreadsheetView} from "../Utilities/spreadsheet";
import {LifeSheet} from "../Element/lifesheet";
import {ContentGestion} from "./contentGestion";
import {LoadingPage} from "../Common/loadingPage";
import {Banner} from "../Common/banner";
import {
    BALISE_BUTTON_INFO,
    BALISE_BUTTON_PDF,
    BALISE_BUTTON_SIGNATURE,
    generateKey,
    getPosDivEntity
} from "../Common/utilities";
import {ContentSummary} from "../Common/contentSummary";
import {BullContainer} from "../Common/bullContainer";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {TableTest} from "../Common/Table/newTable";
import {useIsMounted} from "../Utilities/useIsMounted";

function RenderIntervView(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setLoading] = useState(true)
    const [summary, setSummary] = useState([])
    const [gestion, setGestion] = useState([])
    const [etalon, setEtalon] = useState([])
    const [banner, setBanner] = useState([])
    const [linkedAnomalies, setLinkedAnomalies] = useState([])
    const [triggeredAnomalies, setTriggeredAnomaliesJson] = useState([])
    const [characteristics, setCharacteristics] = useState([])
    const [id, setId] = useState(null)
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const isMounted = useIsMounted()
    const [topDivEntity, setTopDivEntity] = useState(0)

    useEffect(async () => {
        //Verifie si l'utilisateur est connecté sinon le renvoie à la page login
        if (window.localStorage.getItem('token') == "null") {
            navigate("/login")
        }
        if (id == null)
            return
        setLoading(true)
        //Envoie des requête au serviceweb pour récupérer les données de la page
        await Promise.all([
            axios.get(`/eData/interventions/banner/${id}`),
            axios.get(`/eData/interventions/summary/${id}`),
            axios.get(`/eData/interventions/management/${id}`),
            axios.get(`/eData/interventions/standardGenerators/${id}`),
            axios.get(`/eData/interventions/linkedAnomalies/${id}`),
            axios.get(`/eData/interventions/triggeredAnomalies/${id}`),
            axios.get(`/helpers/characteristics/get/intervention/${id}`),
        ]).then(axios.spread(function (res1, res2, res3, res4, res5, res6, res7) {
            if (isMounted.current) {
                setBanner(res1.data)
                setSummary(res2.data)
                setGestion(res3.data)
                setEtalon(res4.data)
                setLinkedAnomalies(res5.data)
                setTriggeredAnomaliesJson(res6.data)
                setCharacteristics(res7.data)
            }
        }))
        setLoading(false)
        setPostDivEntity()
    }, [id])
    useEffect(() => {
        if (banner.designation) {
            document.title = banner.designation;
        }
    }, [banner])
    useEffect(() => {
        if (searchParams.get('id') == null)
            return
        setId(searchParams.get('id'))

    }, [searchParams])

    let setPostDivEntity = () => {
        setTopDivEntity(getPosDivEntity() + "px")
    }

    useEffect(()=>{
        window.addEventListener("resize",setPostDivEntity);
        return () => {
            window.removeEventListener('resize', setPostDivEntity)
        }
    },[])

    return isLoading ? (<LoadingPage/>)
        : (
            <>
                <Menu general={false} caract={false} controleCard={false} lifesheet={false} tableau={false} controlCard={true}/>
                <Banner type={[t("default:intervention"), 'color-yellow']}
                        colorHideBanner={"interv"}
                        imgtype={<img src={require('../../img/intervention.png')}/>}
                        title={banner.designation}
                        buttons={[<ButtonBanner name={"intervention"} id={id} key={generateKey("info")}
                                                img={BALISE_BUTTON_INFO}
                                                content={Information}
                                                tooltip={t("views:informations", {context:"open"})}/>,
                            <ButtonBanner name={"intervention"} id={id} key={generateKey("report")}
                                          img={BALISE_BUTTON_PDF}
                                          content={GenerateReport}
                                          tooltip={t("views:report", {context:"open"})}/>,
                            <ButtonBanner id={id} key={generateKey("signature")}
                                          img={BALISE_BUTTON_SIGNATURE}
                                          content={SignatureRenderView}
                                          tooltip={t("intervention:signature", {context:"open"})}/>
                                        ]}
                        fields={[FieldManager.generateJsonBannerField(null, 'mb-0 d-inline-block mt-1', banner.nature, null, t("views:nature")),
                            FieldManager.generateJsonBannerField(['/element/?id=', banner.element.id], 'mb-0 d-inline-block element-color mt-1', banner.element.designation, null, t("views:on"))]}/>
                <div style={{paddingTop:topDivEntity}} className="margin-constraint">
                    <div id="general">
                        <div className="wrapper d-flex flex-wrap-query">

                            <BullContainer title={t("views:summary")} img={summarySVG}
                                           content={<ContentSummary procedure={summary.procedure}
                                                                    data={[
                                                                        FieldManager.generateJsonSummaryField(null, t("views:state"), summary.element.state, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("default:date"), summary.date, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1 gap-15', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("views:end_date"), summary.endDate, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("intervention:judgment"), summary.judgment, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("views:operator"), summary.stakeholders, 'w-45 mb-0 mt-1', 'fw-bold w-25 mb-0 mt-1 d-flex align-items-center justify-content-end', 'parc-ope'),
                                                                        FieldManager.generateJsonSummaryField(null, t("intervention:certificate"), summary.certificateName, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("views:requestor"), summary.requestorName, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null),
                                                                        FieldManager.generateJsonSummaryField('', t("views:procedure"), summary.procedure == null ? "" : summary.procedure.name, 'w-45 mb-0 mt-1 lien-color', 'fw-bold w-25 text-right mb-0 mt-1', null)]}/>}/>
                            <BullContainer title={t("views:management")} img={gestionSVG}
                                           content={<ContentGestion interv={gestion}/>}/>
                        </div>
                        <BullContainer title={t("views:standard_generator")}
                                       img={etalonSVG}
                                       content={<TableTest
                                           data={FieldManager.deleteField(etalon, ["elementId", "calibrationId"])}
                                           col={FieldManager.generateColumns([[t("default:element"), 'elementDesignation'], [t("default:type"), 'type'], [t("default:intervention"), 'calibrationDesignation'], [t("intervention:judgment"), 'calibrationJudgment'], [t("views:standard_generator", {context: "state"}), 'state']])}
                                           clickableColumn={FieldManager.generateClickableColumns(etalon, [0, 2], ["elementId", "calibrationId"])}
                                           classtd={['element-color', '', 'intervention-color', '', '']}
                                           ope={[]}
                                           cause={[]}
                                           path={["/element", "", "/intervention", "", ""]}
                                           isCaract={false}
                                           setSearchParams={setSearchParams}/>}/>
                        <BullContainer title={t("views:linked_anomalies")}
                                       img={etalonSVG}
                                       content={<TableTest
                                           data={linkedAnomalies}
                                           col={FieldManager.generateColumns([[t("default:element"), 'elementDesignation'], [t("views:designation"), 'designation'], [t("views:nature"), 'nature'], [t("default:date"), 'date', "date"], [t("views:cause"), 'cause', "cause"], [t("views:operator"), 'operator', "operator"], [t("anomaly:delay"), 'nextDate', "date"]])}
                                           clickableColumn={FieldManager.generateClickableColumns(linkedAnomalies, [0, 1], ["elementId", "id"])}
                                           classtd={['element-color', 'anomalie-color', '', '', 'intervention-color', '', '']}
                                           ope={[5]}
                                           cause={[4]}
                                           path={["/element", "/anomaly", "", "", "/intervention", "", ""]}
                                           isCaract={false}
                                           setSearchParams={setSearchParams}/>}/>
                        <BullContainer title={t("views:triggered_anomalies")}
                                       img={etalonSVG}
                                       content={<TableTest
                                           data={triggeredAnomalies}
                                           col={FieldManager.generateColumns([[t("default:element"), 'elementDesignation'], [t("views:designation"), 'designation'], [t("views:nature"), 'nature'], [t("default:date"), 'date', "date"], [t("views:operator"), 'operator', "operator"], [t("anomaly:delay"), 'nextDate', "date"]])}
                                           clickableColumn={FieldManager.generateClickableColumns(triggeredAnomalies, [0, 1], ["elementId", "id"])}
                                           classtd={['element-color', 'anomalie-color', '', '', '', '']}
                                           ope={[4]}
                                           cause={[]}
                                           path={["/element", "/anomaly", "", "", "", ""]}
                                           isCaract={false}
                                           setSearchParams={setSearchParams}/>}/>
                    </div>
                    <div id="caract">
                        <BullContainer title={t("default:characteristics")}
                                       img={caracSVG}
                                       content={<TableTest
                                           data={FieldManager.generateCharacteristicsField(characteristics)}
                                           col={FieldManager.generateColumns([[t("default:characteristics", {context: "format"}), 'format', "format"], [t("default:name"), 'name', "caract"], [t("default:value"), 'values', "caract"], [t("default:description"), 'description']])}
                                           clickableColumn={undefined}
                                           classtd={['', 'fw-bold']}
                                           ope={[]}
                                           cause={[]}
                                           path={["", ""]}
                                           isCaract={true}
                                       />}
                        />
                    </div>
                    <div id="tableau">
                        <SpreadsheetView name={"intervention"} id={id}/>
                    </div>
                    <div id="lifesheet">
                        <LifeSheet setSearchParams={setSearchParams} id={banner.element.id}/>
                    </div>
                </div>
            </>
        )
}

export default RenderIntervView
const normalSVG = <svg enableBackground="new 0 0 30.4 30.4" version="1.1" viewBox="0 0 30.4 30.4">
    <path className="fdv"
          d="m29.5 5.1c0-0.1 0-0.1-0.1-0.1-0.1-0.1-0.3-0.1-0.3 0l-4.7 4.7c-1.9 0.1-3.6-1.3-3.7-3.2v-0.5l4.7-4.7c0.1-0.1 0.1-0.3 0-0.3h-0.1c-4.2-1.9-9 0-10.9 4.2-0.5 1.1-0.7 2.2-0.7 3.4 0 0.8 0.1 1.7 0.4 2.5l-8.6 8.6c-2.9 0-5.3 2.5-5.2 5.4 0 2.9 2.5 5.3 5.4 5.2 2.9 0 5.2-2.4 5.2-5.2l8.6-8.6c4.3 1.4 9-1 10.4-5.3 0.5-2.1 0.4-4.2-0.4-6.1z"/>
</svg>

const standartSVG = <svg enableBackground="new 0 0 30.4 30.4" version="1.1" viewBox="0 0 30.4 30.4">
    <path className="fdv"
          d="m29.5 5.1c0-0.1 0-0.1-0.1-0.1-0.1-0.1-0.3-0.1-0.3 0l-4.7 4.7c-1.9 0.1-3.6-1.3-3.7-3.2v-0.5l4.7-4.7c0.1-0.1 0.1-0.3 0-0.3h-0.1c-4.2-1.9-9 0-10.9 4.2-0.5 1.1-0.7 2.2-0.7 3.4 0 0.8 0.1 1.7 0.4 2.5l-8.6 8.6c-2.9 0-5.3 2.5-5.2 5.4 0 2.9 2.5 5.3 5.4 5.2 2.9 0 5.2-2.4 5.2-5.2l8.6-8.6c4.3 1.4 9-1 10.4-5.3 0.5-2.1 0.4-4.2-0.4-6.1z"/>
    <path className="fdv_etalon"
          d="m5.1 0.9c0 0.1-0.1 0.1-0.1 0.1-0.1 0.1-0.1 0.2 0 0.3l4.7 4.7c0.2 2-1.3 3.6-3.2 3.8h-0.5l-1.8-1.9-2.9-2.9c-0.1-0.1-0.2-0.1-0.3 0v0.1c-1.9 4.2 0 9 4.2 10.9 1.1 0.5 2.2 0.7 3.4 0.7 0.8 0 1.7-0.1 2.5-0.4l8.6 8.6c0 2.9 2.5 5.3 5.4 5.2 2.9 0 5.3-2.5 5.2-5.4 0-2.9-2.4-5.2-5.2-5.2l-8.7-8.5c1.4-4.3-1-9-5.3-10.4-2-0.6-4.1-0.5-6 0.3z"/>
</svg>


const generatorSVG = <svg enableBackground="new 0 0 30.4 30.4" version="1.1" viewBox="0 0 30.4 30.4">
    <path className="fdv_generateur_clair"
          d="m29.9 14.7v0c-0.1-0.2-0.1-0.3-0.2-0.4s-0.1-0.1-0.2-0.1c-0.1-0.2-0.2-0.2-0.4-0.2h-6l2.7-6.4c0.1-0.3 0.1-0.6-0.1-0.8-0.1-0.2-0.4-0.4-0.7-0.4h-6-0.2l-1.2 3.8-2.6 8.5h8.2l-4.6 11.3s0 0.1 0.1 0.1c0.1 0.1 0.2 0.1 0.3 0.2 0.1 0 0.2 0.1 0.3 0.1h0.1c0.1 0 0.2 0 0.2-0.1h0.1c0.1-0.1 0.2-0.1 0.3-0.3l9.9-14.7c0-0.2 0.1-0.4 0-0.6z"/>
    <path className="fdv_generateur_fonce"
          d="m15 18.7 2.6-8.4 1.2-3.8c-0.1 0-0.2 0.1-0.2 0.1-0.1 0.1-0.2 0.2-0.3 0.4l-1.5 3.7-3.4 8.3c-0.1 0.1-0.1 0.3-0.1 0.4 0 0.4 0.4 0.7 0.8 0.7h6.6l-2.4 9.2v0.4 0.1c0 0.1 0.1 0.2 0.1 0.2l4.6-11.3h-8z"/>
    <path className="fdv"
          d="m27.7 8.4-1.5 3.6h3 0.2c0.2-0.4 0.3-0.7 0.5-1.1 0.6-1.9 0.5-4-0.3-5.9-0.1-0.1-0.3-0.1-0.3 0l-1.4 1.4c0.1 0.6 0.1 1.3-0.2 2z"/>
    <path className="fdv"
          d="m11.4 19.6c-0.1-0.5 0-0.9 0.2-1.4l3.3-8.3 1.5-3.7c0.2-0.5 0.6-1 1-1.3 0.2-0.2 0.5-0.3 0.8-0.4s0.5-0.1 0.8-0.1h3.2l3.2-3.2c0.1-0.1 0.1-0.3 0-0.3h-0.1c-4.2-1.9-9 0-10.9 4.2-0.5 1.1-0.7 2.2-0.7 3.4 0 0.8 0.1 1.7 0.4 2.5l-8.6 8.6c-2.9 0-5.3 2.5-5.2 5.4s2.5 5.3 5.4 5.2c2.9 0 5.2-2.4 5.2-5.2l2.8-2.8c-1.2-0.4-2.1-1.4-2.3-2.6z"/>
</svg>

const consumablesSVG = <svg enableBackground="new 0 0 30.9 30.9" version="1.1" viewBox="0 0 30.9 30.9">
    <path className="fdv_consommable_couleur1"
          d="m14.4 9.4-5.8 3.4c-0.5 0.3-0.8 0.9-0.8 1.5v6.7c0 0.6 0.3 1.2 0.8 1.5l5.8 3.4c0.5 0.3 1.2 0.3 1.7 0l5.8-3.4c0.5-0.3 0.8-0.9 0.8-1.5v-6.7c0-0.6-0.3-1.2-0.8-1.5l-5.8-3.4c-0.5-0.3-1.2-0.3-1.7 0z"/>
    <path className="fdv_consommable_couleur2"
          d="m22.3 13.8-5.8 3.4c-0.5 0.3-0.8 0.9-0.8 1.5v6.7c0 0.6 0.3 1.2 0.8 1.5l5.8 3.4c0.5 0.3 1.2 0.3 1.7 0l5.8-3.4c0.5-0.3 0.8-0.9 0.8-1.5v-6.7c0-0.6-0.3-1.2-0.8-1.5l-5.8-3.4c-0.6-0.3-1.2-0.3-1.7 0z"/>
    <path className="fdv_consommable_couleur1" d="m30.1 17.4s0.5 0.4 0.5 1l-0.3 0.3-0.5-0.8 0.1-0.4 0.2-0.1z"/>
    <path className="fdv_consommable_couleur3"
          d="m30.6 18.4v7.1c0 0.6-0.3 1.1-0.8 1.3l-5.9 3.4c-0.3 0.2-0.7 0-0.6-0.4v-6.9c0-0.6 0.3-1.1 0.8-1.3l6.1-3.6c0.2 0 0.4 0.2 0.4 0.4z"/>
    <path className="fdv_consommable_couleur1"
          d="m30.1 18.2-6.3 3.7c-0.4 0.2-0.8 0.2-1.2 0l-6.4-3.7c-0.3-0.2-0.3-0.6 0-0.8l6.4-3.7c0.4-0.2 0.8-0.2 1.2 0l6.3 3.7c0.3 0.1 0.3 0.6 0 0.8z"/>
    <path className="fdv_consommable_couleur1"
          d="m22.2 21.7s0.9 0.4 0.9 0.7c0.2 0.8 0.2 1.5 0.2 1.5v-0.9s0-0.4 0.2-0.8 0.5-0.5 0.5-0.5l-0.7 0.1-0.7-0.1h-0.4z"/>
    <path className="fdv_consommable_couleur1"
          d="m16.1 17.4s-0.2 0.1-0.4 0.4c-0.1 0.3-0.1 0.8-0.1 0.8s0-0.1 0-0.2c0-0.2 0.4-0.3 0.4-0.3v-0.4l0.1-0.3z"/>
    <path className="fdv_consommable_couleur2"
          d="m15.6 18.3v7c0 0.6 0.3 1.1 0.8 1.4l6.1 3.5c0.3 0.2 0.6 0 0.6-0.3l0.1-6.8c0-0.6-0.3-1.2-0.8-1.5l-6.2-3.6c-0.3-0.1-0.6 0.1-0.6 0.3z"/>
    <path className="fdv_consommable_couleur4"
          d="m20.7 22.5 0.8 0.5c0.2 0.1 0.3 0.3 0.3 0.5v1c0 0.4-0.4 0.7-0.8 0.5l-0.8-0.5c-0.2-0.1-0.3-0.3-0.3-0.5v-1c0-0.5 0.5-0.7 0.8-0.5z"/>
    <polygon className="fdv_consommable_couleur2" points="19.2 15.6 27 20 27.3 19.9 19.5 15.4"/>
    <path className="fdv_consommable_couleur2"
          d="m6.9 13.8-5.8 3.4c-0.5 0.3-0.8 0.9-0.8 1.5v6.7c0 0.6 0.3 1.2 0.8 1.5l5.8 3.4c0.5 0.3 1.2 0.3 1.7 0l5.8-3.4c0.5-0.3 0.8-0.9 0.8-1.5v-6.7c0-0.6-0.3-1.2-0.8-1.5l-5.8-3.4c-0.6-0.3-1.2-0.3-1.7 0z"/>
    <path className="fdv_consommable_couleur1" d="m14.7 17.3s0.5 0.4 0.5 1l-0.2 0.4-0.5-0.8 0.1-0.4 0.1-0.2z"/>
    <path className="fdv_consommable_couleur3"
          d="m15.3 18.3v7.1c0 0.6-0.3 1.1-0.8 1.3l-5.9 3.4c-0.3 0.2-0.7 0-0.6-0.4v-6.9c0-0.6 0.3-1.1 0.8-1.3l6.1-3.6c0.1 0.1 0.4 0.2 0.4 0.4z"/>
    <path className="fdv_consommable_couleur1"
          d="m14.7 18.2-6.3 3.7c-0.4 0.1-0.9 0.1-1.3-0.1l-6.4-3.7c-0.3-0.2-0.3-0.6 0-0.8l6.4-3.7c0.4-0.2 0.8-0.2 1.2 0l6.3 3.7c0.4 0.2 0.4 0.7 0.1 0.9z"/>
    <path className="fdv_consommable_couleur1"
          d="m6.8 21.6s0.9 0.4 0.9 0.7c0.2 0.8 0.2 1.5 0.2 1.5v-0.9s0-0.4 0.2-0.8 0.5-0.5 0.5-0.5l-0.7 0.1-0.7-0.1h-0.4z"/>
    <path className="fdv_consommable_couleur1"
          d="m0.7 17.3s-0.2 0.1-0.4 0.4c-0.1 0.3-0.1 0.8-0.1 0.8s0-0.1 0-0.2c0-0.2 0.4-0.3 0.4-0.3v-0.4l0.1-0.3z"/>
    <path className="fdv_consommable_couleur2"
          d="m0.2 18.3v7c0 0.6 0.3 1.1 0.8 1.4l6.1 3.5c0.3 0.2 0.6 0 0.6-0.3l0.1-6.8c0-0.6-0.3-1.2-0.8-1.5l-6.2-3.6c-0.2-0.2-0.6 0-0.6 0.3z"/>
    <path className="fdv_consommable_couleur4"
          d="m5.3 22.4 0.8 0.5c0.2 0.1 0.3 0.3 0.3 0.5v1c0 0.4-0.4 0.7-0.8 0.5l-0.8-0.5c-0.2-0.1-0.3-0.3-0.3-0.5v-1c0-0.5 0.5-0.7 0.8-0.5z"/>
    <polygon className="fdv_consommable_couleur2" points="3.7 15.6 11.5 20 11.8 19.8 4 15.4"/>
    <path className="fdv_consommable_couleur2"
          d="m14.5 0.7-5.8 3.4c-0.5 0.3-0.8 0.9-0.8 1.5v6.7c0 0.6 0.3 1.2 0.8 1.5l5.8 3.4c0.5 0.3 1.2 0.3 1.7 0l5.8-3.4c0.5-0.3 0.8-0.9 0.8-1.5v-6.7c0-0.6-0.3-1.2-0.8-1.5l-5.8-3.4c-0.5-0.3-1.1-0.3-1.7 0z"/>
    <path className="fdv_consommable_couleur1" d="m22.4 4.3s0.5 0.4 0.5 1l-0.3 0.3-0.5-0.8 0.1-0.4 0.2-0.1z"/>
    <path className="fdv_consommable_couleur3"
          d="m22.9 5.3v7.1c0 0.6-0.3 1.1-0.8 1.3l-5.9 3.4c-0.3 0.2-0.7 0-0.6-0.4v-6.9c0-0.6 0.3-1.1 0.8-1.3l6.1-3.6c0.2 0 0.4 0.2 0.4 0.4z"/>
    <path className="fdv_consommable_couleur1"
          d="m22.3 5.1-6.3 3.7c-0.4 0.2-0.8 0.2-1.2 0l-6.4-3.7c-0.3-0.2-0.3-0.6 0-0.8l6.4-3.7c0.4-0.2 0.8-0.2 1.2 0l6.3 3.7c0.4 0.2 0.4 0.6 0 0.8z"/>
    <path className="fdv_consommable_couleur1"
          d="m14.5 8.6s0.9 0.4 0.9 0.7c0.2 0.8 0.2 1.5 0.2 1.5v-0.9s0-0.4 0.2-0.8 0.5-0.5 0.5-0.5l-0.7 0.1-0.7-0.1h-0.4z"/>
    <path className="fdv_consommable_couleur1"
          d="m8.4 4.3s-0.2 0.1-0.4 0.4c-0.1 0.4-0.1 0.8-0.1 0.8s0-0.1 0-0.2c0-0.2 0.4-0.3 0.4-0.3v-0.4l0.1-0.3z"/>
    <path className="fdv_consommable_couleur2"
          d="m7.9 5.3v7c0 0.6 0.3 1.1 0.8 1.4l6.1 3.5c0.3 0.2 0.6 0 0.6-0.3l0.1-6.8c0-0.6-0.3-1.2-0.8-1.5l-6.2-3.7c-0.3-0.1-0.6 0.1-0.6 0.4z"/>
    <path className="fdv_consommable_couleur4"
          d="m13 9.4 0.8 0.5c0.2 0.1 0.3 0.3 0.3 0.5v1c0 0.4-0.4 0.7-0.8 0.5l-0.8-0.5c-0.2-0.1-0.3-0.3-0.3-0.5v-1c0-0.5 0.4-0.7 0.8-0.5z"/>
    <polygon className="fdv_consommable_couleur2" points="11.4 2.6 19.1 7 19.4 6.8 11.7 2.4"/>
</svg>


export {normalSVG, standartSVG, generatorSVG, consumablesSVG}
function ModaleImg(props) {
    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }
    return (
        <div className="modal-box-img">
            <div className="title-modal-img">
                <h3>{props.propsAdd.title}</h3>
                <button onClick={closeButton}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
            </div>
            <img src={props.propsAdd.url}/>
        </div>
    )
}

export {ModaleImg}
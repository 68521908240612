import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Menu} from "../Menu/menu";
import {ButtonBanner} from "../Common/buttonBanner";
import {Information} from "../Modal/information";
import {GenerateReport} from "../Modal/generateReport";
import {FieldManager} from "../../Utilities/FieldManager";
import {caracSVG, gestionSVG, summarySVG} from "../../style/logoSVG";
import {SpreadsheetView} from "../Utilities/spreadsheet";
import {LifeSheet} from "../Element/lifesheet";
import {ContentGestion} from "./contentGestion";
import {LoadingPage} from "../Common/loadingPage";
import {Banner} from "../Common/banner";
import {BALISE_BUTTON_INFO, BALISE_BUTTON_PDF, generateKey, getPosDivEntity} from "../Common/utilities";
import {BullContainer} from "../Common/bullContainer";
import {ContentSummary} from "../Common/contentSummary";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {TableTest} from "../Common/Table/newTable";
import {useIsMounted} from "../Utilities/useIsMounted";

function RenderAnomalieView(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [id, setId] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [banner, setBannerData] = useState([])
    const [summary, setSummary] = useState([])
    const [gestion, setGestion] = useState([])
    const [characteristics, setCharacteristics] = useState([])
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const isMounted = useIsMounted()
    const [topDivEntity, setTopDivEntity] = useState(0)

    useEffect(async () => {
        if (window.localStorage.getItem('token') == "null") {
            navigate("/login")
        }
        if (id == null)
            return
        setLoading(true)
        await Promise.all([
            axios.get(`/eData/anomalies/banner/${id}`),
            axios.get(`/eData/anomalies/summary/${id}`),
            axios.get(`/eData/anomalies/management/${id}`),
            axios.get(`/helpers/characteristics/get/anomaly/${id}`)
        ]).then(axios.spread(function (res1, res2, res3, res4) {
            if (isMounted.current) {
                setBannerData(res1.data)
                setSummary(res2.data)
                setGestion(res3.data)
                setCharacteristics(res4.data)
            }
        }))
        setLoading(false)
        setPostDivEntity()
    }, [id])
    useEffect(() => {
        if (banner.designation)
            document.title = banner.designation
    }, [banner])

    useEffect(() => {
        if (searchParams.get('id') == null)
            return
        setId(searchParams.get('id'))
    }, [searchParams])

    let setPostDivEntity = () => {
        setTopDivEntity(getPosDivEntity() + "px")
    }

    useEffect(()=>{
        window.addEventListener("resize",setPostDivEntity);
        return () => {
            window.removeEventListener('resize', setPostDivEntity)
        }
    },[])

    return (isLoading ? (<LoadingPage/>) :
            <>
                <Menu general={false} caract={false} controlCard={true} lifesheet={false} tableau={false} />
                <Banner type={[t("default:anomaly"), 'color-red']}
                        colorHideBanner={"anom"}
                        imgtype={<img src={require('../../img/anomalie.png')}/>}
                        title={banner.designation}
                        buttons={[<ButtonBanner name={"anomaly"} id={id} key={generateKey("info")}
                                                img={BALISE_BUTTON_INFO}
                                                content={Information}/>,
                            <ButtonBanner name={"anomaly"} id={id} key={generateKey("report")} img={BALISE_BUTTON_PDF}
                                          content={GenerateReport}/>]}
                        fields={[FieldManager.generateJsonBannerField(null, 'mb-0 d-inline-block mt-1', banner.nature, null, t("views:nature")),
                            FieldManager.generateJsonBannerField(['/element/?id=', banner.element.id], 'mb-0 d-inline-block element-color mt-1', banner.element.designation, null, t("views:on")),
                            FieldManager.generateJsonBannerField(null, 'mb-0 d-flex align-items-center mt-1', banner.state, banner.state == "Fermée" ?
                                <img src={require('../../img/non-conforme.png')}/> :
                                <img src={require('../../img/en-cours.png')}/>, t("views:state", 'statut'))]}
                />
                <div style={{paddingTop:topDivEntity}} className="margin-constraint">
                    <div id="general">
                        <div className="wrapper d-flex flex-wrap-query">
                            <BullContainer title={t("views:summary")} img={summarySVG}
                                           content={<ContentSummary procedure={summary.procedure}
                                                                    data={[
                                                                        FieldManager.generateJsonSummaryField(null, t("anomaly:date", {context: "opening"}), summary.beginDate, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null, null),
                                                                        FieldManager.generateJsonSummaryField(null, t("anomaly:element_state", {context: "opening"}), summary.openingState, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null, null),
                                                                        FieldManager.generateJsonSummaryField(null, t("anomaly:date", {context: "closing"}), summary.endDate, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("anomaly:element_state", {context: "closing"}), summary.closingState, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null, null),
                                                                        FieldManager.generateJsonSummaryField(null, t("views:cause"), summary.cause.intervention == null ? summary.cause.designation : summary.cause.intervention.designation, summary.cause.intervention == null ? 'w-45 mb-0 mt-1' : 'mb-0 mt-0 intervention-color', 'fw-bold w-25 text-right mb-0 mt-1', null, summary.cause.intervention == null ? null : "/intervention/?id=" + summary.cause.intervention.id),
                                                                        FieldManager.generateJsonSummaryField(null, t("views:operator"), [summary.operator], 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', 'parc-ope', null),
                                                                        FieldManager.generateJsonSummaryField(null, t("anomaly:delay"), summary.nextDate, 'w-45 mb-0 mt-1', 'fw-bold w-25 text-right mb-0 mt-1', null, null),
                                                                        FieldManager.generateJsonSummaryField('', t("views:procedure"), summary.procedure == null ? "" : summary.procedure.name, 'mb-0', 'fw-bold w-25 text-right mb-0 mt-1', null, null)]}/>}/>
                            <BullContainer title={t("views:management")} img={gestionSVG}
                                           css={"gestion-anomalie box mb-60"}
                                           content={<ContentGestion data={FieldManager.deleteField([gestion], ["id"])}
                                                                    names={[t("anomaly:unavailability"), t("anomaly:cost"), t("anomaly:cost", {context: "currency"}), t("anomaly:cost", {context: "tax"}), t("anomaly:linked_interventions")]}/>}/>
                        </div>
                    </div>
                    <div id="caract">
                    <BullContainer title={t("default:characteristics")}
                                   img={caracSVG}
                                   content={<TableTest
                                       data={FieldManager.generateCharacteristicsField(characteristics)}
                                       col={FieldManager.generateColumns([[t("default:characteristics", {context: "format"}), 'format'], [t("default:name"), 'name'], [t("default:value"), 'values'], [t("default:description"), 'description']])}
                                       clickableColumn={undefined}
                                       classtd={['', 'fw-bold']}
                                       ope={[]}
                                       cause={[]}
                                       path={["", ""]}
                                       isCaract={true}
                                   />}
                        />
                    </div>
                    <div id="tableau">
                        <SpreadsheetView name={"anomaly"} id={id}/>
                    </div>
                    <div id="lifesheet">
                    <LifeSheet setSearchParams={setSearchParams} id={banner.element.id} />
                    </div>
                </div>
            </>
    )

}

export default RenderAnomalieView
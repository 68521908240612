import axios from "axios";
import {deleteDiv, getColumnType, getPath, getTrad, getType} from "../../Utilities/treeviewProperty";
import {ChartsMenuView} from "../../Modal/chartsMenu";
import React, {useEffect, useRef, useState} from "react";
import {AggregationTreeview, onlyNumberKey} from "./aggregationTreeview";
import {cloneObjectInList, ListViewCharts} from "./listChart";
import {
    checkChart,
    convertJsonChart,
    convertJsonPage,
    generateChartQuery,
    loadChartData,
    setAggregationData,
    setValueTreeView
} from "./utilities";
import {PreviewChartRender} from "./previewChartRender";
import {useTranslation} from "react-i18next";
import {QueryShow} from "./QueryShow";

function RenderViewCustomChart(props) {
    const {t, i18n} = useTranslation()
    const [loadChart, setLoadChart] = useState(false)
    const [valueTitle, setValueTitle] = useState(t("chart:with_out_title"))
    const [chartQuery, setChartQuery] = useState("")
    const chartValue = useRef({
        id: 0,
        name: t("chart:with_out_title"),
        type: null,
        service: null,
        isCenter: false,
        dateValue: "",
        label: "",
        dataChart: []
    })
    const [loadDataTreeview, setLoadDataTreeview] = useState(false)
    const [refreshAuto, setRefreshAuto] = useState(true)
    const [refreshForced, setRefreshForced] = useState(false)
    const chartNoModif = useRef()
    const pages = useRef([])
    const [dataTreeView, setDataTreeView] = useState({
        all: [],
        numeric: []
    })
    const [jsonChart, setJsonChart] = useState({
        id: 0,
        name: t("chart:with_out_title"),
        type: null,
        service: null,
        isCenter: false,
        dateValue: "",
        label: "",
        dataChart: []
    })
    const pageOpenedValue = useRef({
        id: 1,
        isOpen: true,
        content: {
            title: "",
            nameLegend: "",
            type: {
                aggregation: "",
                groupBy: "",
            },
            groupBy: "",
            dataTreeView: [],
            aggregation: {
                operation: "",
                property: "",
                data: []
            },
        }
    })
    const [pageOpened, setPageOpened] = useState()
    const [position, setPosition] = useState({
        sizeX: 2,
        sizeY: 2,
        col: 0,
        row: 0
    })
    const loading = useRef(false)
    const saveGroupBy = useRef({
        Grid: null,
        Number: null,
        anyone: null
    })

    let closeModal = () => {
        if (loading.current)
            return
        deleteDiv()
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    let loadData = async (query, pageOpenedId, load) => {
        let jsonData = {
            all: [],
            numeric: []
        }
        let data = null
        await axios.get(`/helpers/query/columnData/${getType(query, t).field}`).then(res => data = res.data)
        jsonData.all.push({
            id: "9999999",
            field: getType(query, t).field,
            label: getType(query, t).text,
            columns: data.all
        })
        jsonData.numeric.push({
            id: "9999999",
            field: getType(query, t).field,
            label: getType(query, t).text,
            columns: data.numeric
        })
        chartValue.current.dataChart[pageOpenedId - 1].dataTreeView = jsonData
        if (chartValue.current.dataChart[pageOpenedId - 1].groupBy != null && chartValue.current.type != "Number") {
            if (getType(chartValue.current.dataChart[pageOpenedId - 1].groupBy, t).field != getType(query, t).field) {
                chartValue.current.dataChart[pageOpenedId - 1].groupBy = null
                chartValue.current.dataChart[pageOpenedId - 1].aggregateOn = null
                pageOpenedValue.current.content.groupBy = ""
                pageOpenedValue.current.content.aggregation.property = ""
                pageOpenedValue.current.content.aggregation.data = []
                setPageOpened({...pageOpenedValue.current})
            }
        }
        if (chartValue.current.type == "Number") {
            if (chartValue.current.dataChart[pageOpenedId - 1].aggregateOn != null) {
                if (getType(chartValue.current.dataChart[pageOpenedId - 1].aggregateOn, t).field != getType(query, t).field) {
                    chartValue.current.dataChart[pageOpenedId - 1].aggregateOn = null
                    pageOpenedValue.current.content.aggregation.property = ""
                    pageOpenedValue.current.content.aggregation.data = []
                    setPageOpened({...pageOpenedValue.current})
                }
            }
            pageOpenedValue.current.content.aggregation.data = [setAggregationData(chartValue.current.dataChart[pageOpenedId - 1].filter.split(".")[0], chartValue.current.dataChart[pageOpenedId - 1].dataTreeView, t)]
        }

        if (load) {
            if ((chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregateOn != null && chartValue.current.dataChart[pageOpenedValue.current.id - 1].groupBy != null) || (chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregateOn != null && chartValue.current.type == "Number") || (chartValue.current.dataChart[pageOpenedValue.current.id - 1].groupBy != null && chartValue.current.type == "Grid")) {
                await handleSendDataChart()
            }
        }
    }

    useEffect(() => {
        if (props.propsAdd == undefined)
            return
        if (props.propsAdd.chart.panel == undefined)
            return
        let pos = {}
        pos.sizeY = props.propsAdd.chart.panel.sizeY
        pos.sizeX = props.propsAdd.chart.panel.sizeX
        pos.col = props.propsAdd.chart.panel.col
        pos.row = props.propsAdd.chart.panel.row
        setPosition(pos)
    }, [props.propsAdd])

    useEffect(() => {
        if (pageOpened == undefined)
            return
        let selectedPage = pages.current.find(page => page.id == pageOpened.id)
        selectedPage = pageOpened
    }, [pageOpened])

    useEffect(() => {
        setChartQuery(createQuery())
    }, [jsonChart])


    useEffect(async () => {
        chartValue.current.id = Math.ceil(Math.random() * 1000000000)

        if (props.propsAdd != undefined) {
            if (props.propsAdd.chart.jsonChart.type == "Note") {
                props.propsAdd.chart.pages = []
                props.propsAdd.chart.jsonChart.dataChart = []
            }
            loading.current = true
            chartValue.current = props.propsAdd.chart.jsonChart
            chartNoModif.current = props.propsAdd.chart.jsonChart
            setValueTitle(chartValue.current.name)
            for (let i = 0; i < props.propsAdd.chart.pages.length; i++) {
                await loadData(chartValue.current.dataChart[props.propsAdd.chart.pages[i].id - 1].filter, props.propsAdd.chart.pages[i].id, i ==  props.propsAdd.chart.pages.length - 1)
                if (props.propsAdd.chart.pages[i].id != 1)
                    props.propsAdd.chart.pages[i].isOpen = false
                else
                    props.propsAdd.chart.pages[i].isOpen = true
                if (props.propsAdd.chart.pages[i].content.aggregation.data.length == 0) {
                    props.propsAdd.chart.pages[i].content.groupBy = getTrad(props.propsAdd.chart.pages[i].content.groupBy, chartValue.current.dataChart[props.propsAdd.chart.pages[i].id - 1].dataTreeView.all).replaceAll('.', " ➧ ")
                    props.propsAdd.chart.pages[i].content.aggregation.data.push(setAggregationData(chartValue.current.dataChart[props.propsAdd.chart.pages[i].id - 1].filter.split(".")[0], chartValue.current.dataChart[props.propsAdd.chart.pages[i].id - 1].dataTreeView, t))
                    if (chartValue.current.type == "Grid" || chartValue.current.dataChart[props.propsAdd.chart.pages[i].id - 1].aggregator == "Calc")
                        continue
                    props.propsAdd.chart.pages[i].content.type.aggregation = getColumnType(props.propsAdd.chart.pages[i].content.aggregation.data, props.propsAdd.chart.pages[i].content.aggregation.property.split('.'))
                    props.propsAdd.chart.pages[i].content.aggregation.property = getTrad(props.propsAdd.chart.pages[i].content.aggregation.property, props.propsAdd.chart.pages[i].content.aggregation.data).replaceAll('.', " ➧ ")
                }
            }
            if (props.propsAdd.chart.pages.length > 0) {
                pages.current = cloneObjectInList(props.propsAdd.chart.pages)
                pageOpenedValue.current = pages.current.find(page => page.isOpen)
                setPageOpened({...pageOpenedValue.current})
            } else {
                initialiseDataChartAddedTab()
                setJsonChart({...chartValue.current})
            }
            setLoadDataTreeview(true)
            loading.current = false
        }
    }, [])

    let handleInputTitle = (e) => {
        chartValue.current.name = e.target.value
        setJsonChart({...chartValue.current})

        pageOpenedValue.current.title = e.target.value
        setPageOpened({...pageOpenedValue.current})
    }

    let handleOnChangeTitle = (e) => {
        if (e.target.value.length > 50)
            return
        chartValue.current.name = e.target.value
        setValueTitle(chartValue.current.name)
    }

    let handleNameLegend = (e) => {
        if (e.target.value.length > 50)
            return

        pageOpenedValue.current.content.nameLegend = e.target.value
        setPageOpened({...pageOpenedValue.current})
        chartValue.current.dataChart[pageOpenedValue.current.id - 1].name = e.target.value
        if (chartValue.current.type == "Grid")
            chartValue.current.series[pageOpenedValue.current.id - 1].name = e.target.value
        setJsonChart({...chartValue.current})

    }

    let handleSelectGroupBy = (type, e, values) => {
        if (getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, chartValue.current.dataChart[pageOpenedValue.current.id - 1].dataTreeView.all, false) == chartValue.current.dataChart[pageOpenedValue.current.id - 1].groupBy)
            return
        let chartfind = setValueTreeView("groupBy", e, chartValue.current, "all")
        chartValue.current.dateValue = type == "date" ? "OVER YEARS" : ""
        pageOpenedValue.current.content.aggregation.data = []
        pageOpenedValue.current.content.aggregation.data.push(setAggregationData(chartfind.groupBy.split(".")[0], chartValue.current.dataChart[pageOpenedValue.current.id - 1].dataTreeView, t))

        pageOpenedValue.current.content.groupBy = getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, chartValue.current.dataChart[pageOpenedValue.current.id - 1].dataTreeView.all, true).replaceAll('.', " ➧ ")
        pageOpenedValue.current.content.aggregation.data = pageOpenedValue.current.content.aggregation.data
        setPageOpened({...pageOpenedValue.current})

        if (chartValue.current.type == "Grid") {
            for (var name in saveGroupBy.current) {
                if (saveGroupBy.current[name] != null) {
                    saveGroupBy.current[name].dataChart[pageOpenedValue.current.id - 1].groupBy = chartValue.current.dataChart[pageOpenedValue.current.id - 1].groupBy
                    let pageOpenTemp = saveGroupBy.current[name].pages.find(page => page.id == pageOpenedValue.current.id)
                    pageOpenTemp.content.groupBy = pageOpenedValue.current.content.groupBy
                    pageOpenTemp.content.aggregation.data = pageOpenedValue.current.content.aggregation.data
                }
            }
        }

        if ((chartfind.aggregateOn != null && chartfind.aggregator != null) || chartValue.current.type == "Grid") {
            handleSendDataChart()
        }
    }

    let handleSelectAggregationProperty = async (type, e, values) => {
        let chartfind = setValueTreeView("aggregateOn", e, chartValue.current, "numeric")
        pageOpenedValue.current.content.aggregation.property = getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, chartValue.current.dataChart[pageOpenedValue.current.id - 1].dataTreeView.numeric, true).replaceAll('.', " ➧ ")
        if (pageOpenedValue.current.content.type.aggregation != type) {
            if (chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregator != "Count") {
                chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregator = "Count"
                pageOpenedValue.current.content.aggregation.operation = "Count"
            }
        }

        pageOpenedValue.current.content.type.aggregation = type
        setPageOpened({...pageOpenedValue.current})

        if (chartValue.current.type == "Number") {
            for (var name in saveGroupBy.current) {
                if (saveGroupBy.current[name] != null) {
                    saveGroupBy.current[name].dataChart[pageOpenedValue.current.id - 1].aggregateOn = chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregateOn
                    saveGroupBy.current[name].dataChart[pageOpenedValue.current.id - 1].aggregator = chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregator
                    let pageOpenTemp = saveGroupBy.current[name].pages.find(page => page.id == pageOpenedValue.current.id)
                    pageOpenTemp.content.type = type
                    pageOpenTemp.content.aggregation.operation = pageOpenedValue.current.content.aggregation.operation
                    pageOpenTemp.content.aggregation.property = pageOpenedValue.current.content.aggregation.property
                }
            }
        }

        if ((chartfind.groupBy != null && chartfind.aggregator != null) || (chartfind.aggregator != null && chartValue.current.type == "Number")) {
            await handleSendDataChart()
        }
    }

    let handleLimit = (e, params) => {
        if (!onlyNumberKey(e.target.value) && e.target.value != "")
            return
        for (let i = 0; i < pages.current.length; i++) {
            chartValue.current.dataChart[i].limit[params] = e.target.value == "" ? null : parseInt(e.target.value)
            pages.current[i].content.limit[params] = e.target.value
        }
        handleSendDataChart()
    }

    let handleDropdownAggregation = async (e) => {
        let chart = chartValue.current.dataChart[pageOpenedValue.current.id - 1]
        if (chart == undefined)
            return
        if (pageOpenedValue.current.content.aggregation.operation == e.value)
            return
        pageOpenedValue.current.content.aggregation.operation = e.value
        setPageOpened({...pageOpenedValue.current})
        if (e.value == null)
            return
        chart.aggregator = e.value

        if (chartValue.current.type == "Number") {

            for (var name in saveGroupBy.current) {
                if (saveGroupBy.current[name] != null) {
                    saveGroupBy.current[name].dataChart[pageOpenedValue.current.id - 1].aggregator = chartValue.current.dataChart[pageOpenedValue.current.id - 1].aggregator
                    let pageOpenTemp = saveGroupBy.current[name].pages.find(page => page.id == pageOpenedValue.current.id)
                    pageOpenTemp.content.aggregation.operation = pageOpenedValue.current.content.aggregation.operation
                }
            }
        }

        if ((chart.aggregateOn != null && chart.groupBy != null) || (chart.aggregateOn != null && chartValue.current.type == "Number")) {
            await handleSendDataChart()
        }
    }

    let handleOperatorCenterText = async (e) => {
        chartValue.current.dataChart[0].totalAggregator = e.value

        if (chartValue.current.dataChart[0].aggregateOn != null && chartValue.current.dataChart[0].groupBy != null && chartValue.current.dataChart[0].aggregator != null) {
            await handleSendDataChart()
        }
    }

    let handleSendDataChart = async (force) => {
        if (!refreshAuto && force == undefined)
            return
        setLoadChart(true)
        let chart = null
        let idJsonChart = chartValue.current.id

        chart = chartValue.current.dataChart[pageOpenedValue.current.id - 1]
        if (chart == null)
            return setLoadChart(false)

        if (chartValue.current.type == "Number")
            chart.groupBy = null
        let jsonChartTemp = null
        if (!checkChart(chartValue.current))
            return setLoadChart(false)
        await axios.post("/eData/dashboard/process/chart", {chart: createQuery()}).then(res => jsonChartTemp = res.data)

        let chartTemp = convertJsonChart(jsonChartTemp)
        chartTemp.id = idJsonChart

        for (let i = 0; i < chartTemp.dataChart.length; i++) {
            chartTemp.dataChart[i].dataTreeView = chartValue.current.dataChart[i].dataTreeView
        }
        chartValue.current = chartTemp
        setJsonChart({...chartValue.current})
        setLoadChart(false)
    }
    let handleCheckCenterText = (e) => {
        chartValue.current.isCenter = e.target.checked
        setJsonChart({...chartValue.current})
    }

    let handleCheckedTrendLine = (e) => {
        pageOpenedValue.current.content.isTrendLine = e.target.checked
        chartValue.current.dataChart.find(data => data.id == pageOpenedValue.current.id - 1).trendLine.isChecked = e.target.checked
        setPageOpened({...pageOpenedValue.current})
        handleSendDataChart()
    }

    let initialiseDataChartAddedTab = () => {
        let newChart = {
            id: chartValue.current.dataChart.length + 1,
            data: [],
            dataCenter: null,
            color: ["#0db1e7"],
            groupBy: "",
            aggregateOn: null,
            aggregator: null,
            totalAggregator: null,
            name: "",
            filter: "",
            dataTreeView: [],
            trendLine: {
                isChecked: false,
                data: null,
            },
            limit: {
                min: chartValue.current.dataChart.length > 0 ? chartValue.current.dataChart[0].limit.min : null,
                max: chartValue.current.dataChart.length > 0 ? chartValue.current.dataChart[0].limit.max : null,
                objective: chartValue.current.dataChart.length > 0 ? chartValue.current.dataChart[0].limit.objective : null,
            },
            ids: []
        }
        chartValue.current.dataChart.push(newChart)
        for (var name in saveGroupBy.current) {
            if (saveGroupBy.current[name] != null)
                saveGroupBy.current[name].dataChart.push({...newChart})
        }
    }
    let validate = async () => {
        if (loading.current)
            return
        if (!checkChart(chartValue.current))
            return
        if (chartValue.current.name == t("chart:with_out_title") || chartValue.current.name == "")
            return alert(t("chart:missing_title"))
        if (chartValue.current.type == "Pie" || chartValue.current.type == "Doughnut") {
            for (var i = 1; i < chartValue.current.dataChart.length; i++) {
                chartValue.current.dataChart.splice(i, 1)
            }
        }
        let query = {
            category: parseInt(props.catId),
            query: createQuery()
        }
        if (props.propsAdd) {
            await axios.put("/eData/dashboard/edit/chart/" + chartValue.current.id, query)
        } else {
            await axios.post("/eData/dashboard/new/chart", query).then(res => {
                chartValue.current.id = res.data
            })
        }
        if (props.propsAdd) {
            props.propsAdd.addChart(chartValue.current, pages.current)
            document.body.classList.remove("overflow-hidden")
            props.setShowModal(false)
        } else {
            let div = document.getElementById('container-modalCustomBlock')
            div.id = 'container-modaladdBlock'
            deleteDiv()
            props.setContent(
                <ChartsMenuView addChart={props.addChart} title={props.title} setShowModal={props.setShowModal}
                                setContent={props.setContent}
                                catId={props.catId} listBlocks={props.listBlocks}/>
            )
        }

    }

    let createQuery = (chart) => {
        return generateChartQuery(chartValue.current)
    }

    let handleRefreshAuto = (e) => {
        setRefreshAuto(refreshAuto ? false : true)
    }

    let handleClickRefresh = async (e) => {
        handleSendDataChart(true)
        setRefreshForced(true)
    }


    let sendTextChart = async (query) => {
        setLoadChart(true)
        let data = null
        await axios.post("/eData/dashboard/process/chart", {
            chart:
            query
        }).then(res => data = res.data)
        data.id = chartValue.current.id
        chartValue.current = convertJsonChart(data)
        let pageTemp = convertJsonPage(data)
        setValueTitle(chartValue.current.name)
        for (let i = 0; i < chartValue.current.dataChart.length; i++) {
            await loadData(chartValue.current.dataChart[i].filter, pageTemp[i].id, i ==  chartValue.current.dataChart.length - 1)
            if (pageTemp[i].id != 1)
                pageTemp[i].isOpen = false
            else
                pageTemp[i].isOpen = true
            if (pageTemp[i].content.aggregation.data.length == 0) {
                pageTemp[i].content.groupBy = getTrad(pageTemp[i].content.groupBy, chartValue.current.dataChart[pageTemp[i].id - 1].dataTreeView.all).replaceAll('.', " ➧ ")
                pageTemp[i].content.aggregation.data.push(setAggregationData(chartValue.current.dataChart[pageTemp[i].id - 1].filter.split(".")[0], chartValue.current.dataChart[pageTemp[i].id - 1].dataTreeView, t))
                if (chartValue.current.type == "Grid")
                    continue
                if (pageTemp[i].content.aggregation.operation == "Calc")
                    continue
                pageTemp[i].content.type.aggregation = getColumnType(pageTemp[i].content.aggregation.data, pageTemp[i].content.aggregation.property.split('.'))
                pageTemp[i].content.aggregation.property = getTrad(pageTemp[i].content.aggregation.property, pageTemp[i].content.aggregation.data).replaceAll('.', " ➧ ")
            }
        }

        setJsonChart({...chartValue.current})
        pages.current = pageTemp
        pageOpenedValue.current = pages.current.find(page => page.isOpen)
        setPageOpened({...pageOpenedValue.current})
        setLoadDataTreeview(true)
        setLoadChart(false)
    }

    let handlePeriodDropdown = async (e) => {
        chartValue.current.dateValue = e.value
        let chartTemp = {...await loadChartData(chartValue.current)}
        chartValue.current = chartTemp
        setJsonChart({...chartValue.current})
    }

    let backCategory = () => {
        let div = document.getElementById('container-modalCustomBlock')
        div.id = 'container-modaladdBlock'
        deleteDiv()
        props.setContent(<ChartsMenuView isDefault={props.isDefault} listBlocks={props.listBlocks}
                                         addChart={props.addChart}
                                         title={props.title}
                                         setShowModal={props.setShowModal}
                                         setContent={props.setContent}
                                         catId={props.catId}
                                         refreshBlock={props.refreshBlock}
        />)
    }


    return (
        <div className="modal-box-custom">
            <div className="modal-box-button">
                <button onClick={closeModal} className="close">
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path
                            d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
            </div>
            <div className="custom-container">
                <div className="d-flex align-items-center">
                    {props.canBack ? <button onClick={backCategory} className="previous-button">
                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path
                                d="m27.4 14.9v11.7c0 1.8-1.9 2.9-3.5 2l-10.1-5.8-10.1-5.9c-1.5-0.9-1.5-3.1 0-4l20.2-11.6c1.5-0.9 3.5 0.2 3.5 2v11.6z"/>
                        </svg>
                    </button> : null}
                    <h1 className="mt-0 mb-0">{t("chart:header")}</h1>
                </div>
                <div className="custom-box-container">
                    <QueryShow
                        chartQuery={chartQuery}
                        sendTextChart={sendTextChart}
                    />
                    <div className="mb-1 d-flex align-items-center">
                        <input id="switch-flat-refresh" className="switch-refresh switch--flat--refresh" type="checkbox"
                               defaultChecked={true} onChange={handleRefreshAuto}/>
                        <label htmlFor="switch-flat-refresh"></label>
                        <p>{t("chart:refresh", {context: "auto"})}</p>
                    </div>
                    <div id={"query-overflow-vertical"} className="d-flex customchart-container">
                        <ListViewCharts chartValue={chartValue} refresh={refreshAuto} setRefreshForced={setRefreshForced}
                                        setJsonChart={setJsonChart}
                                        jsonChart={jsonChart}
                                        pageOpened={pageOpened}
                                        pages={pages}
                                        handleSendDataChart={handleSendDataChart}
                                        saveGroupBy={saveGroupBy}
                                        setPageOpened={setPageOpened}
                                        loadData={loadData}
                                        chartValue={chartValue}
                        />
                        <div
                            className={jsonChart.type == null ? "aggregation-treeview disabled-treeview" : jsonChart.type == "Note" ? "aggregation-treeview d-none" : "aggregation-treeview"}
                        >
                            <div>
                                <div>
                                    <p className="mt-0">{t("chart:title")}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <input onBlur={handleInputTitle} onChange={handleOnChangeTitle} value={valueTitle}
                                           type="text"
                                           name="inputTitle"
                                           className="input-text"/>
                                    <button onClick={handleClickRefresh}
                                            className={refreshAuto ? "refresh-button disabled" : "refresh-button"}
                                            title={t("chart:refresh")}>
                                        <svg enableBackground="new 0 0 33.1 33.1" version="1.1" viewBox="0 0 33.1 33.1"
                                             className="spin-animation">
                                            <path
                                                d="m17 2.6c-0.7 0-1.4 0.1-2.1 0.2l-0.8-1.6c-0.4-0.8-1.4-0.8-1.9-0.1l-3.6 5.5c-0.5 0.7 0 1.6 0.9 1.7l6.6 0.4c0.8 0 1.4-0.8 1-1.6l-0.7-1.4h0.6c5.8 0 10.5 4.7 10.5 10.5 0 1.3-0.2 2.6-0.7 3.7-0.3 0.8 0.1 1.6 0.9 1.9 0.2 0.1 0.4 0.1 0.5 0.1 0.6 0 1.2-0.4 1.4-1 0.6-1.5 0.9-3.2 0.9-4.8 0-7.5-6.1-13.5-13.5-13.5z"/>
                                            <path
                                                d="m16.1 30.5c0.7 0 1.4-0.1 2.1-0.2l0.8 1.7c0.4 0.8 1.4 0.8 1.9 0.1l3.6-5.5c0.5-0.7 0-1.6-0.9-1.7l-6.6-0.4c-0.8 0-1.4 0.8-1 1.6l0.7 1.4h-0.6c-5.8 0-10.5-4.7-10.5-10.5 0-1.3 0.2-2.6 0.7-3.7 0.3-0.8-0.1-1.6-0.9-1.9-0.2-0.1-0.4-0.1-0.5-0.1-0.6 0-1.2 0.4-1.4 1-0.6 1.5-0.9 3.2-0.9 4.8 0 7.4 6.1 13.4 13.5 13.4z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <AggregationTreeview
                                pages={pages}
                                pageOpened={pageOpened}
                                setPageOpened={setPageOpened}
                                dataTreeView={dataTreeView}
                                handleSelectGroupBy={handleSelectGroupBy}
                                handleSelectAggregationProperty={handleSelectAggregationProperty}
                                handleDropdownOperation={handleDropdownAggregation}
                                jsonChart={jsonChart}
                                setJsonChart={setJsonChart}
                                handleNameLegend={handleNameLegend}
                                initialiseDataChartAddedTab={initialiseDataChartAddedTab}
                                handleCheckCenterText={handleCheckCenterText}
                                handleOperatorCenterText={handleOperatorCenterText}
                                idModal={props.idModal}
                                chartValue={chartValue}
                                loadData={loadData}
                                loadDataTreeView={loadDataTreeview}
                                setLoadDataTreeView={setLoadDataTreeview}
                                pageOpenedValue={pageOpenedValue}
                                saveGroupBy={saveGroupBy}
                                setChartQuery={setChartQuery}
                                createQuery={createQuery}
                                handleLimit={handleLimit}
                                handleSendDataChart={handleSendDataChart}
                                handleCheckedTrendLine={handleCheckedTrendLine}
                            />
                        </div>
                        {loadChart ? <div className={"treeview-spinner-chart-div"}>
                            <span className={"treeview-spinner-chart"}></span>
                        </div> : <div className="period-container">
                            <PreviewChartRender inDashboard={false} setRefreshForced={setRefreshForced}
                                                refreshForced={refreshForced}
                                                refreshAuto={refreshAuto} inDashboard={false}
                                                pageOpened={pageOpened}
                                                dataQuery={props.dataQuery} chart={jsonChart}
                                                chartValue={chartValue} randomId={true} setJsonChart={setJsonChart}
                                                eventChange={handlePeriodDropdown}
                                                editNote={true}
                                                handleInputTitle={handleInputTitle}
                                                handleOnChangeTitle={handleOnChangeTitle}
                                                valueTitle={valueTitle}
                            />
                                </div>}


                    </div>
                </div>
                <div className="pt-1">
                    <button className="bouton ml-auto" onClick={validate}>{t("default:proceed")}</button>
                </div>
            </div>
        </div>
    )
}


export {RenderViewCustomChart}
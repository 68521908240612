import {TABLE_SVG} from "../../style/menuSVG";
import {SpreadsheetComponent} from "@syncfusion/ej2-react-spreadsheet";
import React, {useRef, useState} from "react";
import axios from "axios";
import {BullContainer} from "../Common/bullContainer";
import {useTranslation} from "react-i18next";
import {isNullOrUndefined} from '@syncfusion/ej2-base';
import {CommonErrors, dateToInt, isNumber} from "@syncfusion/ej2-spreadsheet";

function SpreadsheetView(props) {
    const {t, i18n} = useTranslation()
    const spreadsheet = useRef()
    const [loadingSheet, setLoadingSheet] = useState(false)
    const [loadedSheet, setLoadedSheet] = useState(false)

    const createdSheet = async () => {
        setLoadingSheet(true)
        await axios.get('/helpers/spreadsheet/get/' + props.name + '/' + props.id, {responseType: 'blob', doNotCatch: true}).then(res => {
            let file = new File([res.data], "spreadsheet.xlsx")

            spreadsheet.current.workbookFormulaModule.calculateInstance.setParseArgumentSeparator(t('views:separator', {
                context: 'argument'
            }))
            spreadsheet.current.workbookFormulaModule.calculateInstance.setParseDecimalSeparator(t('views:separator', {
                context: 'decimal'
            }))

            // Hook dans le code de syncfusion pour corriger le problème des décimales
            spreadsheet.current.workbookFormulaModule.calculateInstance.getValArithmetic = function (stack, operator) {
                var num1 = stack.pop();
                num1 = num1 === this.emptyString ? '0' : num1.replaceAll(this.getParseDecimalSeparator(), '.');
                var num = Number(num1);
                if (isNaN(num)) {
                    if (num1 === this.getErrorStrings()[CommonErrors.divzero]) {
                        throw this.getErrorStrings()[CommonErrors.divzero];
                    } else {
                        throw this.getErrorStrings()[CommonErrors.value];
                    }
                }

                var num2 = stack.pop();
                num2 = num2 === this.emptyString ? '0' : num2.replaceAll(this.getParseDecimalSeparator(), '.');
                num = Number(num2);
                if (isNaN(num)) {
                    if (num1 === this.getErrorStrings()[CommonErrors.divzero]) {
                        throw this.getErrorStrings()[CommonErrors.divzero];
                    } else {
                        throw this.getErrorStrings()[CommonErrors.value];
                    }
                }

                let numTemp;

                if (operator === 'add') {
                    numTemp = Number(num2) + Number(num1);
                }
                if (operator === 'sub') {
                    numTemp = Number(num2) - Number(num1);
                }
                if (operator === 'mul') {
                    numTemp = Number(num2) * Number(num1);
                }
                if (operator === 'div') {
                    if (this.isNaN(this.parseFloat(num1)) || this.isNaN(this.parseFloat(num2))) {
                        stack.push(this.getErrorStrings()[CommonErrors.value]);
                    } else if (this.parseFloat(num1) === 0) {
                        stack.push(this.getErrorStrings()[CommonErrors.divzero]);
                    } else {
                        numTemp = Number(num2) / Number(num1);
                    }
                }

                stack.push((Number(num2) + Number(num1)).toString().replaceAll('.', this.getParseDecimalSeparator()));
            };

            spreadsheet.current.spreadsheetChartModule.processChartSeries = function (options, sheetIndex, xRange, yRange, lRange) {
                options = options || {};
                var seriesName = '';
                var dataLabel = {};
                var val;
                var xValue;
                var lValue;
                var diff;
                var pArr;
                var pObj = {};
                var j;
                var i = 0;
                var yInc = 0;
                var sArr = [];
                var dtVal;
                sheetIndex = isNullOrUndefined(sheetIndex) ? this.parent.getActiveSheet().index : sheetIndex;
                var sheet = this.parent.sheets[sheetIndex];
                var yValue = this.getRangeData({range: yRange, sheetIdx: sheetIndex, skipFormula: true, isYvalue: true});
                var rDiff = ((yRange[2] - yRange[0]) + 1) - this.parent.hiddenCount(yRange[0], yRange[2], 'rows', sheet);
                var cDiff = ((yRange[3] - yRange[1]) + 1) - this.parent.hiddenCount(yRange[0], yRange[2], 'columns', sheet);
                if (options.isSeriesInRows) {
                    xValue = lRange ? this.toArrayData(this.getRangeData({range: lRange, sheetIdx: sheetIndex, skipFormula: false, isYvalue: false})) :
                        this.getVirtualXValues(cDiff + 1);
                    if (xRange) {
                        lValue = this.toArrayData(this.getRangeData({range: xRange, sheetIdx: sheetIndex, skipFormula: false, isYvalue: false}));
                    }
                    diff = rDiff;
                } else {
                    xValue = xRange ? this.toArrayData(this.getRangeData({range: xRange, sheetIdx: sheetIndex, skipFormula: false, isYvalue: false})) :
                        this.getVirtualXValues(rDiff + 1);
                    if (lRange) {
                        lValue = this.toArrayData(this.getRangeData({range: lRange, sheetIdx: sheetIndex, skipFormula: false, isYvalue: false}));
                    }
                    diff = cDiff;
                }
                var len = xValue.length;
                var inc = options.isSeriesInRows ? 1 : diff;
                if (!isNullOrUndefined(options.dataLabelSettings)) {
                    dataLabel.visible = options.dataLabelSettings.visible;
                    dataLabel.position = options.dataLabelSettings.position;
                }
                while (i < diff) {
                    j = 0;
                    pArr = [];
                    yInc = options.isSeriesInRows ? yInc : i;
                    while (j < len) {
                        if (yValue[yInc]) {
                            val = yValue[yInc].value;
                            val = val.replaceAll(this.parent.workbookFormulaModule.calculateInstance.getParseDecimalSeparator(), '.')
                            if (isNumber(val)) {
                                val = Number(val);
                            } else {
                                dtVal = dateToInt(val);
                                val = isNaN(dtVal) ? 0 : dtVal;
                            }
                            pArr.push({x: xValue[j], y: val});
                        }
                        yInc += inc;
                        j++;
                    }
                    if (lValue && lValue.length > 0) {
                        seriesName = lValue[i];
                    } else {
                        seriesName = 'series' + i;
                    }
                    if (options.type) {
                        var type = options.type;
                        if (type === 'Line' || type === 'StackingLine' || type === 'StackingLine100') {
                            pObj = {
                                dataSource: pArr, type: options.type, xName: 'x', yName: 'y', name: seriesName.toString(), marker: {
                                    visible: true,
                                    width: 10,
                                    height: 10,
                                    dataLabel: dataLabel
                                }
                            };
                        } else if (type === 'Scatter') {
                            pObj = {
                                dataSource: pArr, type: options.type, xName: 'x', yName: 'y', name: seriesName.toString(), marker: {
                                    visible: false,
                                    width: 12,
                                    height: 12,
                                    shape: 'Circle',
                                    dataLabel: dataLabel
                                }
                            };
                        } else if (type === 'Pie' || type === 'Doughnut') {
                            var innerRadius = options.type === 'Pie' ? '0%' : '40%';
                            var visible = dataLabel.visible;
                            var position = isNullOrUndefined(dataLabel.position) ? 'Inside' : dataLabel.position === 'Outer' ? 'Outside' : 'Inside';
                            pObj = {
                                dataSource: pArr,
                                dataLabel: {
                                    visible: !isNullOrUndefined(visible) ? visible : false, position: position, name: 'text', font: {fontWeight: '600'}
                                },
                                radius: '100%', xName: 'x', yName: 'y', innerRadius: innerRadius
                            };
                        } else {
                            pObj = {
                                dataSource: pArr, type: options.type, xName: 'x', yName: 'y',
                                name: seriesName.toString(), marker: {dataLabel: dataLabel}
                            };
                        }
                    }
                    sArr.push(pObj);
                    i++;
                }
                var retVal;
                if (options.type) {
                    retVal = {
                        series: sArr, xRange: options.isSeriesInRows ? lRange : xRange,
                        yRange: yRange, lRange: options.isSeriesInRows ? xRange : lRange
                    };
                }
                return retVal;
            };

            spreadsheet.current.open({file: file})
        }).catch(error => {
            console.log(error.response.status)
        })
        setLoadingSheet(false)
        setLoadedSheet(true)
    }

    return (
        <BullContainer title={t("default:spreadsheet")} img={TABLE_SVG} content={
            <>
                {loadedSheet ? null : <div className="tableau-chargement">
                    <div className="tableau-chargement-message text-center">
                        <p>{t("views:load_spreadsheet")}</p>
                        <button onClick={createdSheet}
                                className={loadingSheet ? "bouton mt-3 pl-1 pt-1 pr-1 pb-1 loading" : "bouton mt-3 pl-1 pt-1 pr-1 pb-1"}>{loadingSheet ?
                            <span
                                className="spinner"></span> : t("views:load_spreadsheet", {context: "button"})}</button>
                    </div>
                </div>}
                <SpreadsheetComponent className="tableau" ref={spreadsheet}
                                      locale={i18n.language}
                                      openUrl={axios.defaults.baseURL + "helpers/spreadsheet/open"}
                                      allowOpen={true} showSheetTabs={true} height={'800px'} showFormulaBar={false} showRibbon={false}/>
            </>}/>
    )
}

export {SpreadsheetView}
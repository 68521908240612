import {FieldManager} from "../../Utilities/FieldManager";
import React from "react";
import {generateKey} from "../Common/utilities";
import {useTranslation} from "react-i18next";
import {TableTest} from "../Common/Table/newTable";

function ContentGestion(props) {
    const {t, i18n} = useTranslation()
    let divs = []
    let i = 0
    for (var name in props.data[0]) {
        if (name == "linkedInterventions") {
            divs.push(
                <div key={generateKey(props.names[i])} className="mt-5 px-3">
                    <p className="fw-bold mb-0 mt-1 mb-1">{props.names[i]}</p>
                    <div className="box-shadow-mini">
                        <TableTest
                            data={props.data[0][name]}
                            col={FieldManager.generateColumns([[t("default:name"), 'designation'], [t("anomaly:linked_interventions", {context: "cost"}), 'totalCost', "cout"]])}
                            clickableColumn={FieldManager.generateClickableColumns(props.data[0][name], [0], ["id"])}
                            classtd={['intervention-color', '']}
                            ope={[]}
                            cause={[]}
                            path={["/intervention", ""]}
                            isCaract={false}
                        />
                    </div>
                </div>)
        } else {
            divs.push(<div key={generateKey(props.names[i])}
                           className="wrapper d-flex justify-content-center align-items-center gap-15">
                <p className="fw-bold w-25 text-right mb-0 mt-1 flex-1">{props.names[i]}</p>
                <p className="w-45 mb-0 mt-1 flex-1">{props.data[0][name]}</p>
            </div>)
        }
        i++
    }
    return (
        <div className="pt-3 pb-3">
            {divs}
        </div>
    )
}

export {ContentGestion}
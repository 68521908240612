import {ChartComponent, Inject, SeriesCollectionDirective} from '@syncfusion/ej2-react-charts'
import React, {useEffect, useState} from "react";
import {Browser} from '@syncfusion/ej2-base'


function ChartLineBarColumn(props) {
    let handleLoad = (e) => {
        e.chart.height = '95%'
        e.chart.width = '95%'
    }
    let getLabelIntersection = () => {
        if (props.chart.dataChart[0].data == undefined)
            return
        let inter = "Rotate45"
        for (let i = 0; i < props.chart.dataChart[0].data.length; i++) {
            if (props.chart.dataChart[0].data[i].x.length > 30) {
                inter = "Trim"
            }
        }
        return inter
    }
    let stripLineAdd = () => {
        let lines = []
        if (props.chart.dataChart[0].limit == undefined)
            return []
        let lineMin = {
            zIndex: "Over",
            start: props.chart.dataChart[0].limit.min,
            horizontalAlignment: "End",
            verticalAlignment: "End",
            text: props.chart.dataChart[0].limit.min + "",
            textStyle: {color: "red", size: 13},
            size: 2,
            sizeType: 'Pixel',
            dashArray: "10,5",
            color: "red",
        }
        let lineMax = {
            zIndex: "Over",
            start: props.chart.dataChart[0].limit.max,
            horizontalAlignment: "End",
            verticalAlignment: "End",
            text: props.chart.dataChart[0].limit.max + "",
            textStyle: {color: "red", size: 13},
            size: 2,
            sizeType: 'Pixel',
            dashArray: "10,5",
            color: "red"
        }
        let lineObj = {
            zIndex: "Over",
            start: props.chart.dataChart[0].limit.objective,
            horizontalAlignment: "End",
            verticalAlignment: "End",
            text: props.chart.dataChart[0].limit.objective + "",
            textStyle: {color: "green", size: 13},
            size: 2,
            sizeType: 'Pixel',
            dashArray: "10,5",
            color: "green"
        }
        if (lineMax.start != null) {
            lines.push(lineMax)
        }
        if (lineMin.start != null) {
            lines.push(lineMin)
        }
        if (lineObj.start != null) {
            lines.push(lineObj)
        }
        return lines
    }

    return (

        <ChartComponent
            load={handleLoad}
            ref={props.chartRef}
            palettes={props.color}
            id={'charts' + props.id}
            style={props.inDashboard ? {textAlign: "center", "height": "calc(100% - 50px)", "width": "95%"} : null}
            primaryXAxis={{
                labelIntersectAction: getLabelIntersection(),
                valueType: props.abscissaType,
                interval:1,
                majorGridLines: {width: 0},
                labelStyle: {
                    color: "#2b587b",
                },
                labelFormat: props.abscissaType == "DateTime" ? 'dd/MM/yyyy' : undefined,
                minimum: props.abscissaType == "DateTime" ? null : props.xScale ?  props.xScale.min : null,
                maximum: props.abscissaType == "DateTime" ? null : props.xScale ?  props.xScale.max : null,
            }}
            primaryYAxis={{
                majorGridLines: {width: 0},
                majorTickLines: {width: 1}, lineStyle: {width: 1}, labelStyle: {color: "#2b587b"},
                stripLines:stripLineAdd(),
               minimum: props.yScale ? props.yScale.min : null,
                maximum: props.yScale ? props.yScale.max : null,
            }}
            chartArea={{border: {width: 0}}} tooltip={{enable: true}}
            width={Browser.isDevice ? '100%' : '60%'}
            title={props.chart.name}
            zoomSettings={{
                enableMouseWheelZooming: props.zoomEnabled,
                toolbarItems: ['Reset', 'Zoom', 'Pan'],
                mode: 'X',
                enableScrollbar: true
            }
            }
        >
            <Inject services={props.services}/>
            <SeriesCollectionDirective>
            </SeriesCollectionDirective>
        </ChartComponent>
    )
}


export {ChartLineBarColumn}
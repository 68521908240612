import {useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Menu} from "../Menu/menu";
import {ButtonBanner} from "../Common/buttonBanner";
import {Information} from "../Modal/information";
import {GenerateReport} from "../Modal/generateReport";
import {FieldManager} from "../../Utilities/FieldManager";
import {anomalySVG, caracSVG, etalonSVG, summarySVG, controlcardSVG} from "../../style/logoSVG";
import {SpreadsheetView} from "../Utilities/spreadsheet";
import {LifeSheet} from "./lifesheet";
import {ContentPlanning} from "./contentPlanning";
import {LoadingPage} from "../Common/loadingPage";
import {BALISE_BUTTON_INFO, BALISE_BUTTON_PDF, generateKey, getPosDivEntity} from "../Common/utilities";
import {BullContainer} from "../Common/bullContainer";
import {ContentSummary} from "../Common/contentSummary";
import {TableTest} from "../Common/Table/newTable";
import {Banner} from "../Common/banner";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useIsMounted} from "../Utilities/useIsMounted";
import {ControleCard} from "../Common/controleCard";
import {convertJsonChart} from "../charts/CustomChartWindow/utilities";


function RenderElementView(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setLoading] = useState(true)
    const [banner, setBannerData] = useState([])
    const [summary, setSummary] = useState([])
    const [anomalies, setAnomalies] = useState([])
    const [delayedInterventions, setDelayedInterventions] = useState([])
    const [plannedInterventions, setPlannedInterventions] = useState([])
    const [characteristics, setCharacteristics] = useState([])
    const [loans, setLoans] = useState([])
    const [id, setId] = useState(null)
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const isMounted = useIsMounted()
    const [topDivEntity, setTopDivEntity] = useState(0)

    useEffect(async () => {
        //Verifie si l'utilisateur est connecté sinon le renvoie à la page login
        if (window.localStorage.getItem('token') == "null") {
            navigate("/login")
        }
        if (id == null)
            return
        setLoading(true)
        //Envoie des requête au serviceweb pour récupérer les données de la page
        await axios.all([
            axios.get(`/eData/elements/banner/${id}`),
            axios.get(`/eData/elements/summary/${id}`),
            axios.get(`/eData/elements/anomalies/${id}`),
            axios.get(`/eData/elements/plannedInterventions/${id}`),
            axios.get(`/eData/elements/delayedInterventions/${id}`),
            axios.get(`/helpers/characteristics/get/element/${id}`),
            axios.get(`/eData/elements/loans/${id}`),
        ]).then(axios.spread(function (res1, res2, res3, res4, res5, res6, res7) {
            if (isMounted.current) {
                setBannerData(res1.data)
                setSummary(res2.data)
                setAnomalies(res3.data)
                setPlannedInterventions(res4.data)
                setDelayedInterventions(res5.data)
                setCharacteristics(res6.data)
                setLoans(res7.data)
            }
        }))
        setLoading(false)
        setPostDivEntity()
    }, [id])
    useEffect(() => {
        if (banner.designation)
            document.title = banner.designation
        return () => {
        }
    }, [banner])

    useEffect(() => {
        if (searchParams.get('id') == null)
            return
        setId(searchParams.get('id'))
        return () => {
        }
    }, [searchParams])

    let setPostDivEntity = () => {
        setTopDivEntity(getPosDivEntity() + "px")
    }

    useEffect(()=>{
        window.addEventListener("resize",setPostDivEntity);
        return () => {
            window.removeEventListener('resize', setPostDivEntity)
        }
    },[])


    return isLoading ? (<LoadingPage/>) : (
        <>
            <Menu general={false} caract={false} controleCard={false} lifesheet={false} tableau={false} controlCard={false}/>
            <Banner type={[t("default:element"), 'color-green']}
                    colorHideBanner={"elem"}
                    imgtype={<img src={require('../../img/element.png')}/>}
                    title={banner.designation}
                    buttons={[<ButtonBanner name={"Element"} id={id} key={generateKey("info")} img={BALISE_BUTTON_INFO}
                                            content={Information}/>,
                        <ButtonBanner name={"element"} id={id} key={generateKey("report")} img={BALISE_BUTTON_PDF}
                                      content={GenerateReport}/>]}
                    fields={[FieldManager.generateJsonBannerField(null, 'mb-0 d-inline-block mt-1', banner.reference, null, t("element:reference")),
                        FieldManager.generateJsonBannerField(null, 'mb-0 d-inline-block mt-1', banner.type, null, t("default:type")),
                        FieldManager.generateJsonBannerField(null, 'mb-0 d-flex align-items-center mt-1', banner.state, null, t("views:state"))]}
            />
            <div style={{paddingTop:topDivEntity}} className="margin-constraint">
                <div id="general">
                    <div className="wrapper d-flex flex-wrap-query">
                        <BullContainer title={t("views:summary")} img={summarySVG} content=
                            {<ContentSummary
                                setSearchParams={setSearchParams}
                                name={"element"}
                                data={[FieldManager.generateJsonSummaryField(null, t("element:code"), summary.code, 'w-45 mb-0 mt-1', 'fw-bold w-45 text-right mb-0 mt-1', null),
                                    FieldManager.generateJsonSummaryField(null, t("element:owner"), summary.owner == null ? "" : summary.owner.designation, summary.owner == null ? 'w-45 mb-0 mt-1' : 'p-0 mb-0 mt-0 element-color', 'fw-bold w-45 text-right mb-0 mt-1', null, summary.owner == null ? null : "/element/?id=" + summary.owner.id),
                                    FieldManager.generateJsonSummaryField(null, t("element:status"), summary.status, 'w-45 mb-0 mt-1', 'fw-bold w-45 text-right mb-0 mt-1', null),
                                    FieldManager.generateJsonSummaryField(null, t("element:plannable"), summary.isPlannable, 'w-45 mb-0 mt-1', 'fw-bold w-45 text-right mb-0 mt-1', null),
                                    FieldManager.generateJsonSummaryField(null, t("element:lent"), summary.isLent, 'w-45 mb-0 mt-1', 'fw-bold w-45 text-right mb-0 mt-1', null),
                                    FieldManager.generateJsonSummaryField(null, t("element:managers"), summary.manager, 'w-45 mb-0 mt-1', 'fw-bold w-45 mb-0 mt-1 d-flex align-items-center justify-content-end', 'parc-ope')]}/>}/>
                        <BullContainer title={t("element:ongoing_anomalies")}
                                       img={anomalySVG}
                                       content={<TableTest
                                           data={FieldManager.deleteField(anomalies, ["anoId", "isOpened"])}
                                           col={FieldManager.generateColumns([[t("views:designation"), 'designation', "string"], [t("views:nature"), 'nature', "string"], [t("views:date"), 'date', "date"], [t("views:cause"), 'cause', "cause"], [t("views:operator"), 'operator', "operator"], [t("anomaly:delay"), 'nextDate', "date"]])}
                                           clickableColumn={FieldManager.generateClickableColumns(anomalies, [0], ["id"])}
                                           classtd={['anomalie-color', '', '', 'intervention-color', '', '']}
                                           ope={[4]}
                                           cause={[3]}
                                           path={["/anomaly", "", "", "/intervention", "", ""]}
                                           isCaract={false}
                                       />}
                        />
                    </div>
                    <BullContainer title={t("element:delayed_interventions")}
                                   img={anomalySVG}
                                   content={<TableTest
                                       data={delayedInterventions}
                                       col={FieldManager.generateColumns([[t("views:designation"), 'designation', "string"], [t("views:nature"), 'nature', "string"], [t("views:date"), 'date', "date"], [t("views:requestor"), 'requestor', "operator"], [t("intervention:stakeholder", {context: "main"}), 'operator', "operator"], [t("intervention:certificate"), 'certificateName', "string"], [t("intervention:judgment"), 'judgment', "string"]])}
                                       clickableColumn={FieldManager.generateClickableColumns(delayedInterventions, [0], ["id"])}
                                       classtd={['intervention-color', '', '', '', '']}
                                       ope={[3, 4]}
                                       cause={[]}
                                       path={["/intervention", "", "", "", ""]}
                                       isCaract={false}
                                   />}
                    />
                    <BullContainer title={t("views:planning")}
                                   img={etalonSVG}
                                   content={<ContentPlanning dataIntervention={plannedInterventions}
                                                             dataLoans={loans}/>}
                    />
                </div>

                <div id="caract">
                    <BullContainer title={t("default:characteristics")}
                                   img={caracSVG}
                                   content={<TableTest
                                       data={FieldManager.generateCharacteristicsField(characteristics)}
                                       col={FieldManager.generateColumns([[t("default:characteristics", {context: "format"}), 'format', "format"], [t("default:name"), 'name', "caract"], [t("default:value"), 'values', "caract"], [t("default:description"), 'description']])}
                                       clickableColumn={undefined}
                                       classtd={['', 'fw-bold']}
                                       ope={[]}
                                       cause={[]}
                                       path={["", ""]}
                                       isCaract={true}
                                   />}
                    />
                </div>
                <div id="tableau">
                    <SpreadsheetView name={"element"} id={id}/>
                </div>
                <div id="controlCard">
                    <BullContainer  title={t("default:controlcard")}
                                    img={controlcardSVG}
                                    content={ <ControleCard name={"element"} id={id}/>}
                    />

                </div>
                <div id="lifesheet">
                    <LifeSheet id={id}/>
                </div>
            </div>
        </>
    )
}

export default RenderElementView
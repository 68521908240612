import React, {useState} from "react";
import {generateChartQuery, loadChartData} from "../charts/CustomChartWindow/utilities";
import axios from "axios";
import {ChartBlock} from "../Dashboard/chartBlock";

function FullScreenChart(props) {
    const [chartValue, setChartValue] = useState(props.propsAdd.b)

    let closeModale = () => {
        props.setShowModal(false)
        if (props.propsAdd.inModal)
            return
        document.body.classList.remove("overflow-hidden")
    }

    let handleChangePeriod = async (e, chart) => {
        chart.dateValue = e.value
        let query = {
            query: generateChartQuery(chart)
        }
        await axios.put("/eData/dashboard/edit/chart/" + chart.id, query)
        let chartTemp = {...await loadChartData(chart)}
        let block = {...props.propsAdd.b}
        block.jsonChart = chartTemp
        setChartValue({...block})
    }

    return (
        <div className="modal-fullscreen">
            <ChartBlock deletePanel={props.propsAdd.deletePanel}
                        editablePanel={props.propsAdd.editablePanel}
                        editable={props.propsAdd.editable}
                        handlePeriod={handleChangePeriod}
                        block={chartValue}
                        cancelEdit={props.propsAdd.cancelEdit}
                        fullScreen={true}
                        chart={{value: props.propsAdd.chart, index: props.propsAdd.index}}
                        zoom={true}
                        randomId={true}
                        closeModale={closeModale}
                        category={props.propsAdd.category}
            />
        </div>
    )
}

export {FullScreenChart}
const SEARCH_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
            <g transform="translate(-40 -187)">
    <path d="m69.5 214-6.8-6.8c4.2-5.6 3-13.5-2.5-17.7-5.6-4.2-13.5-3-17.7 2.5s-3 13.5 2.5 17.7c4.5 3.3 10.6 3.3 15.1 0l6.8 6.8c0.7 0.7 1.9 0.7 2.5-0.1 0.8-0.7 0.8-1.7 0.1-2.4zm-25.9-14.4c0-5 4-9 9-9s9 4 9 9-4 9-9 9-9-4-9-9z"/>
</g>
</svg>
const DASHBOARD_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
<path d="m1.7 16.7h10c0.9 0 1.7-0.7 1.7-1.7v-13.3c0-0.9-0.7-1.7-1.7-1.7h-10c-1 0-1.7 0.7-1.7 1.7v13.3c0 0.9 0.7 1.7 1.7 1.7zm-1.7 11.6c0 1 0.7 1.7 1.7 1.7h10c0.9 0 1.7-0.7 1.7-1.7v-6.7c0-0.9-0.7-1.7-1.7-1.7h-10c-1 0.1-1.7 0.8-1.7 1.8v6.6zm16.7 0c0 0.9 0.7 1.7 1.7 1.7h10c0.9 0 1.7-0.7 1.7-1.7v-11.6c0-0.9-0.7-1.7-1.7-1.7h-10c-0.9 0-1.7 0.7-1.7 1.7v11.6zm1.6-16.6h10c0.9 0 1.7-0.7 1.7-1.7v-8.3c0-1-0.7-1.7-1.7-1.7h-10c-0.9 0-1.7 0.7-1.7 1.7v8.3c0.1 0.9 0.8 1.7 1.7 1.7z"/>
    </svg>
const GENERAL_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
<path d="M28.3,3.5H1.7C0.8,3.5,0,2.7,0,1.7v0C0,0.8,0.8,0,1.7,0h26.5c1,0,1.7,0.8,1.7,1.7v0C30,2.7,29.2,3.5,28.3,3.5z"/>
<path d="m28.3 12.3h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
<path d="m28.3 21.2h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
<path d="m28.3 30h-26.6c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h26.5c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.6 1.7z"/>
</svg>
const CHARACTERISTICS_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
<path d="m28.3 3.7h-21.3c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h21.3c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.7 1.7z"/>
<circle cx="1.8" cy="1.9" r="1.7"/>
<path d="m28.3 12.5h-21.3c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.8 1.7-1.8h21.3c1 0 1.7 0.8 1.7 1.7 0.1 1-0.7 1.8-1.7 1.8z"/>
<circle cx="1.8" cy="10.8" r="1.7"/>
<path d="m28.3 21.3h-21.3c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h21.3c1 0 1.7 0.8 1.7 1.7 0.1 1-0.7 1.7-1.7 1.7z"/>
<circle cx="1.8" cy="19.6" r="1.7"/>
<path d="m28.3 30.2h-21.3c-1 0-1.7-0.8-1.7-1.7 0-1 0.8-1.7 1.7-1.7h21.3c1 0 1.7 0.8 1.7 1.7 0.1 0.9-0.7 1.7-1.7 1.7z"/>
<circle cx="1.8" cy="28.4" r="1.7"/>
</svg>
const CONTROLCARD_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
<path d="m8.2 30h-7c-0.7 0-1.2-0.5-1.2-1.2v-23.3c0-0.7 0.5-1.2 1.2-1.2h7c0.7 0 1.2 0.5 1.2 1.2v23.3c0 0.7-0.6 1.2-1.2 1.2zm-4.9-2.5h2.8c0.4 0 0.8-0.4 0.8-0.8v-19.1c0-0.4-0.4-0.8-0.8-0.8h-2.8c-0.4 0-0.8 0.4-0.8 0.8v19.1c0 0.4 0.3 0.8 0.8 0.8z"/>
<path d="m28.8 30h-7c-0.7 0-1.2-0.5-1.2-1.2v-27.6c0-0.7 0.5-1.2 1.2-1.2h7c0.7 0 1.2 0.5 1.2 1.2v27.6c0 0.7-0.5 1.2-1.2 1.2zm-4.9-2.5h2.8c0.4 0 0.8-0.4 0.8-0.8v-23.4c0-0.4-0.4-0.8-0.8-0.8h-2.8c-0.4 0-0.8 0.4-0.8 0.8v23.4c0 0.4 0.4 0.8 0.8 0.8z"/>
<path d="m18.5 30h-7c-0.7 0-1.2-0.5-1.2-1.2v-15.4c0-0.7 0.5-1.2 1.2-1.2h7c0.7 0 1.2 0.5 1.2 1.2v15.4c0 0.7-0.6 1.2-1.2 1.2zm-4.9-2.5h2.7c0.5 0 0.8-0.4 0.8-0.8v-11.2c0-0.5-0.4-0.8-0.8-0.8h-2.7c-0.5 0-0.8 0.4-0.8 0.8v11.2c0 0.4 0.4 0.8 0.8 0.8z"/>
</svg>
const TABLE_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
<path d="m28 0h-26c-1.1 0-2 0.9-2 2v26c0 1.1 0.9 2 2 2h26c1.1 0 2-0.9 2-2v-26c0-1.1-0.9-2-2-2zm-23.5 12h7.7c0.6 0 1 0.5 1 1v3.1c0 0.6-0.5 1-1 1h-7.7c-0.6 0-1-0.5-1-1v-3.1c0-0.5 0.5-1 1-1zm-1 13.5v-3.9c0-0.6 0.5-1 1-1h7.7c0.6 0 1 0.5 1 1v3.9c0 0.6-0.5 1-1 1h-7.7c-0.5 0-1-0.5-1-1zm22 1h-7.7c-0.6 0-1-0.5-1-1v-3.9c0-0.6 0.5-1 1-1h7.7c0.6 0 1 0.5 1 1v3.9c0 0.5-0.5 1-1 1zm0-9.4h-7.7c-0.6 0-1-0.5-1-1v-3.1c0-0.6 0.5-1 1-1h7.7c0.6 0 1 0.5 1 1v3.1c0 0.6-0.5 1-1 1zm-8.7-8.6h-12.3c-0.6 0-1-0.5-1-1v-3c0-0.6 0.5-1 1-1h21c0.6 0 1 0.5 1 1v3c0 0.6-0.5 1-1 1h-8.7z"/>
</svg>
const LIFESHEET_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">	
<path d="m23.2 7.9h-16.4c-0.6 0-1-0.5-1-1.1s0.5-1 1-1h16.3c0.6 0 1 0.5 1 1 0.2 0.6-0.3 1.1-0.9 1.1z"/>
<path d="m23.2 13.3h-16.4c-0.6 0-1-0.5-1-1 0-0.6 0.5-1 1-1h16.3c0.6 0 1 0.5 1 1 0.2 0.5-0.3 1-0.9 1z"/>
<path d="m23.2 18.8h-16.4c-0.6 0-1-0.5-1-1 0-0.6 0.5-1 1-1h16.3c0.6 0 1 0.5 1 1 0.2 0.5-0.3 1-0.9 1z"/>
<path d="m23.2 24.2h-16.4c-0.6 0-1-0.5-1-1 0-0.6 0.5-1 1-1h16.3c0.6 0 1 0.5 1 1 0.2 0.6-0.3 1-0.9 1z"/>
<path d="M26.3,3C26.7,3,27,3.3,27,3.7v22.7c0,0.4-0.3,0.7-0.7,0.7H3.7C3.3,27,3,26.7,3,26.3V3.7C3,3.3,3.3,3,3.7,3H26.3 M26.3,0   H3.7C1.6,0,0,1.6,0,3.7v22.7c0,2,1.6,3.7,3.7,3.7h22.7c2,0,3.7-1.6,3.7-3.7V3.7C30,1.6,28.4,0,26.3,0L26.3,0z"/>
</svg>

const DECO_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
<g transform="translate(-3 -4.498)">
    <path d="m3.7 20.3 4.9 3.9c0.7 0.5 1.6 0.1 1.6-0.8v-2.4h12c0.8 0 1.5-0.7 1.5-1.5s-0.7-1.5-1.5-1.5h-12v-2.4c0-0.8-1-1.3-1.6-0.8l-4.9 3.9c-0.5 0.4-0.5 1.2 0 1.6z"/>
    <path d="m19.2 6c-3 0-6 1-8.3 2.9-0.7 0.5-0.7 1.6-0.1 2.2 0.5 0.5 1.4 0.6 2 0.1 4.2-3.3 10.3-2.9 14.1 1.1s3.8 10.4 0 14.4-9.9 4.3-14.1 1.1c-0.6-0.5-1.4-0.4-2 0.1-0.6 0.6-0.6 1.7 0.1 2.2 5.3 4.1 13 3.8 17.8-1.1 5.3-5.3 5.3-13.8 0-19.1-2.5-2.5-5.9-3.9-9.5-3.9z"/>
</g>
</svg>

const CONNEXION_LIST_SVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
    <path d="m20.8 16.5c0.6 1.3 1.8 2.2 3.3 2.2 2 0 3.7-1.6 3.7-3.7s-1.6-3.7-3.7-3.7c-1.5 0-2.8 0.9-3.3 2.2h-8c0-0.1 0-0.1-0.1-0.2l9.5-6.5c0.6 0.3 1.2 0.5 1.9 0.5 2 0 3.7-1.6 3.7-3.7s-1.6-3.7-3.7-3.7-3.7 1.6-3.7 3.7c0 0.2 0 0.4 0.1 0.6l-9.5 6.7c-0.9-0.8-2.1-1.3-3.4-1.3-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4c1.3 0 2.5-0.5 3.4-1.3l9.5 6.5c0 0.2-0.1 0.4-0.1 0.6 0 2 1.6 3.7 3.7 3.7s3.7-1.6 3.7-3.7-1.6-3.7-3.7-3.7c-0.7 0-1.3 0.2-1.9 0.5l-9.5-6.5c0-0.1 0-0.1 0.1-0.2h8zm3.3-3.2c0.9 0 1.7 0.7 1.7 1.7s-0.7 1.7-1.7 1.7-1.7-0.7-1.7-1.7 0.8-1.7 1.7-1.7zm0-11.2c0.9 0 1.7 0.7 1.7 1.7s-0.8 1.6-1.7 1.6-1.7-0.7-1.7-1.7 0.8-1.6 1.7-1.6zm-16.5 15.3c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4zm16.5 7.2c0.9 0 1.7 0.7 1.7 1.7s-0.7 1.7-1.7 1.7-1.7-0.7-1.7-1.7 0.8-1.7 1.7-1.7z"/>
</svg>




export {SEARCH_SVG,DASHBOARD_SVG,GENERAL_SVG,CHARACTERISTICS_SVG,CONTROLCARD_SVG,TABLE_SVG,LIFESHEET_SVG,DECO_SVG,CONNEXION_LIST_SVG}
import {
    HtmlEditor,
    Image,
    Inject,
    Link,
    QuickToolbar,
    RichTextEditorComponent,
    Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";

function Note(props) {
    const {t, i18n} = useTranslation()
    const richText = useRef()
    const [value, setValue] = useState()
    const [valueTitle, setValueTitle] = useState("")

    let handleChange = (e) => {
        props.chartValue.current.label = e.value
    }

    let handleChangeTitle = (e) => {
        if (e.target.value.length > 50)
            return
        setValueTitle(e.target.value)
    }

    useEffect(() => {
        setValue(props.label)
    }, [props.label])

    useEffect(() => {
        setValueTitle(props.title)
    }, [props.title])

    return (
        <>
            {!props.readOnly ? <div className="title-note">
                <div>
                    <p className="mt-0">{t("chart:title")}</p>
                </div>
                <input onBlur={props.handleInputTitle} onChange={handleChangeTitle} value={valueTitle}
                       type="text"
                       name="inputTitle"
                       className="input-text"/>
            </div> : <h2 id={"charts" + props.id + "_" + "ChartTitle"}>{props.title}</h2>}
            <div className="note-container">
                <RichTextEditorComponent
                    toolbarSettings={{
                        enable: props.showToolBar, items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                            'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
                            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                            'Outdent', 'Indent', '|',
                            'CreateLink', '|', 'ClearFormat', 'Print',
                            'FullScreen', '|', 'Undo', 'Redo']
                    }}
                    readonly={props.readOnly}
                    value={value}
                    ref={richText}
                    change={handleChange}
                >
                    <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}/>
                </RichTextEditorComponent>
            </div>
        </>
    )
}

export {Note}
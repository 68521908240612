import '../../style/Render404View.css'
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

function Render404View(props) {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    let handleButton = () => {
        navigate(props.path)
    }
    return (
        <div className="container-404">
            <div className="titre-404">
                <p>{props.number}</p>
            </div>
            <div className="texte-404">
                <div>
                    <h1>{t("default:error", { context: "404" })}</h1>
                    <p>{props.text}</p>
                    <button onClick={handleButton}>{t("default:back_home")}</button>
                </div>
            </div>
        </div>
    )
}

export {Render404View}
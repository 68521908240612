import {PreviewChartRender} from "../charts/CustomChartWindow/previewChartRender";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {convertJsonChart} from "../charts/CustomChartWindow/utilities";
import {useTranslation} from "react-i18next";

function ControleCard(props){
    const [cardChart , setCardChart] = useState(null)
    const [disabledButton , setDisabledButton] = useState({next:true, prev:true})
    const cardsChart = useRef([])
    const indexControlCard = useRef(1)
    const [loadingSheet, setLoadingSheet] = useState(false)
    const [loadedSheet, setLoadedSheet] = useState(false)
    const {t, i18n} = useTranslation()

    let loadEvent = (e) => {

        getControleCard()
    }


    let getControleCard = async () =>{
        setLoadingSheet(true)
       await  axios.get(`/eData/controlCard/${props.name}/${props.id}`, {doNotCatch:true}).then(res => {
           if(res.data.length == 0){
               return alert(t("views:controlcard", {context:"none"}))
           }

            for (let card of res.data){
                cardsChart.current.push(convertJsonChart(card.chart,card.options))
            }


        }).catch(err => {
            if(err.response.status == 400)
                return alert(t("views:controlcard", {context:"invalid"}))
       })
        if(cardsChart.current.length == 0)
            return setLoadingSheet(false)
        initialiseButton()
        setCardControl()
        setLoadingSheet(false)
        setLoadedSheet(true)
    }

    let initialiseButton = () => {
        if(cardsChart.current.length == 1){
            disabledButton.next = false
            disabledButton.prev = false
        }
        else{
            disabledButton.prev = false
            disabledButton.next = true
        }

        setDisabledButton({...disabledButton})
    }

    let nextControlCard = () => {
        indexControlCard.current++
        if(indexControlCard.current >= cardsChart.current.length){
            indexControlCard.current = cardsChart.current.length
            disabledButton.next = false
            disabledButton.prev = true
        }
        else{
            disabledButton.prev = true
        }
        setDisabledButton({...disabledButton})
        setCardControl()
    }

    let previousControlCard = () => {
        indexControlCard.current--
        if(indexControlCard.current == 1){
            disabledButton.prev = false
        }
        disabledButton.next = true
        setDisabledButton({...disabledButton})
        setCardControl()
    }

    let setCardControl = () => {
        setCardChart({...cardsChart.current[indexControlCard.current - 1]})
    }


    return(
        <>
        {loadedSheet ? null : <div className="tableau-chargement">
                <div className="tableau-chargement-message text-center">
                    <p>{ t("views:load", {context: "controlcard"})}</p>
                    <button onClick={loadEvent}
                            className={loadingSheet ? "bouton mt-3 pl-1 pt-1 pr-1 pb-1 loading" : "bouton mt-3 pl-1 pt-1 pr-1 pb-1"}>{loadingSheet ?
                        <span
                            className="spinner"></span> : t("views:load", {context: "controlcard_button"})}</button>
                </div>
            </div>}
            {cardChart == null ? null : <PreviewChartRender zoom={props.zoom} inDashboard={false} setRefreshForced={() => {
            }} refreshForced={false} refreshAuto={true}
                                 chart={cardChart} eventChange={() => {
            }} snapshot={true}
                                 randomId={false} editNote={false}/>}
            {cardChart == null ? null : <div className="div-snapshot">
                <button id="prev-button" className={disabledButton.prev ? "" : "disabled"}
                        onClick={previousControlCard}>
                    <svg enableBackground="new 0 0 4.9 7.7" version="1.1" viewBox="0 0 4.9 7.7">
                        <path
                            d="m0 3.9c0-0.3 0.1-0.5 0.3-0.7l2.9-2.9c0.4-0.4 1-0.4 1.4 0s0.4 1 0 1.4l-2.2 2.2 2.2 2.1c0.4 0.4 0.4 1 0 1.4s-1 0.4-1.4 0l-2.9-2.8c-0.2-0.2-0.3-0.5-0.3-0.7z"/>
                    </svg>
                </button>
                <p className={"center-txt"}>{cardChart.label}</p>
                <button className={disabledButton.next ? "" : "disabled"} onClick={nextControlCard}>
                    <svg enableBackground="new 0 0 4.9 7.7" version="1.1" viewBox="0 0 4.9 7.7">
                        <path
                            d="m4.9 3.8c0 0.3-0.1 0.5-0.3 0.7l-2.9 2.9c-0.4 0.4-1 0.4-1.4 0s-0.4-1 0-1.4l2.2-2.2-2.2-2.1c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l2.9 2.8c0.2 0.2 0.3 0.5 0.3 0.7z"/>
                    </svg>
                </button>
            </div>}
        </>
    )
}

export {ControleCard}
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {convertJsonChart} from "../charts/CustomChartWindow/utilities";
import {PreviewChartRender} from "../charts/CustomChartWindow/previewChartRender";
import ModalPopup from "./modal";
import {CsvExportGridViewModal} from "./csvExportGridViewModal";

function GridViewModal(props) {
    const [isLoading, setLoading] = useState(false)
    const [dataGrids, setDataGrids] = useState([])
    const [gridCharts, setGridCharts] = useState([])
    const [prevDataGrid, setPrevDataGrid] = useState([])
    const [modalExportGridView, setModalExportGridView] = useState(false)
    const names = useRef([])
    useEffect(async () => {
        setLoading(true)
        await axios.get(`/eData/dashboard/process/grid/${props.propsAdd.id}`).then(res => setDataGrids(res.data))
        setLoading(false)
    }, [props.propsAdd])

    useEffect(() => {
        if (dataGrids.length == 0)
            return
        let grids = []
        dataGrids.forEach(dataGrid => {
            grids.push(convertJsonChart(dataGrid))
        })
        setGridCharts(grids)
    }, [dataGrids])

    useEffect(() => {
        if (gridCharts.length == 0)
            return
        let prevgrids = []
        names.current = []
        gridCharts.forEach(gridChart => {
            names.current.push({id: gridChart.id, text: gridChart.name})
            prevgrids.push(<PreviewChartRender zoom={false} inDashboard={false} setRefreshForced={() => {
            }} refreshForced={false} refreshAuto={true}
                                               chart={gridChart} eventChange={undefined} snapshot={undefined}
                                               randomId={false} editNote={false}/>)
        })
        setPrevDataGrid(prevgrids)
    }, [gridCharts])

    let closeModale = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    return (
        <>
            <div className="modal-fullscreen gridview">
                <div className="button-panel">
                    <button className="view-fullscreen" onClick={() => setModalExportGridView(true)}
                            title="Exporter la grille de données au format CSV">
                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path
                                d="m26.8 10.4h-23.6c-1.3 0-2.3 1.1-2.3 2.3v9.9c0 1.3 1.1 2.3 2.3 2.3h23.6c1.3 0 2.3-1.1 2.3-2.3v-9.9c0.1-1.2-1-2.3-2.3-2.3zm-18.7 10.6c0.6 0 1.1-0.1 1.6-0.4 0.4-0.2 0.9-0.1 1.1 0.3s0.1 0.9-0.3 1.1c-0.7 0.4-1.5 0.6-2.4 0.6-2.7 0-5-2.2-5-4.9s2.2-5 5-5c0.8 0 1.6 0.2 2.4 0.6 0.4 0.2 0.6 0.7 0.3 1.1-0.2 0.4-0.7 0.6-1.1 0.3-0.5-0.3-1-0.4-1.6-0.4-1.8 0-3.3 1.5-3.3 3.3 0 1.9 1.5 3.4 3.3 3.4zm10.2-1.5c0 0.8-0.3 1.6-0.9 2.2s-1.5 1-2.4 1h-0.1c-1.2 0-2.2-0.7-2.7-1.8-0.1-0.1-0.1-0.2-0.1-0.4 0-0.3 0.2-0.6 0.5-0.7 0.1-0.1 0.2-0.1 0.3-0.1 0.3 0 0.6 0.2 0.8 0.5 0.2 0.5 0.7 0.8 1.3 0.8 0.5 0 0.9-0.2 1.2-0.5s0.4-0.6 0.4-1v-0.1c0-0.7-1.3-1.2-1.8-1.4h-0.1c-1.5-0.5-2.2-1.4-2.2-2.7v-0.1c0-0.7 0.4-1.4 1-1.8 0.5-0.4 1.2-0.6 1.9-0.6h0.1c0.6 0 1.2 0.3 1.7 0.6 0.2 0.2 0.3 0.4 0.3 0.7 0 0.2-0.1 0.4-0.2 0.5-0.2 0.2-0.4 0.3-0.7 0.3-0.2 0-0.3 0-0.5-0.2s-0.5-0.3-0.7-0.3h-0.1c-0.7 0-1.2 0.4-1.2 0.9v0.1c0 0.7 0.5 0.9 1.1 1.2 0.4 0.1 2.9 0.9 3 2.9zm8.5-5.6-3.3 8.2c-0.1 0.3-0.4 0.5-0.8 0.5-0.3 0-0.7-0.2-0.8-0.5l-3.3-8.2c0-0.1-0.1-0.2-0.1-0.3 0-0.4 0.3-0.8 0.8-0.8 0.3 0 0.6 0.2 0.8 0.5l2.5 6.3 2.5-6.3c0.1-0.3 0.4-0.5 0.8-0.5 0.5 0 0.8 0.4 0.8 0.8 0.1 0.1 0.1 0.2 0.1 0.3z"/>
                            <path
                                d="m6.1 3.5c0-0.7 0.6-1.3 1.3-1.3h10.5v-1c0-0.7 0.6-1.2 1.2-1.1h-11.7c-1.8 0-3.3 1.5-3.3 3.3v7h2v-6.9z"/>
                            <path d="m24.7 8h-0.8v2.4h1.9v-3.4c-0.1 0.6-0.4 1-1.1 1z"/>
                            <path
                                d="M23.9,26.6c0,0.7-0.6,1.3-1.3,1.3H7.4c-0.7,0-1.3-0.6-1.3-1.3V25h-2v1.6c0,1.8,1.5,3.3,3.3,3.3h15.1 c1.8,0,3.3-1.5,3.3-3.3V25h-1.9V26.6z"/>
                            <path
                                d="m25.5 6.2-5.7-5.7c-0.2-0.2-0.5-0.3-0.7-0.4-0.6-0.1-1.2 0.4-1.2 1.1v5.3c0 0.8 0.7 1.5 1.5 1.5h5.3c0.6 0 1-0.5 1.1-1 0-0.2-0.1-0.5-0.3-0.8z"/>
                        </svg>
                    </button>
                    <button onClick={closeModale} className="close" title="Fermer la fenêtre">
                        <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                            <path className="st0"
                                  d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                        </svg>
                    </button>
                </div>
                {isLoading ?
                    <span className={"treeview-spinner-chart"}></span> : <div className="container-gridview">
                        {prevDataGrid}
                    </div>}
            </div>
            {modalExportGridView ?
                <ModalPopup
                    propsAdd={{grids: names.current, chartId: props.propsAdd.id}}
                    closeCLicked={false}
                    content={CsvExportGridViewModal}
                    setShowModal={setModalExportGridView}/> : null}
        </>
    )
}

export {GridViewModal}
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ListViewComponent} from "@syncfusion/ej2-react-lists";
import {useTranslation} from "react-i18next";

function PropagateDashboardView(props) {
    const listView = useRef()
    const [data, setData] = useState(null)
    const [treeViewSource, setTreeViewSource] = useState([])
    const [selectedTemp, setSelectedTemp] = useState([])
    const {t, i18n} = useTranslation()
    let closeButton = () => {
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    useEffect(async () => {
        let data = null
        await axios.get(`/edata/dashboard/groups/${props.propsAdd.idDashboard}`).then(res => data = res.data)

        data.forEach(d => {
            let json = {
                id: d.id,
                text: d.name,
                isChecked: d.propagated
            }
            if (json.isChecked) {
                selectedTemp.push(d.id)
            }
            treeViewSource.push(json)
        })
        setTreeViewSource([...treeViewSource])
        setSelectedTemp([...selectedTemp])
    }, [])

    let handleClickValidate = async () => {
        let selectedItems = []
        let deletedItems = []
        listView.current.getSelectedItems().data.forEach(i => {
            let findIndex = selectedTemp.findIndex(item => item == i.id)
            if (findIndex == -1)
                selectedItems.push(i.id)
        })
        selectedTemp.forEach(select => {
            let findIndex = listView.current.getSelectedItems().data.findIndex(item => item.id == select)
            if (findIndex == -1) {
                deletedItems.push(select)
            }
        })
        await Promise.all([
            axios.post(`/edata/dashboard/propagate/${props.propsAdd.idDashboard}`, selectedItems),
            axios.post(`/edata/dashboard/unpropagate/${props.propsAdd.idDashboard}`, deletedItems)
        ])
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    return (
        <div className="modal-box-confirm">
            <div className="box-title d-flex align-items-center justify-content-between">
                <p className="m-0 fw-bold">{t("dashboard:propagation")}</p>
                <button onClick={closeButton}>
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path
                            d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"></path>
                    </svg>
                </button>
            </div>
            <div className="wide-container">
                <div className="confirm-container">
                    <ListViewComponent ref={listView} key={treeViewSource} showCheckBox={true}
                                       dataSource={treeViewSource} id={"list"}/>
                </div>
                <div>
                    <button onClick={handleClickValidate} className="bouton">{t("default:proceed")}</button>
                </div>
            </div>
        </div>

    )
}

export {PropagateDashboardView}
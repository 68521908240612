import React from "react";

function Operator(props) {
    let mailImg = props.mail == "" || props.mail == "-" ? null : <img src={require('../../../img/message.png')}/>
    let principalName = props.isPrincipal == true ? <span className="fw-bold"> {props.name} </span> :
        <span>{props.name}</span>
    let principalImg = props.isPrincipal == true ?
        <img className="ope-principal" src={require('../../../img/principal.png')}/> : ""
    return (
        <span className="ope-color d-flex align-items-center">
            {principalImg}
            {principalName}
            <a href={'mailto:' + props.mail} className="pl-2 d-flex">{mailImg}</a>
        </span>)
}

export {Operator}
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {AutoCompleteComponent, DropDownListComponent} from '@syncfusion/ej2-react-dropdowns'
import {NumericTextBoxComponent} from '@syncfusion/ej2-react-inputs'
import {getPath, getType, TreeViewProperty} from '../Utilities/treeviewProperty'
import {generateKey} from "../Common/utilities";
import {useTranslation} from "react-i18next";
import ModalPopup from "./modal";
import {ConfirmModalView} from "./confirmModal";
import {CalendarComponent} from "@syncfusion/ej2-react-calendars";
import ReactDOM from "react-dom";

function DropdownCustom(props) {
    const [value, setValue] = useState("")
    useEffect(() => {
        setValue(props.valueReceived)
    }, [props.valueReceived])
    return (
        <DropDownListComponent value={value}
                               id={props.idDp}
                               change={(e) => props.handle(e)}
                               dataSource={props.data}
                               fields={props.fields}
                               popupHeight="220px"/>
    )
}

function CalandarCustom(props) {
    const [showCalandar, setShowCalandar] = useState(false)
    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(props.value)
    }, [props.valueInput])

    useEffect(() => {
        createDiv(props.id)
    }, [])

    let handleCalandar = (e) => {
        setShowCalandar(showCalandar ? false : true)
    }

    let onChangeCalandar = (e) => {
        setValue(reformatDate(e.value))
        setShowCalandar(false)
    }

    function createDiv(id, idModal) {
        let div = document.createElement('div');
        div.id = 'calendar' + id
        div.classList.add("query-builder-calendar")
        if (idModal != undefined) {
            document.getElementById(idModal).appendChild(div)
        } else {
            document.getElementById('container-modal').appendChild(div)
        }
    }

    window.addEventListener('click', function (e) {
        if (e.target.className == "e-day")
            return
        if (e.target.className == "e-day e-title")
            return
        if (e.target.className == "")
            return
        if (e.target.className == "e-date-icon-prev e-icons")
            return
        if (e.target.className == "e-date-icon-next  e-icons")
            return
        if (e.target.className == "calendar-button")
            return
        setShowCalandar(false)
    }, false)

    useEffect(() => {
        if (showCalandar) {
            ReactDOM.render(<CalendarComponent created={handleCreated} onChange={onChangeCalandar}/>
                ,
                document.getElementById('calendar' + props.id)
            )
        } else {
            ReactDOM.render(
                null,
                document.getElementById('calendar' + props.id)
            )
        }
    }, [showCalandar])

    let handleCreated = () => {
        let elem = document.getElementById('calendar' + props.id)
        if (elem == null)
            return
        let tree = elem.firstElementChild
        if (tree == null)
            return
        let selectTree = document.getElementById('autoCompleteField' + props.id)
        tree.style.top = selectTree.getBoundingClientRect().top + selectTree.getBoundingClientRect().height + 3 + 'px'
        tree.style.left = selectTree.getBoundingClientRect().left + 'px'
        tree.style.width = selectTree.getBoundingClientRect().width < 275 ? 275 + 'px' : selectTree.getBoundingClientRect().width + 'px'
        tree.style.display = "block"
    }


    return (
        <div id={"autoCompleteField" + props.id}>
            <button onClick={handleCalandar} class="calendar-button"></button>
            <AutoCompleteCustom id={props.id} handle={props.handle} value={value} dataSource={[
                "NOW",
                "TODAY",
                "TODAY-1Y",
                "TODAY-1M",
                "TODAY+1M",
                "START_OF_YEAR",
                "START_OF_MONTH",
                "START_OF_WEEK"]} fields={{value: "name"}}/>
        </div>

    )
}

function AutoCompleteCustom(props) {
    const [value, setValue] = useState("")
    useEffect(() => {
        setValue(props.value)
    }, [props.value])
    return (
        <AutoCompleteComponent value={value} change={(e) => props.handle(e)} showPopupButton={true}
                               dataSource={props.dataSource} suggestionCount={500}/>
    )
}

function GridBuilder(props) {
    const [groups, setGroups] = useState([])
    const [dataBuilder, setDataBuilder] = useState([dataQueryBuilder(0, false, "", "", "", "")])
    const [groupsTh, setgroupsTh] = useState(0)
    const [enabledGroup, setEnableGroup] = useState(false)
    const [eventTriggerSelected, setEventTriggerSelected] = useState(null)
    const [dataTreeView, setDataTreeView] = useState([])
    const [commonVariable, setCommonVariable] = useState({entities: null, validateEvent: null, backEvent: null})
    const {t, i18n} = useTranslation()
    const [valueDp, setValueDp] = useState("")

    useEffect(() => {
        if (props.propsAdd) {
            let variables = {
                entities: props.propsAdd.entities,
                validateEvent: props.propsAdd.validateEvent,
                backEvent: props.propsAdd.backEvent
            }
            setCommonVariable({...variables})
            if (props.propsAdd.option)
                setValueDp(props.propsAdd.option)
        } else {
            let variables = {
                entities: props.entities,
                validateEvent: props.validateEvent,
                backEvent: props.backEvent
            }
            setCommonVariable({...variables})
        }
    }, [])

    let handleCheckBoxe = (e) => {
        let data = dataBuilder.find(d => d.id == parseInt(e.target.id))
        data.checked = e.target.checked
        let dataGrouped = dataBuilder.filter(d => d.checked)
        if (dataGrouped.length > 1) {
            for (let i = 0; i < dataGrouped.length - 1; i++) {
                if (dataGrouped[i].id + 1 == dataGrouped[i + 1].id)
                    setEnableGroup(true)
                else
                    setEnableGroup(false)
            }
        }
        if (dataGrouped.length <= 1)
            setEnableGroup(false)

        setDataBuilder([...dataBuilder])
    }
    let handleProperty = (type, e, values, autocompletion) => {
        let data = dataBuilder.find(d => d.id == parseInt(e.node.offsetParent.id))
        data.propertyShow = getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, dataTreeView, true).replaceAll('.', " ➧ ")
        data.property = getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, dataTreeView, false)
        data.type = type
        data.valuesEnum = values
        data.autocompletionValues = autocompletion
        if (data.type == "nothing") {
            data.condition = ""
            data.value = ""
            data.operator = ""
        }
        setDataBuilder([...dataBuilder])
    }
    let handleDropdownOperator = (e) => {
        let data = dataBuilder.find(d => d.id == parseInt(e.element.id))
        data.operator = e.value
        setDataBuilder([...dataBuilder])
    }
    let handleDropdownCondition = (e) => {
        let data = dataBuilder.find(d => d.id == parseInt(e.element.id))
        data.condition = e.value
        setDataBuilder([...dataBuilder])
    }
    let handleInputValue = (id, value) => {
        let data = dataBuilder.find(d => d.id == parseInt(id))
        data.value = value
        setDataBuilder([...dataBuilder])
    }

    let handleAddButton = (e) => {
        let newData = dataQueryBuilder(0, false, "", "", "", "")
        //ajoute la nouvelle ligne dans les groupes correspondant
        groups.filter(gr =>{
            if(gr.rows.find(row => row.id == e.target.id) != undefined)
                return true
            else
                return false
        }).slice().reverse().forEach(gr => {
            if(gr.rows.slice(-1)[0].id == e.target.id)
                return
            newData.group.push(gr)
                gr.rows.splice(gr.rows.findIndex(row=>row.id == e.target.id) + 1,0,newData)
        })
        //ajoute la nouvelle ligne dans la liste à la bonne position
        dataBuilder.splice(parseInt(e.target.id) + 1, 0, newData)
        for (let i = 0; i < dataBuilder.length; i++)
            dataBuilder[i].id = i
        setDataBuilder([...dataBuilder])
    }
    let handleDeleteButton = (e) => {
        // supprime la ligne des différents groupes liés à celle ci
        groups.filter(gr =>{
            if(gr.rows.find(row => row.id == e.target.id) != undefined)
                return true
            else
                return false
        }).forEach(gr => {
            gr.rows.splice(gr.rows.findIndex(row => row.id == e.target.id),1)
            if(gr.rows.length <= 1){
            let e = {
                target:{
                    id: gr.id
                }
            }
                handleDeleteGroup(e)
            }
            if(groupExist(gr.rows,groups))
                handleDeleteGroup(e)
        })
        //supprime la ligne de la liste
        dataBuilder.splice(e.target.id, 1)
        //supprime la div liée à la ligne
        document.getElementById('container-modal').removeChild(document.getElementById('treeview' + e.target.id))
        for (let i = 0; i < dataBuilder.length; i++) {
            document.getElementById('treeview' + dataBuilder[i].id).setAttribute('id', 'treeview' + i)
            dataBuilder[i].id = i
        }
        setDataBuilder([...dataBuilder])
    }
    let handleGroupButton = () => {
        if (!enabledGroup)
            return
        //récupère les lignes cochées
        let dataGrouped = dataBuilder.filter(d => d.checked)
        let group = {
            id: 0,
            rows: dataGrouped,
            parents: [],
            childs: [],
            pos: 0,
    }
        
        let inGroup = false
        //Vérifie que le groupe créé n'éxiste pas déjà
        if(groupExist(dataGrouped,groups))
            return alert("Error")
        //récupère la ligne avec le plus de groupe
        let groupMaxLength = dataGrouped[0]
        dataGrouped.forEach(datagr => {
            if (datagr.group.length > groupMaxLength.group.length)
                groupMaxLength = datagr
        })
        // insère dans la liste groupLength les groupes à la même position que le premier groupe de groupMaxLength  
        let groupLength = []
        dataGrouped.forEach(datagr => {
            datagr.group.forEach(gr =>{
                if(gr.pos == groupMaxLength.group[0].pos)
                    groupLength.push(datagr)
            })
        })
        let intern = false
        // Vérifie que le groupe créé est conforme
        for (let i = 0; i < dataGrouped.length; i++) {
            if (dataGrouped[i].group.length > 0) {
                inGroup = true
                for (let j = 0; j < groupLength.length; j++) {
                    if (groupLength[j].group[0].rows[0].id >= group.rows[0].id && groupLength[j].group[0].rows.slice(-1)[0].id <= group.rows.slice(-1)[0].id)
                    intern = false
                else if (groupLength[j].group.slice(-1)[0].rows[0].id <= group.rows[0].id && groupLength[j].group.slice(-1)[0].rows.slice(-1)[0].id >= group.rows.slice(-1)[0].id)
                    intern = true
                else
                    return alert("Error")
                }
                
            }
        }
        groups.push(group)
        let ids = []
        groups.forEach(gr => ids.push(gr.id))
        group.id = Math.max(...ids) + 1
        if (inGroup) {
            if (intern) {
                //insère les parents du groupe
                group.parents = [...dataGrouped[0].group.slice(-1)[0].parents]
                group.parents.push(dataGrouped[0].group.slice(-1)[0])
                // Trie les parents en fonction de leur position
                group.parents.sort((a, b) => {
                    if (a.pos > b.pos)
                        return -1
                    else if (a.pos < b.pos)
                        return 1
            
                    return 0
                })
                //mets à jour la position des autres groupes ainsi que leurs enfants
                group.parents.slice().reverse().forEach(parent => {
                    parent.childs.push(group)
                    parent.pos = deleteDuplicatesPos(parent.childs).length
                })
            }
            else {
                // récupère les groupes à la même position
                let posNavigation = deleteDuplicatesPos(groups)
                for (let i = 0; i < posNavigation.length; i++) {
                    let groupsSamePosition =  groups.filter(gr =>{
                        let find = false
                        if(gr.pos == posNavigation[i] && gr.id != group.id){
                            group.rows.forEach(row =>{
                                if(gr.rows.find(r => r.id == row.id) != undefined)
                                    find = true
                            })
                        }
                        return find
                    })
                    groupsSamePosition.forEach(grPos => group.childs.push(grPos)) 
                }
                //mets à jour les parents des enfants du groupe
                group.childs.forEach(child=>{
                    child.parents.push(group)
                })
                //mets à jour la position du groupe
                group.pos = deleteDuplicatesPos(group.childs).length
            }
        }
        // ajoute le nouveau groupe dans les lignes
        dataGrouped.forEach(data => {
            if(intern)
                data.group.push(group)
            else
                data.group.splice(0,0,group)
        })
        dataBuilder.forEach(data => data.group = [...data.group])
        let posGroupList = []
        groups.forEach(gr => posGroupList.push(gr.pos))
        let groupFilterPos = posGroupList.filter((g, index) => {
            return posGroupList.indexOf(g) === index
        })
        dataGrouped.forEach(data => data.checked = false)
        setEnableGroup(false)
        setgroupsTh(groupFilterPos.length)
        setGroups([...groups])
        setDataBuilder([...dataBuilder])
    }
    let handleDeleteGroup = (e) => {
        // récupère le groupe supprimé
        let groupDeleted = groups.find(gr => gr.id == e.target.id)
        // on le supprime de la liste des groups
        groups.splice(groups.findIndex(gr => gr.id == e.target.id), 1)
        // on supprime le groupe des parents et des enfants sur les autres groupes
        for (let i = 0; i < groups.length; i++) {
            let parentIndex = groups[i].parents.findIndex(gr => gr.id == groupDeleted.id)
            let childIndex = groups[i].childs.findIndex(gr => gr.id == groupDeleted.id)
            if (parentIndex != -1)
                groups[i].parents.splice(parentIndex, 1)
            if (childIndex != -1)
                groups[i].childs.splice(childIndex, 1)
            groups[i].pos = deleteDuplicatesPos(groups[i].childs).length
        }
        //supprime le groupe dans la liste des lignes
        for (let i = 0; i < dataBuilder.length; i++) {
            let groupIndex = dataBuilder[i].group.findIndex(gr => gr.id == groupDeleted.id)
            if (groupIndex != -1)
                dataBuilder[i].group.splice(groupIndex, 1)
            dataBuilder[i].group = [...dataBuilder[i].group]
        }
        setgroupsTh(deleteDuplicatesPos(groups).length)
        setGroups([...groups])
        setDataBuilder([...dataBuilder])
    }
    let handleClickGroup = (e) =>{
        // récupère le group en fonction de l'id
        let group = groups.find(gr => gr.id == e.target.id)
        if(group == undefined)
            return
        //coche les checkbox lées au groupe
        let ids = []
        dataBuilder.filter(data => data.checked).forEach(data => ids.push(data.id))
        let idsGroup = []
        group.rows.forEach(row => idsGroup.push(row.id))
        if(ids.toString() == idsGroup.toString()){
            group.rows.forEach(row =>  row.checked = false)
            setEnableGroup(false)
        }
        else{
            group.rows.forEach(row =>  row.checked = true)
            setEnableGroup(true)
        }
        //décoche les autres checkbox
        let rowNotInGroup = dataBuilder.filter(data => {
            if(data.group.find(gr => gr.id == group.id) == undefined)
                return true
            else
                return false
        })
        rowNotInGroup.forEach(data => data.checked = false)
        setDataBuilder([...dataBuilder])
    }
    let hooverGroup = (e) => {
        let groupsElements = document.getElementsByClassName("group")
        for (let i = 0; i < groupsElements.length; i++) {
            if(groupsElements[i].id == e.target.id && !groupsElements[i].className.split(" ").includes("group-hover"))
                groupsElements[i].className += " " + "group-hover"
        }
    }
    let hooverOutGroup = (e) => {
        let groupsElements = document.getElementsByClassName("group")
        for (let i = 0; i < groupsElements.length; i++) {
            if (groupsElements[i].id == e.target.id && groupsElements[i].className.split(" ").includes("group-hover")) {
                let newClassName = ""
                for (let j = 0; j < groupsElements[i].className.split(" ").length - 1; j++) {
                    newClassName += groupsElements[i].className.split(" ")[j] + " "
                }
                groupsElements[i].className = newClassName
            }
        }
    }

    let handleDropdownType = async (e) => {
        let data = null
        await axios.get('/helpers/query/columnData/' + e.itemData.id).then(res => data = res.data)

        setDataTreeView([{
            id: "999999",
            field: getType(e.itemData.id, t).field,
            label: getType(e.itemData.id, t).text,
            type: "nothing",
            columns: data.all
        }])
        setDataBuilder([dataQueryBuilder(0, false, "", getType(e.itemData.id, t).field, getType(e.itemData.id, t).text, "", "")])

    }


    let handleClose = () => {
        let modalElem = document.getElementById('temp')
        if (modalElem != null) {
            modalElem.id = 'container-modal'
        }
        props.setShowModal(false)
        document.body.classList.remove("overflow-hidden")
    }

    return (
        <>
            <div id="treeviewpopup"></div>
            <div className="modal-box-query position-relative">
                <div className="d-flex align-items-center">
                    {props.propsAdd.showButtonDelete ?
                        <button onClick={commonVariable.backEvent} className="previous-button">
                            <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <path
                                    d="m27.4 14.9v11.7c0 1.8-1.9 2.9-3.5 2l-10.1-5.8-10.1-5.9c-1.5-0.9-1.5-3.1 0-4l20.2-11.6c1.5-0.9 3.5 0.2 3.5 2v11.6z"/>
                            </svg>
                        </button> : null}
                    <div className="">
                        <h1>{t("query:header")}</h1>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <p className="mr-1">{t("views:on")}</p><DropDownListComponent dataSource={commonVariable.entities}
                                                                                  fields={{text: "text", value: "id"}}
                                                                                  change={handleDropdownType}
                                                                                  value={valueDp}
                                                                                  idDp={"props.id"}/></div>
                <div className="modal-box-button">{props.propsAdd.showButtonDelete ? null
                    : <button onClick={handleClose} className="close">
                        <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                            <path className="st0"
                                  d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                        </svg>
                    </button>}
                </div>
                <div id="query-overflow-vertical" className="overflow-auto modal-box-container">
                    <table id="query-table-id" className="query-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>
                                <button onClick={handleGroupButton} className={enabledGroup ? "" : "disabled"}
                                        title={t("query:group", {context: "selected"})}>
                                    <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                        <path
                                            d="m27.2 24.6h-17.7c-0.8 0-1.5-0.7-1.5-1.6v-4.4c0-0.8 0.7-1.5 1.5-1.5h17.6c0.8 0 1.5 0.7 1.5 1.5v4.4c0.1 0.9-0.6 1.6-1.4 1.6z"/>
                                        <rect x="2.2" y="8.2" width="3.7" height="1.8"/>
                                        <rect x="2.2" y="19.9" width="3.7" height="1.8"/>
                                        <rect transform="matrix(6.1232e-17 -1 1 6.1232e-17 -12.779 17.239)" x="-4.5"
                                              y="14.1" width="13.5" height="1.8"/>
                                        <path
                                            d="m27.2 12.9h-17.7c-0.8 0-1.5-0.7-1.5-1.5v-4.4c0-0.8 0.7-1.5 1.5-1.5h17.6c0.8 0 1.5 0.7 1.5 1.5v4.4c0.1 0.8-0.6 1.5-1.4 1.5z"/>
                                    </svg>
                                </button>
                            </th>
                            {groupsTh > 0 ? <th colSpan={groupsTh}></th> : null}
                            <th className="query-table-and-or-th">{t("query:and_or")}</th>
                            <th>{t("query:criteria")}</th>
                            <th className="query-table-operator-th">{t("query:condition")}</th>
                            <th className="query-table-value-th">{t("query:value")}</th>
                        </tr>
                        </thead>
                        <tbody>
                            {dataBuilder.map((item, idx) => {
                                return (
                                    <RowQueryBuilder key={idx} id={idx}
                                                     checked={item.checked}
                                                     valueProperty={item.property}
                                                     valuePropertyShow={item.propertyShow}
                                                     valueInput={item.value}
                                                     valueOperator={item.operator}
                                                     valueCondition={item.condition}
                                                     group={item.group}
                                                     maxGroup={groupsTh}
                                                     type={item.type}
                                                     valuesEnum={item.valuesEnum}
                                                     autocompletionValues={item.autocompletionValues}
                                                     dataTreeView={dataTreeView}
                                                     setDataBuilder={setDataBuilder}
                                                     dataBuilder={dataBuilder}
                                                     handleCheckBoxe={handleCheckBoxe}
                                                     handleProperty={handleProperty}
                                                     handleDropdownOperator={handleDropdownOperator}
                                                     handleDropdownCondition={handleDropdownCondition}
                                                     handleInputValue={handleInputValue}
                                                     handleAddButton={handleAddButton}
                                                     handleDeleteButton={handleDeleteButton}
                                                     eventTriggerSelected={eventTriggerSelected}
                                                     setEventTriggerSelected={setEventTriggerSelected}
                                                     handleDeleteGroup={handleDeleteGroup}
                                                     handleClickGroup={handleClickGroup}
                                                     hooverGroup={hooverGroup}
                                                     hooverOutGroup={hooverOutGroup}/>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pt-1">
                    <ValidateQuery validateEvent={commonVariable.validateEvent}
                                   setShowModal={props.setShowModal}
                                   data={dataBuilder}
                                   groups={groups}
                                   dataBuilder={dataBuilder}
                    />
                </div>
            </div>
        </>
    )
}

export { GridBuilder }

function RowQueryBuilder(props) {
    const {t, i18n} = useTranslation()
    const operatorsDataList = [
        {id: "EQUALS", operator: t("query:condition", {context: "eq"})},
        {id: "DOES NOT EQUAL", operator: t("query:condition", {context: "neq"})},
        {id: "GREATER THAN", operator: t("query:condition", {context: "gt"})},
        {id: "LESS THAN", operator: t("query:condition", {context: "lt"})},
        {id: "CONTAINS", operator: t("query:condition", {context: "cont"})},
        {id: "DOES NOT CONTAIN", operator: t("query:condition", {context: "ncont"})}
    ]
    const [inputTd, setInputTd] = useState(<td></td>)
    const [groupTd, setGroupTd] = useState([])
    const [dataEnum, setDataEnum] = useState([])
    const [operatorsData, setOperatorsData] = useState([])
    const [autocompleteData, setAutocompleteData] = useState([])


    let tdButtonDelete = <td>
        <button id={props.id} className="query-add-delete-button button-delete"
                onClick={(e) => props.handleDeleteButton(e)} title={t("query:criteria", {context:"delete"})}>
            <svg id={props.id} enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                <path id={props.id}
                      d="m2 28c-1.3-1.3-1.3-3.5 0-4.8l8.2-8.2-8.2-8.1c-1.3-1.3-1.3-3.4 0-4.7s3.5-1.3 4.8 0l8.2 8.2 8.2-8.2c1.3-1.3 3.5-1.3 4.8 0s1.3 3.5 0 4.8l-8.2 8 8.2 8.2c1.3 1.3 1.3 3.5 0 4.8s-3.5 1.3-4.8 0l-8.2-8.2-8.2 8.2c-1.2 1.3-3.5 1.4-4.8 0z"/>
            </svg>
        </button>
    </td>
    let tdButtonAdd = <td>
        <button id={props.id} className="query-add-delete-button button-add" onClick={(e) => props.handleAddButton(e)} title={t("query:criteria", {context:"add"})}>
            <svg id={props.id} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                <path id={props.id}
                      d="m15 29.7c-1.5 0-2.7-1.2-2.7-2.7v-9.2h-9.2c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h9.2v-9.3c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7v9.2h9.2c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7h-9.2v9.2c0 1.5-1.2 2.8-2.7 2.8z"/>
            </svg>
        </button>
    </td>
    let tdChekbox = <td><input checked={props.checked} id={props.id} type="checkbox"
                               onChange={(e) => props.handleCheckBoxe(e)} className="checkbox-style filled"/></td>
    let tdTreeView = <td className="position-relative query-table-field"><TreeViewProperty data={props.dataTreeView}
                                                                                           eventTriggerSelected={props.eventTriggerSelected}
                                                                                           setEventTriggerSelected={props.setEventTriggerSelected}
                                                                                           valueProperty={props.valuePropertyShow}
                                                                                           handle={props.handleProperty}
                                                                                           idTv={props.id}/></td>
    let tdCondition = <td className="query-table-and-or"><DropdownCustom data={[
        {id: "AND", condition: t("query:and")},
        {id: "OR", condition: t("query:or")}

    ]} fields={{text: "condition", value: "id"}} valueReceived={props.valueCondition}
                                                                         handle={props.handleDropdownCondition}
                                                                         idDp={props.id}/></td>
    let tdOperator = <td className="query-table-operator"><DropdownCustom data={operatorsData}
                                                                          fields={{text: "operator", value: "id"}}
                                                                          valueReceived={props.valueOperator}
                                                                          handle={props.handleDropdownOperator}
                                                                          idDp={props.id}/></td>

    let tdInputString = <td className="query-table-value"><input value={props.valueInput}
                                                                 onChange={(e) => props.handleInputValue(e.target.id, e.target.value)}
                                                                 id={props.id} type="text" name="inputValue"/></td>
    let tdInputNumeric = <td className="query-table-value"><NumericTextBoxComponent id={props.id} change={function (e) {
        props.handleInputValue(this.id, e.value)
    }} value={props.valueInput}/></td>
    let tdInputDate = <td className="query-table-value"><CalandarCustom id={props.id}
                                                                        value={props.valueInput}
                                                                        handle={function (e) {
                                                                            props.handleInputValue(this.id, e.value)
                                                                        }}/></td>
    let tdInputBoolean = <td className="query-table-value"><input checked={props.valueInput} id={props.id}
                                                                  type="checkbox"
                                                                  onChange={(e) => props.handleInputValue(e.target.id, e.target.checked)}
                                                                  className="checkbox-style filled"/></td>

    let tdInputEnum = <td className="query-table-value"><DropdownCustom data={dataEnum}
                                                                        fields={{text: "operator", value: "id"}}
                                                                        valueReceived={props.valueInput}
                                                                        handle={(e) => props.handleInputValue(e.element.id, e.value)}
                                                                        idDp={props.id}/></td>

    let tdAutocomplete = <td className="query-table-value"><AutoCompleteCustom id={props.id} handle={function (e) {
        props.handleInputValue(this.id, e.value)
    }} value={props.valueInput} dataSource={autocompleteData} fields={{value: "name"}}/></td>

    useEffect(() => {
        let dataSelected = props.dataBuilder.find(d => d.id == props.id)
        setOperatorsData(operatorsDataList)
        switch (props.type) {
            case 'string':
                setInputTd(tdInputString)
                setOperatorsData(operatorsDataList.filter(operator => {
                    if (operator.id == "GREATER THAN" || operator.id == "LESS THAN")
                        return false
                    else
                        return true
                }))
                break;
            case 'number':
                setInputTd(tdInputNumeric)
                setOperatorsData(operatorsDataList.filter(operator => {
                    if (operator.id == "CONTAINS" || operator.id == "DOES NOT CONTAIN")
                        return false
                    else
                        return true
                }))
                break;
            case 'date':
                setInputTd(tdInputDate)
                break;
            case 'boolean':
                setInputTd(tdInputBoolean)
                setOperatorsData(operatorsDataList.filter(operator => operator.id == "EQUALS" || operator.id == "DOES NOT EQUAL"))
                if (dataSelected.value === "" || dataSelected.value === undefined) {
                    dataSelected.value = false
                    props.setDataBuilder([...props.dataBuilder])
                }
                break;
            case 'enum':
                setOperatorsData(operatorsDataList.filter(operator => operator.id == "EQUALS" || operator.id == "DOES NOT EQUAL"))
                let jsonValues = []
                let temp = []
                props.valuesEnum.forEach(e => {
                    let obj = {
                        id: e.value,
                        operator: e.enumLabel,
                    }
                    jsonValues.push(obj)
                    temp.push(e.enumLabel)
                })
                let tempDataEnum = []
                dataEnum.forEach(data => tempDataEnum.push(data.operator))
                if (JSON.stringify(temp) != JSON.stringify(tempDataEnum)) {
                    setDataEnum(jsonValues)
                }
                break;
            case 'autocompletestring':
                setOperatorsData(operatorsDataList.filter(operator => {
                    if (operator.id == "GREATER THAN" || operator.id == "LESS THAN")
                        return false
                    else
                        return true
                }))
                setAutocompleteData([...props.autocompletionValues])
                break;
            case "":
            case "nothing":
            case null:
                setInputTd(<td></td>)
                break;
        }
    }, [props.type, props.valueInput, props.valuesEnum, props.dataBuilder])

    useEffect(() => {
        let dataSelected = props.dataBuilder.find(d => d.id == props.id)
        if (dataSelected.value !== "" && dataSelected.value !== false) {
            dataSelected.value = ""
            props.setDataBuilder([...props.dataBuilder])
        }
    }, [props.type])
    useEffect(() => {
        let groupTdTemp = []
        if (props.group.length == 0 && props.maxGroup > 0) {
            groupTdTemp.push(<td key={generateKey(groupTdTemp.length)} className='no-group'
                                 colSpan={props.maxGroup}></td>)
        } else if (props.group.length > 0) {
            for (let i = 0; i < props.group.length; i++) {
                let spanId = <span className="group-id">{props.group[i].id}</span>
                if (props.maxGroup != deleteDuplicatesPos(props.group[i].childs).length + i + 1 && props.group[i].parents.length == 0) {
                    groupTdTemp.push(<td key={generateKey(groupTdTemp.length)} id={props.group[i].id} onClick={props.handleClickGroup} className='no-group' colSpan={props.maxGroup - (deleteDuplicatesPos(props.group[i].childs).length + 1 + i)}></td>)
                }
                let colspanNumber = getNumberColspan(props.group[i], i, props.id)
                if (props.id == props.group[i].rows[0].id) {
                    groupTdTemp.push(<td onMouseLeave={props.hooverOutGroup} onMouseEnter={(e) => props.hooverGroup(e)} key={generateKey(groupTdTemp.length)} id={props.group[i].id} onClick={props.handleClickGroup} className={'group group-start g-cat-' + props.group[i].pos % 5} colSpan={colspanNumber}><button onClick={props.handleDeleteGroup} className="group-delete" title="Supprimer le groupe">
                        <svg id={props.group[i].id} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <rect className="st0 svg-clair" x="2.3" y="8.4" width="3.7" height="1.8"/>
                            <rect className="st0 svg-clair" x="2.3" y="20.1" width="3.7" height="1.8"/>
                            <rect className="st0 svg-clair" x="1.4" y="8.4" width="1.8" height="13.5"/>
                            <path id={props.group[i].id} className="st0 svg-clair" d="m27.3 24.7h-17.7c-0.8 0-1.5-0.7-1.5-1.6v-4.4c0-0.8 0.7-1.5 1.5-1.5h17.6c0.8 0 1.5 0.7 1.5 1.5v4.4c0.1 0.9-0.6 1.6-1.4 1.6z"/>
                            <path id={props.group[i].id} className="st0 svg-clair" d="m27.3 13h-17.7c-0.8 0-1.5-0.7-1.5-1.5v-4.4c0-0.8 0.7-1.5 1.5-1.5h17.6c0.8 0 1.5 0.7 1.5 1.5v4.4c0.1 0.8-0.6 1.5-1.4 1.5z"/>
                            <path id={props.group[i].id} className="st1 svg-fonce" d="m21.2 15.2 7.9-7.9c0.8-0.8 0.8-2 0-2.9s-2-0.8-2.9 0l-7.9 8-7.9-7.9c-0.8-0.8-2-0.8-2.9-0.1s-0.8 2-0.1 2.9c0 0 0 0.1 0.1 0.1l8 7.8-7.9 7.9c-0.8 0.8-0.8 2 0 2.9 0.8 0.8 2 0.8 2.9 0l7.9-7.9 7.8 7.9c0.8 0.8 2 0.8 2.9 0s0.8-2 0-2.9l-7.9-7.9z"/>
                        </svg>
                    </button>{spanId}</td>)
                }
                else if (props.id == props.group[i].rows.slice(-1)[0].id) {
                    groupTdTemp.push(<td onMouseLeave={props.hooverOutGroup} onMouseEnter={(e) => props.hooverGroup(e)} key={generateKey(groupTdTemp.length)} id={props.group[i].id} onClick={props.handleClickGroup} className={'group group-end g-cat-' + props.group[i].pos % 5} colSpan={colspanNumber}>{spanId}</td>)
                }
                else {
                    groupTdTemp.push(<td onMouseLeave={props.hooverOutGroup} onMouseEnter={(e) => props.hooverGroup(e)} key={generateKey(groupTdTemp.length)} id={props.group[i].id} onClick={props.handleClickGroup} className={'group group-middle g-cat-' + props.group[i].pos % 5} colSpan={colspanNumber}>{spanId}</td>)
                }
            }
        }
        setGroupTd([...groupTdTemp])
    }, [props.group, props.maxGroup])
    useEffect(() => {
        if (dataEnum.length == 0)
            return
        setInputTd(tdInputEnum)
    }, [dataEnum])
    useEffect(() => {
        if (autocompleteData.length == 0)
            return
        setInputTd(tdAutocomplete)
    }, [autocompleteData])
    return (
        <tr>
            {tdButtonAdd}
            {props.id == 0 ? <td></td> : tdButtonDelete}
            {tdChekbox}
            {groupTd}
            {props.id == 0 ? <td></td> : tdCondition}
            {tdTreeView}
            {props.type === "nothing" || props.type == "" ? <td></td> : tdOperator}
            {inputTd}
        </tr>
    )
}


function ValidateQuery(props) {
    const [isLoading, setIsLoading] = useState()
    const {t, i18n} = useTranslation()
    const [confirmModal, setConfirmModal] = useState(false)

    let confirmModalHandle = () => {
        let query = getQueryJson(props.data, props.groups)
        let modalElem = document.getElementById('temp')
        if (modalElem != null)
            modalElem.id = 'container-modal'
        props.validateEvent(query)
    }

    let validateQuery = async () => {
        let query = getQueryJson(props.data, props.groups).trim()
        if (query === "")
            return
        let count = 0
        setIsLoading(true)
        await axios.post('helpers/query/countResolve/' + getType(query, t).field, {query}).then(res => count = res.data).finally(() => {
            setIsLoading(false)
        })

        setIsLoading(false)
        if (count == 0)
            setConfirmModal(true)
        else {
            props.dataBuilder.forEach(item => {
                let element = document.getElementById("treeview" + item.id)
                element.parentNode.removeChild(element)
            })
            let modalElem = document.getElementById('temp')
            if (modalElem != null)
                modalElem.id = 'container-modal'
            props.validateEvent(query)
        }

    }
    return(
        <>
            <button onClick={validateQuery}
                    className={isLoading ? "bouton loading" : "bouton"}>{isLoading ?
                <span className="spinner"></span> : t("default:proceed")}</button>
            {confirmModal ?
                <ModalPopup propsAdd={{
                    function: confirmModalHandle,
                    id: 0,
                    txt: t("query:empty"),
                    title: t("query:confirmation")
                }} closeCLicked={true}
                            content={ConfirmModalView}
                            setShowModal={setConfirmModal}/> : null}
        </>
    )
}

function reformatDate(date){
    let day = date.toString().split(" ")[2]
    let month = ""
    let year = date.toString().split(" ")[3]
    switch (date.toString().split(" ")[1]) {
        case "Jan":
            month = "01"
            break;
        case "Feb":
            month = "02"
            break;
        case "Mar":
            month = "03"
            break;
        case "Apr":
            month = "04"
            break;
        case "May":
            month = "05"
            break;
        case "Jun":
            month = "06"
            break;
        case "Jul":
            month = "07"
            break
        case "Aug":
            month = "08"
            break;
        case "Sep":
            month = "09"
            break;
        case "Oct":
            month = "10"
            break;
        case "Nov":
            month = "11"
            break;
        case "Dec":
            month = "12"
            break;
        default:
            break;
    }
    return `${day}/${month}/${year}`
}

function groupExist(dataGrouped, groups){
    let ids = []
        dataGrouped.forEach(data => ids.push(data.id))
        for (let i = 0; i < groups.length; i++) {
            let idsCompare = []
            groups[i].rows.forEach(row => idsCompare.push(row.id))
            if (idsCompare.toString() == ids.toString())
                return true
        }
}

function getNumberColspan(group, start, indexRow) {
    let col = 1
    let groupFusionChild = [group]
    group.childs.forEach(child => groupFusionChild.push(child))
    groupFusionChild.sort((a, b) => {
        if (a.pos > b.pos)
            return -1
        else if (a.pos < b.pos)
            return 1

        return 0
    })
    let groupFusionChildDeleteDuplicate = []
    for (let i = 0; i < groupFusionChild.length; i++) {
        if(groupFusionChildDeleteDuplicate.find(gr => gr.pos == groupFusionChild[i].pos)==undefined)
            groupFusionChildDeleteDuplicate.push(groupFusionChild[i])
    }
    for (let j = 1; j < groupFusionChildDeleteDuplicate.length; j++) {
        let rowFind = groupFusionChildDeleteDuplicate[j].rows.find(row => row.id == indexRow)
        if (rowFind == undefined) {
            if (groupFusionChild.filter(gr => gr.pos == groupFusionChildDeleteDuplicate[j].pos).length > 0) {
                groupFusionChild.filter(gr => gr.pos == groupFusionChildDeleteDuplicate[j].pos).forEach(gr => {
                    let findTemp = gr.rows.find(row => row.id == indexRow)
                    if (findTemp != undefined) {
                        rowFind = findTemp
                        return
                    }
                })
            }
        }
        if (rowFind != undefined)
            break
        else
            col++
    }
    return col
}

function deleteDuplicatesPos(array) {
    let posGroupList = []
    array.forEach(gr => posGroupList.push(gr.pos))
    return posGroupList.filter((g, index) => {
        return posGroupList.indexOf(g) === index
    })

}


function dataQueryBuilder(id, checked, condition, property, propertyShow, operator, value) {
    return {
        id: id,
        checked: checked,
        condition: condition,
        property: property,
        propertyShow: propertyShow,
        operator: operator,
        value: value,
        type: "",
        valuesEnum: null,
        autocompletionValues: null,
        group: []
    }

}

function getQueryJson(data, groups){
    let text = ""
    for (let i = 0; i < data.length; i++) {
        if(i != 0)
            text += " " + data[i].condition + " " 
        groups.forEach(gr => {
            let index = gr.rows.findIndex(row => row.id == data[i].id)
            if(index == 0)
                text += "("
            else
                text += ""
        })
        text += data[i].property + " " + data[i].operator + " "
        if (data[i].type.includes('string') && (data[i].operator == "IN" || data[i].operator == "NOT IN"))
            text += data[i].value
        else if (data[i].type.includes('string'))
            text += "\"" + data[i].value + "\""
        else
            text += data[i].value
        groups.forEach(gr => {
            let index = gr.rows.findIndex(row => row.id == data[i].id)
            if(index == gr.rows.length - 1)
                text += ")"
            else
                text += ""
        })
    }
    return text
}

import {
	CHARACTERISTICS_SVG,
	CONNEXION_LIST_SVG,
	DASHBOARD_SVG,
	DECO_SVG,
	GENERAL_SVG,
	LIFESHEET_SVG,
	SEARCH_SVG,
	TABLE_SVG
} from "../../style/menuSVG"
import {Link as LinkScroll} from "react-scroll"
import React, {useEffect, useState} from "react"
import {Link} from 'react-router-dom'
import {generateKey} from "../Common/utilities";
import axios from "axios";
import {useTranslation} from "react-i18next";
import ModalPopup from "../Modal/modal";
import {ModalListConnexion} from "../Modal/modalListConnexion";
import {controlcardSVG} from "../../style/logoSVG";

function Menu(props) {
	const [isAdmin, setIsAdmin] = useState(false)
	const [groups, setGroups] = useState([])
	const {t, i18n} = useTranslation()
	const [defaultLanguage, setDefaultLanguage] = useState("")
	const [defaultGroup, setDefaultGroup] = useState("")
	const [nameUser, setNameUser] = useState()
	const [modalListConnections, setModalListConnections] = useState(false)

	useEffect(async () => {
		let operators = null
		let defaultOperator = null
		let name = null
		await axios.get("/helpers/operators/groupsOfCurrent").then(res => {
			operators = res.data
		})
		await axios.get("/authentication/currentGroup").then(res => {
			defaultOperator = res.data
		})
		await axios.get("/helpers/operators/NameOfCurrent/First").then(res => {
			name = res.data
		})
		if (name == "") {
			await axios.get("/helpers/operators/NameOfCurrent/Last").then(res => {
				name = res.data
			})
		}

		if (defaultOperator == 1) {
			setIsAdmin(true)
		}
		setNameUser(name)
		setGroups(operators)
		setDefaultLanguage(i18n.language)
		setDefaultGroup(defaultOperator)
	}, [])

	useEffect(async () => {
		if (defaultGroup == null || defaultGroup == "")
			return
			await axios.get(`/authentication/changeGroup/${defaultGroup}`).then(res => {
				window.localStorage.setItem("token", res.data)
				if (defaultGroup == 1)
					setIsAdmin(true)
				else
					setIsAdmin(false)
			})
		if (props.setGroup)
			props.setGroup(defaultGroup)
	}, [defaultGroup])

	let changeGroups = async (e) => {
		setDefaultGroup(e.target.value)
	}
	let changeLangue = async (e) => {
		i18n.changeLanguage(e.target.value)
		localStorage.setItem('language', e.target.value)
		setDefaultLanguage(i18n.language)
		window.location.reload()
	}

	let openModalListConnections = () => {
		setModalListConnections(true)
	}


	return (
		<header id="mainHeader">

			<nav role="navigation" id="nav-desktop">

				<div className="navBurger">
					<div className="burger"></div>
				</div>

				<ul className="nav_groupe">
					<h2>{t('default:hello', { name: nameUser })}</h2>
					<ComponentDropdown defaultValue={defaultGroup} title={""} handleChange={changeGroups}
									   data={groups}/>
				</ul>

				<ul className="nav_principale">
					<ComponentMenu isActive={props.dashboard == undefined ? false : props.dashboard} path={"/dashboard"}
								   isScroll={false} name={t("default:dashboard")} logo={DASHBOARD_SVG} css={"d-flex"}/>
					<ComponentMenu isActive={props.search == undefined ? false : props.search} path={"/search"}
								   isScroll={false} name={t("default:search")} logo={SEARCH_SVG} css={"d-flex"}/>
				</ul>

				<ul className="nav_onglet">
					<ComponentMenu isDisabled={props.general} isScroll={true} idScroll={"general"}
								   name={t("default:general")}
								   logo={GENERAL_SVG} css={"d-flex"}/>
					<ComponentMenu isDisabled={props.caract} isScroll={true} idScroll={"caract"}
								   name={t("default:characteristics")} logo={CHARACTERISTICS_SVG} css={"d-flex"}/>
					<ComponentMenu isDisabled={props.tableau} isScroll={true} idScroll={"tableau"}
								   name={t("default:spreadsheet")}
								   logo={TABLE_SVG} css={"d-flex"}/>
					<ComponentMenu isDisabled={props.controlCard} isScroll={true} idScroll={"controlCard"}
								   name={t("default:controlcard")}
								   logo={controlcardSVG} css={"d-flex"}/>
					<ComponentMenu isDisabled={props.lifesheet} isScroll={true} idScroll={"lifesheet"}
								   name={t("default:lifesheet")} logo={LIFESHEET_SVG} css={"d-flex"}/>
		</ul>

				<ul className="nav_deco">
					<li>
						<ComponentSwitchTheme idSwitch={"switch-flat"} addText={true}/>
					</li>
					<ComponentDropdown title={t("default:language")} defaultValue={defaultLanguage}
									   handleChange={changeLangue}
									   data={[{id: "fr", name: "FR"}, {id: "en", name: "EN"}, {id: "pl", name: "PL"}]}/>
					{isAdmin ? <ButtonComponentMenu icon={CONNEXION_LIST_SVG} title={t("dashboard:connections_list")}
													handle={openModalListConnections}/> : null}
					<ComponentMenu isActive={false} isScroll={false} path={"/login"} name={t("default:logout")}
								   logo={DECO_SVG}
								   css={"d-flex"}/>
				</ul>
		
    </nav>

	<nav role="navigation" id="nav-mobile-haut">

		<input type="checkbox" id="burger-mobile"/>
		<label htmlFor="burger-mobile" className="burger-mobile-btn">
			<div className="burger"></div>
		</label>
		
		<div className="nav-mobile-container">
			<ul className="d-flex align-items-center">
				<li>
					<ComponentSwitchTheme idSwitch={"switch-flat-2"} addText={false}/>
				</li>
				<ComponentDropdown title={t("default:language")} defaultValue={defaultLanguage}
								   handleChange={changeLangue}
								   data={[{id: "fr", name: "FR"}, {id: "en", name: "EN"}, {id: "pl", name: "PL"}]}/>
			</ul>

			<ul className="nav_onglet">
				<ComponentMenu isScroll={false} path={"/search"} name={t("default:search")} logo={SEARCH_SVG}
							   css={"d-flex"}/>
				<ComponentMenu isScroll={false} path={"/dashboard"} name={t("default:dashboard")} logo={DASHBOARD_SVG}
							   css={"d-flex"}/>
				<ComponentMenu isDisabled={props.general} isScroll={true} idScroll={"general"}
							   name={t("default:general")} logo={GENERAL_SVG} css={"d-flex"}/>
				<ComponentMenu isDisabled={props.caract} isScroll={true} idScroll={"caract"}
							   name={t("default:characteristics")} logo={CHARACTERISTICS_SVG} css={"d-flex"}/>
				<ComponentMenu isDisabled={props.tableau} isScroll={true} idScroll={"tableau"}
							   name={t("default:spreadsheet")} logo={TABLE_SVG} css={"d-flex"}/>
				<ComponentMenu isDisabled={props.controlCard} isScroll={true} idScroll={"controlCard"}
							   name={t("default:controlcard")}
							   logo={controlcardSVG} css={"d-flex"}/>
				<ComponentMenu isDisabled={props.lifesheet} isScroll={true} idScroll={"lifesheet"}
							   name={t("default:lifesheet")}
							   logo={LIFESHEET_SVG} css={"d-flex"}/>
				{isAdmin ? <ButtonComponentMenu icon={CONNEXION_LIST_SVG} title={t("dashboard:connections_list")}
												handle={openModalListConnections}/> : null}
				<ComponentMenu isActive={false} isScroll={false} path={"/login"} name={t("default:logout")}
							   logo={DECO_SVG}
							   css={"d-flex"}/>
			</ul>
		</div>
	</nav>
			{modalListConnections ?
				<ModalPopup propsAdd={{}}
							closeCLicked={true}
							content={ModalListConnexion}
							setShowModal={setModalListConnections}/> : null}

		</header>
 )
}

function ComponentDropdown(props) {
	const [dropdown, setDropdown] = useState([])

	useEffect(() => {
		let temp = []
		props.data.forEach(d => {
			temp.push(<option key={d.id} value={d.id}>{d.name}</option>)
		})
		setDropdown(temp)
	}, [props.data])
	return (
		<li className="language_change pl-2">
			<div className="d-flex align-items-center">
				<label>
					<select value={props.defaultValue} onChange={props.handleChange} className="language_select">
						{dropdown}
					</select>
				</label>
				<p>{props.title}</p>
			</div>
		</li>
	)
}

function ComponentSwitchTheme(props){
	const [darkTheme, setDarkTheme] = useState(localStorage.getItem('darktheme'))
	const {t, in18} = useTranslation()
	const [defaultValue, setDefaultValue] = useState(localStorage.getItem('darktheme') == "sombre" ? true : false)
	let changeTheme = () => {
		let enableb = darkTheme == "clair" ? "sombre" : "clair"
		localStorage.setItem('darktheme', enableb)
		setDarkTheme(enableb)

	}
	useEffect(() => {
		if (darkTheme == "sombre")
			document.body.className = "dark-mode"
		else
			document.body.className = ""
	}, [darkTheme])

	useEffect(() => {
		setDarkTheme(localStorage.getItem('darktheme'))
	}, [])
	let contents = []
	if (props.addText)
		contents.push(<p key={generateKey("p")}>{t("default:darkmode")}</p>)
	return (
		<div className="d-flex align-items-center">
			<input defaultChecked={defaultValue} id={props.idSwitch} className="switch switch--flat"
				   onChange={changeTheme} type="checkbox"/>
			<label htmlFor={props.idSwitch}></label>
			{contents}
		</div>
	)
}

function ComponentMenu(props){
	let li = null
	if(props.isScroll){
		li = <LinkScroll activeClass=" active-link" to={props.idScroll} smooth={true}
		offset={-150} duration={1000} spy={true} className={props.isDisabled ? props.css + " disabled" : props.css}>
						{props.logo}
						<p>{props.name}</p>
					</LinkScroll>
	}
	else{
		li = <Link to={props.path} className={props.isActive ? props.css + " active-link": props.css}>
			{props.logo}
			<p>{props.name}</p>
		</Link>
	}
	return (
		<li>
			{li}
		</li>
	)
}

function ButtonComponentMenu(props) {
	return (
		<li>
			<button onClick={props.handle} className="d-flex align-items-center">
				{props.icon}
				<p>{props.title}</p>
			</button>
		</li>
	)

}

export {Menu}


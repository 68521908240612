import {useInViewRef} from "rooks";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {generateKey} from "../Common/utilities";

function Banner(props) {
    const [refBanner, inView] = useInViewRef()
    const [changeBannerCss, setChangeBannerCss] = useState("wrapper d-flex padding-constraint")
    const [scrollPosition, setScrollPosition] = useState(0)
    const handleScroll = () => {
        const position = window.pageYOffset
        setScrollPosition(position)
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])
    useEffect(() => {
        //Change la bannière en fonction de la position de la scrollbar
        if (scrollPosition > 45 && changeBannerCss == bannerCss)
            setChangeBannerCss(bannerHideCss)
        if (changeBannerCss == bannerHideCss && scrollPosition <= 45)
            setChangeBannerCss(bannerCss)
    }, [scrollPosition])
    let bannerCss = "wrapper d-flex padding-constraint"
    let bannerHideCss = `wrapper d-flex padding-constraint banner-scroll banner-scroll-${props.colorHideBanner}`
    let typeDiv = <div className="pr-2">
        {props.imgtype}
        <p className={`text-center ${props.type[1]}`}>{props.type[0]}</p>
    </div>
    let buttonDiv = <div className="w-100 banner-title">
        <h1 className="pr-2 d-inline-block mt-0">{props.title}</h1>
        <div className="d-inline-block">
            {props.buttons}
        </div>
    </div>
    let fields = []
    for (let i = 0; i < props.fields.length; i++) {
        let field = null
        if (props.fields[i].href != null) {
            field =
                <Link key={generateKey(props.fields[i].value)} to={props.fields[i].href[0] + props.fields[i].href[1]}
                      className={props.fields[i].css}>{props.fields[i].value}</Link>
        } else if (props.fields[i].img != null) {
            field = <div key={generateKey(props.fields[i].value)} className={`mt-1 ${props.fields[i].css}`}>
                <div className="d-flex">{props.fields[i].img}</div>
                <p className={`ml-10 ${props.fields[i].css}`}>{props.fields[i].value}</p>
            </div>
        } else {
            field =
                <p key={generateKey(props.fields[i].value)} className={props.fields[i].css}>{props.fields[i].value}</p>
        }
        fields.push(<div key={generateKey(props.fields[i].value)} className="d-flex align-items-center">
            <p className="fw-bold mb-0 d-inline-block mt-1">{props.fields[i].name}</p>
            {field}
        </div>)
    }
    return (
        <div id={"banner-container"} ref={refBanner} className={changeBannerCss}>
            <div className="banner flex-1">
                <div className="d-flex align-items-center pb-1 pt-1 flex-wrap-query">
                    {typeDiv}
                    <div className="w-100">
                        {buttonDiv}
                        {fields}
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Banner}
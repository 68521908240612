import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/customChart.css';
import {BrowserRouter as Router} from "react-router-dom"
import App from './components/app';
import axios from 'axios';
import './i18n.js';
import {LoadingPage} from "./components/Common/loadingPage";
import {t} from 'i18next';


axios.defaults.baseURL = process.env.REACT_APP_API_URL || "https://localhost:7078/api/";
axios.defaults.headers.post['content-type'] = 'application/json';

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem('token');

    if (token !== null && token !== "null") {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.headers['Accept-Language'] = window.localStorage.getItem('language') || "fr"

    return config;
}, error => Promise.reject(error));

axios.interceptors.response.use(response => response, error => {
    if (error.config.doNotCatch)
        return Promise.reject(error);

    switch (error.response.status) {
        case 400:
            alert(t('bad_format'));
            break;
        case 401:
            window.localStorage.removeItem('token')
            window.location.replace('/login');
            break;
        case 403:
            window.location.replace('/forbidden')
            break;
        case 404:
            window.location.replace('/*')
            break;
        case 500:
            alert(t('server_error'));
            console.log(error);
            break;
        default:
            alert(t('unknown_error', {
                code: error.response.status
            }));
            console.log(error);
            break;
    }

    return Promise.reject(error);
})

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <React.Suspense fallback={<LoadingPage/>}>
                <App/>
            </React.Suspense>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

//serviceWorker.unregister();
//reportWebVitals();

import React, {useEffect, useState} from "react";
import axios from "axios";
import ModalPopup from "../../Modal/modal";
import {ModaleImg} from "../../Modal/modaleImg";
import {generateKey} from "../utilities";
import {useTranslation} from "react-i18next";

function FileCharacteristic(props) {
    const [showModalImg, setShowModalImg] = useState(false)
    const [urlImg, setUrlImg] = useState(null)
    const {t, i18n} = useTranslation()
    let handleClickDL = async (e) => {
        await Promise.all([axios.get('/helpers/characteristics/getfile/' + props.data.id, {responseType: 'blob'}).then(res => {
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = props.data.value
            a.click();

        })]).catch(error => {
            console.log(error)
        })
    }

    let handleClickView = async (e) => {
        await axios.get('/helpers/characteristics/getFile/' + props.data.id, {responseType: 'blob'}).then(res => {
            console.log(res.data)
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            setUrlImg(url)
        })
    }

    useEffect(() => {
        if (urlImg != null)
            setShowModalImg(true)
    }, [urlImg])

    let buttonDl = <button onClick={handleClickDL} className="download ml-1" title={t("default:characteristics", {context:"download"})}>
        <svg enableBackground="new 0 0 30 38.5" version="1.1" viewBox="0 0 30 38.5">
            <path className="download-animation-arrow"
                  d="m24.5 17.4c-1-0.9-2.4-0.9-3.3 0.1l-3.9 4.2v-19.4c0-1.3-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v19.4l-3.9-4.2c-0.9-1-2.4-1-3.3-0.1-1 0.9-1 2.4-0.1 3.3l7.9 8.5c0.9 1 2.4 1 3.4 0l7.9-8.5c0.9-1 0.9-2.4-0.1-3.3z"/>
            <path className="download-animation-line"
                  d="m28 37.2h-26c-1.1 0-2-0.9-2-2s0.9-2 2-2h26c1.1 0 2 0.9 2 2s-0.9 2-2 2z"/>
        </svg>
    </button>
    let buttonView = <button onClick={handleClickView} className="view ml-1" title={t("default:characteristics", {context:"view"})}>
        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
            <path
                d="m15 10.1c-0.5 0-1 0.1-1.4 0.2 0.8 0.4 1.3 1.3 1.3 2.3 0 1.5-1.1 2.7-2.6 2.7-0.9 0-1.7-0.5-2.1-1.1-0.1 0.3-0.1 0.6-0.1 0.9 0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9-2.2-5-4.9-5z"/>
            <path
                d="m28.5 12.3c-2.9-2.7-7.8-6.3-13.5-6.3s-10.6 3.6-13.5 6.3c-1.7 1.6-1.7 3.9 0 5.5 2.9 2.6 7.8 6.2 13.5 6.2s10.6-3.6 13.5-6.3c1.7-1.5 1.7-3.9 0-5.4zm-13.5 9.7c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7c0 3.8-3.1 7-7 7z"/>
        </svg>
    </button>


    let content = null
    if (props.underDesignation)
        content = <tr key={generateKey(props.data.value)}>
            <td className="d-flex align-items-center">{props.data.value}{props.data.isInternal && props.data.value != "" ? props.data.value.split('.')[1] == "jpg" || props.data.value.split('.')[1] == "png" ? <>{buttonView} {buttonDl}</> : buttonDl : null}</td>
        </tr>
    else
        content = <><span
            className="d-flex align-items-center">{props.data.value}{props.data.isInternal && props.data.value != "" ? props.data.value.split('.')[1] == "jpg" || props.data.value.split('.')[1] == "png" ? <>{buttonView} {buttonDl}</> : buttonDl : null}</span></>
    return (
        <>
            {content}
            {showModalImg ?
                <ModalPopup removeScrollBarOnClose={true} closeCLicked={true} propsAdd={{url: urlImg, title: props.data.value}} content={ModaleImg}
                            setShowModal={setShowModalImg}/> : null}
        </>
    )
}

export {FileCharacteristic}
import React from "react";
import {generateKey} from "../utilities"
import {FileCharacteristic} from "./fileCharacteristic";

function CharacteristicsTable(props) {
    let content = null
    if (props.column == 0) {
        switch (props.data) {
            case 0:
                content = <img src={require('../../../img/format-texte.png')}/>
                break;
            case 1:
                content = <img src={require('../../../img/format-numerique.png')}/>
                break;
            case 3:
                content = <img src={require('../../../img/format-fichier.png')}/>
                break;
            case 2:
                content = <img src={require('../../../img/format-date.png')}/>
                break;
            default:
                break;
        }
    } else if (props.column == 3) {
        content = <>{props.data}</>
    } else {
        if (props.data.length > 1) {
            let underDesignations = []
            for (let i = 1; i < props.data.length; i++) {
                if (props.format == 3 && props.column == 2)
                    underDesignations.push(<FileCharacteristic key={generateKey(props.data[i] + i)}
                                                               underDesignation={true} data={props.data[i]}/>)
                else {
                    let td = <td>{props.data[i]}</td>
                    underDesignations.push(<tr key={generateKey(props.data[i] + i)}>{td}</tr>)
                }
            }
            content = <table>
                <thead className="height-39">
                <tr>
                    <th className="th-carac">{props.data[0]}</th>
                </tr>
                </thead>
                <tbody className={props.css}>
                {underDesignations}
                </tbody>
            </table>
        } else {
            if (props.format == 3 && props.column == 2)
                content = <FileCharacteristic underDesignation={false} data={props.data[0]}/>
            else
                content = <>{props.data[0]}</>
        }
    }
    return content
}

export {CharacteristicsTable}
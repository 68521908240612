
export const CHARACTERISTIQUES_NAME_SORT = function(rowA,rowB,id,desc){
    var nameA = rowA.values[id][0].toUpperCase()
    var nameB = rowB.values[id][0].toUpperCase()
    if (nameA < nameB)
        return -1
    if (nameA > nameB)
        return 1
}
export const CHARACTERISTIQUES_VALUES_SORT = function(rowA,rowB,id,desc){
    if(typeof rowA.values[id][0]=="number")
        return 1
    if(typeof rowB.values[id][0]=="number")
        return -1
    var nameA = rowA.values[id][0].toUpperCase()
    var nameB = rowB.values[id][0].toUpperCase()
    if (nameA[0] < nameB[0])
        return -1
    if (nameA[0] > nameB[0])
        return 1
}

export const sort_by = (field, reverse, primer, property) => {
    const key = primer ?
        function (x) {
            let value = property ? x[field][property] : x[field]
            return primer(value)
        } :
        function (x) {
            let value = property ? x[field][property] : x[field]
            return value
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}
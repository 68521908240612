import {useEffect, useRef, useState} from "react";
import {ListBoxComponent} from "@syncfusion/ej2-react-dropdowns";
import ReactDOM from "react-dom";
import axios from "axios";

function BoardListView(props) {
    const [showListBox, setShowListBox] = useState(false)
    const [value, setValue] = useState("")
    const tempData = useRef([])
    const listBox = useRef()
    useEffect(() => {
        if (showListBox) {
            document.getElementById("textBoard").classList.add("anim")
            ReactDOM.render(<ListBoxComponent id="ListBox-Board"
                                              ref={listBox}
                                              created={handleCreated}
                                              cssClass={"listBox-none"}
                                              allowDragAndDrop={true}
                                              change={handlelistBoxChange}
                                              dataSource={props.dataSource}
                                              fields={{text: "name", value: "id"}}/>
                ,
                document.getElementById('ListBox-Container')
            )
        } else {
            document.getElementById("textBoard").classList.remove("anim")
            ReactDOM.render(
                null,
                document.getElementById('ListBox-Container')
            )
            handleBeforeDrop()
            if (tempData.current.length > 0) {
                props.setDashboards([...tempData.current])
            }
        }
    }, [showListBox])

    useEffect(() => {
        if (props.value == undefined)
            return
            setValue(props.value)
    }, [props.value])

    let handlelistBoxChange = (e) => {
        props.handleDropdownDashBoard(e)
        setShowListBox(false)
    }

    let handleBeforeDrop = async (e) => {
        if (listBox.current == undefined)
            return
        let baseListId = []
        props.dataSource.forEach(data => baseListId.push(data.id))
        tempData.current = []
        let listId = []
        listBox.current.getItems().forEach(data => listId.push(parseInt(data.id)))
        for (let i = 0; i < listId.length; i++) {
            let elem = props.dashboards.find(d => d.id == listId[i])
            tempData.current.push(elem)
        }
        if (baseListId.toString() == listId.toString())
            return
        await axios.put("/eData/dashboard/order/dashboard", listId)
    }

    let handleCreated = () => {
        let elem = document.getElementById('ListBox-Container')
        if (elem == null)
            return
        let tree = elem.firstElementChild
        if (tree == null)
            return
        let selectTree = document.getElementById('textBoard')
        tree.style.top = selectTree.getBoundingClientRect().top + selectTree.getBoundingClientRect().height + 2 + 'px'
        tree.style.left = selectTree.getBoundingClientRect().left + 'px'
        tree.style.width = selectTree.getBoundingClientRect().width < 275 ? 275 + 'px' : selectTree.getBoundingClientRect().width + 'px'
        tree.style.display = "block"
    }

    let handleClickTexte = () => {
        setShowListBox(showListBox ? false : true)
    }
    window.addEventListener('click', function (e) {
        if (e.target.className == undefined)
            return
        if (e.target.className.baseVal != undefined)
            return
        if (e.target.className.includes("hg"))
            return
        if (e.target.className == "e-list-item e-selected")
            return
        setShowListBox(false)
    }, false)
    return (
        <div className={"hg"} onClick={handleClickTexte} id={"textBoard"}>{value}</div>
    )

}

export {BoardListView}
import {useNavigate} from "react-router";
import axios from "axios";
import React from "react";
import {generateKey} from "../Common/utilities";
import {Operator} from "./Table/operator";

function ContentSummary(props) {
    let navigate = useNavigate()
    let handleCLick = (path) => {
        if (!window.location.href.split('/')[3].includes(path))
            navigate(path)
        else
            props.setSearchParams({id: path.split('=')[1]})
    }

    let handleLink = async (e) => {
        await axios.get(`/helpers/documents/getFile/${props.procedure.id}`, {responseType: 'blob'}).then(res => {
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = props.procedure.name + '.' + props.procedure.file.name.split('.')[1]
            a.click();
        })
    }

    let data = props.data
    let divs = []
    for (let i = 0; i < data.length; i++) {
        if (data[i].href != null) {
            divs.push(<div key={generateKey(data[i].name)}
                           className="wrapper d-flex justify-content-center align-items-center gap-15">
                <p className={data[i].cssName}>{data[i].name}</p>
                <div className='w-45 mb-0 mt-1 d-flex align-items-center'>
                    <p className="mb-0 mt-0">{data[i].value}</p>{props.procedure == null ? null : props.procedure.file.isInternal ?
                    <button onClick={handleLink} className={data[i].css} className="download ml-1">
                        <svg enableBackground="new 0 0 30 38.5" version="1.1" viewBox="0 0 30 38.5">
                            <path className="download-animation-arrow"
                                  d="m24.5 17.4c-1-0.9-2.4-0.9-3.3 0.1l-3.9 4.2v-19.4c0-1.3-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v19.4l-3.9-4.2c-0.9-1-2.4-1-3.3-0.1-1 0.9-1 2.4-0.1 3.3l7.9 8.5c0.9 1 2.4 1 3.4 0l7.9-8.5c0.9-1 0.9-2.4-0.1-3.3z"/>
                            <path className="download-animation-line"
                                  d="m28 37.2h-26c-1.1 0-2-0.9-2-2s0.9-2 2-2h26c1.1 0 2 0.9 2 2s-0.9 2-2 2z"/>
                        </svg>
                    </button> : null}
                </div>
            </div>)
        } else if (data[i].link != null) {
            divs.push(<div key={generateKey(data[i].name)}
                           className="wrapper d-flex justify-content-center align-items-center gap-15">
                <p className={data[i].cssName}>{data[i].name}</p>
                <div className="w-45 mt-1">
                    <button onClick={() => handleCLick(data[i].link)} className={data[i].css}>{data[i].value}</button>
                </div>
            </div>)
        } else if (data[i].listId != null) {
            let li = []
            for (let j = 0; j < data[i].value.length; j++) {
                if (data[i].listId == 'parc-element') {
                    if (j > 0)
                        li.push(<li key={generateKey(data[i].value[j])}>&gt; {data[i].value[j]}</li>)
                    else
                        li.push(<li key={generateKey(data[i].value[j])}> {data[i].value[j]}</li>)
                } else {
                    li.push(<li key={generateKey(data[i].value[j].name)} className="w-100"><Operator
                        isPrincipal={data[i].value[j].isPrincipal} name={data[i].value[j].name}
                        mail={data[i].value[j].mail}/></li>)
                }
            }
            divs.push(<div key={generateKey(data[i].name)}
                           className="wrapper d-flex justify-content-center align-items-center gap-15">
                <p className={data[i].cssName}>{data[i].name}</p>
                <ul className={data[i].css} id={data[i].listId}>
                    {li}
                </ul>
            </div>)
        } else {
            divs.push(<div key={generateKey(data[i].name)}
                           className="wrapper d-flex justify-content-center align-items-center gap-15">
                <p className={data[i].cssName}>{data[i].name}</p>
                <p className={data[i].css}>{data[i].value}</p>
            </div>)
        }
    }
    return (
        <div className="pt-3 pb-3">
            {divs}
        </div>
    )
}

export {ContentSummary}
import {getPath, TreeViewProperty} from "../Utilities/treeviewProperty";
import {DropdownAggregation} from "../charts/CustomChartWindow/dropdownAggregation";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";

function CalculatedAggregation(props) {
    const {t, i18n} = useTranslation()
    const [loadingDataTreeView, setLoadingDataTreeView] = useState(false)
    const [eventTriggerSelected, setEventTriggerSelected] = useState(null)
    const [value, setValue] = useState("")
    const [valueArea, setValueArea] = useState("")
    const valueAggregator = useRef("")
    const [typeAggregation, setTypeAggregation] = useState("")

    let handleDropDown = (e) => {
        valueAggregator.current = e.value
    }

    let handleSelect = (type, e, values) => {
        let properties = getPath(e.node.offsetParent.ej2_instances[0], e.nodeData, props.propsAdd.data, false)
        setValue(properties)
        setTypeAggregation(type)
    }
    let add = () => {
        let tempArea = valueArea
        tempArea += `${valueAggregator.current}(${value})`
        setValueArea(tempArea)
    }

    let handleValidate = (e) => {
        props.setShowModal(false)
        props.propsAdd.setValueCalculated(valueArea)
    }
    let handleChangeTextArea = (e) => {
        setValueArea(e.target.value)
    }

    return (
        <div className="modal-calculated-aggregation">
            <div className="modal-box-button">
                <button onClick={() => props.setShowModal(false)} className="close">
                    <svg enableBackground="new 0 0 31.4 31.4" version="1.1" viewBox="0 0 31.4 31.4">
                        <path className="st0"
                              d="M19.5,15.7L30,5.2c1-1,1-2.7,0-3.8s-2.7-1-3.8,0L15.7,12L5.2,1.5c-1-1.1-2.7-1.1-3.8-0.1  c-1.1,1-1.1,2.7-0.1,3.8c0,0,0,0.1,0.1,0.1L12,15.7L1.5,26.2c-1,1-1,2.7,0,3.8c1,1,2.7,1,3.8,0l10.5-10.5L26.2,30c1,1,2.7,1,3.8,0  s1-2.7,0-3.8L19.5,15.7z"/>
                    </svg>
                </button>
            </div>
            <h1 className="m-0">{t("chart:aggregation", {context:"calc"})}</h1>
            <div className="d-flex align-item-center">
                    <TreeViewProperty value={value}
                                    data={props.propsAdd.data}
                                    calculated={false}
                                    eventTriggerSelected={eventTriggerSelected}
                                    setEventTriggerSelected={setEventTriggerSelected}
                                    handle={handleSelect}
                                    idTv={"AggregationCalculated-" + props.propsAdd.pageOpened.id}
                                    loadingDataTreeView={loadingDataTreeView}
                                    styleField={{'pointerEvents': loadingDataTreeView ? 'none' : 'initial'}}
                                      idModal={'container-modalcalculatedModal'}
                    />
                    <DropdownAggregation
                        type={typeAggregation}
                        handle={handleDropDown}
                        id={props.propsAdd.pageOpened.id}/>
                    <button onClick={add} className="bouton-recup" title={t("chart:calc", {context:"add"})}>
                        <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path d="m16.9 17h10.8c1.1 0 1.9-0.9 1.9-1.9s-0.9-1.9-1.9-1.9h-10.8v-10.8c0-1.1-0.8-2-1.9-2s-2 0.8-2 1.9v0.1 10.8h-10.8c-1.1 0-1.9 0.9-1.9 1.9 0 1.1 0.9 1.9 1.9 1.9h10.8v10.8c0 1.1 0.9 1.9 1.9 1.9s1.9-0.9 1.9-1.9l0.1-10.8z"></path>
                        </svg>
                    </button>
            </div>
            <textarea onChange={handleChangeTextArea} value={valueArea}></textarea>
            <button onClick={handleValidate} className="bouton">{t("default:proceed")}</button>
        </div>
    )
}

export {CalculatedAggregation}
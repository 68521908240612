const columnSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <path className="svg-fonce" d="m6.6 29.5h-4.6c-0.8 0-1.5-0.7-1.5-1.5v-12.2c0-0.8 0.7-1.5 1.5-1.5h4.6c0.8 0 1.5 0.7 1.5 1.5v12.2c0 0.8-0.7 1.5-1.5 1.5z"/>
        <path className="svg-clair" d="m17.3 29.5h-4.6c-0.8 0-1.5-0.7-1.5-1.5v-26c0-0.8 0.7-1.5 1.5-1.5h4.6c0.8 0 1.5 0.7 1.5 1.5v26c0 0.8-0.7 1.5-1.5 1.5z"/>
        <path className="svg-fonce" d="m28 29.5h-4.6c-0.8 0-1.5-0.7-1.5-1.5v-16.8c0-0.8 0.7-1.5 1.5-1.5h4.6c0.8 0 1.5 0.7 1.5 1.5v16.8c0 0.8-0.7 1.5-1.5 1.5z"/>
    </svg>

const barSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <path className="svg-clair" d="m0.5 6.6v-4.6c0-0.8 0.7-1.5 1.5-1.5h12.2c0.8 0 1.5 0.7 1.5 1.5v4.6c0 0.8-0.7 1.5-1.5 1.5h-12.2c-0.8 0-1.5-0.7-1.5-1.5z"/>
        <path className="svg-fonce" d="m0.5 17.3v-4.6c0-0.8 0.7-1.5 1.5-1.5h26c0.8 0 1.5 0.7 1.5 1.5v4.6c0 0.8-0.7 1.5-1.5 1.5h-26c-0.8 0-1.5-0.7-1.5-1.5z"/>
        <path className="svg-clair" d="m0.5 28v-4.6c0-0.8 0.7-1.5 1.5-1.5h16.8c0.8 0 1.5 0.7 1.5 1.5v4.6c0 0.8-0.7 1.5-1.5 1.5h-16.8c-0.8 0-1.5-0.7-1.5-1.5z"/>
    </svg>


const stackbarSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <g transform="translate(-458 -222)">
            <path className="svg-clair" d="m481.5 229.4h4.4c0.9 0 1.6 0.7 1.6 1.6v5.6c0 0.9-0.7 1.6-1.6 1.6h-4.4c-0.9 0-1.6-0.7-1.6-1.6v-5.6c-0.1-0.9 0.7-1.6 1.6-1.6z"/>
            <path className="svg-fonce" d="m481.5 239.4h4.4c0.9 0 1.6 0.7 1.6 1.6v8.9c0 0.9-0.7 1.6-1.6 1.6h-4.4c-0.9 0-1.6-0.7-1.6-1.6v-8.9c-0.1-0.9 0.7-1.6 1.6-1.6z"/>
            <path className="svg-clair" d="m471 222.5h4.4c0.9 0 1.6 0.7 1.6 1.6v13.3c0 0.9-0.7 1.6-1.6 1.6h-4.4c-0.9 0-1.6-0.7-1.6-1.6v-13.3c0-0.9 0.7-1.6 1.6-1.6z"/>
            <path className="svg-fonce" d="m471 240.2h4.4c0.9 0 1.6 0.7 1.6 1.6v8.1c0 0.9-0.7 1.6-1.6 1.6h-4.4c-0.9 0-1.6-0.7-1.6-1.6v-8.1c0-0.9 0.7-1.6 1.6-1.6z"/>
            <path className="svg-fonce" d="m460.1 244.7h4.4c0.9 0 1.6 0.7 1.6 1.6v3.6c0 0.9-0.7 1.6-1.6 1.6h-4.4c-0.9 0-1.6-0.7-1.6-1.6v-3.6c0-0.9 0.7-1.6 1.6-1.6z"/>
            <path className="svg-clair" d="m460.1 235.7h4.4c0.9 0 1.6 0.7 1.6 1.6v4.4c0 0.9-0.7 1.6-1.6 1.6h-4.4c-0.9 0-1.6-0.7-1.6-1.6v-4.4c0-0.8 0.7-1.6 1.6-1.6z"/>
        </g>
    </svg>

const curveSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <rect className="svg-fonce" transform="matrix(.6945 -.7195 .7195 .6945 -9.5308 10.012)" x="1.8" y="15" width="10.4" height="2.5"/>
        <rect className="svg-fonce" transform="matrix(.7107 -.7035 .7035 .7107 -7.26 14.803)" x="13.1" y="10.9" width="2.5" height="10.6"/>
        <rect className="svg-fonce" transform="matrix(.6491 -.7607 .7607 .6491 -3.5692 22.263)" x="15.8" y="13.7" width="13.1" height="2.5"/>
        <circle className="svg-clair" cx="3.4" cy="20" r="2.9"/>
        <circle className="svg-clair" cx="18.1" cy="20" r="2.9"/>
        <circle className="svg-clair" cx="26.6" cy="10" r="2.9"/>
        <circle className="svg-clair" cx="10.6" cy="12.5" r="2.9"/>
    </svg>


const pieSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <path className="svg-fonce" d="m11.8 2.2c-6.9 1.2-12 7.6-11.2 15.1 0.7 6.3 5.8 11.4 12.1 12.1 7.4 0.8 13.9-4.3 15.1-11.2 0.2-1.3-0.8-2.5-2.1-2.5h-9.3c-1.2 0-2.1-0.9-2.1-2.1v-9.3c0-1.4-1.2-2.4-2.5-2.1z"/>
        <path className="svg-clair" d="m15.9 2.6v9.4c0 1.2 0.9 2.1 2.1 2.1h9.3c1.3 0 2.3-1.2 2.1-2.5-1-5.6-5.4-10-11-11.1-1.3-0.2-2.5 0.8-2.5 2.1z"/>
    </svg>

const doughnutSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
		<path className="svg-fonce" d="m26.8 19.8-1.3-0.4c-0.7-0.2-1.3 0.1-1.7 0.7-1.3 2.3-3.5 4.1-6.2 4.7-2.6 0.7-5.4 0.4-7.7-1-2.4-1.3-4.1-3.5-4.7-6.2-0.7-2.4-0.4-4.9 0.7-7.1 0.9-1.8 2.3-3.3 4.1-4.3 0.6-0.3 0.9-1 0.7-1.7l-0.4-1.3c-0.2-1-1.3-1.4-2.1-1-2.6 1.4-4.7 3.5-6 6.1-1.8 3.2-2.2 7-1.2 10.4 0.9 3.7 3.4 6.9 6.7 8.8 3.3 2 7.3 2.5 11 1.5 3.9-1 7.1-3.6 9-7.1 0.5-0.9 0-1.9-0.9-2.1z"/>
		<path className="svg-clair" d="m29 11.2c-0.6-2.3-1.7-4.4-3.3-6-1.8-1.9-4.1-3.3-6.7-4-1.4-0.4-2.8-0.6-4.2-0.5-0.9 0-1.6 1-1.4 1.9l0.4 1.4c0.2 0.6 0.8 1 1.5 1 0.9 0 1.8 0.1 2.6 0.3 1.4 0.4 2.7 1 3.8 2 0.1 0.1 0.3 0.2 0.4 0.3l0.8 0.8c0.9 1.1 1.6 2.4 2 3.8 0.2 0.9 0.3 1.7 0.3 2.6 0 0.7 0.4 1.3 1.1 1.4l1.4 0.4c0.9 0.3 1.9-0.4 1.9-1.3-0.1-1.3-0.2-2.7-0.6-4.1z"/>
        <path className="svg-clair" d="m29.1 2.4"/>
    </svg>

const numberSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <path className="svg-fonce" d="m24.1 29.5h-18.2c-3 0-5.4-2.4-5.4-5.4v-18.2c0-3 2.4-5.4 5.4-5.4h18.2c3 0 5.4 2.4 5.4 5.4v18.2c0 3-2.4 5.4-5.4 5.4z"/>
    </svg>

const ListSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
		<circle className="svg-fonce" cx="4.6" cy="7.3" r="2.8"/>
		<path className="svg-fonce" d="m26.3 9.3h-14.3c-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.9-1.9 1.9-1.9h14.2c1.1 0 1.9 0.9 1.9 1.9 0.1 1-0.8 1.9-1.8 1.9z"/>
		<circle className="svg-clair" cx="4.6" cy="15" r="2.8"/>
		<path className="svg-clair" d="m26.3 16.9h-14.3c-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.9-1.9 1.9-1.9h14.2c1.1 0 1.9 0.9 1.9 1.9 0.1 1.1-0.8 1.9-1.8 1.9z"/>
		<circle className="svg-fonce" cx="4.6" cy="22.7" r="2.8"/>
		<path className="svg-fonce" d="m26.3 24.6h-14.3c-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.9-1.9 1.9-1.9h14.2c1.1 0 1.9 0.9 1.9 1.9 0.1 1.1-0.8 1.9-1.8 1.9z"/>
    </svg>

const noteSVG = <svg enableBackground="new 0 0 20.4 20.4" version="1.1" viewBox="0 0 20.4 20.4">
        <path className="svg-clair" d="M2,14.7l-1.2,3.1L0.4,19c-0.2,0.5,0.3,1,0.8,0.8l1.2-0.5l3.1-1.2L2,14.7z"/>
        <path className="svg-fonce" d="m19.4 1.9-1.1-1.1c-0.7-0.7-1.7-0.7-2.4 0l-13.9 13.9 3.5 3.5 13.9-13.9c0.6-0.7 0.6-1.8 0-2.4z"/>
    </svg>

const cumulatedcurveSVG = <svg enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
        <rect className="svg-fonce" transform="matrix(.6945 -.7195 .7195 .6945 -13.985 11.768)" x="1.7" y="21.1" width="10.4" height="2.5"/>
        <rect className="svg-fonce" transform="matrix(.9345 -.356 .356 .9345 -4.9217 6.5642)" x="10.1" y="15.4" width="10.6" height="2.5"/>
        <rect className="svg-fonce" transform="matrix(.4973 -.8676 .8676 .4973 3.9147 25.163)" x="17.1" y="8" width="13.1" height="2.5"/>
        <circle className="svg-clair" cx="3.2" cy="26.1" r="2.9"/>
        <circle className="svg-clair" cx="20.2" cy="14.7" r="2.9"/>
        <circle className="svg-clair" cx="26.8" cy="3.6" r="2.9"/>
        <circle className="svg-clair" cx="10.4" cy="18.6" r="2.9"/>
    </svg>




export {columnSVG,barSVG,stackbarSVG,curveSVG,pieSVG,doughnutSVG,numberSVG,ListSVG,noteSVG,cumulatedcurveSVG}
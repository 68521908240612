import {Category, DataLabel, DateTime, Legend, StripLine, Tooltip, Zoom} from "@syncfusion/ej2-react-charts";
import {ChartLineBarColumn} from "../barChart";
import {Doughnut} from "../doughnutChart";
import {useEffect, useRef, useState} from "react";
import {NumberBlock} from "./numberBlock";
import {getOpositeColor, setCornerRadius} from "./utilities";
import {Datagrid} from "./datagrid";
import {DropdownPeriode} from "./dropdownPeriode";
import {Note} from "./note";
import {NoRightBlock} from "./noRightBlock";

function PreviewChartRender(props) {
    const [loading, setLoading] = useState(false)
    const [primaryAxis, setPrimaryAxis] = useState({y: null, x: null})
    const [chart, setChart] = useState(null)
    const chartRef = useRef()
    const [showPeriod, setShowPeriod] = useState(false)
    let randomIdGenerate = useRef(Math.floor(Math.random() * 10000))

    let handleChange = async (e) => {
        props.eventChange(e, props.chart)
    }

    useEffect(() => {
        if (props.chart == null)
            return setChart(<NoRightBlock/>)
        if (!props.refreshAuto && !props.refreshForced)
            return
        if (props.chart.type === null)
            return
        primaryAxis.y = {
            majorGridLines: {width: 0},
            majorTickLines: {width: 0}, lineStyle: {width: 0}, labelStyle: {color: 'transparent'}
        }
        primaryAxis.x = {valueType: 'Category', interval: 1, majorGridLines: {width: 0}}
        setPrimaryAxis(primaryAxis)
        let servicesTemps = [Legend, Tooltip, Category, DataLabel, StripLine,DateTime]
        if (props.chart.service != null) {
            servicesTemps.push(props.chart.service)
        }
        if (props.zoom) {
            servicesTemps.push(Zoom)
        }

        let palettesTemp = setColor(props.chart.dataChart)
        /*props.chart.dataChart.forEach(c => {
            palettesTemp.push(c.color[0])
        })*/
        let chartContent = <ChartLineBarColumn id={props.randomId ? randomIdGenerate.current : props.chart.id}
                                               inDashboard={props.inDashboard} chartRef={chartRef} chart={props.chart}
                                               color={palettesTemp}
                                               services={servicesTemps}
                                               yScale={props.chart.yScale}
                                               xScale={props.chart.xScale}
                                               abscissaType={props.chart.abscissaType}
                                               zoomEnabled={props.zoom == undefined ? false : true}
        />
        let pieChartContent = props.chart.dataChart.length == 0 ? null :
            <Doughnut
                inDashboard={props.inDashboard}
                showCenterTxt={props.chart.isCenter}
                contentCenterTxt={props.chart.dataChart[0].dataCenter}
                radius={props.chart.type == "Pie" ? 0 : 60}
                chartRef={chartRef}
                title={props.chart.name}
                colors={props.chart.dataChart[0].color}
                name={props.chart.dataChart[0].name}
                data={props.chart.dataChart[0].data}
                id={props.randomId ? randomIdGenerate.current : props.chart.id}/>
        if (props.chart.type == "Pie" || props.chart.type == "Doughnut") {
            setChart(pieChartContent)
        } else if (props.chart.type == "Number") {
            setChart(<NumberBlock id={props.randomId ? randomIdGenerate.current : props.chart.id} color={palettesTemp}
                                  pageOpened={props.pageOpened}
                                  title={props.chart.name}
                                  data={props.chart.dataChart}/>)
        } else if (props.chart.type == "Grid") {
            if (props.chart.series != null) {
                setChart(<Datagrid id={props.randomId ? randomIdGenerate.current : props.chart.id}
                                   tableData={props.chart}/>)
            }
            else {
                setChart(null);
            }
        } else if (props.chart.type == "Note") {
            setChart(<Note handleOnChangeTitle={props.handleOnChangeTitle}
                           handleInputTitle={props.handleInputTitle} showToolBar={props.editNote}
                           readOnly={!props.editNote} setJsonChart={props.setJsonChart} chartValue={props.chartValue}
                           label={props.chart.label}
                           title={props.chart.name}
                           id={props.randomId ? randomIdGenerate.current : props.chart.id}/>)
        } else {
            setChart(chartContent)
        }
        if (props.chart.dateValue != "") {
            setShowPeriod(true)
        } else {
            setShowPeriod(false)
        }

        if (props.refreshForced)
            props.setRefreshForced(false)

    }, [props.chart])

    useEffect(() => {
        if (props.chart == null)
            return
        if (chart == null)
            return
        if (props.chart.type == null)
            return
        if (props.chart.type == "Bar" || props.chart.type == "Column" || props.chart.type == "StackingColumn" || props.chart.type == "Line" || props.chart.type == "Cumulative") {
            chartRef.current.clearSeries()
            let pos = 0
            props.chart.dataChart.forEach(c => {
                let serie = [{
                    type: props.chart.type == "Cumulative" ? "Line" : props.chart.type,
                    dataSource: c.data,
                    xName: 'x',
                    width: 2,
                    yName: 'y',
                    marker: {
                        visible: props.chart.type == "Line" || props.chart.type == "Cumulative" ? true : false,
                        width: 5,
                        height: 5,
                        dataLabel: {
                            visible: true,
                            position: 'Top',
                            font: {
                                fontWeight: '800',
                                color: props.chart.type == "Line" ? "#2b587b" : getOpositeColor(c.color[0])
                            },
                        }
                    },
                    name: c.name,
                    cornerRadius: setCornerRadius(props.chart.type, pos, props.chart.dataChart.length - 1)

                }]
                chartRef.current.addSeries(serie)
                if (c.trendLine.data != null)
                    chartRef.current.addSeries(getTrendLine(c))
                pos++
            })

        } else if (props.chart.type == "Pie" || props.chart.type == "Doughnut")
            chartRef.current.refresh()
        let idTitle = props.chart.type == "Pie" || props.chart.type == "Doughnut" ? "title" : "ChartTitle"
        let idNumberTitle = props.randomId ? randomIdGenerate.current : props.chart.id
        let title = document.getElementById("charts" + idNumberTitle + "_" + idTitle)
        if (title != null)
            title.innerHTML = props.chart.name

    }, [chart])


    return (

        <>
            {props.chart == null ? null : <>
                {!props.snapshot ?
                    <DropdownPeriode show={showPeriod} id={props.chart.id} type={props.chart.type} chartId={props.chart.id}
                                     handleChange={handleChange}
                                     value={props.chart.dateValue}/> : null}
            </>}
            {chart}
        </>

    )
}

function getTrendLine(serie, t) {
    let trendLineData = [{
        type: "Line",
        dataSource: serie.trendLine.data,
        xName: 'x',
        width: 2.5,
        yName: 'y',
        marker: {
            visible: false,
            width: 5,
            height: 5,
            dataLabel: {
                visible: false,
                position: 'Top',
                font: {
                    fontWeight: '800',
                    color: "#2b587b"
                },
            }
        },
        name: `${serie.name}-Courbe de tendance`,

    }]
    return trendLineData
}

function setColor(dataChart) {
    let palette = []
    for (let i = 0; i < dataChart.length; i++) {
        palette.push(dataChart[i].color[0])
        if (dataChart[i].trendLine.data != null) {
            palette.push(colorLuminance(dataChart[i].color[0], -0.15))
        }
    }
    return palette
}

function colorLuminance(hex, lum) {

    hex = String(hex).replace(/[^0-9a-f]/gi, '')
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    lum = lum || 0

    var rgb = "#", c, i
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16)
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
        rgb += ("00" + c).substr(c.length)
    }

    return rgb;
}

export {PreviewChartRender}
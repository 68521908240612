import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function QueryShow(props) {
    const {t, i18n} = useTranslation()
    const [editable, setEditable] = useState(false)
    const [chartQueryText, setChartQueryText] = useState("")



    useEffect(() => {
        setChartQueryText(props.chartQuery)
        const handleEsc = (event) => {

            if (event.keyCode === 27) {
                escape()
            }
        }
        window.addEventListener('keydown', handleEsc)
        window.addEventListener('click', closeClick, false)
        return () => {
            window.removeEventListener('keydown', handleEsc)
            window.removeEventListener('click', closeClick)
        }
    }, [props.chartQuery])

    let closeClick = (e) => {
        if (e.target.id == "text-area-query")
            return
        if (e.target.id == "clipboard")
            return
        if (e.target.id == "send-query")
            return
        if (e.target.id == "edite-query")
            return
        if (e.target.id == "label-show-query")
            return
        if (e.target.id == "query-show")
            return
        if (e.target.id == "query-show-button")
            return
        escape()
    }


    let handleEdite = () => {
        setEditable(true)
    }

    let handleKey = (e) => {
        if (e.key === "Enter") {
            validate()
        }
    }

    let validate = (e) => {
        props.sendTextChart(chartQueryText)
        let query = document.getElementById("query-show")
        if (query != null)
            query.checked = false
        setEditable(false)
    }

    let escape = () => {
        setChartQueryText(props.chartQuery)
        setEditable(false)
        let query = document.getElementById("query-show")
        if (query != null)
            query.checked = false
    }

    let handleChangeChartQuery = (e) => {
        setChartQueryText(e.target.value)
    }

    return (
        <div className="position-relative">
            <input type="checkbox" id="query-show"/>
            <p id={"label-show-query"} className="query-show">
                <label id={"label-show-query"} htmlFor="query-show">
                    {t("chart:query", {context: "show"})}
                </label>
            </p>
            <div className="query-show-div">
                        <textarea id={"text-area-query"} className={editable ? "textarea-edit" : ""}
                                  onKeyPress={handleKey} readOnly={editable ? false : true}
                                  onChange={handleChangeChartQuery} value={chartQueryText}>
                        </textarea>
                <div id={"query-show-button"} className="query-show-button">
                    <button id={"clipboard"} onClick={(e) => {
                        navigator.clipboard.writeText(props.chartQuery)
                    }} title={t("chart:query", {context: "copy"})} className="button-copy">
                        <svg id={"clipboard"} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path id={"clipboard"}
                                  d="m24.1 28.5h-9.4c-2 0-3.7-1.7-3.7-3.7v-14.1c0-2 1.7-3.7 3.7-3.7h9.4c2 0 3.7 1.7 3.7 3.7v14.1c0 2-1.7 3.7-3.7 3.7z"/>
                            <path id={"clipboard"}
                                  d="m14.7 4.8h4.3c-0.2-1.8-1.8-3.3-3.7-3.3h-9.4c-2 0-3.7 1.7-3.7 3.7v14.1c0 2 1.7 3.7 3.7 3.7h2.9v-12.3c0-3.3 2.6-5.9 5.9-5.9z"/>
                        </svg>
                    </button>
                    {editable ?
                        <button id={"send-query"} onClick={validate} title={t("chart:query", {context: "send"})}>
                            <svg id={"send-query"} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <g id={"send-query"} transform="translate(-9.182 -10.676)">
                                    <path id={"send-query"}
                                          d="m34.2 14.8c1-1.1 2.7-1.1 3.8-0.1s1.1 2.7 0.1 3.8l-0.1 0.1-14.3 17.9c-1 1.1-2.7 1.2-3.8 0.1l-9.6-9.6c-1.1-1-1.2-2.7-0.2-3.8s2.7-1.2 3.8-0.2l7.7 7.7 12.6-15.9v0z"/>
                                </g>
                            </svg>
                        </button> :
                        <button id={"edite-query"} onClick={handleEdite} title={t("chart:query", {context: "edit"})}>
                            <svg id={"edite-query"} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                                <path id={"edite-query"}
                                      d="m28.3 3.2-1.5-1.5c-1-1-2.5-1-3.5 0l-3.4 3.4-16.4 16.5-1.7 4.4-0.7 1.8c-0.3 0.7 0.4 1.4 1.1 1.1l1.8-0.7 4.5-1.7 19.8-19.8c0.9-1 0.9-2.5 0-3.5z"/>
                            </svg>
                        </button>}
                </div>
            </div>
        </div>
    )
}

export {QueryShow}
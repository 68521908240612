export const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
}

export const getPosDivEntity = () => {
    let divBanner = document.getElementById('banner-container')
    return divBanner.getBoundingClientRect().height + 30
}

export const BALISE_BUTTON_INFO = <img src={require('../../img/information.png')}/>
export const BALISE_BUTTON_PDF = <img src={require('../../img/pdf.png')}/>
export const BALISE_BUTTON_SIGNATURE = <img src={require('../../img/signature.png')}/>